import React from "react";
import styles from "./logo.module.css";

type Variant = "default" | "navbar" | "white";

/**
 * BossFotos logo
 * @returns
 */
const BossFotos = ({
  variant,
  width,
  height,
  alt,
}: {
  variant: Variant;
  width?: number;
  height?: number;
  alt?: string;
}) => {
  let w = width;
  let h = height;

  if (h !== undefined && w) {
    w = h * 5.7;
  }

  if (w !== undefined && h) {
    h = w * 0.1754;
  }

  const style = styles[variant];

  return (
    <div title={alt}>
      <svg
        version="1.1"
        id="svg1"
        width={w}
        height={h}
        viewBox="0 0 1073.7235 188.42718"
      >
        <defs id="defs1">
          <clipPath clipPathUnits="userSpaceOnUse" id="clipPath3">
            <path
              d="M 0,166.35 H 841.89 V 0 H 0 Z"
              transform="translate(-515.47271,-105.5624)"
              id="path3"
            />
          </clipPath>
          <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5">
            <path
              d="M 0,166.35 H 841.89 V 0 H 0 Z"
              transform="translate(-478.70901,-83.095105)"
              id="path5"
            />
          </clipPath>
          <clipPath clipPathUnits="userSpaceOnUse" id="clipPath7">
            <path
              d="M 0,166.35 H 841.89 V 0 H 0 Z"
              transform="translate(-528.53032,-78.907104)"
              id="path7"
            />
          </clipPath>
          <clipPath clipPathUnits="userSpaceOnUse" id="clipPath9">
            <path
              d="M 0,166.35 H 841.89 V 0 H 0 Z"
              transform="translate(-514.29202,-17.5878)"
              id="path9"
            />
          </clipPath>
          <clipPath clipPathUnits="userSpaceOnUse" id="clipPath11">
            <path
              d="M 0,166.35 H 841.89 V 0 H 0 Z"
              transform="translate(-492.98241,-62.787002)"
              id="path11"
            />
          </clipPath>
          <clipPath clipPathUnits="userSpaceOnUse" id="clipPath13">
            <path
              d="M 0,166.35 H 841.89 V 0 H 0 Z"
              transform="translate(-518.99902,-103.4261)"
              id="path13"
            />
          </clipPath>
          <clipPath clipPathUnits="userSpaceOnUse" id="clipPath15">
            <path
              d="M 0,166.35 H 841.89 V 0 H 0 Z"
              transform="translate(-477.65231,-39.983798)"
              id="path15"
            />
          </clipPath>
          <clipPath clipPathUnits="userSpaceOnUse" id="clipPath17">
            <path
              d="M 0,166.35 H 841.89 V 0 H 0 Z"
              transform="translate(-68.869102,-35.691301)"
              id="path17"
            />
          </clipPath>
          <clipPath clipPathUnits="userSpaceOnUse" id="clipPath19">
            <path
              d="M 0,166.35 H 841.89 V 0 H 0 Z"
              transform="translate(-174.8584,-35.317298)"
              id="path19"
            />
          </clipPath>
          <clipPath clipPathUnits="userSpaceOnUse" id="clipPath21">
            <path
              d="M 0,166.35 H 841.89 V 0 H 0 Z"
              transform="translate(-295.4272,-63.544299)"
              id="path21"
            />
          </clipPath>
          <clipPath clipPathUnits="userSpaceOnUse" id="clipPath23">
            <path
              d="M 0,166.35 H 841.89 V 0 H 0 Z"
              transform="translate(-378.23781,-63.544299)"
              id="path23"
            />
          </clipPath>
          <clipPath clipPathUnits="userSpaceOnUse" id="clipPath25">
            <path
              d="M 0,166.35 H 841.89 V 0 H 0 Z"
              transform="translate(-414.24071,-132.2411)"
              id="path25"
            />
          </clipPath>
          <clipPath clipPathUnits="userSpaceOnUse" id="clipPath27">
            <path
              d="M 0,166.35 H 841.89 V 0 H 0 Z"
              transform="translate(-614.42192,-37.945703)"
              id="path27"
            />
          </clipPath>
          <clipPath clipPathUnits="userSpaceOnUse" id="clipPath29">
            <path
              d="M 0,166.35 H 841.89 V 0 H 0 Z"
              transform="translate(-693.40232,-35.317298)"
              id="path29"
            />
          </clipPath>
          <clipPath clipPathUnits="userSpaceOnUse" id="clipPath31">
            <path
              d="M 0,166.35 H 841.89 V 0 H 0 Z"
              transform="translate(-813.97075,-63.544299)"
              id="path31"
            />
          </clipPath>
        </defs>
        <g id="g1" transform="translate(-25.277468,-18.186533)">
          <g id="group-MC0">
            <path
              id="path2"
              d="m 0,0 c -25.319,-0.068 -45.117,-20.468 -45.134,-44.906 -0.017,-24.869 20.142,-45.036 45.057,-45.045 24.876,-0.008 45.106,20.117 45.101,44.929 C 45.02,-20.212 25.008,-0.109 0,0 m 48.313,-45.013 c -0.068,-27.221 -22.2,-48.557 -48.557,-48.447 -26.499,0.111 -48.19,21.735 -48.179,48.509 0.012,26.572 21.527,48.465 48.395,48.438 26.733,-0.027 48.355,-21.727 48.341,-48.5"
              className={style}
              style={{
                fillOpacity: 1,
                fillRule: "nonzero",
                stroke: "none",
              }}
              transform="matrix(1.3333333,0,0,-1.3333333,687.29693,81.050133)"
              clipPath="url(#clipPath3)"
            />
            <path
              id="path4"
              d="m 0,0 c 7.895,-13.674 15.69,-27.178 23.552,-40.794 -0.386,-0.022 -0.635,-0.048 -0.883,-0.048 -8.044,-0.003 -16.088,0.005 -24.132,-0.016 -0.595,-0.001 -0.86,0.218 -1.088,0.735 -1.84,4.185 -3.032,8.536 -3.501,13.088 -0.85,8.26 0.545,16.115 4.199,23.567 C -1.306,-2.354 -0.684,-1.275 0,0"
              className={style}
              style={{
                fillOpacity: 1,
                fillRule: "nonzero",
                stroke: "none",
              }}
              transform="matrix(1.3333333,0,0,-1.3333333,638.27867,111.00653)"
              clipPath="url(#clipPath5)"
            />
            <path
              id="path6"
              d="m 0,0 h 0.975 c 7.994,0 15.987,-0.008 23.98,0.016 0.649,0.002 0.948,-0.208 1.203,-0.789 1.707,-3.896 2.873,-7.938 3.379,-12.166 1.15,-9.615 -0.655,-18.63 -5.49,-27.029 -0.138,-0.238 -0.292,-0.467 -0.504,-0.805 C 15.678,-27.151 7.88,-13.646 0,0"
              className={style}
              style={{
                fillOpacity: 1,
                fillRule: "nonzero",
                stroke: "none",
              }}
              transform="matrix(1.3333333,0,0,-1.3333333,704.70707,116.59053)"
              clipPath="url(#clipPath7)"
            />
            <path
              id="path8"
              d="M 0,0 C 7.882,13.652 15.68,27.158 23.544,40.776 27.885,33.258 32.149,25.874 36.435,18.453 32.439,12.753 27.396,8.255 21.264,4.997 14.674,1.497 7.632,-0.132 0,0"
              className={style}
              style={{
                fillOpacity: 1,
                fillRule: "nonzero",
                stroke: "none",
              }}
              transform="matrix(1.3333333,0,0,-1.3333333,685.72267,198.3496)"
              clipPath="url(#clipPath9)"
            />
            <path
              id="path10"
              d="m 0,0 c -4.343,7.524 -8.604,14.907 -12.886,22.326 4.036,5.759 9.127,10.279 15.323,13.535 6.551,3.442 13.536,5.077 21.096,4.896 C 15.678,27.153 7.879,13.646 0,0"
              className={style}
              style={{
                fillOpacity: 1,
                fillRule: "nonzero",
                stroke: "none",
              }}
              transform="matrix(1.3333333,0,0,-1.3333333,657.30987,138.084)"
              clipPath="url(#clipPath11)"
            />
            <path
              id="path12"
              d="m 0,0 c 7.376,-0.639 14.104,-2.969 20.218,-7.042 5.866,-3.906 10.488,-8.963 13.954,-15.219 H -12.851 C -8.562,-14.832 -4.305,-7.458 0,0"
              className={style}
              style={{
                fillOpacity: 1,
                fillRule: "nonzero",
                stroke: "none",
              }}
              transform="matrix(1.3333333,0,0,-1.3333333,691.99867,83.898533)"
              clipPath="url(#clipPath13)"
            />
            <path
              id="path14"
              d="M 0,0 C 15.702,0 31.277,0 46.854,0 46.745,-0.598 36.368,-18.768 34.146,-22.242 27.717,-21.706 21.676,-19.815 16.077,-16.53 9.236,-12.516 3.921,-7.016 0,0"
              className={style}
              style={{
                fillOpacity: 1,
                fillRule: "nonzero",
                stroke: "none",
              }}
              transform="matrix(1.3333333,0,0,-1.3333333,636.86973,168.48827)"
              clipPath="url(#clipPath15)"
            />
            <path
              id="path16"
              d="m 0,0 c 3.365,0 6.511,0.683 9.441,2.056 2.926,1.369 5.514,3.207 7.758,5.514 2.242,2.305 3.986,5.016 5.233,8.132 1.245,3.114 1.87,6.479 1.87,10.095 0,3.487 -0.625,6.822 -1.87,10 -1.247,3.178 -2.991,5.918 -5.233,8.225 -2.244,2.305 -4.832,4.143 -7.758,5.515 -2.93,1.37 -6.076,2.056 -9.441,2.056 -3.364,0 -6.513,-0.468 -9.439,-1.402 -2.931,-0.934 -5.486,-2.43 -7.665,-4.486 -2.182,-2.057 -3.896,-4.738 -5.141,-8.038 -1.247,-3.304 -1.869,-7.384 -1.869,-12.245 0,-4.363 0.622,-8.131 1.869,-11.309 1.245,-3.178 2.959,-5.795 5.141,-7.851 2.179,-2.057 4.734,-3.616 7.665,-4.674 C -6.513,0.528 -3.364,0 0,0 m 3.552,74.585 c 6.479,0 12.524,-1.279 18.132,-3.831 5.608,-2.556 10.469,-6.046 14.581,-10.469 4.113,-4.424 7.319,-9.597 9.628,-15.515 2.304,-5.921 3.458,-12.245 3.458,-18.973 0,-6.73 -1.154,-13.056 -3.458,-18.974 -2.309,-5.921 -5.515,-11.094 -9.628,-15.515 -4.112,-4.426 -8.973,-7.916 -14.581,-10.469 -5.608,-2.552 -11.653,-3.832 -18.132,-3.832 -5.485,0 -10.749,0.967 -15.796,2.897 -5.047,1.931 -9.005,4.394 -11.87,7.384 l -3.739,-8.038 h -22.058 v 136.461 h 25.797 V 64.492 c 7.104,6.729 16.324,10.093 27.666,10.093"
              className={style}
              style={{
                fillOpacity: 1,
                fillRule: "nonzero",
                stroke: "none",
              }}
              transform="matrix(1.3333333,0,0,-1.3333333,91.825467,174.2116)"
              clipPath="url(#clipPath17)"
            />
            <path
              id="path18"
              d="m 0,0 c 3.113,0 6.01,0.622 8.692,1.869 2.678,1.244 5.047,2.96 7.103,5.141 2.057,2.178 3.674,4.796 4.86,7.851 1.184,3.052 1.777,6.45 1.777,10.187 0,3.74 -0.593,7.226 -1.777,10.469 -1.186,3.239 -2.803,6.076 -4.86,8.505 -2.056,2.43 -4.425,4.362 -7.103,5.795 -2.682,1.432 -5.579,2.15 -8.692,2.15 -3.117,0 -6.046,-0.718 -8.787,-2.15 -2.742,-1.433 -5.14,-3.365 -7.196,-5.795 -2.057,-2.429 -3.678,-5.266 -4.86,-8.505 -1.187,-3.243 -1.777,-6.729 -1.777,-10.469 0,-3.737 0.59,-7.135 1.777,-10.187 1.182,-3.055 2.803,-5.673 4.86,-7.851 2.056,-2.181 4.454,-3.897 7.196,-5.141 C -6.046,0.622 -3.117,0 0,0 m 0,74.959 c 6.603,0 12.833,-1.279 18.692,-3.831 5.857,-2.556 10.966,-6.046 15.329,-10.469 4.361,-4.424 7.819,-9.597 10.375,-15.515 2.553,-5.921 3.832,-12.245 3.832,-18.973 0,-6.73 -1.279,-13.056 -3.832,-18.974 C 41.84,1.276 38.382,-3.897 34.021,-8.318 29.658,-12.744 24.549,-16.234 18.692,-18.787 12.833,-21.339 6.603,-22.619 0,-22.619 c -6.73,0 -13.024,1.28 -18.881,3.832 -5.859,2.553 -10.968,6.043 -15.328,10.469 -4.364,4.421 -7.822,9.594 -10.375,15.515 -2.556,5.918 -3.832,12.244 -3.832,18.974 0,6.728 1.276,13.052 3.832,18.973 2.553,5.918 6.011,11.091 10.375,15.515 4.36,4.423 9.469,7.913 15.328,10.469 5.857,2.552 12.151,3.831 18.881,3.831"
              className={style}
              style={{
                fillOpacity: 1,
                fillRule: "nonzero",
                stroke: "none",
              }}
              transform="matrix(1.3333333,0,0,-1.3333333,233.14453,174.71027)"
              clipPath="url(#clipPath19)"
            />
            <path
              id="path20"
              d="m 0,0 c 6.852,-4.861 10.282,-11.777 10.282,-20.75 0,-4.113 -0.967,-7.977 -2.898,-11.59 -1.934,-3.616 -4.58,-6.794 -7.945,-9.533 -3.364,-2.743 -7.29,-4.893 -11.776,-6.449 -4.487,-1.557 -9.286,-2.337 -14.394,-2.337 -3.865,0 -7.635,0.5 -11.31,1.495 -3.676,0.997 -7.074,2.366 -10.187,4.112 -3.117,1.745 -5.827,3.833 -8.131,6.263 -2.308,2.431 -4.02,5.141 -5.141,8.132 2.864,1.37 6.043,2.897 9.533,4.579 3.488,1.682 6.543,3.208 9.159,4.58 1.122,-1.869 2.866,-3.584 5.235,-5.14 2.365,-1.56 5.608,-2.337 9.72,-2.337 4.235,0 7.353,0.996 9.347,2.991 1.992,1.992 2.99,3.861 2.99,5.608 0,1.244 -0.686,2.397 -2.055,3.458 -1.373,1.058 -2.929,1.899 -4.674,2.524 -2.494,0.996 -4.55,1.524 -6.169,1.589 -1.621,0.061 -3.49,0.403 -5.607,1.028 -6.73,1.618 -11.871,3.768 -15.422,6.449 -3.552,2.678 -6.14,5.389 -7.759,8.131 -1.62,2.741 -2.523,5.358 -2.71,7.851 -0.187,2.492 -0.28,4.487 -0.28,5.983 0,3.987 0.87,7.786 2.617,11.403 1.744,3.612 4.142,6.759 7.197,9.439 3.052,2.679 6.604,4.829 10.655,6.45 4.048,1.618 8.38,2.43 12.992,2.43 3.861,0 7.539,-0.561 11.029,-1.683 3.488,-1.121 6.665,-2.68 9.534,-4.673 2.865,-1.995 5.327,-4.363 7.384,-7.104 2.055,-2.742 3.58,-5.794 4.579,-9.159 l -20.002,-6.73 c -0.748,2.244 -2.275,4.049 -4.579,5.422 -2.308,1.369 -4.767,2.056 -7.384,2.056 -2.618,0 -4.738,-0.874 -6.356,-2.617 -1.621,-1.746 -2.43,-3.491 -2.43,-5.234 0,-2.121 0.777,-3.771 2.336,-4.954 1.557,-1.186 3.271,-2.027 5.141,-2.524 1.37,-0.251 2.71,-0.467 4.019,-0.654 1.308,-0.187 2.585,-0.406 3.833,-0.654 4.36,-0.999 8.131,-2.121 11.309,-3.365 C -5.14,3.239 -2.369,1.743 0,0"
              className={style}
              style={{
                fillOpacity: 1,
                fillRule: "nonzero",
                stroke: "none",
              }}
              transform="matrix(1.3333333,0,0,-1.3333333,393.90293,137.07427)"
              clipPath="url(#clipPath21)"
            />
            <path
              id="path22"
              d="m 0,0 c 6.852,-4.861 10.282,-11.777 10.282,-20.75 0,-4.113 -0.967,-7.977 -2.898,-11.59 -1.934,-3.616 -4.58,-6.794 -7.945,-9.533 -3.364,-2.743 -7.29,-4.893 -11.776,-6.449 -4.487,-1.557 -9.286,-2.337 -14.394,-2.337 -3.865,0 -7.635,0.5 -11.31,1.495 -3.676,0.997 -7.074,2.366 -10.187,4.112 -3.117,1.745 -5.827,3.833 -8.131,6.263 -2.308,2.431 -4.02,5.141 -5.141,8.132 2.864,1.37 6.043,2.897 9.533,4.579 3.488,1.682 6.543,3.208 9.159,4.58 1.122,-1.869 2.866,-3.584 5.235,-5.14 2.365,-1.56 5.608,-2.337 9.721,-2.337 4.234,0 7.352,0.996 9.346,2.991 1.992,1.992 2.99,3.861 2.99,5.608 0,1.244 -0.686,2.397 -2.055,3.458 -1.373,1.058 -2.929,1.899 -4.674,2.524 -2.494,0.996 -4.55,1.524 -6.169,1.589 -1.621,0.061 -3.49,0.403 -5.607,1.028 -6.73,1.618 -11.871,3.768 -15.422,6.449 -3.552,2.678 -6.14,5.389 -7.759,8.131 -1.62,2.741 -2.523,5.358 -2.71,7.851 -0.187,2.492 -0.28,4.487 -0.28,5.983 0,3.987 0.87,7.786 2.617,11.403 1.744,3.612 4.142,6.759 7.197,9.439 3.052,2.679 6.604,4.829 10.655,6.45 4.048,1.618 8.38,2.43 12.992,2.43 3.861,0 7.539,-0.561 11.029,-1.683 3.488,-1.121 6.665,-2.68 9.534,-4.673 2.865,-1.995 5.327,-4.363 7.384,-7.104 2.055,-2.742 3.58,-5.794 4.579,-9.159 l -20.002,-6.73 c -0.748,2.244 -2.275,4.049 -4.579,5.422 -2.308,1.369 -4.767,2.056 -7.384,2.056 -2.618,0 -4.738,-0.874 -6.356,-2.617 -1.621,-1.746 -2.43,-3.491 -2.43,-5.234 0,-2.121 0.777,-3.771 2.337,-4.954 1.556,-1.186 3.27,-2.027 5.14,-2.524 1.37,-0.251 2.711,-0.467 4.019,-0.654 1.309,-0.187 2.585,-0.406 3.833,-0.654 4.36,-0.999 8.131,-2.121 11.309,-3.365 C -5.14,3.239 -2.369,1.743 0,0"
              className={style}
              style={{
                fillOpacity: 1,
                fillRule: "nonzero",
                stroke: "none",
              }}
              transform="matrix(1.3333333,0,0,-1.3333333,504.31707,137.07427)"
              clipPath="url(#clipPath23)"
            />
            <path
              id="path24"
              d="m 0,0 c 1.945,4.674 4.547,8.536 7.813,11.59 3.265,3.053 7.026,5.296 11.288,6.73 4.262,1.431 8.76,2.149 13.498,2.149 2.864,0 5.731,-0.251 8.599,-0.747 2.865,-0.499 5.731,-1.247 8.599,-2.243 l -2.056,-18.88 -6.17,0.934 c -0.998,0.123 -1.9,0.216 -2.71,0.28 -0.812,0.062 -1.589,0.094 -2.336,0.094 -4.737,0 -8.197,-1.154 -10.376,-3.458 -2.182,-2.307 -3.271,-5.64 -3.271,-10.001 V -24.581 H 47.741 V -47.574 H 22.878 V -117.3 H -2.918 v 69.726 h -12.151 v 13.647 l 12.151,9.346 v 7.851 c 0,6.479 0.972,12.058 2.918,16.73"
              className={style}
              style={{
                fillOpacity: 1,
                fillRule: "nonzero",
                stroke: "none",
              }}
              transform="matrix(1.3333333,0,0,-1.3333333,552.32093,45.478533)"
              clipPath="url(#clipPath25)"
            />
            <path
              id="path26"
              d="m 0,0 c 2.679,-2.377 6.199,-3.563 10.563,-3.563 3.487,0 6.977,0.684 10.468,2.056 v -21.526 c -3.117,-0.988 -6.421,-1.823 -9.908,-2.503 -3.491,-0.678 -6.979,-1.02 -10.469,-1.02 -3.989,0 -7.79,0.5 -11.401,1.496 -3.617,0.998 -6.856,2.71 -9.721,5.14 -2.868,2.431 -5.142,5.67 -6.824,9.721 -1.683,4.048 -2.523,9.066 -2.523,15.048 v 41.873 h -13.273 v 17.384 l 13.273,5.608 v 20.75 h 25.796 v -20.75 h 25.05 V 46.722 H -4.019 V 10.133 C -4.019,5.754 -2.681,2.378 0,0"
              className={style}
              style={{
                fillOpacity: 1,
                fillRule: "nonzero",
                stroke: "none",
              }}
              transform="matrix(1.3333333,0,0,-1.3333333,819.2292,171.20573)"
              clipPath="url(#clipPath27)"
            />
            <path
              id="path28"
              d="m 0,0 c 3.113,0 6.01,0.622 8.69,1.869 2.68,1.244 5.049,2.96 7.105,5.141 2.057,2.178 3.674,4.796 4.86,7.851 1.184,3.052 1.776,6.45 1.776,10.187 0,3.74 -0.592,7.226 -1.776,10.469 -1.186,3.239 -2.803,6.076 -4.86,8.505 -2.056,2.43 -4.425,4.362 -7.105,5.795 -2.68,1.432 -5.577,2.15 -8.69,2.15 -3.117,0 -6.048,-0.718 -8.787,-2.15 -2.743,-1.433 -5.14,-3.365 -7.195,-5.795 -2.057,-2.429 -3.678,-5.266 -4.863,-8.505 -1.185,-3.243 -1.775,-6.729 -1.775,-10.469 0,-3.737 0.59,-7.135 1.775,-10.187 1.185,-3.055 2.806,-5.673 4.863,-7.851 2.055,-2.181 4.452,-3.897 7.195,-5.141 C -6.048,0.622 -3.117,0 0,0 m 0,74.959 c 6.604,0 12.834,-1.279 18.692,-3.831 5.857,-2.556 10.965,-6.046 15.329,-10.469 4.361,-4.424 7.819,-9.597 10.375,-15.515 2.552,-5.921 3.832,-12.245 3.832,-18.973 0,-6.73 -1.28,-13.056 -3.832,-18.974 C 41.84,1.276 38.382,-3.897 34.021,-8.318 29.657,-12.744 24.549,-16.234 18.692,-18.787 12.834,-21.339 6.604,-22.619 0,-22.619 c -6.73,0 -13.025,1.28 -18.882,3.832 -5.858,2.553 -10.967,6.043 -15.328,10.469 -4.363,4.421 -7.821,9.594 -10.374,15.515 -2.556,5.918 -3.833,12.244 -3.833,18.974 0,6.728 1.277,13.052 3.833,18.973 2.553,5.918 6.011,11.091 10.374,15.515 4.361,4.423 9.47,7.913 15.328,10.469 5.857,2.552 12.152,3.831 18.882,3.831"
              className={style}
              style={{
                fillOpacity: 1,
                fillRule: "nonzero",
                stroke: "none",
              }}
              transform="matrix(1.3333333,0,0,-1.3333333,924.5364,174.71027)"
              clipPath="url(#clipPath29)"
            />
            <path
              id="path30"
              d="m 0,0 c 6.852,-4.861 10.28,-11.777 10.28,-20.75 0,-4.113 -0.966,-7.977 -2.897,-11.59 -1.934,-3.616 -4.579,-6.794 -7.944,-9.533 -3.366,-2.743 -7.291,-4.893 -11.777,-6.449 -4.485,-1.557 -9.285,-2.337 -14.393,-2.337 -3.866,0 -7.636,0.5 -11.31,1.495 -3.678,0.997 -7.073,2.366 -10.188,4.112 -3.116,1.745 -5.828,3.833 -8.131,6.263 -2.307,2.431 -4.021,5.141 -5.142,8.132 2.866,1.37 6.043,2.897 9.534,4.579 3.488,1.682 6.544,3.208 9.16,4.58 1.121,-1.869 2.865,-3.584 5.234,-5.14 2.365,-1.56 5.607,-2.337 9.721,-2.337 4.235,0 7.352,0.996 9.345,2.991 1.994,1.992 2.992,3.861 2.992,5.608 0,1.244 -0.686,2.397 -2.057,3.458 -1.372,1.058 -2.928,1.899 -4.673,2.524 -2.494,0.996 -4.55,1.524 -6.168,1.589 -1.621,0.061 -3.49,0.403 -5.608,1.028 -6.73,1.618 -11.87,3.768 -15.422,6.449 -3.552,2.678 -6.139,5.389 -7.757,8.131 -1.622,2.741 -2.524,5.358 -2.71,7.851 -0.188,2.492 -0.282,4.487 -0.282,5.983 0,3.987 0.872,7.786 2.618,11.403 1.742,3.612 4.141,6.759 7.196,9.439 3.053,2.679 6.604,4.829 10.655,6.45 4.048,1.618 8.38,2.43 12.993,2.43 3.86,0 7.538,-0.561 11.029,-1.683 3.487,-1.121 6.664,-2.68 9.532,-4.673 2.866,-1.995 5.329,-4.363 7.385,-7.104 2.056,-2.742 3.58,-5.794 4.579,-9.159 l -20.001,-6.73 c -0.747,2.244 -2.275,4.049 -4.581,5.422 -2.307,1.369 -4.766,2.056 -7.383,2.056 -2.616,0 -4.737,-0.874 -6.356,-2.617 -1.62,-1.746 -2.43,-3.491 -2.43,-5.234 0,-2.121 0.776,-3.771 2.336,-4.954 1.558,-1.186 3.272,-2.027 5.142,-2.524 1.369,-0.251 2.71,-0.467 4.018,-0.654 1.309,-0.187 2.586,-0.406 3.832,-0.654 4.361,-0.999 8.132,-2.121 11.31,-3.365 C -5.142,3.239 -2.369,1.743 0,0"
              className={style}
              style={{
                fillOpacity: 1,
                fillRule: "nonzero",
                stroke: "none",
              }}
              transform="matrix(1.3333333,0,0,-1.3333333,1085.2943,137.07427)"
              clipPath="url(#clipPath31)"
            />
          </g>
        </g>
      </svg>
    </div>
  );
};

export default BossFotos;

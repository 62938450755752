import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    Box,
    Typography,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    withStyles,
    Checkbox,
    TextField,
    Button,
    CircularProgress,
} from "@material-ui/core";
import { CurrencyValue } from '../../v2_components/CurrencyValue'
import { getUserCountry } from '../../actions/user'
import { countryList } from '../../v2_actions/country'
import { green } from "@material-ui/core/colors";
import { sendOrderMailApi } from "../../apis/bookboss";
import { getPriceByphotoGrapherId, getDefaultPrice } from "../../actions/gallery";
import style from "./styles";

const useStyles = makeStyles(style);

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#8080802b",
        color: "black",
        height: 40,
        fontSize: 20,
    },
    body: {
        fontSize: 16,
        height: 70,
    },
}))(TableCell);

const OrderTable = ({ setOrderModal, photoId, photographer_ref }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const country_id = useSelector((state) => getUserCountry(state))
    const country = useSelector(() => countryList.getCountryCode(country_id))
    const [showSpinner, setShowSpinner] = useState(false);
    const defaultValue = useSelector((state) => state.galleryReducer.defaultValue)
    const photographerPrice = useSelector((state) => state.galleryReducer.priceByPid)
    
    const calcMarkUpPrice = (percent, value) => {
        if (percent && value) {
            const int_percent = parseInt(percent)
            const rate = 1 + int_percent / 100
            const rate_adjusted_value = parseInt(value) * rate
            return <CurrencyValue value={rate_adjusted_value} country={photographerPrice.country} />
        } else {
            return null
        }
    }
    
    const orderArray = [
        {
            price: calcMarkUpPrice(
                photographerPrice.print_markup_percentage,
                defaultValue.a4_price
            ),
            itemSize: defaultValue.a4_name,
            description: "A5 (4x6 inch)",
        },
        {
            price: calcMarkUpPrice(
                photographerPrice.print_markup_percentage,
                defaultValue.a3_price
            ),
            itemSize: defaultValue.a3_name,
            description: "A4 (6x8 inch)",
        },
        {
            price: calcMarkUpPrice(
                photographerPrice.print_markup_percentage,
                defaultValue.a2_price
            ),
            itemSize: defaultValue.a2_name,
            description: "A3(6x9 inch)",
        },
        {
            price: calcMarkUpPrice(
                photographerPrice.print_markup_percentage,
                defaultValue.a1_price
            ),
            itemSize: defaultValue.a1_name,
            description: "A2 (8x12 inch)",
        },
        {
            price: calcMarkUpPrice(
                photographerPrice.print_markup_percentage,
                defaultValue.a0_price
            ),
            itemSize: defaultValue.a0_name,
            description: "A1",
        },
    ];
    const [checkState, setCheckState] = useState({ checkItems: [] });
    const [quanity, setQuantity] = useState({ selectQuantity: [] });

    useEffect(() => {
        dispatch(getPriceByphotoGrapherId(photographer_ref));
        dispatch(getDefaultPrice(country));
    }, [photographer_ref])

    const checkHandle = (ind) => {
        let checkItems = checkState.checkItems.slice(0)
        checkItems[ind] = !checkItems[ind]
        setCheckState({ checkItems })
    };

    const changeQuantity = (e, ind) => {
        let selectQuantity = quanity.selectQuantity.slice(0)
        selectQuantity[ind] = e.target.value
        setQuantity({ selectQuantity })
    };

    const handleSubmit = () => {
        setShowSpinner(true);
        let html_mail_content =
            "<html><table border='1px'><thead><tr><td>PhotoId</td><td>Price</td><td>Description</td><td>Quantity</td></tr></thead><tbody>";
        // let html_mail_content =
        //   "<html><table border='1px'><thead><tr><td>PhotoId</td><td>Price</td><td>Item Size</td><td>Description</td><td>Quantity</td></tr></thead><tbody>";
        let tbody = "";
        let tend = "</tbody></table></html>";
        // eslint-disable-next-line
        orderArray.filter((item, ind) => {
            if (checkState.checkItems[ind] === true) {
                tbody += `<tr>`;
                tbody += `<td>${photoId}</td>`;
                tbody += `<td>${item.price}</td>`;
                // tbody += `<td>${item.itemSize}</td>`;
                tbody += `<td>${item.itemSize}</td>`;
                tbody += `<td>${quanity.selectQuantity[ind]}</td>`;
                tbody += `</tr>`;
                return tbody;
            }
        })
        html_mail_content = html_mail_content + tbody + tend;
        const sendMailData = {
            customer_name: localStorage.getItem("auth_name"),
            email: localStorage.getItem("auth_email"),
            mail_content: html_mail_content,
        };
        sendOrderMailApi(sendMailData).then((res) => {
            if (res.data) {
                setOrderModal(false);
                setShowSpinner(true);
            }
        });
    };

    return (
        <Box width="100%" position="releative">
          {showSpinner && (
              <CircularProgress
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    width: 60,
                    height: 60,
                    color: green[500],
                    zIndex: 999,
                }}
              />
          )}
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Price</StyledTableCell>
                {/* <StyledTableCell align="center">Item Size</StyledTableCell> */}
                <StyledTableCell align="center">Description</StyledTableCell>
                <StyledTableCell align="center">Quantity</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orderArray.map((item, ind) => (
                  <TableRow hover key={`tablebody-${ind}`}>
                    <TableCell align="center">
                      <Typography color="textPrimary" variant="body1">
                        <Checkbox onClick={() => checkHandle(ind)} />
                        {item.price}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography color="textPrimary" variant="body1">
                        {item.itemSize}
                      </Typography>
                    </TableCell>
                    {/* <TableCell align="center">{item.description}</TableCell> */}
                    <TableCell align="center">
                      <TextField
                        variant="outlined"
                        value={quanity.selectQuantity[ind]}
                        onChange={(e) => changeQuantity(e, ind)}
                      />
                    </TableCell>
                  </TableRow>
              ))}
            </TableBody>
          </Table>
          <Box display="flex" justifyContent="center">
            <Button className={classes.submitbutton} onClick={handleSubmit}>
              Submit Request
            </Button>
          </Box>
        </Box>
    );
};

export default OrderTable;

import React, { useState, useEffect } from "react"
import {
  Grid,
  Card,
  TextField,
  Box,
  Container,
  // Button,
  // CircularProgress,
  InputAdornment,
  IconButton,
} from "@material-ui/core"
import LinkIcon from "@material-ui/icons/Link"
// import { green } from "@material-ui/core/colors"
import { makeStyles } from "@material-ui/core/styles"
import TopBar from "./topbar"
import SideBar from "../../layout/sidebar"
import CustomSnackBar from "../components/SnackBar"
// import { sendMailApi } from "../../apis/bookboss"
import styles from "./styles"
import { useIsMobile } from "../../actions/ui"

const useStyles = makeStyles(styles)

const Help = () => {
  const classes = useStyles()
  const isMobile = useIsMobile()
  // const [showSpinner, setShowSpinner] = useState(false)
  const [showSideBar, setShowSideBar] = useState(false)
  // const [helpValue, setHelpValue] = useState("")
    const [width, setWidth] = useState(window.innerWidth)
    
  const [snackValue, setSnackValue] = useState({
    openSnackBar: false,
    message: "",
    type: "",
  })
  const [showState, setShowState] = useState({ showItems: [] })
  // const userEmail = localStorage.getItem("auth_email")
  // const userName = localStorage.getItem("auth_name")

  useEffect(() => {
    window.addEventListener("resize", updateWidth)
    return () => window.removeEventListener("resize", updateWidth)
  })

  const updateWidth = () => {
    setWidth(window.innerWidth)
  }

  // const sendQuestion = () => {
  //   if (!helpValue) {
  //     setSnackValue({
  //       ...snackValue,
  //       openSnackBar: true,
  //       message: "Please fill the Question field!",
  //       type: "error",
  //     })
  //   } else {
  //     setShowSpinner(true)
  //     const sendMailData = {
  //       email: userEmail,
  //       customer_name: userName,
  //       mail_type: "help",
  //       mail_content: helpValue,
  //     }
  //     sendMailApi(sendMailData).then((res) => {
  //       setHelpValue("")
  //       setShowSpinner(false)
  //       setSnackValue({
  //         ...snackValue,
  //         openSnackBar: true,
  //         message: res.data,
  //         type: "success",
  //       })
  //     })
  //   }
  // }

  const handleList = (index) => {
    let showItems = showState.showItems.slice(0)
    showItems[index] = !showItems[index]
    // eslint-disable-next-line 
    showItems.map((item, ind) => {
      if (ind !== index) {
        showItems[ind] = false
      }
    })
    setShowState({ showItems })
  }

  return (
    <div style={{ position: "releative" }}>
      {/* {showSpinner && (
        <CircularProgress
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            width: 60,
            height: 60,
            color: green[500],
            zIndex: 999,
          }}
        />
      )} */}
      <CustomSnackBar snackValue={snackValue} setSnackValue={setSnackValue} />
      <TopBar
        showSideBar={showSideBar}
        setShowSideBar={setShowSideBar}
        width={width}
      />
      <SideBar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
      <div
        className={!showSideBar ? classes.nonMennuWrapper : classes.menuWrapper}
        style={{ paddingTop: !isMobile ? 65 : 0 }}
      >
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Container>
              <Grid container spacing={3}>
                <Grid item xs={12} style={{ zIndex: 99 }}>
                  <TextField
                    variant="outlined"
                    type="email"
                    className={
                      !isMobile ? classes.textField : classes.mobileTextField
                    }
                    placeholder="info@bossfotos.com"
                    value="info@bossfotos.com"
                    InputProps={{
                      style: { padding: 5, height: 50 },
                      endAdornment: (
                        <InputAdornment position="end">
                          <a
                            href="mailto:info@bossfotos.com"
                            target="_blank"
                            without
                            rel="noopener noreferrer"
                          >
                            <IconButton>
                              <LinkIcon />
                            </IconButton>
                          </a>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} style={{ zIndex: 99 }}>
                  <Box display="flex" justifyContent="center">
                    <Card
                      className={
                        !isMobile ? classes.card : classes.mobileCard
                      }
                      style={!isMobile ? { padding: 30 } : { padding: 0 }}
                    >
                      <div
                        style={
                          isMobile
                            ? {
                              // height: 350,
                              // width: 250,
                              background: "rgb(235 235 235 / 85%)",
                              borderRadius: 20,
                              margin: 5,
                              overflowY: "scroll",
                              padding: 5,
                            }
                            : {
                              marginTop: 0,
                            }
                        }
                      >
                        <p>Frequently Asked Questions:</p>
                        <ul>
                          <li
                            onClick={() => handleList(0)}
                            style={{ padding: 5 }}
                          >
                            How do I find a picture of myself?
                          </li>
                          {showState.showItems[0] && (
                            <div>
                              <p>
                                Open the menu and click on “search for a
                                gallery”.{" "}
                              </p>
                              <p>
                                This will open a map. Zoom into the location you
                                think pictures were taken of you.
                              </p>
                              <p>
                                {" "}
                                Circles will appear with a picture. Each circle
                                represents a gallery of photographs.{" "}
                              </p>
                              <p>
                                By clicking on a circle the gallery will open up
                                and you can scroll through the pictures to find
                                yourself. If there are too many
                                circles/galleries narrow your choices by using
                                the filters time, photographer and activity.
                              </p>
                              <p>
                                The galleries are also listed in the box with
                                more descriptions.
                              </p>
                            </div>
                          )}
                          <li
                            onClick={() => handleList(1)}
                            style={{ padding: 5 }}
                          >
                            How does the facial recognition work?
                          </li>
                          {showState.showItems[1] && (
                            <div>
                              <p>
                                You need to upload a picture of yourself first
                                in the “edit profile” section.
                              </p>
                              <p>
                                Then go the “Search for a gallery” section and
                                click on the facial recognition button.
                              </p>
                              <p>
                                It will search the galleries for you and
                                highlight any recognized photos.
                              </p>
                              <p>
                                You will also be alerted if new photographs are
                                uploaded with your face.
                              </p>
                              <p>
                                Please note that facial recognition technology
                                is never perfect so do look in the galleries
                                yourself to see if you can find yourself.
                              </p>
                            </div>
                          )}
                          <li
                            onClick={() => handleList(2)}
                            style={{ padding: 5 }}
                          >
                            Why are there no photos for my session?
                          </li>
                          {showState.showItems[2] && (
                            <div>
                              {" "}
                              <p>
                                Maybe the photographer hasn’t uploaded them yet.
                                Give him a few hours or a day to do so and check
                                again.
                              </p>
                              <p>
                                We can never guarantee that a photographer will
                                be present for every session but you can also
                                request for a photographer to attend a session
                                in the “book a boss” page.
                              </p>
                            </div>
                          )}
                          <li
                            onClick={() => handleList(3)}
                            style={{ padding: 5 }}
                          >
                            How do I make sure there is a photographer when I go
                            and do my activity?
                          </li>
                          {showState.showItems[3] && (
                            <div>
                              <p>
                                Use the “book a boss function” to book your
                                preferred photographer.
                              </p>
                              {/* <p>
                                Terms of Service” link. We take privacy very
                                seriously and would NEVER sell your details.
                              </p> */}
                            </div>
                          )}
                          <li
                            onClick={() => handleList(4)}
                            style={{ padding: 5 }}
                          >
                            How do I remove the watermark?
                          </li>
                          {showState.showItems[4] && (
                            <div>
                              <p>
                                You can’t and it is illegal to do so. When you
                                purchase the photograph the watermark will be
                                removed for you.
                              </p>
                            </div>
                          )}
                          <li
                            onClick={() => handleList(5)}
                            style={{ padding: 5 }}
                          >
                            What does the # of days left mean?
                          </li>
                          {showState.showItems[5] && (
                            <div>
                              <p>
                                It is the number of days before the photos are
                                taken down off the site.
                              </p>
                              <p>
                                Make sure you purchase the photograph you want
                                prior to that time. Your purchased photographs
                                will not be deleted.
                              </p>
                            </div>
                          )}
                        </ul>
                      </div>
                      {/* <TextField
                        variant="standard"
                        multiline
                        className={
                          !isMobile
                            ? classes.textArea
                            : classes.mobileTextArea
                        }
                        value={helpValue}
                        onChange={(e) => setHelpValue(e.target.value)}
                        placeholder="Submit your own question:"
                        inputProps={{
                          style: {
                            padding: 5,
                            height: 200,
                            width: !isMobile ? 600 : 300,
                          },
                        }}
                      />
                      <Box m={3} display="flex" justifyContent="center">
                        <Button
                          className={classes.submitBtn}
                          onClick={sendQuestion}
                        >
                          Submit Request
                        </Button>
                      </Box> */}
                    </Card>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Help

import { http } from "../lib";
import { get } from "lodash";
import { v4 as uuidv4 } from "uuid";
import {
  announceObjectsLoaded,
  getObjectsFromState,
  announceSavingObject,
  announceNewObjectSaved,
  announceObjectSaveFailed,
  getObject,
  announceDeletingObject,
  announceObjectDeleted,
  announceObjectDeleteFailed,
  announceObjectSaved,
} from "./orm_objects_actions";

import { ORM_LIST_KEY } from ".";

import {
  getMissingItemIds,
  announceMatchingItemsLoading,
  announceMatchingItemsLoaded,
  announceMatchingItemsLoadFailed,
  announceListLoadFailed,
  announceListLoaded,
  shouldFetchList,
  announceListLoading,
  announceHandlingPost,
  announcePostCompleted,
} from "./orm_list_actions";

import {
  announceGeneralApiError,
  announceClearGeneralApiError,
} from "./orm_general_actions";

import { getSubmissionErrors } from "../v2_actions/error";

import { ThunkDispatch } from "redux-thunk";
import { sentryApi } from "../utils/o11y/sentryApi";

type State = any;
type GetState = () => State;
type Dispatch = ThunkDispatch<State, {}, any>;

let global_http_counter = 0;
export const getGlobalHttpCounter = () => {
  return global_http_counter;
};

let global_api_call_counter_list: Record<string, number> = {};

const getNextCounter = (url: string) => {
  let counter = get(global_api_call_counter_list, url) || 0;
  counter += 1;
  global_api_call_counter_list[url] = counter;
  return counter;
};

const isValidCounter = (url: string, counter: number) => {
  const actual_counter = get(global_api_call_counter_list, url) || 0;
  return counter === actual_counter;
};

export function tryFetchMatchingItems(
  listKey: string,
  entityKey: string,
  requiredItemIds: string[],
  url: string,
  fixResponse: any,
) {
  // The second half of tryFetchListAndItems, separated out for clarity
  return async (dispatch: Dispatch, getState: GetState) => {
    if (!requiredItemIds) {
      return [];
    }
    const state = getState();
    let requestFilter: { ids?: string[] } = {};
    const unmatching_item_ids = getMissingItemIds(
      state,
      requiredItemIds,
      entityKey,
    );
    if (listKey) {
      const l = state[ORM_LIST_KEY][listKey];
      if (l && l.loadingMatchingItems && unmatching_item_ids.length === 0) {
        return getObjectsFromState(state, requiredItemIds);
      }
      requestFilter = Object.assign({}, requestFilter, l && l.filter, {
        all: true,
      });
    }

    if (unmatching_item_ids.length > 0) {
      dispatch(
        announceMatchingItemsLoading(entityKey, listKey, requiredItemIds),
      );
      requestFilter.ids = unmatching_item_ids;
      return http
        .get(url || entityKey, requestFilter)
        .then(([json, response, success]) => {
          global_http_counter += 1;
          dispatch(announceClearGeneralApiError());
          if (success) {
            json = fixResponse ? fixResponse(json) : json;
            dispatch(announceMatchingItemsLoaded(entityKey, listKey));
            dispatch(announceObjectsLoaded(entityKey, json));
            return json;
          } else {
            dispatch(
              announceListLoadFailed(
                entityKey,
                listKey,
                "Failed to load list: " +
                  entityKey +
                  ": " +
                  listKey +
                  " : " +
                  json.toString(),
              ),
            );
            return [];
          }
        })
        .catch(function (error) {
          global_http_counter += 1;
          dispatch(
            announceMatchingItemsLoadFailed(
              entityKey,
              listKey,
              "Failed to load entity list: " + error,
            ),
          );
          dispatch(announceGeneralApiError(error));
        });
    } else {
      global_http_counter += 1;
      return getObjectsFromState(state, requiredItemIds);
    }
  };
}

export function tryFetchListAndItems(
  listKey: string,
  entityKey: string,
  url: string,
  fixResponse?: boolean,
  forceUpdate?: boolean,
  fetchSource?: string,
) {
  return async (dispatch: Dispatch, getState: GetState) => {
    const state = getState();

    const l = get(state, `${ORM_LIST_KEY}.${listKey}`) || {};

    let fetchPromise;
    const visibleItemIds = l.visibleItemIds;

    dispatch(announceListLoading(entityKey, listKey));

    const counter = getNextCounter(url);

    if (!forceUpdate && !shouldFetchList(state, listKey)) {
      if (visibleItemIds) {
        return dispatch(
          tryFetchMatchingItems(
            listKey,
            entityKey,
            visibleItemIds,
            url,
            fixResponse,
          ),
        );
      } else {
        return Promise.resolve([]);
      }
    } else {
      const params = Object.assign({}, l.filter, l.pagination, l.ordering);

      if (url === undefined) {
        url = entityKey + "/";
      }

      fetchSource && (params.__bfsource = encodeURIComponent(fetchSource));

      fetchPromise = http.get(url, params, {});
    }

    let json, response, success, pagination;
    try {
      [json, response, success, pagination] = await fetchPromise;
    } catch (error) {
      dispatch(
        announceListLoadFailed(
          entityKey,
          listKey,
          "Failed to load list: " + entityKey + ": " + listKey + " : " + error,
        ),
      );
      throw error;
    }

    if (!isValidCounter(url, counter)) {
      return null;
    }

    if (!success) {
      dispatch(announceListLoadFailed(entityKey, listKey, json.errors));
    } else {
      dispatch(announceObjectsLoaded(entityKey, json));
      dispatch(announceListLoaded(entityKey, listKey, json, pagination));
    }
    return json;
  };
}

export function fetchListIfNeeded(
  listKey: string,
  entityKey: string,
  url: string,
  fixResponse?: boolean,
  forceUpdate?: boolean,
  fetchSource?: string,
) {
  return (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    if (forceUpdate || shouldFetchList(state, listKey)) {
      if (entityKey === undefined) {
        entityKey = state[ORM_LIST_KEY][listKey].entityKey;
      }
      return dispatch(
        tryFetchListAndItems(
          listKey,
          entityKey,
          url,
          fixResponse,
          forceUpdate,
          fetchSource,
        ),
      );
    }
    return Promise.resolve([]);
  };
}

export function ensureObjectsLoaded(
  entityKey: string,
  listKey: string,
  objectIds: string[],
  url: string,
  fixResponse?: any,
) {
  return (dispatch: Dispatch, getState: GetState) => {
    const objectIdsToLoad = getMissingItemIds(getState(), objectIds, entityKey);
    return dispatch(
      tryFetchMatchingItems(
        listKey,
        entityKey,
        objectIdsToLoad,
        url,
        fixResponse,
      ),
    );
  };
}

export function ensureObjectLoaded(
  entityKey: string,
  listKey: string,
  objectId: string,
  url: string,
  fixResponse: any,
) {
  return ensureObjectsLoaded(entityKey, listKey, [objectId], fixResponse);
}

export function saveNewObject(
  entityKey: string,
  listKey: string,
  item: any,
  { postSaveFilter }: any,
) {
  const newValues = Object.assign({}, item);
  delete newValues.isNewInstance;
  delete newValues.id;

  const unique_id = uuidv4();

  return (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    dispatch(announceSavingObject(entityKey, listKey, { id: unique_id }));

    return http
      .post(state, entityKey, newValues)
      .then(([json, response, success]) => {

        global_http_counter += 1;
        dispatch(announceClearGeneralApiError());
        if (postSaveFilter) {
          json = postSaveFilter(json);
        }

        if (success) {
          dispatch(announceNewObjectSaved(entityKey, listKey, json, unique_id));
          return json;
        } else {
          const submission_errors = getSubmissionErrors(json);
          dispatch(
            announceObjectSaveFailed(
              entityKey,
              listKey,
              Object.assign({ id: unique_id }, json),
              submission_errors,
            ),
          );
          const res = { errors: submission_errors };
          return res;
        }
      })
      .catch((json) => {
        global_http_counter += 1;
        dispatch(
          announceObjectSaveFailed(
            entityKey,
            listKey,
            { id: unique_id },
            json.errors,
          ),
        );
        let res;
        if (json.errors) {
          res = { errors: json.errors };
        } else {
          res = { errors: { _error: json.message } };
        }
        dispatch(announceGeneralApiError(res));
        return res;
      });
  };
}

export function patchObject(
  entityKey: string,
  listKey: string,
  item: any,
  { postUpdateFilter }: any,
) {
  const newValues = Object.assign({}, item);

  return (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    dispatch(announceSavingObject(entityKey, listKey, item));
    const url = `${entityKey}/${item.id}`;
    return http
      .patch(state, url, newValues)
      .then(([json, response, success]) => {
        global_http_counter += 1;
        dispatch(announceClearGeneralApiError());
        if (postUpdateFilter) {
          json = postUpdateFilter(json);
        }

        if (success) {
          dispatch(announceObjectSaved(entityKey, listKey, json));
          return json;
        } else {
          const submission_errors = getSubmissionErrors(json);
          dispatch(
            announceObjectSaveFailed(
              entityKey,
              listKey,
              Object.assign({ id: item.id }, json),
              submission_errors,
            ),
          );
          return { errors: submission_errors };
        }
      })
      .catch((json) => {
        global_http_counter += 1;
        dispatch(
          announceObjectSaveFailed(
            entityKey,
            listKey,
            Object.assign({ id: item.id, error: json.errors }),
            json.errors,
          ),
        );
        let res;
        if (json.errors) {
          res = { errors: json.errors };
        } else {
          res = { errors: { _error: json.message } };
        }
        dispatch(announceGeneralApiError(res));
        return res;
      });
  };
}
export function saveObject(
  entityKey: string,
  listKey: string,
  item: any,
  { postUpdateFilter }: any,
) {
  return (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    dispatch(announceSavingObject(entityKey, listKey, item));
    const url = `${entityKey}/${item.id}`;
    return http
      .put(state, url, item)
      .then(([json, response, success]) => {
        global_http_counter += 1;

        dispatch(announceClearGeneralApiError());
        if (postUpdateFilter) {
          json = postUpdateFilter(json);
        }

        if (success) {
          dispatch(announceObjectSaved(entityKey, listKey, json));
          return json;
        } else {
          const submission_errors = getSubmissionErrors(json);
          dispatch(
            announceObjectSaveFailed(
              entityKey,
              listKey,
              Object.assign({ id: item.id }, json),
              submission_errors,
            ),
          );

          sentryApi.error(`orm_saveobject_failed_${entityKey}`, {
            entityKey,
            listKey,
            error: JSON.stringify(submission_errors),
          });

          return { errors: submission_errors };
        }
      })
      .catch((json) => {
        global_http_counter += 1;
        dispatch(
          announceObjectSaveFailed(
            entityKey,
            listKey,
            Object.assign({ id: item.id, error: json.errors }),
            json.errors,
          ),
        );
        let res;
        if (json.errors) {
          res = { errors: json.errors };
        } else {
          res = { errors: { _error: json.message } };
        }
        dispatch(announceGeneralApiError(res));

        sentryApi.error(`orm_saveobject_error_${entityKey}`, {
          entityKey,
          listKey,
          error: JSON.stringify(res),
        });

        return res;
      });
  };
}

export function deleteObject(
  entityKey: string,
  listKey: string,
  itemId: string,
) {
  return (dispatch: Dispatch, getState: GetState) => {
    const item = getObject(getState(), entityKey, itemId);
    dispatch(announceDeletingObject(entityKey, listKey, itemId));
    if (item.isNewInstance) {
      return dispatch(announceObjectDeleted(entityKey, listKey, itemId));
    }
    return http
      .del(getState(), `${entityKey}/${itemId}`)
      .then(([json, response, success]) => {
        global_http_counter += 1;
        dispatch(announceClearGeneralApiError());
        if (success) {
          dispatch(announceObjectDeleted(entityKey, listKey, itemId));
          return json;
        } else {
          dispatch(
            announceObjectDeleteFailed(entityKey, listKey, itemId, json.errors),
          );
          return { errors: json };
        }
      })
      .catch((err) => {
        global_http_counter += 1;
        dispatch(announcePostCompleted(entityKey, listKey));
        dispatch(announceGeneralApiError(err));
        sentryApi.error(`orm_deleteobject_error_${entityKey}`, {
          entityKey,
          listKey,
          itemId,
          error: err?.message ?? err.toString(),
        });

        return { errors: err };
      });
  };
}

export function handlePost(entityKey: string, listKey: string, postData: any) {
  return (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    dispatch(announceHandlingPost(entityKey, listKey));

    return http
      .post(state, entityKey, postData)
      .then(([json, response]) => {
        global_http_counter += 1;
        dispatch(announceClearGeneralApiError());
        dispatch(announcePostCompleted(entityKey, listKey));
        return json;
      })
      .catch((err) => {
        global_http_counter += 1;
        dispatch(announcePostCompleted(entityKey, listKey));
        dispatch(announceGeneralApiError(err));
        return err;
      });
  };
}

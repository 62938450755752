import get from "lodash/get";
import head from "lodash/head";
import React, { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Input,
  Button,
  Hidden,
  Snackbar,
  Collapse,
  IconButton,
  makeStyles,
  Typography,
  InputAdornment,
} from "@material-ui/core";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import LayersIcon from "@material-ui/icons/Layers";
import CenterFocusWeakIcon from "@material-ui/icons/CenterFocusWeak";
import SearchIcon from "@material-ui/icons/Search";
import style from "./styles";
import { useIsMobile } from "../../../actions/ui";
import {
  shouldShowSearchBox,
  setShowSearchBox,
  flyToMyLocation,
  forceNewMapState,
} from "../../../v2_actions/map";

const publicIp = require("public-ip");

const useStyles = makeStyles(style);
const DEFAULT_TIME_DELAY_TO_DISPLAY_MAP_SEARCH_BOX = 6000;

const {
  StandaloneSearchBox,
} = require("react-google-maps/lib/components/places/StandaloneSearchBox");

const PlaceSearch = ({ mapTypeInd, setMapTypeInd, galleryCount }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const searchInputRef = useRef();
  const [open, setOpen] = useState(false);
  const [showFlyBtn, setShowFlyBtn] = useState(false);
  const [messageKey, setMessageKey] = useState(0);
  const [width, setWidth] = useState(window.innerWidth);
  const [showStyleBtn, setShowStyleBtn] = useState(true);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [showSearchPlaceBtn, setShowSearchPlaceBtn] = useState(true);
  const shouldShowSearchPlace = useSelector((state) =>
    shouldShowSearchBox(state),
  );

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  });

  useEffect(() => {
    if (!isMobile) {
      window.setTimeout(() => {
        dispatch(setShowSearchBox(true));
      }, DEFAULT_TIME_DELAY_TO_DISPLAY_MAP_SEARCH_BOX);
    }
  }, []);

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    if (galleryCount > 20) {
      setOpen(true);
      setMessageKey(0);
    } else if (galleryCount === false) {
      setMessageKey(1);
    } else {
      setOpen(false);
    }
  }, [galleryCount]);

  const handleGoMyLocation = async () => {
    dispatch(
      flyToMyLocation({
        onDenied: () => {
          setErrorMessage(
            "this feature is blocked because permission to access location is blocked ",
          );
          setOpenSnackBar(true);
        },
      }),
    );
  };

  function handlePlacesChanged() {
    const places = this.getPlaces();
    const place = head(places);
    const loc = get(place, ["geometry", "location"]);
    const lat = loc.lat();
    const lng = loc.lng();
    const bounds = get(place, ["geometry", "viewport"]);
    isMobile && dispatch(setShowSearchBox(false));
    dispatch(forceNewMapState({ lat, lng, bounds }));
  }

  const changeStyle = () => {
    if (mapTypeInd === 0) setMapTypeInd(1);
    else setMapTypeInd(0);
  };

  const showSearchBox = () => {
    dispatch(setShowSearchBox(true));
    if (!isMobile) {
      searchInputRef.current.focus();
    }
  };

  return (
    <>
      <div
        className={
          isMobile
            ? classes.searchPlaceWrapper
            : classes.mobileSearchPlaceWrapper
        }
      >
        <Snackbar
          open={openSnackBar}
          anchorOrigin={{ vertical: "center", horizontal: "center" }}
          autoHideDuration={3000}
          onClose={() => setOpenSnackBar(false)}
          style={{ height: `calc(100vh - 300px)` }}
        >
          <Alert onClose={() => setOpenSnackBar(false)} severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
        {!isMobile && shouldShowSearchPlace && (
          <>
            <StandaloneSearchBox onPlacesChanged={handlePlacesChanged}>
              <Input
                autoFocus
                type="text"
                inputRef={searchInputRef}
                placeholder="Search Location"
                className={classes.searchInput}
                startAdornment={
                  <InputAdornment position="start">
                    <LocationOnIcon />
                  </InputAdornment>
                }
              />
            </StandaloneSearchBox>
            {/* <div> */}
            {/*   <IconButton onClick={() => dispatch(setShowSearchBox(false))}> */}
            {/*     <ClearIcon style={{ color: 'white', width: 40, heigth: 40 }} /> */}
            {/*   </IconButton> */}
            {/* </div> */}
          </>
        )}
      </div>
      {open && (
        <Hidden mdDown>
          <div
            className={
              isMobile
                ? classes.searchPlaceWrapper
                : classes.mobileSearchPlaceWrapper
            }
            onMouseLeave={() => setOpen(false)}
          >
            {galleryCount < 4 && (
              <Collapse in={open} style={{ zIndex: 99, paddingTop: 15 }}>
                <Alert
                  color="info"
                  action={
                    <IconButton
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {messageKey === 0 ? (
                    <Typography variant="h6">
                      Not Zoomed in Enough.
                      <br />
                      Zoom in on the map and use the filters to narrow your
                      search for your gallery/photograph
                    </Typography>
                  ) : (
                    <Typography variant="h6">
                      Zoomed in too much or too many filters applied. Zoom out
                      or clear filters to find galleries
                    </Typography>
                  )}
                </Alert>
              </Collapse>
            )}
          </div>
        </Hidden>
      )}
      <Hidden mdDown>
        <div className={classes.mapHoverGroup}>
          {false && (
            <>
              {showStyleBtn && (
                <>
                  <Button className={classes.greybtn} style={{ top: 10 }}>
                    Choose Map Style
                  </Button>
                </>
              )}
              {showFlyBtn && (
                <Button className={classes.greybtn} style={{ top: 90 }}>
                  Fly to My Location
                </Button>
              )}
              {showSearchPlaceBtn && (
                <Button style={{ top: 164 }} className={classes.greybtn}>
                  Search Place Names
                </Button>
              )}
            </>
          )}
        </div>
      </Hidden>
      <div
        className={isMobile ? classes.mapIconGroup : classes.mapMobileIconGroup}
      >
        <IconButton
          className={classes.iconbtn}
          onClick={changeStyle}
          onMouseEnter={() => setShowStyleBtn(true)}
          onMouseLeave={() => setShowStyleBtn(false)}
        >
          <LayersIcon style={{ color: "#0c59cf" }} />
        </IconButton>
        <IconButton
          className={classes.iconbtn}
          onClick={handleGoMyLocation}
          onMouseEnter={() => setShowFlyBtn(true)}
          onMouseLeave={() => setShowFlyBtn(false)}
        >
          <CenterFocusWeakIcon style={{ color: "#0c59cf" }} />
        </IconButton>
        {!isMobile && (
          <>
            <IconButton
              className={classes.iconbtn}
              onClick={showSearchBox}
              onMouseEnter={() => setShowSearchPlaceBtn(true)}
              onMouseLeave={() => setShowSearchPlaceBtn(false)}
            >
              <SearchIcon style={{ color: "#0c59cf" }} />
            </IconButton>
          </>
        )}
        <div></div>
      </div>
    </>
  );
};

export default PlaceSearch;

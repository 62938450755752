export const BOSSFOTOS_WEBSITE = "https://www.bossfotos.com";
export const BOSSFOTOS_INFO_WEBSITE = "https://info.bossfotos.com";

export const CUSTOMER_UI_BASE_PATH = "/customer";
export const CUSTOMER_UI_MAP_PAGE_PATH = `${CUSTOMER_UI_BASE_PATH}/map`;
export const CUSTOMER_UI_EVENTS_PAGE_PATH = `${CUSTOMER_UI_BASE_PATH}/events`;
export const CUSTOMER_UI_SIGNUP_PATH = `${CUSTOMER_UI_BASE_PATH}/signup`;

export const CUSTOMER_UI_CHECKOUT_PATH = (trolley_id) =>
  `${CUSTOMER_UI_BASE_PATH}/shopping/${trolley_id}/?cartreturn=${window.location.pathname}`;

import React from "react";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const CustomSnackBar = ({ snackValue, setSnackValue }) => {
  return (
    <Snackbar
      open={snackValue.openSnackBar}
//      anchorOrigin={{ vertical: "center", horizontal: "center" }}
      autoHideDuration={2000}
      onClose={() => setSnackValue({ ...snackValue, openSnackBar: false })}
      style={{height:"100vh"}}
    >
      <Alert
        onClose={() => setSnackValue({ ...snackValue, openSnackBar: false })}
        severity={snackValue.type}
      >
        {snackValue.message}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackBar;

import React from "react";
import { countryList } from "../v2_actions/country";

const showCents = true;
var formatter = new Intl.NumberFormat("en-GB", {
  style: "decimal",
  minimumFractionDigits: showCents !== false ? 2 : 0,
  maximumFractionDigits: showCents !== false ? 2 : 0,
});

function CurrencyValueInternal({
  value,
  country,
  horizontal_spacing,
  isNegative = false,
}) {
  const sign = value !== null && isNegative ? "-" : "";
  const currency_symbol = countryList.getCurrencySymbol(country);
  const formatted_currency = value !== null ? formatter.format(value) || 0 : "";

  return (
    <dt
      style={{
        ...(horizontal_spacing === true
          ? {
              marginRight: "5px",
            }
          : {}),
        ...{
          display: "inline",
        },
      }}
    >
      {sign}
      {currency_symbol}
      {formatted_currency}
    </dt>
  );
}

export const CurrencyValue = React.memo(CurrencyValueInternal);

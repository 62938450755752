import React from "react"
import { MaterialTextInput } from "./form/MaterialTextInput"
import { Grid, makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
  formGrid:{
    width:'95%',
}
})

export const CustomerPasswordFields = ({ formProps }) => {

  const classes = useStyles()

  return (
    <Grid container className={classes.formGrid}>
      <Grid item xs>
        <Grid container spacing={5}>
          <Grid item md={12} xs={12}>
            <MaterialTextInput
              type="password"
              form={formProps}
              name="old_password"
              field_label="Old Password"
              placeholder="Old Password"
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <MaterialTextInput
              type="password"
              form={formProps}
              name="new_password"
              field_label="New Password"
              placeholder="New Password"
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <MaterialTextInput
              type="password"
              form={formProps}
              name="confirm_password"
              field_label="Confirm Password"
              placeholder="Confirm Password"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
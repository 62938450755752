import React from "react";
import ReactMarkdown from "react-markdown";
import Typography from "@mui/material/Typography";

export const MarkdownText = ({ markdownText }) => {
  return (
    <>
      <Typography sx={{ textAlign: "justify" }}>
        <ReactMarkdown>{markdownText}</ReactMarkdown>
      </Typography>
    </>
  );
};

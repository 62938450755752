import map from "lodash/map";
import get from "lodash/get";
import union from "lodash/union";
import compact from "lodash/compact";
import React, { useState, useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { css, Global } from "@emotion/react";
import { Carousel } from "react-responsive-carousel";
import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { PreviewImageWithCutout } from "./PreviewImageWithCutout";
import { printTypeList } from "../v2_actions/print_type";
import { useIsMobile } from "../actions/ui";
import { trolleyPhotographList } from "../v2_actions/trolley_photograph";

const useStyles = makeStyles((theme) => ({
  commonImageContainerWrapper: {
    display: "flex",
    alignItems: "center",
    aspectRatio: "3/2",
  },
  commonImageContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    margin: "auto",
    justifyContent: "center",
  },
  photoArtCommonImageContainer: {
    [theme.breakpoints.down("sm")]: {
      margin: "30px 0px 0px",
    },
  },
  fullSizeWrapper: {
    cursor: "pointer",
    maxWidth: "100%",
    width: "100%",
    maxHeight: "100%",
    height: "100%",
    objectFit: "cover",
  },
  thumbnailWrapper: {
    maxHeight: "93px",
    height: "93px",
    maxWidth: "133px",
    cursor: "pointer",
    objectFit: "cover",
    aspectRatio: "3/2",
  },
}));

const global_styles = css`
  .carousel .thumb {
    padding: 0px
  }

  div.carousel-root {
    margin: 0px 26px 0px;
    position: relative;
    @media only screen and (max-width: 450px) {
      margin: 0px;
    }
  }

  .carousel .thumbs {
    text-align: center
    padding-left: 0px
  }

  .thumb {
    height: 92px
    //  margin-right: 53px !important
  }

  .carousel .control-arrow {
    display: block
    opacity: 1
    cursor: pointer
  }

  .carousel .control-prev.control-arrow {
    left: -10px
  }

  .carousel .control-next.control-arrow {
    right: -10px
  }

  .carousel .control-prev.control-arrow::before {
    border-right: 8px solid #001
  }

  .carousel .control-next.control-arrow::before {
    border-left: 8px solid #000
  }

  .carousel.carousel-slider .control-arrow {
    display: none
  }

  .carousel .thumb.selected,
  .carousel .thumb:hover {
    border: 2px solid #1464f6
  }

  .carousel-status {
    display: none
  }

  .MuiStepLabel-active b,
  .MuiStepLabel-completed b {
    font-weight: 600
  }

  div.MuiGrid-grid-md-7 {
    @media only screen and (max-width: 450px) {
      margin: 0px
    }
  }
`;

export const TrolleyPhotographCarousel = ({
  style,
  isCheckoutPage,
  photograph,
  print_type_id,
  photo_delivery_type,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const print_type = useSelector(() =>
    printTypeList.getPrintTypeById(print_type_id),
  );
  const genericPrintType = useSelector(() =>
    printTypeList.getGenericPrintType(),
  );
  const digitalOnlyPrintType = useSelector(() =>
    printTypeList.getDigitalOnlyPrintType(),
  );
  const [selectedCarouselItem, setSelectedCarouselItem] = useState(null);
  const isDigitalDeliveryType =
    trolleyPhotographList.isDigitalDeliveryType(photo_delivery_type);

  const getPrintTypeFromSelection = (print_type_id) => {
    if (print_type_id) {
      return get(print_type, "images");
    } else if (isDigitalDeliveryType) {
      return get(digitalOnlyPrintType, "images");
    } else {
      return get(genericPrintType, "images");
    }
  };

  const imageWithCutoutIds = useSelector(() =>
    getPrintTypeFromSelection(print_type_id),
  );

  const forceSelectFirstItemInCarousel = () => {
    setTimeout(() => {
      setSelectedCarouselItem(1);
      setSelectedCarouselItem(0);
    }, 500);
  };

  useEffect(() => {
    forceSelectFirstItemInCarousel();
  }, [photograph]);

  useEffect(() => {
    dispatch(printTypeList.fetchListIfNeeded());
    dispatch(printTypeList.ensureObjectLoaded(get(genericPrintType, "id")));
    dispatch(printTypeList.ensureObjectLoaded(get(digitalOnlyPrintType, "id")));
    if (print_type_id) {
      dispatch(printTypeList.ensureObjectLoaded(print_type_id));
    }
  }, [print_type_id, photo_delivery_type]);

  const getCarouselImages = ({
    className,
    previewClass,
    enableLoadingMessage,
  }) => {
    if (!get(photograph, "id")) {
      return [];
    }
    const images = compact(
      union(
        [
          <div
            id="primaryPhotoArtImage"
            className={classes.commonImageContainerWrapper}
          >
            <div
              className={`
                ${classes.commonImageContainer}, 
                ${isCheckoutPage && classes.photoArtCommonImageContainer}`}
            >
              <img
                className={className}
                key={0}
                src={photograph.gallery_size_file_info.download_url}
              />
            </div>
          </div>,
        ],
        map(imageWithCutoutIds, (imageWithCutoutId, index) => (
          <div
            key={imageWithCutoutId}
            className={`
                ${classes.commonImageContainer}, 
                ${isCheckoutPage && classes.photoArtCommonImageContainer}`}
          >
            <div className={classes.commonImageContainer}>
              <PreviewImageWithCutout
                imageClass={className}
                imageWithCutoutId={imageWithCutoutId}
                photograph={photograph}
                enableLoadingMessage={enableLoadingMessage}
              />
            </div>
          </div>
        )),
      ),
    );
    return images;
  };

  const renderCarouselThumbnails = function (items) {
    return getCarouselImages({
      className: classes.thumbnailWrapper,
      enableLoadingMessage: false,
    });
  };

  const renderTrolleyPhotographCarousel = (trolley_photograph_id) => {
    return (
      <>
        <Carousel
          autoPlay={true}
          swipeable
          selectedItem={selectedCarouselItem}
          infiniteLoop
          showIndicators
          showArrows
          thumbWidth={133}
          showThumbs
          interval={4000}
          renderThumbs={renderCarouselThumbnails}
        >
          {getCarouselImages({
            className: classes.fullSizeWrapper,
            enableLoadingMessage: true,
          })}
        </Carousel>
      </>
    );
  };

  const renderMobileTrolleyPhotographCarousel = (trolley_photograph_id) => {
    return (
      <>
        <div id="mobileTrolleyPhotographCarousel" className={style}>
          {getCarouselImages({
            className: classes.fullSizeWrapper,
            enableLoadingMessage: true,
          })}
        </div>
      </>
    );
  };

  return (
    <>
      <Global styles={global_styles} />
      {!isMobile && renderTrolleyPhotographCarousel()}
      {isMobile && renderMobileTrolleyPhotographCarousel()}
    </>
  );
};

import React, { useState, CSSProperties } from "react";
import { useStore } from "react-redux";
import { makeLogger } from "../utils/o11y/logger";

const logger = makeLogger({ enabled: true, label: "DevTools", color: "black" });

const styles: Record<string, CSSProperties> = {
  wrapper: {
    position: "absolute",
    padding: "10px",
    overflow: "auto",
    display: "flex",
    flexDirection: "row",
    fontSize: "12px!important",
    backgroundColor: "rgba(255, 255, 255, 0.95)",
    border: "solid 1px #ddd",
    zIndex: 1000,
    cursor: "pointer",
  },
  text: {
    fontSize: "12px",
  },
  boldtext: {
    fontSize: "12px",
    fontWeight: "bold",
    whiteSpace: "nowrap",
  },
  list: {
    padding: "0 10px 0 10px",
    borderRight: "solid 1px #ccc",
  },
  itemlist: {
    height: "100px",
    overflowY: "scroll",
  },
};

// uncomment state list ids to see them in the ui
const lists = [
  "gallery_event_for_event_tab_default",
  "album_for_event_tab_default",
  "brief_gallery_list_for_event_tab_default",
  //'brief_gallery_default',
  //'gallery_event_for_event_url_default',
  //'country_list_default',
  //'customer_options__countries',
  //'customer_options__get_all_activity_tags',
  //'customer_options__photographers',
];

export const DevTools = () => {
  const [dragCursorOffset, setDragCursorOffset] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [storeListener, setStoreListener] = useState(0);
  const { getState, subscribe } = useStore();
  const state = getState();

  const [{ left, top }, setBoxStartPos] = useState({ left: 10, top: 400 });
  //const [{ left, top }, setBoxStartPos] = useState({ left: 900, top: 600 });

  subscribe(() => {
    setStoreListener((prev) => prev + 1);
  });

  const handleDragStart: React.DragEventHandler<HTMLDivElement> = (ev) => {
    // capture mouse position relative to element
    setDragCursorOffset({
      x: ev.clientX - left,
      y: ev.clientY - top,
    });

    setIsDragging(true);
  };

  const handleDragEnd: React.DragEventHandler<HTMLDivElement> = (ev) => {
    // calculate end position minus mouse offset
    const endingPosition = {
      x: ev.clientX - dragCursorOffset.x,
      y: ev.clientY - dragCursorOffset.y,
    };

    setIsDragging(false);
    setBoxStartPos({ left: endingPosition.x, top: endingPosition.y });
  };

  const list = (id: string) => state["@lists"][id];

  const itemDesc = (listId: string, itemId: string) => {
    const entityKey = state["@lists"][listId]["entityKey"];
    const item = state["@objects"][entityKey]["itemsById"][itemId];
    if (entityKey === "customer/album") {
      return item.name;
    }
    if (entityKey === "customer/brief_gallery") {
      return `${item.gallery_event_name}/${item.gallery_name} (${item.gallery_type})`;
    }
    if (entityKey === "customer/gallery_event") {
      return `${item.name}`;
    }
    return "item";
  };

  logger.log(state);

  return (
    <div
      id="bossfotos-devtools"
      draggable
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      style={{ ...styles.wrapper, left, top }}
    >
      {lists.map((listId, i) => (
        <div key={`dev-list-${i}`} style={styles.list}>
          <div style={styles.boldtext}>
            {listId} ({list(listId)?.visibleItemIds.length})
            <br />
            <i>{list(listId)?.entityKey}</i>
          </div>
          <div style={styles.itemlist}>
            {list(listId)?.visibleItemIds.map((itemId: string, j: number) => (
              <div key={`dev-item-${j}`} style={styles.text}>
                {itemDesc(listId, itemId)}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

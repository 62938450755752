import { GalleryFilter } from "../GalleryFilter";
import { MainLayout } from "./MainLayout";
import React from "react";
import { isWindowInIframe } from "../../v2_actions/nav";
import { useIsMobile } from "../../actions/ui";

export const GalleryLayout = ({
  children,
  activeTab,
  onStartingLocationSearch,
}) => {
  const isInIframe = isWindowInIframe();
  const isMobile = useIsMobile();
  return (
    <MainLayout isInIframe={isInIframe} activeTab={activeTab}>
      {isMobile && (
        <GalleryFilter onStartingLocationSearch={onStartingLocationSearch} />
      )}
      {children}
    </MainLayout>
  );
};

const initialState = {
  userInfo: false,
  showReteralModal: false,
  forgotEmail:false,
  errorMessage: { showMessage: false, showMethod: false },
};

export default function UserReducer(state = initialState, action) {
  switch (action.type) {
    case "USER/USERINFO":
      return {
        ...state,
        userInfo: action.userInfo,
      };
    case "USER/ERRORMESSAGE":
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    case "USER/SHOWRETERALMODAL":
      return {
        ...state,
        showReteralModal: action.showReteralModal,
      };
    case "USER/SETFORGOTEMAIL":
      return {
        ...state,
        forgotEmail: action.forgotEmail,
      };
    case "USER/CLEARUSER":
      return Object.assign({
        userInfo: false,
        showReteralModal: false,
        forgotEmail:false,
        errorMessage: { showMessage: false, showMethod: false },
      })
    default:
      return state;
  }
}

import { useIsMobile } from "../../actions/ui";
import styles from "./overlayicon.module.css";

export const OverlayIcon = ({
  children,
  selected,
}: React.PropsWithChildren<{ selected?: boolean }>) => {
  const isMobile = useIsMobile();
  const isSelected = selected === undefined ? true : selected;
  const css = [styles.overlayicon];
  if (isSelected) css.push(styles.overlayiconselected);
  if (isMobile) {
    css.push(styles.overlayiconmobile);
  }
  return <div className={css.join(" ")}>{children}</div>;
};

import React from "react";
import styles from "./bossfotos-2024.module.css";

/**
 * ThemeBossFotos
 * @returns
 */
const ThemeBossFotos2024 = ({ children }: React.PropsWithChildren<{}>) => {
    return <div className={styles.theme}>{children}</div>;
};

export default ThemeBossFotos2024;

import React from "react";
import moment from "moment";
import TextField from "@mui/material/TextField";

export default function TextFieldDatePicker({ name, value, onChange }) {
  const onTextFieldDateValueChanged = (value) => {
    onChange(moment(value.target.valueAsNumber));
  };

  const defaultValue = moment(value).format("YYYY-MM-DD");

  return (
    <TextField
      type="date"
      name={name}
      variant="standard"
      sx={{ width: "95%" }}
      defaultValue={defaultValue}
      InputLabelProps={{ required: true }}
      onChange={onTextFieldDateValueChanged}
    />
  );
}

import map from "lodash/map";
import get from "lodash/get";
import { useParams } from "react-router-dom";

import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import Typography from "@mui/material/Typography";
import { countryList } from "../../v2_actions/country";
import { makeLogger } from "../../utils/o11y/logger";
import { styles } from "./styles";
import { useCountryDetection } from "../hooks/useCountryDetection";
import { useReduxStateReconcilation } from "../hooks/useReduxStateReconcilation";

const logger = makeLogger({
  enabled: true,
  label: "FilterCountry",
  color: "pink",
});

const DEFAULT_COUNTRY = {
  label: "Australia",
  value: "f57f2201-0af1-4810-bdba-e483992e5229",
};

export default function FilterCountry() {
  useReduxStateReconcilation();
  const { countryCode, persistCountry } = useCountryDetection();
  const [muiMenuAnchor, setMuiMenuAnchor] = React.useState(null);
  const isMenuOpen = Boolean(muiMenuAnchor);

  const { gallery_event_name } = useParams();

  const countryFilterFromSearchParam = countryCode;
  const countryOptions = countryList
    .getObjects()
    .sort((a, b) => a.country_name.localeCompare(b.country_name))
    .map((c) => ({ label: c.country_name, value: c.country_code }));

  const handleClickListItem = (event) => {
    setMuiMenuAnchor(event.currentTarget);
  };

  const handleMenuItemClick = (option, index) => {
    persistCountry(option?.value);
    setMuiMenuAnchor(null);
  };

  const handleClose = () => {
    setMuiMenuAnchor(null);
  };

  const filterLabel = countryOptions
    .filter((opt) => opt.value === countryFilterFromSearchParam)
    .map((opt) => opt.label)
    .concat("All")
    .shift();

  return (
    <div>
      <Typography
        button
        sx={styles.filterListTitle}
        onClick={handleClickListItem}
      >
        Country
      </Typography>
      <List component="nav" sx={styles.filterList}>
        <ListItem
          button
          sx={styles.filterListItem}
          aria-expanded={isMenuOpen ? "true" : undefined}
        >
          <Checkbox checked={true} disabled={true} size="small" />
          <ListItemText
            onClick={handleClickListItem}
            primary=""
            sx={styles.filterListItemText}
            secondary={<ListItemText primary={filterLabel} />}
          />
        </ListItem>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={muiMenuAnchor}
        open={isMenuOpen}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listbox",
        }}
      >
        <MenuItem
          key={"all"}
          disabled={countryCode === "all"}
          selected={countryCode === "all"}
          onClick={(event) => handleMenuItemClick({ value: "all" })}
        >
          {"All"}
        </MenuItem>
        {map(countryOptions, (option, index) => (
          <MenuItem
            key={get(option, "value")}
            disabled={countryCode === get(option, "value")}
            selected={countryCode === get(option, "value")}
            onClick={(event) => handleMenuItemClick(option, index)}
          >
            {get(option, "label")}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

import { ANNOUNCE_GENERAL_API_ERROR } from './orm_general_actions'

const initialState = {
    error: null
}

export default function orm_general_reducer(state = initialState, action) {
    switch (action.type) {
        case ANNOUNCE_GENERAL_API_ERROR:
            return Object.assign({}, state, {'error': action.error})
        default:
            return state
    }
}

import React from "react";
import styles from "./Pill.module.css";
import CloseIcon from "@mui/icons-material/Close";

/**
 * Pill
 * @returns
 */
const Pill = ({
  children,
  onClear,
}: React.PropsWithChildren<{ onClear?: () => void }>) => {
  return (
    <div className={styles.pill}>
      <div className={styles.pilllabel}>{children}</div>
      {onClear && (
        <button
          className={styles.pillclear}
          onClick={() => onClear && onClear()}
        >
          <CloseIcon
          />
        </button>
      )}
    </div>
  );
};

export default Pill;

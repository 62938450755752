import get from "lodash/get";
import { GalleryCardWrapper } from "./GalleryCardWrapper";
import React from "react";
import { formatSessionNameForURL } from "../v2_actions/nav";
import { useGalleryFilterSearchParams } from "../v2_components/hooks/useGalleryFilterSearchParams";
import { useNavigate } from "react-router-dom";

export const GalleryCard = ({
  tab,
  image,
  title,
  gallery,
  gallery_id,
  gallery_type,
  onSelectPhotoForBuy,
  photo_art_gallery_view = false,
}) => {
  const [, , urlParams] = useGalleryFilterSearchParams();
  const navigate = useNavigate();
  const dbId = get(gallery, "db_id");

  const onSelectedGallery = () => {
    const sessionNameForUrl =
      gallery && formatSessionNameForURL(get(gallery, "session_name"));

    if (gallery_type === "photo_art") {
      navigate(`/customer/photo_art_gallery/${gallery_id}?${urlParams}`);
    } else if (get(gallery, "gallery_event")) {
      navigate(
        `/customer/${formatSessionNameForURL(
          get(gallery, "gallery_event_name"),
        )}/${sessionNameForUrl}/${dbId}?${urlParams}`,
      );
    } else {
      navigate(`/customer/gallery/${sessionNameForUrl}/${dbId}?${urlParams}`);
    }
  };

  return (
    <>
      {gallery_id && (
        <GalleryCardWrapper
          tab={tab}
          title={title}
          image={image}
          gallery={gallery}
          onClick={onSelectedGallery}
          onSelectPhotoForBuy={onSelectPhotoForBuy}
          photo_art_gallery_view={photo_art_gallery_view}
          cardType={"gallery"}
        />
      )}
    </>
  );
};

import { createContext, useContext, useState } from "react";
import ThemeBossFotos from "./ThemeBossFotos";
import ThemeBossFotos2024 from "./ThemeBossFotos2024";

type Theme = "bossfotos" | "bossfotos2024";

interface ThemeContextContent {
  injectable?: any;
};

export const useSiteTheme = () => {
  const searchParams = new URLSearchParams(window.location.search);
  return (searchParams.get("theme") || "bossfotos2024") as Theme;
};

export const ThemeContext = createContext<{
  themeContext: ThemeContextContent,
  setThemeContext: (val: (state: ThemeContextContent) => ThemeContextContent) => void;
}>({
  themeContext: { injectable: undefined },
  setThemeContext: () => { }
});

export const useThemeContext = () => {
  const { setThemeContext } = useContext(ThemeContext);

  const setInjectable = (injectable: React.ReactNode | undefined) => {
    setThemeContext(a => ({
      ...a,
      injectable: injectable
    }))
  }

  return {
    setInjectable
  }
} 

const ThemeInjectable = ({ children }: React.PropsWithChildren<{}>) => {
  const { themeContext } = useContext(ThemeContext);

  return (
    <>
      {themeContext.injectable}
      <>{children}</>
    </>
  );
};

export const ThemeProvider = ({ theme, children }: React.PropsWithChildren<{ theme?: Theme }>) => {
  const urltheme = useSiteTheme();
  const [themeContext, setThemeContext] = useState<ThemeContextContent>({});


  let themePick = urltheme;
  if (theme) {
    themePick = theme; //override with prop if provided 
  }

  // default
  let ThemeComp = ThemeBossFotos2024;

  if (theme === "bossfotos") {
    ThemeComp = ThemeBossFotos;
  }
  if (theme === "bossfotos2024") {
    ThemeComp = ThemeBossFotos2024;
  }

  return (
    <ThemeComp>
      <ThemeContext.Provider value={{ themeContext, setThemeContext }}>
        <ThemeInjectable>{children}</ThemeInjectable>
      </ThemeContext.Provider>
    </ThemeComp>
  );
};

export default ThemeProvider;

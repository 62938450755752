import head from "lodash/head";
import get from "lodash/get";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import { css, Global } from "@emotion/react";
import { makeStyles } from "@material-ui/core";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { CurrencyValue } from "../CurrencyValue";
import { countryList } from "../../v2_actions/country";
import { billingAddressList } from "../../v2_actions/billing_address";

const useStyles = makeStyles(() => ({
  editButton: {
    height: 0,
  },
}));

const global_styles = css`
  div.MuiPaper-elevation {
    margintop: 24px;
    padding: 30px;
    background: #f4f4f4;
    @media only screen and (max-width: 450px) {
      padding: 30px 15px 0px;
      box-shadow: none;
      background: #fff;
    }
  }
  .MuiGrid-root .MuiTypography-root,
  .MuiGrid-root .MuiTypography-root span {
    font-weight: 600;
  }
  hr.MuiDivider-root {
    margin: 10px auto;
    borderbottom: solid 1px #c0c0c0;
  }
  button.MuiButton-root {
    border-radius: 6px;
    margin: 10px auto;
    text-transform: capitalize;
    @media only screen and (max-width: 450px) {
      line-height: 1.5;
    }
  }
  #checkoutBtn {
    background: #0c59cf;
  }

  button.MuiButton-outlinedPrimary {
    margin: 10px auto;
    border: #d6d6d6 solid 2px;
    color: #120639;
  }
  @media only screen and (max-width: 450px) {
    button.MuiButton-outlinedPrimary {
      margin-bottom: 20px;
    }
  }
`;

export const TrolleySummaryCard = ({
  trolley,
  trolleyIsUpdating,
  checkOut,
  photographer,
  disableCheckoutButton,
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const customer_billing_address = useSelector(() =>
    head(billingAddressList.getVisibleObjects()),
  );
  const customer_billing_country = useSelector(() =>
    countryList.getCountryForId(get(customer_billing_address, "country")),
  );
  const is_digital_only_trolley = get(trolley, "is_digital_only_trolley");
  const photographer_country = useSelector(() =>
    countryList.getCountryForId(get(photographer, "country")),
  );

  const conditionsToDisableCheckoutButton =
    get(trolley, "number_of_photos") === 0 ||
    trolleyIsUpdating ||
    disableCheckoutButton;

  useEffect(() => {
    dispatch(countryList.loadAllCountries());
    dispatch(billingAddressList.fetchListIfNeeded());
  }, []);

  const goToEvents = () => {
    navigate("/customer/events");
  };

  const onEditBillingAddress = () => {
    navigate("/customer/shipping_address");
  };

  return (
    <>
      <Global styles={global_styles} />
      <Card>
        <Grid container>
          <Grid item xs={10} pt={1}>
            <Typography>
              Total Items - {get(trolley, "number_of_photos")}
            </Typography>
          </Grid>
          <Grid item xs={2} pt={1}>
            <Typography>
              <CurrencyValue
                country={get(trolley, "country")}
                value={get(trolley, "total_photograph_price")}
              />
            </Typography>
          </Grid>
          <Grid item xs={10} pt={1}>
            <Typography>Bulk Discounts</Typography>
          </Grid>
          <Grid item xs={2} pt={1}>
            <Typography>
              <CurrencyValue
                country={get(trolley, "country")}
                value={get(trolley, "bulk_discount")}
              />
            </Typography>
          </Grid>
          {is_digital_only_trolley === false && (
            <>
              <Grid item xs={10} pt={1}>
                <Typography>
                  Shipping to {get(customer_billing_country, "country_name")},{" "}
                  {get(customer_billing_address, "town")}
                  <Button
                    size="small"
                    className={classes.editButton}
                    onClick={onEditBillingAddress}
                  >
                    Edit
                  </Button>
                </Typography>
              </Grid>
              <Grid item xs={2} pt={1}>
                <Typography>
                  <CurrencyValue country={get(trolley, "country")} value={0} />
                </Typography>
              </Grid>
            </>
          )}
          <Grid item xs={10} pt={1}>
            <Typography>Admin Fee</Typography>
          </Grid>
          <Grid item xs={2} pt={1}>
            <Typography>
              <CurrencyValue
                country={get(trolley, "country")}
                value={get(trolley, "admin_charge")}
              />
            </Typography>
          </Grid>
        </Grid>
        <Divider variant="fullWidth"></Divider>
        <Grid container>
          <Grid item xs={10}>
            <Typography>
              Total Price in {get(photographer_country, "currency_name")}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography>
              <CurrencyValue
                country={get(trolley, "country")}
                value={get(trolley, "total_price_owing_by_customer")}
              />
            </Typography>
          </Grid>
        </Grid>
        <Divider variant="fullWidth" className={classes.divider}></Divider>
        <Grid>
          <Grid sx={{ margin: "auto" }}>
            <Button
              size="large"
              id="checkoutBtn"
              fullWidth={true}
              variant="contained"
              onClick={checkOut}
              disabled={conditionsToDisableCheckoutButton}
            >
              <b>Checkout</b>
            </Button>
            <Button
              size="large"
              fullWidth={true}
              onClick={goToEvents}
              variant="outlined"
              disabled={trolleyIsUpdating}
            >
              Continue Shopping
            </Button>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

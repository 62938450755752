import React from "react";
import styles from "./Pill.module.css";

/**
 * Pill Bar component
 * @returns
 */
const PillBar = ({ children }: React.PropsWithChildren<{}>) => {
  return <div className={styles.pillbar}>{children}</div>;
};

export default PillBar;

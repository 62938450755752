import map from "lodash/map";
import head from "lodash/head";
import get from "lodash/get";
import { DisplayMode, deriveDisplayMode } from "./eventPageUtils";
import React, { useEffect } from "react";
import {
  galleryEventList,
  galleryEventListForEventUrl,
} from "../../v2_actions/gallery_event";
import {
  mergeGalleriesAndAlbums,
  mergeGalleriesAndGalleryEvents,
} from "../../v2_actions/gallery";
import { useNavigate, useParams } from "react-router-dom";

import { AlbumCard } from "../AlbumCard";
import { GalleryCard } from "../GalleryCard";
import { GalleryEventCard } from "../GalleryEventCard";
import LoadingSpinner from "../../views/components/LoadingSpinner";
import { albumListForEventTab } from "../../v2_actions/customer_album";
import { briefGalleryList } from "../../v2_actions/brief_gallery";
import { makeLogger } from "../../utils/o11y/logger";
import { makeStyles } from "@material-ui/core";
import responsive from "../../mobile";
import { useDispatch } from "react-redux";
import { useEventFiltersBroker } from "../hooks/useEventFiltersBroker";
import { useReduxStateReconcilation } from "../hooks/useReduxStateReconcilation";

const logger = makeLogger({
  enabled: true,
  label: "DesktopEventList",
  color: "#FF3333",
});

const useStyles = makeStyles((theme) => ({
  eventListWrapper: {
    height: "80vh",
    overflowY: "visible", // don't apply scroll on mobile
    [`@media only screen and (min-width:${responsive.getMobMaxWidthPixels()})`]:
      {
        overflowY: "scroll",
      },
  },
  galleryEventCard: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gap: "10px",
    margin: "auto",
    marginLeft: 0,
    [`@media only screen and  (min-width: 1260px)and (max-width: ${responsive.getTabMaxWidthPixels()})`]:
      {
        gridTemplateColumns: "repeat(4, 1fr)",
      },
    [`@media only screen and  (min-width:900px) and (max-width: 1260px)`]: {
      margin: "auto",
      gridTemplateColumns: "repeat(3, 1fr)",
    },
    [`@media only screen and (max-width:${responsive.getMobMaxWidthPixels()})`]:
      {
        gridTemplateColumns: "repeat(2, 1fr)",
        width: "100%",
        height: "auto",
        marginTop: "60px",
        // overflowX: "hidden",
        position: "relative",
        zIndex: "999",
      },
  },
  galleryCard: {
    width: "95%",
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gap: "10px",
    margin: "auto",
    marginLeft: 0,
    [`@media only screen and  (min-width:${responsive.getTabMinWidthPixels()})and (max-width: ${responsive.getTabMaxWidthPixels()})`]:
      {
        margin: "auto",
        gridTemplateColumns: "repeat(4, 1fr)",
      },
    [`@media only screen and (max-width:${responsive.getMobMaxWidthPixels()})`]:
      {
        margin: "auto",
        gridTemplateColumns: "repeat(1, 1fr)",
        width: "100%",
        height: "auto",
        marginTop: "60px",
        position: "relative",
        zIndex: "999",
      },
  },
  noGalleriesDiv: {
    margin: "0px 40px",
    position: "absolute",
    top: "200px",
  },
}));

function DesktopEventList({ gallery_event_name, album_name }) {
  useReduxStateReconcilation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { publicUrlPathForGalleryEvent } = useParams();
  const event_page = publicUrlPathForGalleryEvent;
  const displayMode = deriveDisplayMode(gallery_event_name, album_name);
  const albums = albumListForEventTab.getVisibleObjects();

  const briefGalleries = briefGalleryList.getFilteredGalleries();
  const gallery_events = galleryEventList.getVisibleObjects();
  const firstGalleryEvent = head(
    galleryEventListForEventUrl.getVisibleObjects(),
  );

  const mergedGalleriesAndAlbums = mergeGalleriesAndAlbums({
    galleries: briefGalleries,
    albums,
  });

  const mergedGalleriesEventsAndOrphanedGalleries =
    mergeGalleriesAndGalleryEvents({
      galleries: briefGalleries,
      galleryEvents: gallery_events,
    });

  const isBriefGalleryListLoading = briefGalleryList.isLoading();
  const isGalleryEventListLoading = galleryEventList.isLoading();
  const isGalleryEventListForEventUrlLoading =
    galleryEventListForEventUrl.isLoading();

  // TODO: I am suspicious about this code and the password stuff
  useEffect(() => {
    if (event_page && !isGalleryEventListLoading) {
      logger.log("FX: lists loading");
      dispatch(
        galleryEventListForEventUrl.updateListFilter({
          public_or_direct_url: event_page,
        }),
      );
      dispatch(
        galleryEventListForEventUrl.fetchListIfNeeded({
          callSource: "DesktopEventList_evp",
        }),
      );
      firstGalleryEvent &&
        navigate(
          `/customer/events/${get(firstGalleryEvent, "name")}?direct=true`,
        );
      if (get(firstGalleryEvent, "direct_access_url") === event_page) {
        galleryEventListForEventUrl.setPasswordBypassForGalleryEvent(
          get(firstGalleryEvent, "id"),
        );
      }
    }
  }, [
    event_page,
    isGalleryEventListLoading,
    isGalleryEventListForEventUrlLoading,
  ]);

  useEventFiltersBroker({
    galleryEventUrlKey: gallery_event_name,
    albumUrlKey: album_name,
    event_page: event_page,
  });

  const renderGalleryEvents = () => (
    <>
      {map(mergedGalleriesEventsAndOrphanedGalleries, (item, index) => (
        <React.Fragment key={index}>
          {item.type === "gallery_event" && (
            <GalleryEventCard
              tab="events"
              key={index}
              title={item.name}
              gallery_event_id={item.id}
              gallery_event_name={item.name}
              image={get(item, [
                "event_photograph",
                "gallery_size_file_info",
                "download_url",
              ])}
            />
          )}
          {item.type === "gallery" && (
            <GalleryCard
              tab="events"
              gallery={item}
              gallery_id={item.id}
              title={item.gallery_name}
              image={item.gallery_photo_url}
            />
          )}
        </React.Fragment>
      ))}
    </>
  );

  const renderGalleriesAndAlbums = () => (
    <>
      {map(mergedGalleriesAndAlbums, (item, index) => (
        <React.Fragment key={index}>
          {item.type === "gallery" && (
            <GalleryCard
              tab="events"
              gallery={item}
              gallery_id={item.id}
              title={item.gallery_name}
              image={item.gallery_photo_url}
            />
          )}
          {item.type !== "gallery" && (
            <AlbumCard
              tab="events"
              title={item.name}
              album_id={item.id}
              image={item.album_photograph}
              gallery_event_name={item.gallery_event_name}
            />
          )}
        </React.Fragment>
      ))}
    </>
  );

  const renderGalleries = () => (
    <>
      {map(briefGalleries, (gallery, index) => (
        <GalleryCard
          tab="events"
          key={index}
          gallery={gallery}
          gallery_id={gallery.id}
          title={gallery.gallery_name}
          image={gallery.gallery_photo_url}
        />
      ))}
    </>
  );

  const isLoading = isGalleryEventListLoading || isBriefGalleryListLoading;

  const galleriesEventsExist = gallery_events?.length > 0;
  const albumsExist = albums?.length > 0;
  const mergedGalleriesExist = mergedGalleriesAndAlbums?.length > 0;
  const briefGalleriesExist = briefGalleries?.length > 0;

  const showAllGalleries =
    !isLoading &&
    (galleriesEventsExist || briefGalleriesExist) &&
    displayMode === DisplayMode.GalleryEvent;
  const showAlbum =
    !isLoading && albumsExist && displayMode === DisplayMode.Album;
  const showGallery =
    !isLoading && mergedGalleriesExist && displayMode === DisplayMode.Gallery;

  const noData =
    !isLoading &&
    ((!albumsExist && displayMode === DisplayMode.Album) ||
      (!mergedGalleriesExist && displayMode === DisplayMode.Gallery) ||
      (!galleriesEventsExist &&
        !briefGalleriesExist &&
        displayMode === DisplayMode.GalleryEvent));

  logger.log({
    gallery_events,

    gallery_event_name,
    album_name,
    displayMode,

    showAlbum,
    albumsExist,
    albums,

    showGallery,
    mergedGalleriesExist,
    briefGalleries,

    showAllGalleries,
    galleriesEventsExist,
    gallery_events,

    mergedGalleriesAndAlbums,
    mergedGalleriesEventsAndOrphanedGalleries,

    isBriefGalleryListLoading,
    isLoading,

    noData,
  });

  return (
    <>
      <div className={`${classes.eventListWrapper}  gallery-scroll-container`}>
        {showAllGalleries && (
          <div className={`${classes.galleryEventCard} gallery-event-card`}>
            {renderGalleryEvents()}
          </div>
        )}
        {showAlbum && (
          <div className={`${classes.galleryCard} gallery-card`}>
            {renderGalleries()}
          </div>
        )}
        {showGallery && (
          <div className={`${classes.galleryCard} gallery-card`}>
            {renderGalleriesAndAlbums()}
          </div>
        )}
      </div>
      {(isGalleryEventListLoading || isBriefGalleryListLoading) && (
        <LoadingSpinner />
      )}
      {noData && (
        <div className={`${classes.noGalleriesDiv} no-galleries`}>
          <h2>No events nor galleries match your search.</h2>
          <p>Try expanding the date filter or clearing other filters.</p>
        </div>
      )}
    </>
  );
}

export default DesktopEventList;

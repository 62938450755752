import { useLayoutEffect, useState } from "react";

export const BreakPoints = {
  sm: "(min-width: 640px)",
  md: "(min-width: 768px)",
  lg: "(min-width: 1024px)",
  xl: "(min-width: 1280px)",
  "2xl": "(min-width: 640px)",
};

const siteBreakpoints = Object.values(BreakPoints);

const getMatches = (query: string): boolean => {
  return window.matchMedia(query).matches;
};

export const useResponsiveBreakpoints = () => {
  const [matches, setMatches] = useState<Record<string, boolean>>({});

  useLayoutEffect(() => {
    const handleChange = (breakpoint: string) => () => {
      const match = getMatches(breakpoint);
      setMatches((prev) => {
        return {
          ...prev,
          [breakpoint]: match,
        };
      });
    };
    const unsubs: Array<() => void> = [];
    for (const breakpoint of siteBreakpoints) {
      const matchMedia = window.matchMedia(breakpoint);

      handleChange(breakpoint)();

      // Use deprecated `addListener` and `removeListener` to support Safari < 14 (#135)
      if (matchMedia.addListener) {
        matchMedia.addListener(handleChange(breakpoint));
      } else {
        matchMedia.addEventListener("change", handleChange(breakpoint));
      }
      unsubs.push(() => {
        if (matchMedia.removeListener) {
          matchMedia.removeListener(handleChange(breakpoint));
        } else {
          matchMedia.removeEventListener("chkange", handleChange(breakpoint));
        }
      });
    }
    return () => {
      unsubs.forEach((unsub) => unsub());
    };
  }, [JSON.stringify(siteBreakpoints)]);

  let currentBreakpointMatch = BreakPoints.sm;
  Object.entries(matches).forEach(([breakpoint, isMatch]) => {
    if (isMatch && breakpoint > currentBreakpointMatch) {
      currentBreakpointMatch = breakpoint;
    }
  });

  return currentBreakpointMatch;
};

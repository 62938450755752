import get from "lodash/get";
import React, { useState } from "react";
import { Modal } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useIsMobile } from "../../../actions/ui";
import { CurrencyValue } from "../../../v2_components/CurrencyValue";

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    width: "90%",
    background: "white",
    transform: `translate(-${left}%, -${top}%)`,
  };
}

const PriceModal = ({
  width,
  classes,
  openModal,
  setOpenModal,
  photoGrapherName,
}) => {
  const isMobile = useIsMobile();
  const [modalStyle] = useState(getModalStyle);
  const galleryPrice = useSelector((state) => state.galleryReducer.priceByGid);
  const defaultValue = useSelector(
    (state) => state.galleryReducer.defaultValue,
  );
  const photographerPrice = useSelector(
    (state) => state.galleryReducer.priceByPid,
  );

  const calcMarkUpPrice = (percent, value) => {
    if (percent && value) {
      const rate = 1 + percent / 100;
      const adjust_value = value * rate;
      return (
        <CurrencyValue
          value={adjust_value}
          country={get(photographerPrice, "country")}
        />
      );
    } else {
      return null;
    }
  };

  return (
    <Modal open={openModal} onClose={(e) => setOpenModal(false)}>
      <div
        style={modalStyle}
        className={!isMobile ? classes.paper : classes.mobilePaper}
      >
        <h2 id="simple-modal-title">{photoGrapherName}'s Pricing</h2>
        {galleryPrice && (
          <>
            {" "}
            <p>
              Standard digital photographs start from{" "}
              <CurrencyValue
                value={galleryPrice.base_price}
                country={get(galleryPrice, "country")}
              />
            </p>
            <p>Discounts for buying multiple Fotos:</p>
            <div style={{ paddingLeft: 10 }}>
              <p>Fotos 2 - 5 = {galleryPrice.discount1_percentage}%</p>
              <p>Fotos 6- 10 = {galleryPrice.discount2_percentage}%</p>
              <p>Fotos 11 - 50 = {galleryPrice.discount3_percentage}%</p>
              <p>Fotos 51+ = {galleryPrice.discount4_percentage}%</p>
            </div>
            <p>Print Prices:</p>
            <div style={{ paddingLeft: 10 }}>
              <p>
                {defaultValue.a4_name} ={" "}
                {calcMarkUpPrice(
                  photographerPrice.print_markup_percentage,
                  defaultValue.a4_price,
                )}
              </p>
              <p>
                {defaultValue.a3_name} ={" "}
                {calcMarkUpPrice(
                  photographerPrice.print_markup_percentage,
                  defaultValue.a3_price,
                )}
              </p>
              <p>
                {defaultValue.a2_name} ={" "}
                {calcMarkUpPrice(
                  photographerPrice.print_markup_percentage,
                  defaultValue.a2_price,
                )}
              </p>
              <p>
                {defaultValue.a1_name} ={" "}
                {calcMarkUpPrice(
                  photographerPrice.print_markup_percentage,
                  defaultValue.a1_price,
                )}
              </p>
              <p>
                {defaultValue.a0_name} ={" "}
                {calcMarkUpPrice(
                  photographerPrice.print_markup_percentage,
                  defaultValue.a0_price,
                )}
              </p>
            </div>
            <div style={{ padding: 5 }}>
              <p>Private Sessions and Shoots:</p>
              <p style={{ paddingLeft: 10 }}>
                You can book {photoGrapherName} for a private session at{" "}
                <CurrencyValue
                  value={photographerPrice.hourly_rate}
                  country={photographerPrice.country}
                />{" "}
                per hour
              </p>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default PriceModal;

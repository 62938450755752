import bgImg from "../../assets/images/background/help.png";
// eslint-disable-next-line 
export default (theme) => ({
  root: {
    display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%",
  },
  menuWrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    paddingTop: 65,
    [theme.breakpoints.up("lg")]: {
      paddingTop: 65,
      paddingLeft: 300,
    },
  },
  nonMennuWrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  content: {
    flex: "1 1 auto",
    backgroundImage: `url(${bgImg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "calc(100vh - 65px)",
    overflowY: "scroll",
    padding: 20,
  },

  button: {
    backgroundColor: "#0c59cf !important",
    textTransform: "capitalize",
    color: "white",
    fontSize: 20,
    fontWeight: "500",
    padding: 10,
    width: 250,
  },
  child: {
    background: "white",
    position: "relative",
    overflow: "hidden",
    height: "calc(100vh - 90px)",
  },
  card: {
    background: "#d4e3fc",
    width: "100%",
    height: 560,
    borderRadius: 15,
    border: "1px solid black",
    cursor: "pointer",
    overflowY: "scroll",
  },
  mobileCard: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    // height:"100vh",
    background: "#d4e3fc",
    width: "100%",
    borderRadius: 15,
    border: "1px solid black",
    cursor: "pointer",
    overflowY: "scroll",
  },
  textField: {
    background: "white",
    width: 300,
    // border:"2px solid black"
  },
  mobileTextField: {
    background: "white",
    width: 300,
    // border:"2px solid black"
  },
  textArea: {
    background: "white",
    width: 800,
  },
  mobileTextArea: {
    background: "white",
    width: 250,
  },
  submitBtn: {
    color: "white",
    width: 200,
    textTransform: "capitalize",
    backgroundColor: "#0c59cf !important",
  }
});

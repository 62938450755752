import { sendInviteMailApi } from "../apis/bookboss";

export const setMailStatus = (message) => ({
  type: "BOOKBOSS/SENDMAIL",
  message,
});

export const sendMailAction = (mailInfo) => {
  return (dispatch) => {
    sendInviteMailApi(mailInfo).then((res) => {
      if (res.data) {
        dispatch(setMailStatus(res.data));
      }
    });
  };
};

import React from "react";
import styles from "./ListItemLabel.module.css";
import mixinstyles from "../mixins.module.css";

type Alignment = "left" | "center" | "right";
type Size = "default" | "small";

/**
 * ListItemLabel
 * @returns
 */
const ListItemLabel = ({
  align,
  heading,
  children,
  size,
  noPadding,
}: React.PropsWithChildren<{
  size?: Size;
  align?: Alignment;
  heading?: boolean;
  noPadding?: boolean;
}>) => {
  const styleOverrides: Record<string, string> = {
    fontWeight: heading ? "bold" : "inherit",
    fontSize: size === "small" ? "12px" : "inherit",
    color: size === "small" ? "var(--expander-label-color)" : "inherit",
  };
  if (noPadding) {
    styleOverrides.padding = "0px";
  }
  return (
    <label
      style={styleOverrides}
      className={`${styles.listitemlabel} ${mixinstyles["flexalignment" + (align ?? "left")]}`}
    >
      {children}
    </label>
  );
};

export default ListItemLabel;

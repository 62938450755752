import map from "lodash/map";
import get from "lodash/get";
import React, { useMemo, useEffect } from "react";
import { css, Global } from "@emotion/react";
import { useSelector, useDispatch } from "react-redux";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

import { makeStyles } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import { countryList } from "../../v2_actions/country";
import { CurrencyValue } from "../../v2_components/CurrencyValue";
import {
  setCustomValue,
  CUSTOM_VALUE_OPEN_DIGITAL_PRICES_DIALOG,
} from "../../v2_actions/custom_value";

const DELAY_IN_MILLISECONDS_TO_CLOSE_DIGITAL_PRICES_DIALOG = 4000;

const useStyles = makeStyles((theme) => ({
  headings: {
    color: "#000",
    fontWeight: 800,
    textAlign: "left",
    [theme.breakpoints.down("md")]: {
      fontSize: 15,
    },
  },
  pricingDescription: {
    [theme.breakpoints.down("md")]: {
      fontSize: 15,
    },
  },
  pricingAmount: {
    [theme.breakpoints.down("md")]: {
      fontSize: 15,
    },
  },
  closeButton: {
    height: 30,
    width: 146,
    borderRadius: 10,
    padding: "5px 10px",
    background: "#1464f6",
  },
  closeButtonText: {
    fontWeight: 800,
  },
  dialogActionWrapper: {
    marginBottom: 10,
  },
}));

const global_styles = css`
  h2.MuiDialogTitle-root {
    font-size: 16px;
    font-weight: 800;
  }
  div.MuiDialog-paperScrollPaper {
    width: 650px;
    border-radius: 15px;
  }
  @media only screen and (max-width: 600px) {
    div.MuiDialog-paperScrollPaper {
      height: auto;
    }
  }
`;

export function DigitalPricesDialog({ gallery, openDigitalPricesDialog }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const galleryCountryId = get(gallery, ["gallery_price", "country"]);
  const galleryLowResPrice = get(gallery, ["gallery_price", "low_res_price"]);
  const galleryCountry = useSelector(() =>
    countryList.getCountryForId(galleryCountryId),
  );

  const handleClose = () => {
    dispatch(
      setCustomValue({
        name: CUSTOM_VALUE_OPEN_DIGITAL_PRICES_DIALOG,
        value: false,
      }),
    );
  };

  const bulkDiscountBands = useMemo(() => {
    return [
      {
        range: "2-5",
        discount: get(gallery, ["gallery_price", "discount1_percentage"]),
        eachPhotoPrice: (
          <CurrencyValue
            country={galleryCountryId}
            value={
              get(gallery, [
                "gallery_price",
                "discounted_base_price_using_discount1_percentage",
              ]) || 0
            }
          />
        ),
      },
      {
        range: "6-10",
        discount: get(gallery, ["gallery_price", "discount2_percentage"]),
        eachPhotoPrice: (
          <CurrencyValue
            country={galleryCountryId}
            value={
              get(gallery, [
                "gallery_price",
                "discounted_base_price_using_discount2_percentage",
              ]) || 0
            }
          />
        ),
      },
      {
        range: "11-50",
        discount: get(gallery, ["gallery_price", "discount3_percentage"]),
        eachPhotoPrice: (
          <CurrencyValue
            country={galleryCountryId}
            value={
              get(gallery, [
                "gallery_price",
                "discounted_base_price_using_discount3_percentage",
              ]) || 0
            }
          />
        ),
      },
      {
        range: "50+",
        discount: get(gallery, ["gallery_price", "discount4_percentage"]),
        eachPhotoPrice: (
          <CurrencyValue
            country={galleryCountryId}
            value={
              get(gallery, [
                "gallery_price",
                "discounted_base_price_using_discount4_percentage",
              ]) || 0
            }
          />
        ),
      },
    ];
  }, [gallery, galleryCountryId]);

  useEffect(() => {
    setTimeout(() => {
      handleClose();
    }, DELAY_IN_MILLISECONDS_TO_CLOSE_DIGITAL_PRICES_DIALOG);
  }, []);

  return (
    <div>
      <Global styles={global_styles} />
      <Dialog open={true} disablePortal={true} onClick={handleClose}>
        <DialogTitle className={classes.alertDialogHeading}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              top: 8,
              right: 8,
              position: "absolute",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography
              mb={1}
              variant="div"
              component="div"
              className={classes.headings}
            >
              Digital Download Prices
            </Typography>
            <Stack
              spacing={1}
              direction="column"
              justifyContent="flex-start"
              alignItems="center"
            >
              {galleryLowResPrice !== null && (
                <>
                  <Grid container>
                    <Grid item md={7}>
                      <div className={classes.pricingDescription}>
                        Low Res Image (may include Logos)
                      </div>
                    </Grid>
                    <Grid item md={5}>
                      <div className={classes.pricingAmount}>
                        <CurrencyValue
                          country={galleryCountryId}
                          value={get(gallery, [
                            "gallery_price",
                            "low_res_price",
                          ])}
                        />{" "}
                        {get(galleryCountry, "currency_name")}
                      </div>
                    </Grid>
                  </Grid>
                </>
              )}
              <Grid container>
                <Grid item md={7} mr={{ xs: 1, md: 0 }}>
                  <div className={classes.pricingDescription}>
                    Full Res Image
                  </div>
                </Grid>
                <Grid item md={5}>
                  <div className={classes.pricingAmount}>
                    <CurrencyValue
                      country={galleryCountryId}
                      value={get(gallery, ["gallery_price", "base_price"])}
                    />{" "}
                    {get(galleryCountry, "currency_name")}
                  </div>
                </Grid>
              </Grid>
            </Stack>
            <Typography
              my={1}
              variant="div"
              component="div"
              className={classes.headings}
            >
              Bulk Discounts for Full Res Images
            </Typography>
            <Stack
              spacing={1}
              direction="column"
              alignItems="center"
              justifyContent="flex-start"
            >
              {map(bulkDiscountBands, (bulkDiscountBand) => (
                <Grid container>
                  <Grid item xs={12} md={5}>
                    <Typography
                      variant="p"
                      component="b"
                      className={classes.pricingDescription}
                    >
                      Images {get(bulkDiscountBand, "range")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <div className={classes.pricingAmount}>
                      {get(bulkDiscountBand, "eachPhotoPrice")} each. (
                      {get(bulkDiscountBand, "discount")}% Discount)
                    </div>
                  </Grid>
                </Grid>
              ))}
            </Stack>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className={classes.dialogActionWrapper}>
            <Button
              variant="contained"
              onClick={handleClose}
              className={classes.closeButton}
            >
              <p className={classes.closeButtonText}>Got it</p>
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default React.memo(DigitalPricesDialog);

import { useEffect } from "react";

const g = window as any;

g.__setpagetitletimeout = undefined;

export const useSetPageTitle = (title: string) => {
  useEffect(() => {
    clearTimeout(g.__setpagetitletimeout);
    document.title = title;
    return () => {
      g.__setpagetitletimeout = setTimeout(() => {
        document.title = "BossFotos";
      }, 500);
    };
  }, []);
};

import { useEffect } from "react";
import { guessCountryCode } from "../../utils/detectCountryCode";
import get from "lodash/get";
import { countryList } from "../../v2_actions/country";
import { useSharedContext } from "./useSharedContext";
import { useLocation } from "react-router-dom";
import { makeLogger } from "../../utils/o11y/logger";

const logger = makeLogger({
  enabled: true,
  label: "useCountryDetection",
  color: "orange",
});

const PREFERRED_COUNTRY_CODE = "preferred_country_code";

// localStorage.removeItem("preferred_country_code");

/**
 * Returns user's detected location for the current session, storing
 * in session for subsequent calls. Event filters WILL overwrite this
 */
export const useCountryDetection = () => {
  const { setCountryCode, sharedState } = useSharedContext();
  const { pathname } = useLocation();
  const preferredCountryCode = localStorage.getItem(PREFERRED_COUNTRY_CODE);

  // TODO: we should skip this and RESET localStorage IF the first URL
  // accessed is an album/gallery.

  useEffect(() => {
    if (preferredCountryCode !== null) {
      logger.log("skipping detect");
      return;
    }
    if (
      !pathname.startsWith("/customer/events") &&
      !pathname.startsWith("/customer/map")
    ) {
      logger.log("direct access URL, skipping detect");
      localStorage.setItem(PREFERRED_COUNTRY_CODE, "all");
      return;
    }
    guessCountryCode((response: { country_code: string }) => {
      const { country_code } = response;
      logger.log("detected=", {
        apiResponse: country_code,
        response,
        globalState: sharedState?.countryCode,
      });
      localStorage.setItem(PREFERRED_COUNTRY_CODE, country_code);
      setCountryCode(country_code);
    });
  }, []);

  const persistCountry = (code: string | undefined) => {
    setCountryCode(code);
    logger.log("setCountry", code);
    if (code === undefined || code === "undefined") {
      logger.log("resetting ...");
      //   // reset
      localStorage.removeItem(PREFERRED_COUNTRY_CODE);
    } else {
      localStorage.setItem(PREFERRED_COUNTRY_CODE, code);
    }
  };

  const getPreferredCountryApiId = () => {
    const preferredCountryId = get(
      countryList.findForCountryCode(preferredCountryCode),
      "id",
    );
    return preferredCountryId === "all" || !preferredCountryId
      ? undefined
      : preferredCountryId;
  };

  const getPreferredCountryName = () => {
    logger.log(countryList.getAllObjects());
    return (
      countryList.findForCountryCode(preferredCountryCode)?.country_name ??
      "All"
    );
  };

  logger.log({
    preferredCountryCode,
    pathname,
  });

  return {
    countryCode: preferredCountryCode,
    persistCountry,
    getPreferredCountryApiId,
    getPreferredCountryName,
  };
};

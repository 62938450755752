import React, { useEffect} from 'react'
import { clearAllStorage } from '../actions/user'

export const SignOut = () => {

    useEffect(() => {
        clearAllStorage()
        window.location = "/corporate"
    }, [])
    
    return (
        <div>Signing out..."</div>
    )
    
}

 

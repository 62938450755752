import { MainLayout } from "./MainLayout";
import React from "react";

export const EventsLayout = ({ children, activeTab, isInIframe }) => {
  return (
    <MainLayout activeTab={activeTab} isInIframe={isInIframe}>
      {children}
    </MainLayout>
  );
};

import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import { useGalleryFilterSearchParams } from "../hooks/useGalleryFilterSearchParams";
import { makeLogger } from "../../utils/o11y/logger";

const logger = makeLogger({
  enabled: true,
  label: "EventPageSearchBar",
  color: "#FF9999",
});

const DEFAULT_TIMEOUT_TO_CHANGE_ANY_FIELD_FILTER_CHANGE = 500;

export default function EventPageSearchBar() {
  const navigate = useNavigate();
  const [galleryFilterSearchParams, syncGalleryFilterSearchParam, urlParams] =
    useGalleryFilterSearchParams();
  const isSearchActive = galleryFilterSearchParams.any !== undefined;

  const [anyFieldInputText, setAnyFieldInputText] = useState(
    galleryFilterSearchParams.any,
  );

  useEffect(
    function syncTextboxWithUrl() {
      if (!galleryFilterSearchParams.any) {
        setAnyFieldInputText("");
      }
    },
    [galleryFilterSearchParams.any],
  );

  const [timeoutId, setTimeoutId] = useState();
  const handleAnyFieldInputTextChanged = (e) => {
    const { value } = e.target;
    setAnyFieldInputText(value ?? "");
    if (timeoutId) {
      clearTimeout(timeoutId); // cancel pending redirect
    }
    const id = setTimeout(() => {
      // Note we're not using useGalleryFilterSearchParams here. We're
      // forcing all search back to the events page to clear filter and any
      // UI context of galleries/albums. The 'any' url param will still
      // be accessible / valid through useGalleryFilterSearchParams
      if (!anyFieldInputText) {
        navigate(`/customer/events/`);
      } else {
        syncGalleryFilterSearchParam({ any: value });
      }
    }, DEFAULT_TIMEOUT_TO_CHANGE_ANY_FIELD_FILTER_CHANGE);
    setTimeoutId(id);
  };

  const handleClearSearch = () => {
    setAnyFieldInputText("");
    syncGalleryFilterSearchParam({ any: null });
  };

  return (
    <Paper
      component="div"
      sx={{
        width: 500,
        display: "flex",
        alignItems: "center",
        marginTop: "12px",
        marginBottom: "10px",
        paddingLeft: "6px",
        boxShadow: "none",
      }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1, letterSpacing: "-0.2pt" }}
        value={anyFieldInputText}
        onChange={handleAnyFieldInputTextChanged}
        placeholder="Search Galleries by Name, Event, School, Photographer"
      />
      {isSearchActive ? (
        <IconButton
          type="button"
          sx={{ p: "10px" }}
          title="Clear search"
          onClick={handleClearSearch}
        >
          <ClearIcon />
        </IconButton>
      ) : (
        <IconButton type="button" sx={{ p: "10px" }}>
          <SearchIcon />
        </IconButton>
      )}
    </Paper>
  );
}

import size from "lodash/size";
import map from "lodash/map";
import indexOf from "lodash/indexOf";
import get from "lodash/get";
import { IconButton, Modal, Typography, makeStyles } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Carousel from "react-material-ui-carousel";
import CloseIcon from "@material-ui/icons/Close";
import { CurrencyValue } from "../../v2_components/CurrencyValue";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import { makeLogger } from "../../utils/o11y/logger";
import { photographList } from "../../v2_actions/customer_photograph";
import style from "./styles";
import { trolleyPhotographList } from "../../v2_actions/trolley_photograph";
import { useIsMobile } from "../../actions/ui";

const logger = makeLogger({
  enabled: true,
  label: "CarouselPhotos",
  color: "purple",
});

const REMAINING_PHOTOS_IN_BUFFER_TO_TRIGGER_FETCH = 3;

function getModalStyle() {
  const top = 0;
  const left = 0;
  return {
    top: `${top}%`,
    left: `${left}%`,
    background: "black",
    transform: `translate(-${left}%, -${top}%)`,
  };
}

const useStyles = makeStyles(style);

const CarouselPhotos = ({
  photos,
  gallery,
  show,
  setShowMethod,
  photoGrapherName,
  selectedImgId,
  onImageSelected,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const [modalStyle] = useState(getModalStyle);
  const galleryPriceCountry = get(gallery, ["gallery_price", "country"]);
  const galleryLowResPrice = get(gallery, ["gallery_price", "low_res_price"]);
  const activeTrolleyPhotosArray = useSelector(
    (state) => state.trolleyReducer.activePhotoInfos,
  );
  const galleryPrice = useSelector((state) => state.galleryReducer.priceByGid);
  const [selectInd, setSelectInd] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const photographListIsLoading = photographList.isLoading();
  const [isAppendingContent, setIsAppendingContent] = useState(false); // determines whether additional photos are being appended
  const pagination = useSelector(() => photographList.getPagination());

  const photoIds = map(photos, "id");

  useEffect(() => {
    const { current_page, num_pages } = pagination;
    const selectedImageIndexInPhotosMap = indexOf(photoIds, selectedImgId, 0);
    const isNearEnd =
      selectedImageIndexInPhotosMap >
      size(photoIds) - REMAINING_PHOTOS_IN_BUFFER_TO_TRIGGER_FETCH;
    const hasMorePages = current_page < num_pages;
    const shouldFetch =
      isNearEnd &&
      hasMorePages &&
      !isAppendingContent &&
      !photographListIsLoading;

    logger.log("should fetch?", {
      shouldFetch,
      isAppendingContent,
      photographListIsLoading,
      isNearEnd,
      hasMorePages,
      selectedImgId,
    });

    if (shouldFetch) {
      logger.log("FETCH");
      setIsAppendingContent(true);
      const nextPage = current_page + 1;
      dispatch(
        photographList.updateListPagination({
          ...pagination,
          page: nextPage,
        }),
      );
      dispatch(
        photographList.fetchListIfNeeded({
          forceUpdate: true,
          callSource: "Carousel",
        }),
      );
    }
    // }
  }, [
    selectedImgId,
    pagination,
    photographListIsLoading,
    isAppendingContent,
    photoIds,
    dispatch,
  ]);

  useEffect(() => {
    logger.log("Reset appending", photographListIsLoading);
    if (!photographListIsLoading) {
      setIsAppendingContent(false);
    }
  }, [photographListIsLoading]);

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  });

  const updateWidth = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    if (show && selectInd == null && photos && photos.length > 0) {
      photos.map((item, ind) => {
        if (item.id === selectedImgId) {
          setSelectInd(ind);
        }
      });
    }
  }, [photos, selectInd, selectedImgId, show]);

  const handleChange = useCallback(
    (selectedIndex) => {
      console.log("Carousel onChange", { selectedIndex, photos });
      if (photos && photos.length > 0) {
        photos.map((item, key) => {
          if (key === selectedIndex) {
            setSelectInd(selectedIndex);
            onImageSelected(item.id);
          }
        });
      }
    },
    [onImageSelected, photos],
  );

  const onSelectPhotoForBuy = (pid, flag) => {
    if (flag === false) {
      dispatch(trolleyPhotographList.addPhotograph(pid));
    } else {
      dispatch(trolleyPhotographList.removePhotograph(pid));
    }
  };

  const calcWidth = (image_width) => {
    if (!isMobile && height > 1024) {
      const zoom_index = parseInt(width / image_width);
      return image_width * (zoom_index - 0.5);
    } else if (500 < width && width <= 768 && 900 < height && height <= 1024) {
      return "50%";
    } else if (!isMobile && height < 500) return "auto";
    else return "100%";
  };

  const calcHeight = (image_width, image_height) => {
    if (height > 2024) {
      return "auto";
    } else {
      if (image_width > image_height) {
        if (!isMobile && height < 500) return "100%";
        else return height * 0.9;
      } else return "100%";
    }
  };

  const addHighResSrc = (ev, item) => {
    if (ev.target.src !== item.highResSrc) {
      ev.target.src = item.highResSrc;
    }
  };

  return (
    <Modal open={show} onClose={(e) => setShowMethod(false)}>
      <Box
        width="100%"
        height="100vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={modalStyle}
        onContextMenu={(e) => {
          e.preventDefault();
        }}
      >
        <Carousel
          index={selectInd}
          autoPlay={false}
          indicators={false}
          animation={!isMobile ? "fade" : "slide"}
          swipe={true}
          timeout={100}
          cycleNavigation={false}
          navButtonsAlwaysVisible
          onChange={handleChange}
          className={classes.carousel}
        >
          {photos &&
            photos.length > 0 &&
            photos.map((item, ind) => {
              const is_promotional_media = get(item, "is_promotional_media");
              return (
                <React.Fragment key={`carousel_img_${ind}`}>
                  <TransformWrapper
                    defaultScale={1}
                    defaultPositionX={200}
                    defaultPositionY={100}
                  >
                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                      <React.Fragment>
                        <Box
                          key={`${ind}-box`}
                          display="flex"
                          alignItems="center"
                          position="absolute"
                          justifyContent="space-between"
                          top="0px"
                          right={!isMobile ? "5px" : "0px"}
                          width={
                            isMobile ? "100%" : galleryLowResPrice ? 550 : 400
                          }
                          cursor="pointer"
                          zIndex="999"
                        >
                          <Box className="tools">
                            <IconButton onClick={zoomIn}>
                              <ZoomInIcon style={{ color: "white" }} />
                            </IconButton>
                            <IconButton onClick={zoomOut}>
                              <ZoomOutIcon style={{ color: "white" }} />
                            </IconButton>
                          </Box>
                          <Box color="white" m={2}>
                            <Typography className={classes.typography}>
                              {!is_promotional_media && !galleryLowResPrice && (
                                <>
                                  Price:
                                  <CurrencyValue
                                    value={item.price}
                                    country={galleryPriceCountry}
                                  />
                                </>
                              )}
                              {!is_promotional_media && galleryLowResPrice && (
                                <>
                                  Prices: Low Res{" "}
                                  <CurrencyValue
                                    value={galleryLowResPrice}
                                    country={galleryPriceCountry}
                                  />{" "}
                                  {" / "}
                                  High Res{" "}
                                  <CurrencyValue
                                    value={item.price}
                                    country={galleryPriceCountry}
                                  />
                                </>
                              )}
                            </Typography>
                          </Box>
                          {!is_promotional_media && (
                            <IconButton
                              onClick={() =>
                                onSelectPhotoForBuy(item.id, item.selected)
                              }
                            >
                              <ShoppingCartIcon
                                className={classes.icon}
                                style={
                                  item.selected
                                    ? {
                                        color: "red",
                                      }
                                    : { color: "white" }
                                }
                              />
                            </IconButton>
                          )}
                          {/*<IconButton>
                                <ShareIcon style={{ color: "white" }} />
                                </IconButton>*/}
                          <IconButton onClick={() => setShowMethod(false)}>
                            <CloseIcon style={{ color: "white" }} />
                          </IconButton>
                        </Box>
                        <TransformComponent>
                          <img
                            alt=""
                            src={item.src}
                            draggable="false"
                            onLoad={(ev) => addHighResSrc(ev, item)}
                            style={{
                              objectFit: "contain",
                              width: calcWidth(item.width),
                              height: calcHeight(item.width, item.height),
                            }}
                          />
                        </TransformComponent>
                      </React.Fragment>
                    )}
                  </TransformWrapper>
                </React.Fragment>
              );
            })}
        </Carousel>
      </Box>
    </Modal>
  );
};

export default CarouselPhotos;

import React, { useContext, useState } from "react";

interface SharedState {
  countryCode: string | undefined;
}

const defaultState: SharedState = {
  countryCode: "all",
};

const defaultDispatch: React.Dispatch<React.SetStateAction<SharedState>> = () =>
  defaultState;

type SharedContext = [
  SharedState,
  React.Dispatch<React.SetStateAction<SharedState>>,
];

export const SharedContext = React.createContext<SharedContext>([
  defaultState,
  defaultDispatch,
]);

export const SharedContextProvider = ({
  children,
}: React.PropsWithChildren) => {
  const [sharedState, setSharedState] = useState<SharedState>(defaultState);
  return (
    <SharedContext.Provider value={[sharedState, setSharedState]}>
      {children}
    </SharedContext.Provider>
  );
};

export const useSharedContext = () => {
  const [sharedState, setSharedState] = useContext(SharedContext);

  return {
    sharedState,
    setCountryCode: (countryCode: string | undefined) =>
      setSharedState((prev) => ({
        ...prev,
        countryCode,
      })),
  };
};

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import React, { useEffect } from "react";
import {
  formatSessionNameForURL,
  isWindowInIframe,
  replaceDashesWithSpaces,
} from "../../v2_actions/nav";

import DesktopEventList from "./DesktopEventList";
import DesktopEventPageFilter from "./DesktopEventPageFilter";
import { EventPageBreadCrumbs } from "./EventPageBreadcrumbs";
import { EventsLayout } from "../layout/EventsLayout";
import LoadingSpinner from "../../views/components/LoadingSpinner";
import MobileEventList from "../MobileEventList";
import ReactGA from "react-ga4";
import SubEventFilter from "../SubEventFilter";
import { setGalleryNavBackButtonUrl } from "../../v2_actions/nav";
import { useEventsPageStartupEffects } from "../hooks/useEventsPageStartupEffects";
import { useIsMobile } from "../../actions/ui";
import { useParams } from "react-router-dom";

export const EventsPage = () => {
  setGalleryNavBackButtonUrl();
  const isMobile = useIsMobile();
  const isInIframe = isWindowInIframe();
  const { album_name } = useParams();
  const { gallery_event_name } = useParams();
  const { publicUrlPathForGalleryEvent } = useParams();
  const albumNameForAPI = replaceDashesWithSpaces(album_name);
  const galleryEventNameForAPI = replaceDashesWithSpaces(gallery_event_name);
  const galleryEventNameForURL = formatSessionNameForURL(gallery_event_name);

  const isLoadingStaticData = useEventsPageStartupEffects();

  useEffect(() => {
    if (gallery_event_name) {
      ReactGA.event({
        category: "Events/Schools",
        label: `${gallery_event_name}`,
        action: "Entered an events/school page",
      });
    }
    return () => {
      if (gallery_event_name) {
        ReactGA.event({
          category: "Events/Schools",
          label: `${gallery_event_name}`,
          action: "Exited an events/school page",
        });
      }
    };
  }, [gallery_event_name]);

  if (isLoadingStaticData) {
    return (
      <EventsLayout activeTab="events" isInIframe={isInIframe}>
        <LoadingSpinner />
      </EventsLayout>
    );
  }

  return (
    <EventsLayout activeTab="events" isInIframe={isInIframe}>
      {isMobile && (
        <>
          <SubEventFilter
            isInIframe={isInIframe}
            gallery_event_name={galleryEventNameForAPI}
            galleryEventNameForURL={galleryEventNameForURL}
          />
          <MobileEventList
            album_name={albumNameForAPI}
            gallery_event_name={galleryEventNameForAPI}
            galleryEventNameForURL={galleryEventNameForURL}
            publicUrlPathForGalleryEvent={publicUrlPathForGalleryEvent}
          />
        </>
      )}
      {!isMobile && (
        <Box
          sx={{ margin: "auto", flexGrow: 1, width: "95%" }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <DesktopEventPageFilter
                gallery_event_name={galleryEventNameForAPI}
                galleryEventNameForURL={galleryEventNameForURL}
              />
            </Grid>
            <Grid item xs={10}>
              <Grid item xs={12} my={"10px"} ml={"4px"}>
                {!isInIframe && (
                  <EventPageBreadCrumbs
                    album_name={album_name}
                    gallery_event_name={gallery_event_name}
                    galleryEventNameForURL={galleryEventNameForURL}
                  />
                )}
              </Grid>
              <DesktopEventList
                album_name={albumNameForAPI}
                gallery_event_name={galleryEventNameForAPI}
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </EventsLayout>
  );
};

import { PropMixins, cssStyles } from "../mixins";
import React, { CSSProperties } from "react";

import styles from "./Typography.module.css";
import { useResponsiveBreakpoints } from "../primitives/useResponsiveBreakpoints";

type HeaderProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLHeadingElement>,
  HTMLHeadingElement
> &
  PropMixins;

export const H1 = (props: HeaderProps) => {
  const activeBreakpoint = useResponsiveBreakpoints();
  return (
    <h1 {...props} className={styles.h1} style={cssStyles(props, activeBreakpoint)}>
      {props.children}
    </h1>
  );
};

export const H2 = (props: HeaderProps) => {
  const activeBreakpoint = useResponsiveBreakpoints();
  return (
    <h2 {...props} className={styles.h2} style={cssStyles(props, activeBreakpoint)}>
      {props.children}
    </h2>
  );
};
export const H3 = (props: HeaderProps) => {
  const activeBreakpoint = useResponsiveBreakpoints();
  return (
    <h3 {...props} className={styles.h3} style={cssStyles(props, activeBreakpoint)}>
      {props.children}
    </h3>
  );
};
export const H4 = (props: HeaderProps) => {
  const activeBreakpoint = useResponsiveBreakpoints();
  return (
    <h4 {...props} className={styles.h4} style={cssStyles(props, activeBreakpoint)}>
      {props.children}
    </h4>
  );
};
export const H5 = (props: HeaderProps) => {
  const activeBreakpoint = useResponsiveBreakpoints();
  return (
    <h5 {...props} className={styles.h5} style={cssStyles(props, activeBreakpoint)}>
      {props.children}
    </h5>
  );
};

type StrongProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLElement>,
  HTMLHeadingElement
> &
  PropMixins;

export const Strong = (props: StrongProps) => {
  const activeBreakpoint = useResponsiveBreakpoints();
  return (
    <strong {...props} className={styles.strong} style={cssStyles(props, activeBreakpoint)}>
      {props.children}
    </strong>
  );
};

import head from "lodash/head";
import get from "lodash/get";
import size from "lodash/size";
import isEmpty from "lodash/isEmpty";
import { Badge, Button, Chip, makeStyles } from "@material-ui/core";
import {
  CUSTOM_VALUE_NUM_TROLLEY_PHOTOGRAPHS,
  getCustomValue,
} from "../v2_actions/custom_value";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import RefreshIcon from "@mui/icons-material/Refresh";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import WrappingBackdrop from "./WrappingBackdrop";
import { authenticatedTrolleyList } from "../v2_actions/authenticated_trolley";
import { isLoggedIn } from "../v2_actions/auth";
import { getUserId } from "../actions/user";
import { goToCheckoutPage } from "../v2_actions/nav";
import { isWindowInIframe } from "../v2_actions/nav";
import { makeLogger } from "../utils/o11y/logger";
import { sentryApi } from "../utils/o11y/sentryApi";
import { setMessage, getTrolleyID } from "../actions/trolley";
import { trolleyList } from "../v2_actions/trolley";
import { trolleyPhotographList } from "../v2_actions/trolley_photograph.js";
import { useNavigate } from "react-router-dom";
import { useReduxStateReconcilation } from "./hooks/useReduxStateReconcilation";

const logger = makeLogger({
  enabled: true,
  label: "CheckoutButton",
  color: "blue",
});

const useStyles = makeStyles((theme) => ({
  enabledBtn: {
    cursor: "pointer",
    background: "#1464f6",
    [theme.breakpoints.down("md")]: {
      padding: "0px 25px",
    },
  },
  disabledBtn: {
    color: "#fff",
    borderRadius: "50px",
    background: "#a8a8a8",
    bottom: "1.5px",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      top: 10,
      padding: "0px 25px",
    },
  },
  checkoutBtnWrapper: {
    position: "relative",
    top: "1px",
  },
  refreshBtnWrapper: {
    position: "relative",
    top: "1px",
  },
  shoppingCartIcon: {
    width: 20,
    height: 20,
    position: "relative",
    top: "3.5px",
  },
  photoArtShoppingCartIcon: {
    position: "relative",
    top: "3.5px",
  },
  checkoutBtn: {
    color: "#fff",
    height: 32,
    background: "#1464f6",
    borderRadius: "50px",
    position: "relative",
    bottom: 3,
    float: "right",
    [theme.breakpoints.down("md")]: {
      height: 30,
    },
  },
  refreshBtn: {
    bottom: 3,
    height: 29,
    width: 130,
    color: "#fff",
    float: "right",
    borderRadius: 8,
    position: "relative",
    background: "#1464f6",
    "& p": {
      fontSize: 16,
      color: "#fff",
      fontWeight: 800,
      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
      },
    },
    "& svg": {
      width: 20,
      height: 20,
    },
    [theme.breakpoints.down("md")]: {
      height: 30,
    },
  },
  photoArtCheckoutBtn: {
    bottom: 3,
    height: 29,
    width: 130,
    color: "#fff",
    float: "right",
    borderRadius: 8,
    position: "relative",
    background: "#1464f6",
    "& p": {
      fontSize: 16,
      color: "#fff",
      fontWeight: 800,
      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
      },
    },
    "& svg": {
      width: 20,
      height: 20,
    },
    [theme.breakpoints.down("md")]: {
      height: 30,
    },
  },
  disabledPhotoArtCheckoutBtn: {
    bottom: 3,
    height: 29,
    width: 140,
    float: "right",
    borderRadius: 8,
    color: "#000000",
    boxShadow: "none",
    position: "relative",
    background: "#ebebeb",
    "& p": {
      fontSize: 16,
      fontWeight: 400,
      color: "#000000",
      textTransform: "capitalize",
      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
      },
    },
    "& svg": {
      width: 20,
      height: 20,
    },
    [theme.breakpoints.down("md")]: {
      height: 30,
    },
  },
  dialogCheckoutBtn: {
    color: "#1976d2",
    "& span": {
      fontSize: 15,
    },
  },
}));

export const CheckoutButton = ({
  style,
  variant,
  isGalleryPhotographShoppingCart,
}) => {
  useReduxStateReconcilation();
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isInIframe = isWindowInIframe();
  const isUserLoggedIn = useSelector((state) => isLoggedIn(state))
  const loggedInUserId = useSelector((state) => getUserId(state))
  const trolley_id = useSelector((state) => getTrolleyID(state))
  const trolley = useSelector(() =>
    trolleyList.getObject(trolley_id),
  );
  const trolleyListIsLoading = trolleyList.isLoading();
  const trolleyPhotographListIsSavingObject =
    trolleyPhotographList.getIsSavingObject();
  const [showPageRefreshButton, setShowPageRefreshButton] = useState(false);
  const first_trolley_photograph = head(get(trolley, "trolley_photographs"));
  const is_print_checkout_enabled = get(window, [
    "LOCAL_SETTINGS",
    "ENABLE_PRINT_CHECKOUT",
  ]);
  const number_of_selected_photographs = useSelector(
    (state) =>
      getCustomValue({ state, name: CUSTOM_VALUE_NUM_TROLLEY_PHOTOGRAPHS }) ||
      0,
  );
  //const has_selected_photographs = false;
  const has_selected_photographs = number_of_selected_photographs > 0;

  const trolleyIsUpdating =
    trolleyListIsLoading || trolleyPhotographListIsSavingObject;

  useEffect(() => {
    dispatch(trolleyList.ensureObjectLoaded(trolley_id))
  }, [trolley_id])
  
  useEffect(() => {
    dispatch(trolleyList.ensureTrolleyLoaded());
  }, []);

  const onCheckout = () => {
    try {
      if (trolleyListIsLoading || trolleyPhotographListIsSavingObject) {
        const trolleyListDebugInfo = trolleyList?.debug();
        sentryApi.breadcrumb({
          category: "trolley",
          level: "warning",
          message: "checkout_btn_trolley_still_updating",
          data: {
            trolleyid: trolley?.id,
            ...trolleyListDebugInfo,
            number_of_selected_photographs,
          },
        });
      }

      if (isUserLoggedIn) {
        dispatch(authenticatedTrolleyList.invalidateList());
      }

      goToCheckoutPage(
        navigate,
        trolley,
        has_selected_photographs,
        setShowPageRefreshButton,
        loggedInUserId,
      );
    } catch (e) {
      const trolleyListDebugInfo = trolleyList?.debug();
      sentryApi.error("checkout_btn_error", {
        trolleyid: trolley?.id,
        ...trolleyListDebugInfo,
        number_of_selected_photographs,
        error: e,
      });
    }
  };

  const onRefreshPage = () => window.location.reload();

  const renderEmptyTrolleyMessage = () => {
    dispatch(
      setMessage("Please select at least one photograph before checkout"),
    );
  };

  const renderGalleryCheckoutBtn = () => {
    if (has_selected_photographs) {
      return (
        <Badge
          size="medium"
          color="error"
          onClick={onCheckout}
          disabled={trolleyIsUpdating}
          badgeContent={number_of_selected_photographs}
        >
          <Chip
            size="medium"
            color="primary"
            label="Checkout"
            className={classes.enabledBtn}
          />
        </Badge>
      );
    }
  };

  const renderPhotoArtGalleryCheckoutBtn = () => {
    if (has_selected_photographs) {
      return (
        <div className={classes.checkoutBtnWrapper}>
          <Button
            variant="contained"
            onClick={onCheckout}
            className={classes.photoArtCheckoutBtn}
            endIcon={
              <Badge
                color="error"
                className={style}
                badgeContent={number_of_selected_photographs}
              >
                <ShoppingCartIcon
                  className={
                    isGalleryPhotographShoppingCart && classes.shoppingCartIcon
                  }
                />
              </Badge>
            }
          >
            <p>Checkout</p>
          </Button>
        </div>
      );
    } else {
      return (
        <div className={classes.checkoutBtnWrapper}>
          <Button
            variant="contained"
            onClick={renderEmptyTrolleyMessage}
            className={classes.disabledPhotoArtCheckoutBtn}
            endIcon={
              <Badge
                color="error"
                className={style}
                badgeContent={number_of_selected_photographs}
              >
                <ShoppingCartIcon
                  className={
                    isGalleryPhotographShoppingCart && classes.shoppingCartIcon
                  }
                />
              </Badge>
            }
          >
            <p>Checkout</p>
          </Button>
        </div>
      );
    }
  };

  const renderTabCheckoutBtn = () => {
    if (has_selected_photographs) {
      return (
        <>
          <div className="checkoutButton">
            <Button
              variant="text"
              onClick={onCheckout}
              endIcon={<ShoppingCartIcon />}
            >
              Checkout
            </Button>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="checkoutButton">
            <Button
              variant="text"
              disabled={true}
              endIcon={<ShoppingCartIcon />}
            >
              Checkout
            </Button>
          </div>
        </>
      );
    }
  };

  const renderDialogCheckoutBtn = () => (
    <Button onClick={onCheckout} className={classes.dialogCheckoutBtn}>
      Checkout
    </Button>
  );

  const renderPhotoArtDialogCheckoutBtn = () => (
    <Button onClick={onCheckout}>Checkout</Button>
  );

  return (
    <>
      {variant === "photo_art_tab_view" && renderTabCheckoutBtn()}
      {variant === "dialog_checkout_button" && renderDialogCheckoutBtn()}
      {variant === "gallery_photographs_view" && renderGalleryCheckoutBtn()}
      {variant === "dialog_checkout_button_photo_art" &&
        renderPhotoArtDialogCheckoutBtn()}
      {showPageRefreshButton && (
        <WrappingBackdrop
          showButton={true}
          buttonText="Refresh"
          buttonIcon={<RefreshIcon />}
          buttonClickAction={onRefreshPage}
          buttonStyle={classes.photoArtCheckoutBtn}
          buttonWrapperStyle={classes.checkoutBtnWrapper}
        />
      )}
      {variant === "photo_art_gallery_view" &&
        renderPhotoArtGalleryCheckoutBtn()}
    </>
  );
};

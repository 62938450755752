import nth from "lodash/nth";
import get from "lodash/get";
import { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { makeStyles } from "@material-ui/core";
import "react-lazy-load-image-component/src/effects/blur.css";
import ProgressiveImage from "react-progressive-graceful-image";
import { useIsMobile } from "../../actions/ui";
import { trolleyPhotographList } from "../../v2_actions/trolley_photograph";
import InnerLabel from "./innerlabel";
import styles from "./styles";
import {
  getCustomValue,
  setCustomValue,
  CUSTOM_VALUE_TRIGGER_SCROLLDOWN_TO_GALLERY_PHOTO,
} from "../../v2_actions/custom_value";
import { NUM_PHOTOS_PER_GALLERY_PAGE_WHEN_SHOWING_SHOW_MORE_BUTTON } from "../../v2_actions/gallery";
import { getRandomBackgroundColor } from "../../utils/color";
import { makeLogger } from "../../utils/o11y/logger";

const logger = makeLogger({
  enabled: true,
  label: "GalleryImage",
  color: "red",
});

const useStyles = makeStyles(styles);

const PHOTO_INDEX_OFFSET = 2;
const DELAY_IN_MILLISECONDS_TO_TRIGGER_SCROLLDOWN = 1000;

const GalleryImage = ({
  photo,
  gallery,
  imageProps,
  layoutOptions,
  onSelectImage,
  onImageLoaded,
}) => {
  const ref = useRef();
  const [bgColor] = useState(getRandomBackgroundColor());
  const dispatch = useDispatch();
  const classes = useStyles();
  const isMobile = useIsMobile();
  const photoId = get(photo, "id");
  const [loaded, setLoaded] = useState(false);
  const [visibleInViewPort, setVisibleInViewPort] = useState(null);
  const [showPhotographTimeTakenBox, setShowPhotographTimeTakenBox] =
    useState(false);
  const isGettingImage = useSelector(() =>
    trolleyPhotographList.getIsAddingImage(photo.id),
  );
  const triggerScrolldownToGalleryPhoto = useSelector(
    (state) =>
      getCustomValue({
        state,
        name: CUSTOM_VALUE_TRIGGER_SCROLLDOWN_TO_GALLERY_PHOTO,
      }) || false,
  );

  const indexOfPhoto =
    NUM_PHOTOS_PER_GALLERY_PAGE_WHEN_SHOWING_SHOW_MORE_BUTTON -
    PHOTO_INDEX_OFFSET;
  const photoToScrollDownTo = get(
    nth(get(gallery, "photographs"), indexOfPhoto),
    "id",
  );

  // calculate viewport visibility to optimise UI rendering. This will
  // prevent very expensive component trees (InnerLabel) rendering
  // for off screen thumbnails
  useLayoutEffect(() => {
    const scrollContainer = document.querySelector(
      ".gallery-content-container",
    );
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (visibleInViewPort !== entry.isIntersecting) {
          setVisibleInViewPort(entry.isIntersecting);
        }
      },
      { root: scrollContainer, threshold: 0 },
    );
    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, [ref]);

  useEffect(() => {
    if (triggerScrolldownToGalleryPhoto) {
      setTimeout(() => {
        handleScrollDownToPhoto();
      }, DELAY_IN_MILLISECONDS_TO_TRIGGER_SCROLLDOWN);
      dispatch(
        setCustomValue({
          name: CUSTOM_VALUE_TRIGGER_SCROLLDOWN_TO_GALLERY_PHOTO,
          value: false,
        }),
      );
    }
  }, [triggerScrolldownToGalleryPhoto]);

  const handleScrollDownToPhoto = () => {
    const element = document.getElementById(photoToScrollDownTo);
    if (element) {
      element?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleImgLoad = (e, photoId) => {
    setLoaded(true);
    onImageLoaded(photo);
  };

  const tryRenderHighResVersion = (ev) => {
    if (ev.target.src !== photo.highResSrc) {
      ev.target.src = photo.highResSrc;
      handleImgLoad(photo.id);
    }
  };

  const renderTimeTakenAt = () => {
    return (
      <div className={classes.photographTimeTakenWrapper}>
        <div className={classes.photographTimeTakenBox}>
          <p className={classes.text}>
            <span>
              {moment(photo.photograph_taken_at).utc().format("HH:mm a")}
            </span>
          </p>
        </div>
      </div>
    );
  };
  return (
    <>
      <div
        id={photoId}
        ref={ref}
        style={{
          paddingBottom: 0,
          cursor: "pointer",
          position: "relative",
          borderRadius: "4px",
          alignItems: "center",
          border: "2px solid #fff",
          background: bgColor,
          boxSizing: "content-box",
          width: isMobile ? "100%" : imageProps?.style?.width,
          height: isMobile ? "auto" : imageProps?.style?.height,
          aspectRatio: imageProps.style.aspectRatio,
          padding: `${layoutOptions.padding - 2}px`,
        }}
        key={`${photo.id}-galleryPhoto`}
        onMouseEnter={() => setShowPhotographTimeTakenBox(true)}
        onMouseLeave={() => setShowPhotographTimeTakenBox(false)}
      >
        {isMobile && (
          <ProgressiveImage src={photo.highResSrc} placeholder={imageProps.src}>
            {(src, loading) => (
              <img
                className={`image${loading ? " loading" : " loaded"}`}
                src={src}
                alt={imageProps.alt}
                onClick={() => onSelectImage(photo.id)}
                style={{
                  padding: 0,
                  width: "100%",
                  display: imageProps.style.display,
                  boxSizing: imageProps.style.boxSizing,
                  aspectRatio: imageProps.style.aspectRatio,
                }}
              />
            )}
          </ProgressiveImage>
        )}
        {!isMobile && (
          <img
            visibleByDefault
            alt={imageProps.alt}
            // src={imageProps.src}
            loading="lazy"
            src={photo.highResSrc}
            onClick={() => onSelectImage(photo.id)}
            onLoad={(e) => handleImgLoad(e, photo.id)}
            // onMouseOver={(ev) => tryRenderHighResVersion(ev)}
            style={{
              padding: 0,
              opacity: loaded ? 1 : 0,
              transition: "opacity 0.25s",
              width: "100%",
              display: imageProps.style.display,
              boxSizing: imageProps.style.boxSizing,
              aspectRatio: imageProps.style.aspectRatio,
            }}
          />
        )}
        {onSelectImage && visibleInViewPort && (
          <InnerLabel
            showIcons
            gallery={gallery}
            photoId={photo.id}
            price={photo.price}
            width={photo.width}
            size={photo.filesize}
            photoSrc={photo.smallSrc}
            selected={photo.selected}
            trolleyPhotograph={photo.trolleyPhotograph}
            showLoader={isGettingImage}
            key={`${photo.id}-innerlabel`}
            photographer={photo.photographer}
            showPhotographTimeTakenBox={showPhotographTimeTakenBox}
          />
        )}
        {!isMobile && showPhotographTimeTakenBox && renderTimeTakenAt()}
      </div>
    </>
  );
};

export default GalleryImage;

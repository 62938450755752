import React from "react"
import { Link as RouterLink, useNavigate } from "react-router-dom"
import clsx from "clsx"
import PropTypes from "prop-types"
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  Typography,
} from "@material-ui/core"
import MenuIcon from "@material-ui/icons/Menu"
import HomeIcon from "@material-ui/icons/Home"
import AccountCircleIcon from "@material-ui/icons/AccountCircle"
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart"
import { useIsMobile } from "../../../actions/ui"

const useStyles = makeStyles(() => ({
  root: {
    height: 65,
    borderBottom: "2px solid",
  },
  avatar: {
    width: 50,
    height: 50,
  },
  logoTitle: {
    fontSize: 40,
    color: "black",
  },
  iconbtn: {
    width: 35,
    height: 35,
    color: "black",
  },
  middleiconbtn: {
    width: 30,
    height: 30,
    color: "black",
  },
  text: {
    color: "#606060",
  },
  box: {
    borderRadius: 20,
  },
  greybtn: {
    background: "#b3b3ba !important",
    fontSize: 16,
    color: "white",
    height: 30,
    textTransform: "capitalize",
    borderRadius: 20,
  },
}))

const TopBar = ({ className, showSideBar, setShowSideBar, ...rest }) => {
  const classes = useStyles()
  const isMobile = useIsMobile()
    const navigate = useNavigate()
    

  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
      color="transparent"
      position={!isMobile ? "fixed" : "relative"}
    >
      <Toolbar>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            {!showSideBar && (
              <IconButton onClick={() => setShowSideBar(!showSideBar)}>
                <MenuIcon className={classes.iconbtn} />
              </IconButton>
            )}
            <Hidden mdDown>
              <RouterLink to="/customer">
                <Typography className={classes.logoTitle}>
                  Privacy Policy
                </Typography>
              </RouterLink>
            </Hidden>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <IconButton
              onClick={() => navigate(`/customer/events`)}
            >
              <HomeIcon className={classes.iconbtn} />
            </IconButton>{" "}
            <IconButton
              onClick={() => navigate(`/customer/editprofile`)}
            >
              <AccountCircleIcon className={classes.iconbtn} />
            </IconButton>
            <IconButton
              onClick={() => navigate(`/customer/v2_trolley`)}
            >
              <ShoppingCartIcon className={classes.iconbtn} />
            </IconButton>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
}

export default TopBar

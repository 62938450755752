import { NavInline, Navigation } from "bossfotos-design-system";

import { BossFotosNavBarLogo } from "../../design-system/BossFotosNavBarLogo";
import MenuButton from "../../v3_components/navigation/MenuButton";
import HelpButton from "../../v3_components/navigation/HelpButton";

export const NavBar = ({
  onMenuClicked,
  onHelpClicked,
}: {
  onMenuClicked: () => void;
  onHelpClicked?: () => void;
}) => {
  const handleMenuIconClick = () => {
    onMenuClicked();
  };
  const handleHelpIconClick = () => {
    onHelpClicked && onHelpClicked();
  };

  return (
    <Navigation>
      <MenuButton />
      <NavInline>
        <BossFotosNavBarLogo height={28} />
      </NavInline>
      <HelpButton />
    </Navigation>
  );
};

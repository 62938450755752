import React from "react"
import Grid from "@mui/material/Grid"
import { makeStyles } from "@material-ui/core"
import Typography from "@mui/material/Typography"

const useStyles = makeStyles({
    title: {
      fontWeight: 600
    },
})

export const Description = ({ title, description }) => {
  const classes = useStyles()
  return (
    <>
      {description &&
       <Grid item mt={3}>
         <Typography>
           <b>
             <span className={classes.title}>
              {title}: &nbsp;
             </span>
           </b>
           {description}
         </Typography>
       </Grid>
      }
    </>
  )
}
import Inline from "./Inline";
import { PropMixins } from "../mixins";
import React from "react";
import Stack from "./Stack";
import { useResponsiveBreakpoints } from "./useResponsiveBreakpoints";

type ResponsiveContainerProps = {
  breakpoints: Record<string, React.FunctionComponent<any>>;
};

/**
 * ResponsiveContainer
 * @returns
 */
const ResponsiveContainer = ({
  children,
  breakpoints,
  ...rest
}: React.PropsWithChildren<PropMixins & ResponsiveContainerProps>) => {
  const breakMatch = useResponsiveBreakpoints();
  if (breakMatch) {
    const Comp = breakpoints[breakMatch];
    return <Comp {...rest}>{children}</Comp>;
  }

  return <Stack {...rest}>{children}</Stack>;
};

export default ResponsiveContainer;

import get from "lodash/get";
import React from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { useIsMobile } from "../../actions/ui";
import { useNavigate } from "react-router-dom";
import { css, Global } from "@emotion/react";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import TableCell from "@mui/material/TableCell";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  trolleyPhotographThumbnail: {
    paddingLeft: "0px !important",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      aspectRatio: "3/2",
      display: "flex",
      overflow: "hidden",
      alignItems: "center",
      position: "relative",
      top: "30px",
    },
  },
  trolleyPhotographThumbnailImageWrapper: {
    width: "100%",
    height: "100%",
    margin: "auto",
    display: "flex",
    overflow: "hidden",
    textAlign: "center",
    marginBottom: "0px",
    justifyContent: "center",
    "& img": {
      maxWidth: "80%",
      border: "none",
    },
    [theme.breakpoints.down("sm")]: {
      left: 20,
      bottom: 10,
      position: "relative",
      "& img": {
        maxWidth: "100%",
        border: "none",
        height: "100%",
      },
    },
    [theme.breakpoints.up("md")]: {
      "& img": {
        height: "100%",
        maxHeight: "80%",
        objectFit: "cover",
      },
    },
  },
  trolleyPhotographThumbnailActionButtons: {
    display: "flex",
    margin: "auto",
    float: "left",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px !important",
    position: "relative",
    bottom: "5px",
    right: "15px",
  },
  trolleyPhotographThumbnailEditButton: {
    position: "relative",
    left: "20px",
  },
  trolleyPhotographThumbnailMagnifyButton: {
    position: "relative",
    top: "2px",
    fontSize: "35px",
    cursor: "pointer",
  },
}));

const global_styles = css`
  .MuiTableCell-root .MuiButton-root {
    text-transform: capitalize;
    text-decoration: underline;
    color: #000;
  }
`;

export const CheckoutTrolleyPhotographThumbnail = ({
  trolley_photograph,
  trolleyIsUpdating,
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const isMobile = useIsMobile();

  const onEditPhoto = (trolley_photograph) =>
    navigate(`/customer/v2_trolley/${get(trolley_photograph, "id")}`);

  return (
    <>
      <Global styles={global_styles} />
      <TableCell component="th" scope="row">
        <Stack
          alignItems="center"
          justifyContent="center"
          direction={{ xs: "row", sm: "column" }}
        >
          <div className={classes.trolleyPhotographThumbnailImageWrapper}>
            <img
              className={classes.trolleyPhotographThumbnailImage}
              alt={get(trolley_photograph, ["photograph", "photo_name"])}
              src={get(trolley_photograph, [
                "photograph",
                "gallery_size_file_info",
                "download_url",
              ])}
            />
          </div>
          <div>
            <div className={classes.trolleyPhotographThumbnailActionButtons}>
              <ZoomInIcon
                sx={{ display: { xs: "none", md: "block" } }}
                className={classes.trolleyPhotographThumbnailMagnifyButton}
              />
              <Button
                disabled={trolleyIsUpdating}
                onClick={() => onEditPhoto(trolley_photograph)}
                className={classes.trolleyPhotographThumbnailEditButton}
              >
                Edit
              </Button>
            </div>
          </div>
        </Stack>
      </TableCell>
    </>
  );
};

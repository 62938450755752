import React from "react";
import styles from "./Footer.module.css";

/**
 * Footer
 */
const Footer = ({ children }: React.PropsWithChildren<{}>) => {
  return <div className={styles.footer}>{children}</div>;
};

export default Footer;

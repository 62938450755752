import * as React from 'react'
import {
  Grid,
  makeStyles,
  InputBase,
  IconButton
} from '@material-ui/core'
import SearchIcon from '@mui/icons-material/Search'

const useStyles = makeStyles((theme) => ({
  searchGalleryNameFilter: {
    width: 230,
    padding: 10,
    borderRadius: 5,
    display: 'flex',
    height: '38px',
    marginTop: '10px !important',
    alignItems: 'center',
    position: 'relative',
    border: '#7a7a7a solid 1px',
    justifyContent: 'space-around',
    '& div input': {
      '&::placeholder': {
        fontWeight: 400,
        fontSize: '16px !important'
      }
    },
  },
  searchGalleryNameFilterIcon: {
    paddingRight: 0,
  }
}))

export const SearchGalleryNameFilter = ({ value, handleChange }) => {
  const classes = useStyles()
  return (
    <Grid className={ classes.searchGalleryNameFilter }>
      <InputBase
        value={value}
        onChange={ handleChange }
        placeholder="Search Gallery Names"
      />
      <IconButton
        type="button"
        aria-label="search"
        className={ classes.searchGalleryNameFilterIcon }
      >
        <SearchIcon/>
      </IconButton>
    </Grid>
  );
}

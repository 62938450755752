import {
  Inline,
  NavButton,
  NavInline,
  NavItem,
  Navigation,
} from "bossfotos-design-system";

import React from "react";
import { Strong } from "bossfotos-design-system";
import { useIsMobile } from "../../actions/ui";
import { BossFotosNavBarLogo } from "../../design-system/BossFotosNavBarLogo";
import MenuButton from "../../v3_components/navigation/MenuButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import HelpButton from "../../v3_components/navigation/HelpButton";

export const NavBar = ({
  onBackClicked,
  onHomeClicked,
}: {
  onBackClicked?: () => void;
  onHomeClicked: () => void;
}) => {
  const isMobile = useIsMobile();

  const handleBackClick = () => {
    onBackClicked && onBackClicked();
  };
  const handleHomeClick = () => {
    onHomeClicked();
  };

  return (
    <Navigation>
      <Inline style={{ flexBasis: "50%" }}>
        {onBackClicked && (
          <NavButton hiddenmd={true} onClick={handleBackClick}>
            <ChevronLeftIcon fontSize="large" />
          </NavButton>
        )}
        <MenuButton hiddensm={true} />
        {!isMobile && (
          <NavItem>
            <div
              title="BossFotos"
              style={{ marginLeft: "14px", height: "28px" }}
            >
              <BossFotosNavBarLogo height={24} />
            </div>
          </NavItem>
        )}
      </Inline>
      <NavInline>
        <Strong>Checkout</Strong>
      </NavInline>
      <Inline style={{ flexBasis: "50%" }} justifyContent="right">
        <HelpButton />
      </Inline>
    </Navigation>
  );
};

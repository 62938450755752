import React from "react";
import styles from "./List.module.css";

/**
 * List
 * @returns
 */
const List = ({ children }: React.PropsWithChildren<{}>) => {
  return (
    <div role="list" className={styles.list}>
      {children}
    </div>
  );
};

export default List;

import React from 'react'
import { Backdrop, CircularProgress, Button, Stack } from '@mui/material/'

export default function WrappingBackdrop({
  showButton,
  buttonText,
  buttonIcon,
  buttonStyle,
  buttonClickAction,
  buttonWrapperStyle
}) {

  return (
    <div>
      <Backdrop open sx={{ color: '#fff', zIndex: 999 }}>  
        <Stack
          spacing={ 5 }
          direction='column'
          alignItems='center'
          justifyContent='center'
        >
          <div>
            <CircularProgress color='inherit' />
          </div>
          {showButton &&
           <div className={ buttonWrapperStyle }>
             <Button
               variant='contained'
               className={ buttonStyle }
               onClick={ buttonClickAction }
               endIcon={ buttonIcon }
             >
              <p>{ buttonText }</p>
             </Button>
           </div>
          }
        </Stack>
      </Backdrop>
    </div>
  )
}

import lowerCase from "lodash/lowerCase";
import filter from "lodash/filter";
import get from "lodash/get";
import head from "lodash/head";
import { ItemList } from "../orm";

class PhotographerList extends ItemList {
  getEntityKey() {
    return "customer/photographer";
  }

  loadAllPhotographers() {
    return async (dispatch, getState) => {
      dispatch(this.updatePaginationNumItemsPerPage(1000));
      dispatch(this.fetchListIfNeeded());
    };
  }

  getAllPhotographers() {
    return this.getVisibleObjects();
  }

  getPhotographerForId(photographer_id) {
    return this.getObject(photographer_id);
  }

  getPhotographerIdForNickname(photographer_nickname) {
    const photographer = head(
      filter(
        this.getAllPhotographers(),
        (photographer) =>
          lowerCase(get(photographer, "last_name")) ===
          lowerCase(photographer_nickname),
      ),
    );
    return get(photographer, "id");
  }
}

export const photographerList = new PhotographerList("photographer_default");

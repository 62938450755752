import get from "lodash/get";
import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { CurrencyValue } from "../CurrencyValue";
import { useIsMobile } from "../../actions/ui";
import {
  setCustomValue,
  CUSTOM_VALUE_OPEN_DIGITAL_PRICES_DIALOG,
} from "../../v2_actions/custom_value";

const galleryPhotographPriceBox = {
  color: "#fff",
  width: "100px",
  height: "40px",
  display: "flex",
  fontSize: "16px",
  alignItems: "center",
  fontWeight: "bold",
  borderRadius: "20px",
  justifyContent: "center",
  backgroundColor: "rgb(96 96 96 / 50%)",
};

const extendedGalleryPhotographPriceBox = {
  ...galleryPhotographPriceBox,
  width: "130px",
};

const lowHighPricingDisplay = {
  margin: "auto",
  fontSize: "14px",
  marginTop: 0,
  marginBottom: 0,
  "& span": {
    fontSize: "14px",
  },
};

export default function GalleryPhotographPriceBox({
  price,
  gallery,
  showPhotographTimeTakenBox,
}) {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const galleryLowResPrice = get(gallery, ["gallery_price", "low_res_price"]);

  const openDigitalPricingDialog = () => {
    dispatch(
      setCustomValue({
        name: CUSTOM_VALUE_OPEN_DIGITAL_PRICES_DIALOG,
        value: true,
      }),
    );
  };

  const renderPriceBox = () => {
    return (
      <Box onClick={isMobile && openDigitalPricingDialog}>
        <Typography
          sx={
            galleryLowResPrice
              ? extendedGalleryPhotographPriceBox
              : galleryPhotographPriceBox
          }
        >
          {galleryLowResPrice !== null && (
            <Stack direction="row" useFlexGap flexWrap="wrap">
              <Typography sx={lowHighPricingDisplay}>
                <CurrencyValue
                  showCents={false}
                  value={galleryLowResPrice}
                  country={get(gallery, ["gallery_price", "country"])}
                />
                /
                <CurrencyValue
                  value={price}
                  showCents={false}
                  country={get(gallery, ["gallery_price", "country"])}
                />
              </Typography>
              {(showPhotographTimeTakenBox || isMobile) && (
                <Typography sx={lowHighPricingDisplay}>Low/High Res</Typography>
              )}
            </Stack>
          )}
          {galleryLowResPrice == null && (
            <CurrencyValue
              value={price}
              showCents={false}
              country={get(gallery, ["gallery_price", "country"])}
            />
          )}
        </Typography>
      </Box>
    );
  };

  return <Box>{renderPriceBox()}</Box>;
}

import React from "react";
import { useDispatch } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  AppBar,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
} from "@material-ui/core";
import Box from "@mui/material/Box";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import HomeIcon from "@material-ui/icons/Home";
import LogoImg from "../../../assets/logo/black_logo.png";
import { setMessage } from "../../../actions/trolley";
import { useIsMobile } from "../../../actions/ui";
import { getCheckoutNavBackButtonUrl } from "../../../v2_actions/nav";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 65,
    padding: "0px 25px",
    [theme.breakpoints.down("md")]: {
      padding: "0px 15px",
    },
    "& .MuiToolbar-root": {
      borderBottom: "1px solid #d6d6d6",
      "& button": {
        paddingLeft: "0px",
        paddingRight: "0px",
      },
    },
  },
  iconbtn: {
    width: 30,
    height: 30,
    color: "black",
  },
  backbtn: {
    marginRight: "18px",
  },
  homebtn: {
    marginLeft: "25px",
    marginTop: "8px",
    fontSize: "30px !important",
  },
  cartbtn: {
    paddingTop: "10px",
    "& svg": {
      fontSize: "30px",
    },
  },
  logo: {
    paddingRight: "25px",
  },
  middletitle: {
    paddingTop: "12px",
    fontSize: "18px",
  },
  checkoutBtn: {
    height: 40,
    backgroundColor: "#72bb53 !important",
    textTransform: "capitalize",
    color: "white",
    border: "1px solid black",
    borderRadius: 10,
    fontWeight: "400",
    fontSize: 20,
    padding: 12,
    float: "right",
  },
  checkoutmobileBtn: {
    height: 40,
    width: 250,
    textTransform: "capitalize",
    fontWeight: "400",
    fontSize: 20,
    padding: "10px 25px",
  },
}));

const TopBar = ({ className, checkOut, galleryId, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const customerId = localStorage.getItem("auth_id");

  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
      color="white"
      position={!isMobile ? "fixed" : "relative"}
    >
      <Toolbar>
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          alignItems="center"
        >
          <Box display="flex" justifyContent="space-evenly" alignItems="center">
            <IconButton
              onClick={() => {
                dispatch(setMessage(false));
                navigate(getCheckoutNavBackButtonUrl());
              }}
              pt={"18px"}
            >
              <ArrowBackIosIcon
                className={[classes.iconbtn, classes.backbtn]}
              />
            </IconButton>
            <Hidden mdDown>
              <RouterLink to={`/customer`}>
                <img src={LogoImg} alt="/" className="logo_img_map" />
              </RouterLink>
            </Hidden>
          </Box>
          <Hidden mdDown>
            <Box mr={"180px"}>
              <h1 className={classes.middletitle}>Check Out Trolley</h1>
            </Box>
          </Hidden>
          <Box display="flex" alignItems="center">
            <Hidden lgUp>
              <h1 className={classes.checkoutmobileBtn}>Check Out Trolley</h1>
            </Hidden>
            <IconButton
              onClick={() => {
                // const url = `/customer/events`
                // dispatch(deleteTrolley(galleryId, customerId, navigate, url))

                dispatch(setMessage(false));
                navigate("/customer/events");
              }}
            >
              <HomeIcon className={classes.iconbtn} />
            </IconButton>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};

export default TopBar;

import React, { useState, useEffect } from "react"
import { Link as RouterLink, useNavigate } from "react-router-dom"
import clsx from "clsx"
import PropTypes from "prop-types"
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  Typography,
} from "@material-ui/core"
import MenuIcon from "@material-ui/icons/Menu"
import HomeIcon from "@material-ui/icons/Home"
// import ShoppingCartIcon from "@material-ui/icons/ShoppingCart"
import LogoImg from "../../../assets/logo/black_logo.png"
import { useIsMobile } from "../../../actions/ui"

const useStyles = makeStyles(() => ({
  root: {
    height: 65,
  },
  avatar: {
    width: 50,
    height: 50,
  },
  logo: {
    fontFamily: "Aguafina Script",
    fontSize: 50,
    fontWeight: "400",
    color: "black",
  },
  logoTitle: {
    fontSize: 40,
    color: "black",
  },
  iconbtn: {
    width: 35,
    height: 35,
    color: "black",
  },
  middleiconbtn: {
    width: 30,
    height: 30,
    color: "black",
  },
  text: {
    color: "#606060",
  },
  box: {
    borderRadius: 20,
  },
  greybtn: {
    background: "#b3b3ba !important",
    fontSize: 16,
    color: "white",
    height: 30,
    textTransform: "capitalize",
    borderRadius: 20,
  },
}))

const TopBar = ({ className, showSideBar, setShowSideBar, ...rest }) => {
  const navigate = useNavigate()
  const classes = useStyles()
  const isMobile = useIsMobile()
  // const loginUser = localStorage.getItem("auth_id")
    const [width, setWidth] = useState(window.innerWidth)
    

  const goPage = () => {
    navigate(`/customer/events`)
  }

  useEffect(() => {
    window.addEventListener("resize", updateWidth)
    return () => window.removeEventListener("resize", updateWidth)
  })

  const updateWidth = () => {
    setWidth(window.innerWidth)
  }

  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
      color="transparent"
      position={!isMobile ? "fixed" : "relative"}
    >
      <Toolbar>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Hidden mdDown>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="20%"
            >
              {!showSideBar && (
                <IconButton onClick={() => setShowSideBar(!showSideBar)}>
                  <MenuIcon className={classes.iconbtn} />
                </IconButton>
              )}
              <Hidden mdDown>
                <RouterLink to={`/customer`}>
                  <img src={LogoImg} alt="/" className="logo_img_map" />
                </RouterLink>
              </Hidden>
              <Hidden lgUp>
                <RouterLink to={`/customer`}>
                  <img src={LogoImg} alt="/" className="logo_img_mobile" />
                </RouterLink>
              </Hidden>
            </Box>
          </Hidden>
          <Hidden lgUp>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              {!showSideBar && (
                <IconButton onClick={() => setShowSideBar(!showSideBar)}>
                  <MenuIcon className={classes.iconbtn} />
                </IconButton>
              )}
              <Hidden mdDown>
                <RouterLink to={`/customer`}>
                  <img src={LogoImg} alt="/" className="logo_img_map" />
                </RouterLink>
              </Hidden>
              <Hidden lgUp>
                <RouterLink to={`/customer`}>
                  <img
                    src={LogoImg}
                    alt="/"
                    className={
                      width > 750 ? "logo_img_how_middle" : "logo_img_mobile"
                    }
                  />
                </RouterLink>
              </Hidden>
            </Box>
          </Hidden>
          <Hidden mdDown>
            <Box display="flex">
              <RouterLink to={`/customer`}>
                <Typography className={classes.logoTitle}>
                  How it Works
                </Typography>
              </RouterLink>
            </Box>
          </Hidden>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <IconButton onClick={goPage}>
              <HomeIcon className={classes.iconbtn} />
            </IconButton>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
}

export default TopBar

import get from "lodash/get";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { makeStyles } from "@material-ui/core";
import { useIsMobile } from "../../actions/ui";
import RangeSlider from "react-time-range-slider";
import {
  addMinutesToTime,
  subtractMinutesToTime,
  convertDBTimeTo12HrTime,
  displayTimeAs12hourHHMM,
  DEFAULT_TIME_END_12HR,
  DEFAULT_TIME_START_12HR,
} from "../../v2_actions/time";

const TICK_INCREMENT_MINUTES = 1;

const useStyles = makeStyles((theme) => ({
  rangeSliderText: {
    fontSize: 16,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  timeFilterSliderIcon: {
    top: 3,
    width: 16,
    position: "relative",
  },
  rangeSliderTextWrapper: {
    height: 25,
  },
  cancelButton: {
    height: 28,
    width: 134,
    background: "#929292",
    "& p": {
      fontSize: 12,
      fontWeight: 600,
    },
  },
  setTimeButton: {
    height: 28,
    width: 134,
    "& p": {
      fontSize: 12,
      fontWeight: 600,
    },
  },
}));

export default function TimeRangeSlider({
  value,
  ranges,
  minValue,
  maxValue,
  handleChange,
  onClickSetTime,
  changeStartHandler,
  changeCompleteHandler,
  onClickCancelRangeSlider,
  isFacialRecognitionFilterActive,
}) {
  const classes = useStyles();
  const isMobile = useIsMobile();
  let minTimeLessMinutes = subtractMinutesToTime(
    TICK_INCREMENT_MINUTES,
    minValue,
  );
  let min = isFacialRecognitionFilterActive
    ? DEFAULT_TIME_START_12HR
    : convertDBTimeTo12HrTime(minValue);
  let maxTimePlusMinutes = addMinutesToTime(TICK_INCREMENT_MINUTES, maxValue);
  let max = isFacialRecognitionFilterActive
    ? DEFAULT_TIME_END_12HR
    : convertDBTimeTo12HrTime(maxTimePlusMinutes);
  const [sliderIsPristine, setSliderIsPristine] = useState(true);

  useEffect(() => {
    if (value.start !== DEFAULT_TIME_START_12HR) {
      setSliderIsPristine(false);
    }
  }, [handleChange]);

  return (
    <Box sx={{ width: 300 }}>
      <RangeSlider
        format={12}
        value={value}
        minValue={min}
        maxValue={max}
        name="time_range"
        disabled={false}
        onChange={handleChange}
        step={TICK_INCREMENT_MINUTES}
        onChangeStart={changeStartHandler}
        onChangeComplete={changeCompleteHandler}
      />
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        className={classes.rangeSliderTextWrapper}
      >
        <p className={classes.rangeSliderText}>
          {sliderIsPristine
            ? displayTimeAs12hourHHMM(min)
            : displayTimeAs12hourHHMM(get(value, "start"))}
        </p>
        <p className={classes.rangeSliderText}>
          {sliderIsPristine
            ? displayTimeAs12hourHHMM(max)
            : displayTimeAs12hourHHMM(get(value, "end"))}
        </p>
      </Stack>
      {isMobile && (
        <Stack
          mt={3}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Button
            variant="contained"
            sx={{ background: "#929292" }}
            className={classes.cancelButton}
            onClick={onClickCancelRangeSlider}
          >
            <p>Cancel</p>
          </Button>
          <Button
            variant="contained"
            onClick={onClickSetTime}
            className={classes.setTimeButton}
          >
            <p>Set Time</p>
          </Button>
        </Stack>
      )}
    </Box>
  );
}

import axios from "axios"
import { clearAllStorage } from '../actions/user'

export const makeHeader = () => {
    let token = localStorage.getItem("token")
    let config = {
        headers: {
            Authorization: "Token " + token,
            'X-BOSSFOTOS-AUTHENTICATION-TOKEN': 'Token ' + token,
        },
    }
    return config
}


export const signIn = async (data) => {
    return await axios.post(
        window.LOCAL_SETTINGS.API_BASE + "/api/login/login/",
        data
    )
}

export const signUp = async (data) => {
    removeLocalStorage()
    return await axios.post(
        window.LOCAL_SETTINGS.API_BASE + "/api/signup/signup/",
        data
    )
}

export const signOut = async (auth_email) => {
    removeLocalStorage()
}

export const forgotPasswordApi = async (data) => {
    return await axios.post(
        window.LOCAL_SETTINGS.API_BASE + "/api/login/send_otp_email/",
        data
    )
}

export const setPasswordApi = async (data) => {
    return await axios.post(
        window.LOCAL_SETTINGS.API_BASE + "/api/login/set_password/",
        data
    )
}

export const edifProfileApi = async (data) => {
    return await axios.post(
        window.LOCAL_SETTINGS.API_BASE + "/api/editprofile/",
        data,
        makeHeader()
    )
}

export const getUserInfo = async () => {
    return await axios.get(
        window.LOCAL_SETTINGS.API_BASE + "/api/getuserinfo/customer_user_info/",
        makeHeader()
    )
}

export const removeLocalStorage = () => {
    clearAllStorage()
}

import { makeLogger } from "../../utils/o11y/logger";
import { useSearchParams } from "react-router-dom";

const logger = makeLogger({
  enabled: true,
  label: "useGalleryFilters",
  color: "orange",
});

const filters = [
  "date",
  "activity",
  // "country",
  "photographers",
  "any",
] as const;

export type FilterKeys = (typeof filters)[number];

type FilterParams = Record<FilterKeys, string | undefined>; //{ [K in FilterKeys]: string | undefined };

type PartialFilterUpdate = Partial<FilterParams>;

type useGalleryFilterSearchParamsResult = [
  FilterParams,
  (params: PartialFilterUpdate) => string,
  string,
];

const undefinedIfNull = (value: any) => (value === null ? undefined : value);

/**
 * Helper util to wrap the URL search/query params
 * @returns
 */
export const useGalleryFilterSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const filterVals = filters.map((f) => ({
    [f]: undefinedIfNull(searchParams.get(f)),
  }));

  const syncGalleryFilterSearchParams = (params: PartialFilterUpdate) => {
    let settingParams: URLSearchParams | undefined;
    setSearchParams((prev) => {
      Object.keys(params).forEach((key) => {
        const value = params[key as FilterKeys];
        value ? prev.set(key, value) : prev.delete(key);
      });
      settingParams = prev;
      return prev;
    });
    return settingParams?.toString() ?? "";
  };

  return [
    Object.assign({}, ...filterVals), // returns URL params as key/value pair object
    syncGalleryFilterSearchParams, // func to update/sync the URL params based on local app state
    searchParams.toString(), // params as URL string for inclusion in navigation urls
  ] as useGalleryFilterSearchParamsResult;
};

import * as Sentry from "@sentry/browser";

export const initialiseSentry = (
  sentryDsn: string | undefined,
  isDev: boolean,
) => {
  if (sentryDsn && !isDev) {
    Sentry.init({
      dsn: sentryDsn,
      release: "bossfotos_customer",
      environment: isDev ? "development" : "production",
      integrations: [
        Sentry.browserTracingIntegration({
          traceFetch: true,
          traceXHR: true,
        }),
        Sentry.captureConsoleIntegration({
          levels: ["error"],
        }),
      ],
      // this need server support to allow "baggage" header
      // tracePropagationTargets: ["localhost", /^https:\/\/api\.irestaging\.bossfotos\.com\/api/, /^https:\/\/api\.bossfotos\.com\/api/],
      tracesSampleRate: 1.0,
      // debug: true, // enable to see what sentry sends in browser console
      ignoreErrors: [
        "%cBF:",
        "Invalid DOM property",
        "Each child in a list should have a unique",
        "validateDOMNesting",
        "React does not recognize",
        "Invalid prop",
        'prop "value" must be in between ',
        "Warning: A component is changing an uncontrolled input",
      ],
    });
  }
};

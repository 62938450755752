import size from "lodash/size";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import head from "lodash/head";
import get from "lodash/get";
import React, { useEffect, useState } from "react";
import {
  galleryEventList,
  galleryEventListForEventUrl,
} from "../v2_actions/gallery_event";

import { AlbumCard } from "./AlbumCard";
import { GalleryCard } from "./GalleryCard";
import { GalleryEventCard } from "./GalleryEventCard";
import LoadingSpinner from "../views/components/LoadingSpinner";
import { SeeMoreButton } from "./SeeMoreButton";
import { albumListForEventTab } from "../v2_actions/customer_album";
import { briefGalleryList } from "../v2_actions/brief_gallery";
import { makeStyles } from "@material-ui/core";
import { mergeGalleriesAndAlbums } from "../v2_actions/gallery";
import responsive from "../mobile";
import { useDispatch } from "react-redux";
import { useEventFiltersBroker } from "./hooks/useEventFiltersBroker";
import { useNavigate } from "react-router-dom";
import { useReduxStateReconcilation } from "../v2_components/hooks/useReduxStateReconcilation";

const useStyles = makeStyles((theme) => ({
  galleryScrollContainer: {
    height: "80vh",
    overflowY: "scroll",
  },
  galleryEventCard: {
    width: "95%",
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gap: "10px",
    margin: "auto",
    [`@media only screen and  (min-width:${responsive.getTabMinWidthPixels()})and (max-width: ${responsive.getTabMaxWidthPixels()})`]:
      {
        gridTemplateColumns: "repeat(4, 1fr)",
      },
    [`@media only screen and (max-width:${responsive.getMobMaxWidthPixels()})`]:
      {
        gridTemplateColumns: "repeat(2, 1fr)",
        width: "100%",
        height: "80vh",
        marginTop: "60px",
        overflowX: "hidden",
        position: "relative",
        zIndex: "999",
      },
  },
  galleryCard: {
    width: "95%",
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gap: "10px",
    margin: "auto",
    [`@media only screen and  (min-width:${responsive.getTabMinWidthPixels()})and (max-width: ${responsive.getTabMaxWidthPixels()})`]:
      {
        gridTemplateColumns: "repeat(4, 1fr)",
      },
    [`@media only screen and (max-width:${responsive.getMobMaxWidthPixels()})`]:
      {
        gridTemplateColumns: "repeat(1, 1fr)",
        width: "100%",
        height: "auto",
        marginTop: "60px",
        position: "relative",
        zIndex: "999",
      },
  },
  noGalleriesDiv: {
    margin: "0px 40px",
    position: "absolute",
    top: "200px",
    [`@media only screen and (max-width:${responsive.getMobMaxWidthPixels()})`]:
      {
        position: "relative",
        top: "50px",
      },
  },
}));

function MobileEventList({
  gallery_event_name,
  album_name,
  publicUrlPathForGalleryEvent,
}) {
  useReduxStateReconcilation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [displayMode, setDisplayMode] = useState("gallery_event");
  const albums = albumListForEventTab.getVisibleObjects();
  const galleries = briefGalleryList.getFilteredEventGalleries();
  const gallery_events = galleryEventList.getVisibleObjects();
  const gallery_event = head(galleryEventListForEventUrl.getVisibleObjects());
  const mergedGalleriesAndAlbums = mergeGalleriesAndAlbums({
    galleries,
    albums,
  });
  const isBriefGalleryListLoading = briefGalleryList.isLoading();
  const isGalleryEventListLoading = galleryEventList.isLoading();
  const isGalleryEventListForEventUrlLoading =
    galleryEventListForEventUrl.isLoading();

  useEffect(() => {
    if (publicUrlPathForGalleryEvent && !isGalleryEventListLoading) {
      dispatch(
        galleryEventListForEventUrl.updateListFilter({
          public_or_direct_url: publicUrlPathForGalleryEvent,
        }),
      );
      dispatch(galleryEventListForEventUrl.fetchListIfNeeded());
      gallery_event &&
        navigate(`/customer/events/${get(gallery_event, "name")}`);
      if (
        get(gallery_event, "direct_access_url") === publicUrlPathForGalleryEvent
      ) {
        galleryEventListForEventUrl.setPasswordBypassForGalleryEvent(
          get(gallery_event, "id"),
        );
      }
    }
  }, [
    publicUrlPathForGalleryEvent,
    isGalleryEventListLoading,
    isGalleryEventListForEventUrlLoading,
  ]);

  useEffect(() => {
    if (gallery_event_name && !album_name) {
      setDisplayMode("gallery");
      dispatch(
        albumListForEventTab.updateListFilter({
          gallery_event_name: gallery_event_name,
        }),
      );
      dispatch(
        briefGalleryList.updateListFilter({
          album: null,
          gallery_event: gallery_event_name,
          exclude_galleries_with_an_album: true,
        }),
      );
    } else if (gallery_event_name && album_name) {
      setDisplayMode("album");
      dispatch(
        briefGalleryList.updateListFilter({
          album: album_name,
          gallery_event: gallery_event_name,
          exclude_galleries_with_an_album: false,
        }),
      );
    } else {
      setDisplayMode("gallery_event");
    }
    dispatch(albumListForEventTab.invalidateList());
    dispatch(galleryEventListForEventUrl.invalidateList());
    dispatch(albumListForEventTab.fetchListIfNeeded());
    dispatch(
      briefGalleryList.fetchListIfNeeded({
        callSource: "MobileEventList",
      }),
    );
  }, [gallery_event_name, album_name]);

  const renderGalleriesAndAlbums = () => (
    <>
      {map(mergedGalleriesAndAlbums, (item, index) => (
        <React.Fragment key={index}>
          {item.type === "gallery" && (
            <GalleryCard
              tab="events"
              gallery={item}
              gallery_id={item.id}
              title={item.gallery_name}
              image={item.gallery_photo_url}
            />
          )}
          {item.type !== "gallery" && (
            <AlbumCard
              tab="events"
              title={item.name}
              album_id={item.id}
              image={item.album_photograph}
              gallery_event_name={item.gallery_event_name}
            />
          )}
        </React.Fragment>
      ))}
    </>
  );

  const renderGalleries = () => (
    <>
      {map(galleries, (gallery, index) => (
        <GalleryCard
          tab="events"
          key={index}
          gallery={gallery}
          gallery_id={gallery.id}
          title={gallery.gallery_name}
          image={gallery.gallery_photo_url}
        />
      ))}
    </>
  );

  const renderGalleryEvents = () => (
    <>
      {map(gallery_events, (gallery_event, index) => (
        <GalleryEventCard
          tab="events"
          key={index}
          title={gallery_event.name}
          gallery_event_id={gallery_event.id}
          gallery_event_name={gallery_event.name}
          image={get(gallery_event, [
            "event_photograph",
            "gallery_size_file_info",
            "download_url",
          ])}
        />
      ))}
    </>
  );

  useEventFiltersBroker({
    galleryEventUrlKey: gallery_event_name,
    albumUrlKey: album_name,
    event_page: "true",
  });

  return (
    <div
      className={`${classes.galleryScrollContainer} gallery-scroll-container`}
    >
      <div>
        {(isEmpty(albums) === false || isEmpty(galleries) === false) &&
          !isBriefGalleryListLoading && (
            <>
              {displayMode === "gallery" && (
                <div className={`${classes.galleryCard} gallery-card`}>
                  {renderGalleriesAndAlbums()}
                </div>
              )}
            </>
          )}
        {isEmpty(galleries) === false && (
          <>
            {displayMode === "album" && (
              <div className={`${classes.galleryCard} gallery-card`}>
                {renderGalleries()}
              </div>
            )}
          </>
        )}
        {displayMode === "gallery_event" && (
          <div className={`${classes.galleryEventCard} gallery-event-card`}>
            {renderGalleryEvents()}
          </div>
        )}
      </div>
      {isGalleryEventListLoading && <LoadingSpinner />}
      {!isGalleryEventListLoading &&
        !isBriefGalleryListLoading &&
        isEmpty(galleries) &&
        isEmpty(gallery_events) &&
        isEmpty(albums) && (
          <div className={classes.noGalleriesDiv}>
            <h2>No events nor galleries match your search.</h2>
            <p>Try expanding the date filter or clearing other filters.</p>
          </div>
        )}
      {size(galleries) > 0 && (
        <div className={classes.mobiMapButton}>
          <SeeMoreButton list={briefGalleryList} />
        </div>
      )}
    </div>
  );
}

export default MobileEventList;

import SearchIcon from "@material-ui/icons/Search";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";
import SettingsIcon from "@material-ui/icons/Settings";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import {
  CUSTOMER_UI_BASE_PATH,
  BOSSFOTOS_INFO_WEBSITE,
} from "./v2_actions/routes";

export const itemFirstPart = [
  {
    title: "Search for a Gallery",
    icon: SearchIcon,
    href: `${CUSTOMER_UI_BASE_PATH}/`,
  },
  {
    title: "Shopping Trolley",
    icon: ShoppingCartIcon,
    href: `${CUSTOMER_UI_BASE_PATH}/shopping`,
  },
  {
    title: "My Purchased Fotos",
    icon: VideoLibraryIcon,
    href: `${CUSTOMER_UI_BASE_PATH}/purchased`,
  },
];

export const itemSecondPart = [
  {
    title: "Edit Profile",
    icon: SettingsIcon,
    href: `${CUSTOMER_UI_BASE_PATH}/editprofile`,
  },
  {
    title: "Help-FAQs",
    icon: HelpOutlineIcon,
    openInNewBrowserTab: true,
    href: `${BOSSFOTOS_INFO_WEBSITE}/info-centre`,
  },
];

export const itemThirdPart = [
  {
    title: "Photographers",
    href: `/photographer`,
    openAsWindowLocation: true,
  },
  {
    title: "How it works",
    href: `${CUSTOMER_UI_BASE_PATH}/howitworks`,
  },
  {
    title: "Term of Service",
    openInNewBrowserTab: true,
    href: `${BOSSFOTOS_INFO_WEBSITE}/terms`,
  },
  {
    title: "Privacy Policy",
    openInNewBrowserTab: true,
    href: `${BOSSFOTOS_INFO_WEBSITE}/privacy-policy/`,
  },
];

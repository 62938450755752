import map from "lodash/map";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  makeStyles,
  Typography,
  Card,
} from "@material-ui/core";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { createTheme } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import HomeIcon from "@material-ui/icons/Home";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { useIsMobile } from "../../../actions/ui";
import { briefGalleryList } from "../../../v2_actions/brief_gallery";
import { galleryEventList } from "../../../v2_actions/gallery_event";
import { constructGalleryFilter } from "../../../v2_actions/gallery";
import { photographerDropdownOptions } from "../../../v2_actions/dropdown_options";

const theme = createTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        "& $notchedOutline": {
          borderColor: "black",
          borderRadius: 20,
        },
        "&&& $input": {
          padding: "1px",
        },
      },
    },
  },
});

const useStyles = makeStyles(() => ({
  root: {
    height: 65,
    borderBottom: "1px solid #eee",
  },
  avatar: {
    width: 50,
    height: 50,
  },
  logoTitle: {
    fontSize: 30,
    color: "#000000",
  },
  ipadTitle: {
    fontSize: 20,
    color: "#000000",
  },
  iconbtn: {
    width: 25,
    height: 25,
    color: "black",
  },
  text: {
    color: "#606060",
  },
  box: {
    borderRadius: 20,
  },
  greybtn: {
    background: "#b3b3ba !important",
    fontSize: 16,
    color: "white",
    height: 30,
    textTransform: "capitalize",
    borderRadius: 20,
  },
}));

const TopBar = ({
  className,
  showSideBar,
  setShowSideBar,
  photographer,
  ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const [photoDropFilter, setPhotoDropFilter] = useState(null);
  const [photographDrop, setPhotographDrop] = useState(true);

  const photographerOptions = photographerDropdownOptions.getAsOptions();

  useEffect(() => {
    dispatch(photographerDropdownOptions.updatePaginationNumItemsPerPage(1000));
    dispatch(
      photographerDropdownOptions.fetchListIfNeeded({ callSource: "TopBar" }),
    );
  }, []);

  useEffect(() => {
    const constructedFilter = constructGalleryFilter({
      photographers: [photoDropFilter],
    });
    dispatch(briefGalleryList.trySetFilter(constructedFilter));
    dispatch(galleryEventList.trySetFilter(constructedFilter));
  }, [photoDropFilter]);

  const filterSaveList = useSelector(
    (state) => state.galleryReducer.filterSaveList,
  );
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  });

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  const onPhotographerFilterChanged = (e) => {
    setPhotoDropFilter(e.target.value);
    setPhotographerIdAsURLParam(e.target.value);
    setPhotographDrop(false);
  };

  const setPhotographerIdAsURLParam = (photographer_id) => {
    navigate(`/customer/bookboss?photographer=${photographer_id}`);
  };

  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
      color="default"
      position={!isMobile ? "fixed" : "relative"}
    >
      <Toolbar>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            {!showSideBar && (
              <IconButton onClick={() => setShowSideBar(!showSideBar)}>
                <MenuIcon className={classes.iconbtn} />
              </IconButton>
            )}
            {!isMobile && (
              <RouterLink to="/customer">
                <Typography
                  className={
                    width > 1000 ? classes.logoTitle : classes.ipadTitle
                  }
                >
                  View/Book our Photographers
                </Typography>
              </RouterLink>
            )}
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width={!isMobile ? "50%" : "90%"}
            bgcolor="whitesmoke"
          >
            <>
              <div className={classes.drop5}>
                <Card>
                  <FormControl sx={{ m: 1, minWidth: 170 }}>
                    <InputLabel
                      id="demo-simple-select-helper-label"
                      className={classes.inputLabel}
                    >
                      <i className="fas fa-camera"></i>
                      Photographer
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      label="Photograph"
                      inputProps={{ "aria-label": "Without label" }}
                      className={classes.select}
                      onChange={onPhotographerFilterChanged}
                    >
                      <MenuItem value={null} className={classes.menuItem}>
                        All
                      </MenuItem>
                      {map(photographerOptions, (option) => (
                        <MenuItem
                          value={option.value}
                          className={classes.menuItem}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Card>
              </div>
            </>
            <Box display="flex">
              <IconButton onClick={() => navigate(`/customer/events`)}>
                <HomeIcon className={classes.iconbtn} />
              </IconButton>
              <IconButton>
                <ShoppingCartIcon className={classes.iconbtn} />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};

export default TopBar;

import get from "lodash/get";
export const SET_CUSTOM_VALUE = "SET_CUSTOM_VALUE";

export const CUSTOM_VALUE_NUM_TROLLEY_PHOTOGRAPHS = "num_trolley_photographs";
export const CUSTOM_VALUE_OPEN_DIGITAL_PRICES_DIALOG =
  "open_digital_prices_dialog";
export const CUSTOM_VALUE_TRIGGER_SCROLLDOWN_TO_GALLERY_PHOTO =
  "trigger_scrolldown_to_gallery_photo";

export function setCustomValue({ name, value }) {
  return {
    type: SET_CUSTOM_VALUE,
    name: name,
    value: value,
  };
}

export function incrementCustomValue({ state, name, count }) {
  let value = getCustomValue({ state, name }) || 0;
  return setCustomValue({ name: name, value: value + (count || 1) });
}

export function decrementCustomValue({ state, name, count }) {
  let value = getCustomValue({ state, name }) || 0;
  return setCustomValue({ name: name, value: value - (count || 1) });
}

export function getCustomValue({ state, name }) {
  const value = get(state, ["customValueReducer", name]);
  return value;
}

import get from "lodash/get";
import React from "react";
import { makeStyles, FormLabel } from "@material-ui/core";
import TextField from "@mui/material/TextField";

const useStyles = makeStyles({
  error: {
    color: "red",
  },
  formLabel: {
    color: "#0c59cf",
    fontSize: "20px",
  },
});

export const MaterialTextInput = ({
  name,
  form,
  type = "text",
  field_label = "",
  placeholder = "",
  variant = "standard",
  ...props
}) => {
  const {
    formState: { errors },
  } = form;
  const field_errors = get(errors, name);
  const classes = useStyles();

  return (
    <>
      <FormLabel className={classes.formLabel}>{field_label}</FormLabel>
      <TextField
        type={type}
        {...props}
        variant={variant}
        shouldValidate={true}
        sx={{ width: "100%" }}
        placeholder={placeholder}
      />
      {field_errors && <p className={classes.error}>{field_errors.message}</p>}
    </>
  );
};

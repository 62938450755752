import {
  getMobMaxWidthPixels,
  getTabMaxWidthPixels,
  getTabMinWidthPixels,
} from "../layout/mobile";
import {
  isWindowInIframe,
  replaceDashesWithSpaces,
} from "../../v2_actions/nav";
import MenuButton from "../../v3_components/navigation/MenuButton";
import HelpButton from "../../v3_components/navigation/HelpButton";

import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import { BossFotosNavBarLogo } from "../../design-system/BossFotosNavBarLogo";
import EventPageSearchBar from "../eventpage/EventPageSearchBar";
import { Link } from "react-router-dom";
import PhotosLogo from "../../v2_images/BossFotos-logo-black on transparent.png";
import { galleryEventList } from "../../v2_actions/gallery_event";
import { makeStyles } from "@material-ui/core";
import { useIsMobile } from "../../actions/ui";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  MainNavDiv: {
    display: "flex",
    position: "sticky",
    top: "0",
    background: "var(--navigation-bg-color)",
    color: "var(--navigation-color)",
    zIndex: 999,
    height: "60px",
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      position: "relative",
      zIndex: "1000",
      paddingTop: "20px",
    },
  },
  ContextMenuTarget: {
    cursor: "pointer",
  },
  NavBar: {
    display: "flex",
    marginLeft: "40px",
    transform: "translateY(-5%)",
    width: "95%",
    "& i": {
      fontSize: "25px",
      [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
        fontSize: "22px",
        paddingRight: "10px",
      },
    },
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      marginLeft: "0px",
      transform: "translateY(-40%)",
      width: "100%",
    },
  },
  NavBarSiteLogo: {
    width: "auto",
    flexGrow: 2,
    alignItems: "center",
    "& img": {
      display: "flex",
      justifyContent: "center",
      marginTop: "5px",
      width: "175px!important",
      height: "35px!important",
    },
  },
  secOneNav: {
    display: "flex",
    alignItems: "center",
    width: "30%",
    textUnderlinePosition: "under",
    "& i": {
      fontSize: "25px",
      paddingLeft: "10px",
      paddingRight: "20px",

      [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
        fontSize: "23px",
        paddingLeft: "10px",
        paddingRight: "0px",
      },
    },
    "& img": {
      width: "200px",
      height: "40px",
      [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
        width: "150px",
        height: "30px",
        margin: "auto",
        position: "relative",
        right: "9px",
      },
    },
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      width: "100%",
    },
  },
  galleryEventLogo: {
    "& img": {
      width: "40px",
      height: "40px",
      [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
        width: "30px",
        height: "30px",
        margin: "auto",
        position: "relative",
        right: "9px",
      },
    },
  },
  secTwoNav: {
    position: "absolute",
    left: "44%",
    transform: "translateX(-50%)",
    transform: "translateY(-19%)",
    top: "0%",
    width: "50%",
    zIndex: "100",

    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      left: "0px",
      transform: "translateX(0%)",
      transform: "translateY(30%)",
      top: "15%",
      width: "100%",
    },
    "& ul": {
      display: "flex",
      paddingLeft: "0px",
      [`@media only screen and (min-width:${getMobMaxWidthPixels()})`]: {
        float: "right",
      },
      "& li": {
        cursor: "pointer",
        borderRight: "2px solid gray",
        [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
          cursor: "pointer",
          border: "none",
          borderLeft: "none",
        },
      },
    },
  },
  underline: {
    width: "95%",
    borderBottom: "2px solid gray",
    marginTop: "1px",
    marginLeft: "40px",
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      display: "none",
    },
  },
  LinkCls: {
    textDecoration: "none",
    color: "black",
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      width: "100%",
    },
  },
  photoOfMeImg: {
    width: "18px",
    height: "18px",
    marginRight: "10px",
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      display: "none",
    },
  },
  NavLi: {
    listStyle: "none",
    paddingRight: "30px",
    paddingLeft: "30px",
    [`@media only screen and (min-width:${getTabMinWidthPixels()})and (max-width: ${getTabMaxWidthPixels()})`]:
      {
        paddingRight: "10px",
        paddingLeft: "10px",
      },
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      listStyle: "none",
      paddingRight: "7px",
      paddingLeft: "0px",
      border: "1px solid #0276f8",
      textAlign: "center",
    },
    "& span": {
      display: "flex",
      height: "39px",
      alignItems: "center",
      "& i": {
        fontSize: "18px",
        marginRight: "10px",
        [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
          display: "none",
        },
      },
      "& p": {
        [`@media only screen and (min-width:${getTabMinWidthPixels()})and (max-width: ${getTabMaxWidthPixels()}) `]:
          {
            fontSize: "12px",
            width: "105px",
            textAlign: "center",
          },
        [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
          width: "100%",
          paddingLeft: "10px",
          fontSize: "14px",
        },
      },
    },
  },
  quesIconHyperLink: {
    color: "var(--navigation-color)",
    flexGrow: "1",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  quesIcon: {
    fontWeight: "400",
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      position: "absolute",
      top: "0%",
      fontWeight: "400",
      right: "0",
      fontSize: "12px",
    },
  },
  inactiveTab: {
    borderTop: "none",
    borderBottom: "none",
    background: "#ebebeb",
    paddingBottom: "3px",
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      border: "1px solid #0276f8 !important",
      background: "white",
      color: "#0276f8",
      paddingBottom: "0px",
    },
    "& span": {
      "& p": {
        fontWeight: "400",
      },
    },
  },
  activeTab: {
    borderBottom: "4px solid white",
    borderTop: "2px solid gray",
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      border: "1px solid #0276f8 !important",
      zIndex: "1 !important",
      borderRight: "none !important",
      backgroundColor: "#0276f8",
      color: "white !important",
    },
    "& span": {
      "& img": {
        width: "18px",
        height: "18px",
        marginRight: "10px",
        [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
          display: "none",
        },
      },
      "& p": {
        fontWeight: "600",
        [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
          fontWeight: "bold",
        },
      },
    },
  },
  mapTab: {
    borderLeft: "2px solid gray",
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      borderColor: " #0276f8",
    },
  },
  eventsTab: {},
  photoArtTab: {
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      color: "#0276f8",
    },
  },
  secNavTabs: {
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      width: "101%",
    },
  },
});

export const NavBar = ({ setShowContextMenu, activeTab }) => {
  const classes = useStyles();
  const isMobile = useIsMobile();
  const isInIframe = isWindowInIframe();
  const { gallery_event_name } = useParams();
  const { pathname } = useLocation();
  const [activeTabName, setActiveTabName] = useState(activeTab);
  const isGalleryEventListLoading = useSelector(() =>
    galleryEventList.isLoading(),
  );
  const [galleryEventLogo, setGalleryEventLogo] = useState(null);

  const onActivateTab = (tabName) => {
    setActiveTabName(tabName);
  };

  useEffect(() => {
    if (gallery_event_name) {
      galleryEventList
        .getGalleryEventLogoForGalleryName(
          replaceDashesWithSpaces(gallery_event_name),
        )
        .then((galleryEventLogoUrl) => {
          setGalleryEventLogo(galleryEventLogoUrl);
        });
    } else {
      setGalleryEventLogo(null);
    }
  }, [gallery_event_name, isGalleryEventListLoading]);

  const showSearchBar =
    !isInIframe && !isMobile && pathname !== "/customer/map/";

  return (
    <div className={classes.MainNavDiv}>
      <div className={classes.NavBar}>
        <div
          className={`${classes.secOneNav}
                 ${galleryEventLogo && classes.galleryEventLogo}`}
        >
          <MenuButton hiddensm={true} />

          {galleryEventLogo && gallery_event_name && (
            <img src={galleryEventLogo} />
          )}
          {!gallery_event_name && (
            <div className={classes.NavBarSiteLogo}>
              <BossFotosNavBarLogo height={28} />
            </div>
          )}
        </div>
        {showSearchBar && <EventPageSearchBar />}
        {(isInIframe || isMobile) && (
          <div className={classes.secTwoNav}>
            <ul className={classes.secNavTabs}>
              <Link to="/customer/map" className={classes.LinkCls}>
                <li
                  onClick={() => onActivateTab("map")}
                  className={`${classes.NavLi}
                                ${classes.mapTab}
                                ${
                                  activeTabName === "map"
                                    ? classes.activeTab
                                    : classes.inactiveTab
                                }`}
                >
                  <span>
                    <img
                      src={PhotosLogo}
                      alt="Photos"
                      className={classes.photoOfMeImg}
                    />
                    <p>Find Yourself</p>
                  </span>
                </li>
              </Link>

              <Link to="/customer/events" className={classes.LinkCls}>
                <li
                  onClick={() => onActivateTab("events")}
                  className={`${classes.NavLi}
                                ${classes.eventsTab}
                                ${
                                  activeTabName === "events"
                                    ? classes.activeTab
                                    : classes.inactiveTab
                                }`}
                >
                  <span>
                    <i className="fas fa-trophy"></i>
                    <p>Schools/Events</p>
                  </span>
                </li>
              </Link>

              <Link to="/customer/photo_art" className={classes.LinkCls}>
                <li
                  onClick={() => onActivateTab("photoart")}
                  className={`${classes.NavLi}
                                ${classes.photoArtTab}
                                ${
                                  activeTabName === "photoart"
                                    ? classes.activeTab
                                    : classes.inactiveTab
                                }`}
                >
                  <span>
                    <i className="fas fa-image"></i>
                    <p>Photo Art</p>
                  </span>
                </li>
              </Link>
            </ul>
          </div>
        )}
        <div
          style={{
            justifyContent: "flex-end",
            alignItems: "center",
            display: "flex",
            flexGrow: "2",
          }}
        >
          <HelpButton />
        </div>
      </div>
    </div>
  );
};

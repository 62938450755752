function getMobMaxWidthPixels() {
    const mob_width= "900px";
    
    return mob_width;
      
}
function getTabMinWidthPixels() {
    const tab_min_width= "1000px";
    
    return tab_min_width
      
}
function getTabMaxWidthPixels() {
    const tab_max_width= "1300px";
    
    return tab_max_width
      
}
export default {getMobMaxWidthPixels,getTabMaxWidthPixels,getTabMinWidthPixels}
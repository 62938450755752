import upperCase from "lodash/upperCase";
import axios from "axios";
import { clearAllStorage } from "../actions/user";

export const makeHeader = () => {
  let token = localStorage.getItem("token");
  let config = {
    headers: {
      Authorization: "Token " + token,
      "X-BOSSFOTOS-AUTHENTICATION-TOKEN": "Token " + token,
    },
  };
  return config;
};

export const signUp = async (data) => {
  removeLocalStorage();
  const json = await axios.post(
    window.LOCAL_SETTINGS.API_BASE + "/api/signup/signup/",
    data,
  );
  const token = json.data.token;
  if (token) {
    const userInfoData = json.data.user;
    if (userInfoData.is_staff) {
      localStorage.setItem("auth_name", userInfoData.first_name);
      localStorage.setItem("last_name", userInfoData.last_name);
      localStorage.setItem("auth_id", userInfoData.id);
      localStorage.setItem("auth_email", userInfoData.email);
      localStorage.setItem("is_staff", userInfoData.is_staff);
      localStorage.setItem("token", token);
    }
  }
  return json.data.user;
};

export const logIn = async (data) => {
  return await axios.post(
    window.LOCAL_SETTINGS.API_BASE + "/api/login/login/",
    data,
  );
};

export const logOut = async (auth_email) => {
  removeLocalStorage();
};

export const forgotPasswordApi = async (data) => {
  return await axios.post(
    window.LOCAL_SETTINGS.API_BASE + "/api/login/send_otp_email/",
    data,
  );
};

export const getUserInfo = async () => {
  return await axios.get(
    window.LOCAL_SETTINGS.API_BASE + "/api/user/photographer_user_info/",
    makeHeader(),
  );
};

export const sendEmail = async (data) => {
  return await axios.post(
    window.LOCAL_SETTINGS.API_BASE +
      "/api/sendmail/send_help_feedback_mail_photographer/",
    data,
    makeHeader(),
  );
};

export const setPasswordApi = async (data) => {
  return await axios.post(
    window.LOCAL_SETTINGS.API_BASE + "/api/login/set_password/",
    data,
  );
};

export const editProfile = async (formData) => {
  return await axios.post(
    window.LOCAL_SETTINGS.API_BASE + "/api/editprofile/",
    formData,
    makeHeader(),
  );
};

export const getPrice = async (pid) => {
  return await axios.get(
    window.LOCAL_SETTINGS.API_BASE +
      "/api/getprice/get_price_by_pid/?pid=" +
      pid,
    makeHeader(),
  );
};

export const createPrice = async (data) => {
  return await axios.post(
    window.LOCAL_SETTINGS.API_BASE + "/api/createprice/",
    data,
    makeHeader(),
  );
};

export const updatePrice = async (data, priceId) => {
  return await axios.put(
    window.LOCAL_SETTINGS.API_BASE + "/api/updateprice/" + priceId,
    data,
    makeHeader(),
  );
};

export const getImgGallery = async () => {
  return await axios.get(
    window.LOCAL_SETTINGS.API_BASE + "/api/getimggallery/",
    makeHeader(),
  );
};

export const getPhotosByGalleryName = async (gallery_id) => {
  return await axios.get(
    window.LOCAL_SETTINGS.API_BASE +
      "/api/getphotos/getphotosbygid/?gid=" +
      gallery_id,
    makeHeader(),
  );
};

export const removePhotosByIds = async (pIds, gallery_id) => {
  const sendData = {
    pIds: pIds,
    gallery_id: gallery_id,
  };
  return await axios.post(
    window.LOCAL_SETTINGS.API_BASE + "/api/deletephotos/",
    sendData,
    makeHeader(),
  );
};

export const updatePricebyPhotoId = async (data) => {
  return await axios.post(
    window.LOCAL_SETTINGS.API_BASE + "/api/updatepricebypid/",
    data,
    makeHeader(),
  );
};

export const upDatePhotoPriceByGalleryId = async (price_data, gid) => {
  const sendData = {
    price_data: price_data,
    gallery_id: gid,
  };
  return await axios.post(
    window.LOCAL_SETTINGS.API_BASE + "/api/updatephotopricebygid/",
    sendData,
    makeHeader(),
  );
};

export const upLoadPhoto = async (data) => {
  return await axios.post(
    window.LOCAL_SETTINGS.API_BASE + "/api/uploadphotobygalleryid/",
    data,
    makeHeader(),
  );
};
export const getLedgerByPhotoGrapherId = async (pid) => {
  return await axios.get(
    window.LOCAL_SETTINGS.API_BASE +
      "/api/ledger/get_ledger_by_photographerid/?pid=" +
      pid,
    makeHeader(),
  );
};

export const getGalleriesByMonth = async (month) => {
  return await axios.get(
    window.LOCAL_SETTINGS.API_BASE +
      "/api/getimggallery/get_gallery_by_month/?month=" +
      month,
    makeHeader(),
  );
};

export const getCountryDefaultValue = async (country) => {
  return await axios.get(
    window.LOCAL_SETTINGS.API_BASE +
      "/api/getpricebycustomer/get_country_value/?country=" +
      upperCase(country),
    makeHeader(),
  );
};

export const getLocation = async (ip_address) => {
  return await axios.get(
    `${window.LOCAL_SETTINGS.API_BASE}/api/getlocation/get_location/?ip_address=${ip_address}`,
  );
};

export const getSignInUrl = async (country, file_name) => {
  return await axios.get(
    window.LOCAL_SETTINGS.API_BASE +
      "/api/gallery/get_signin_url/?country=" +
      country +
      "&file_name=" +
      file_name,
    makeHeader(),
  );
};

export const removeLocalStorage = () => {
  clearAllStorage();
};

import { ItemList } from "../orm";

class PurchasedTrolleyList extends ItemList {
  getEntityKey() {
    return "customer/purchased_trolley";
  }
}

export const purchasedTrolleyList = new PurchasedTrolleyList(
  "purchased_trolley_default",
);

import { EditingFilters, EventFiltersFlyout } from "./EventsFiltersFlyout";
import {
  Header,
  HeaderButton,
  ListItemLabel,
  Shell,
  ViewPort,
} from "bossfotos-design-system";
import {
  FilterKeys,
  useGalleryFilterSearchParams,
} from "../../v2_components/hooks/useGalleryFilterSearchParams";
import {
  formatSessionNameForURL,
  isWindowInIframe,
  replaceDashesWithSpaces,
  setGalleryNavBackButtonUrl,
} from "../../v2_actions/nav";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ActiveFilterPills } from "./ActiveFilterPills";
import { BOSSFOTOS_INFO_WEBSITE } from "../../v2_actions/routes";
import CollectionsIcon from "@mui/icons-material/Collections";
import ContextMenu from "../../v2_components/layout/ContextMenu";
import DesktopEventList from "../../v2_components/eventpage/DesktopEventList";
import { EventFiltersBroker } from "../../v2_components/hooks/useEventFiltersBroker";
import { GalleryMap } from "../../v2_components/GalleryMap";
import LoadingHorseShoe from "../../design-system/layouts/LoadingHorseShoe";
import { NavBar } from "./NavBar";
import PlaceIcon from "@material-ui/icons/Place";
import { SearchBarAny } from "./SearchBarAny";
import { SearchBarLocation } from "./SearchBarLocation";
import { briefGalleryList } from "../../v2_actions/brief_gallery";
import { makeLogger } from "../../utils/o11y/logger";
import styles from "./Events.module.css";
import { useCountryDetection } from "../../v2_components/hooks/useCountryDetection";
import { useEventsPageStartupEffects } from "../../v2_components/hooks/useEventsPageStartupEffects";
import { useReduxStateReconcilation } from "../../v2_components/hooks/useReduxStateReconcilation";
import { useRouteInfo } from "../../v2_components/hooks/useRouteInfo";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

const logger = makeLogger({
  enabled: true,
  label: "MobileEvents",
  color: "red",
});

const MobileEvents = () => {
  const isLoading = useEventsPageStartupEffects();

  if (isLoading) {
    return <LoadingHorseShoe />;
  }

  return <MobileEventsInner />;
};

/**
 * Events screen component
 * @returns
 */
const MobileEventsInner = () => {
  const isInIframe = isWindowInIframe();
  useReduxStateReconcilation();
  const navigate = useNavigate();
  const { isMapView, eventsUrlPath } = useRouteInfo();
  const { gallery_event_name, album_name } = useParams();
  const [
    galleryFilterSearchParams,
    syncGalleryFilterSearchParams,
    urlSearchParams,
  ] = useGalleryFilterSearchParams();

  const { persistCountry } = useCountryDetection();

  const [filterFlyoutActive, setFiltersFlyoutActive] = useState(false);
  const [showContextMenu, setShowContextMenu] = useState(false);

  setGalleryNavBackButtonUrl();

  const isMapFilterActive = briefGalleryList.isMapFilterActive();

  // are filters active? exclude search (visible) and country (permanent)
  const hasActivefilters =
    Object.keys(galleryFilterSearchParams).filter(
      (k) =>
        galleryFilterSearchParams[k as FilterKeys] !== undefined &&
        k !== "country" &&
        k !== "any",
    ).length > 0 ||
    !!gallery_event_name ||
    (!isMapView && isMapFilterActive);

  const showFilterSummary = (isMapView && hasActivefilters) || !isMapView;

  logger.log({
    hasActivefilters,
    galleryFilterSearchParams,
    isMapFilterActive,
  });

  const handleFilterClicked = () => {
    setFiltersFlyoutActive(true);
  };

  const handleFilterFlyoutClose = () => {
    setFiltersFlyoutActive(false);
  };
  const handleMenuClicked = () => {
    setShowContextMenu(true);
  };
  const handleHelpClicked = () => {
    window.open(`${BOSSFOTOS_INFO_WEBSITE}/info-centre/`);
  };

  const handleFilterFlyoutShowResults = (
    editedFilters: Partial<EditingFilters>,
  ) => {
    setFiltersFlyoutActive(false);
    logger.log({ editedFilters });
    if (editedFilters.countryCode) {
      persistCountry(editedFilters.countryCode);
    }

    if (editedFilters.event !== gallery_event_name) {
      const newParams = syncGalleryFilterSearchParams({
        date: editedFilters.date,
        activity: editedFilters.activity,
        photographers: editedFilters.photographers,
      });
      const redirectUrl = `/customer/events/${formatSessionNameForURL(editedFilters.event)}?${newParams}`;
      navigate(redirectUrl);
    } else {
      syncGalleryFilterSearchParams({
        date: editedFilters.date,
        activity: editedFilters.activity,
        photographers: editedFilters.photographers,
      });
    }
  };

  return (
    <ViewPort>
      <Shell>
        <ContextMenu
          showContextMenu={showContextMenu}
          setShowContextMenu={setShowContextMenu}
        />
        {!isInIframe && (
          <NavBar
            onMenuClicked={handleMenuClicked}
            onHelpClicked={handleHelpClicked}
          />
        )}
        <div
          data-testid="legacy-mobile-eventlist-wrapper"
          className={`${styles.legacymobileeventlistwrapper} legacymobileeventlistwrapper`}
        >
          {!isInIframe &&
            (isMapView ? <SearchBarLocation /> : <SearchBarAny />)}
          <Header>
            <HeaderButton onClick={handleFilterClicked}>
              <FilterAltIcon />
              Filters
            </HeaderButton>
            <ListItemLabel
              heading={false}
              noPadding={true}
              size="small"
            ></ListItemLabel>
            {isMapView ? (
              <HeaderButton
                onClick={() => navigate(`/customer/events?${urlSearchParams}`)}
              >
                <CollectionsIcon />
                Gallery View
              </HeaderButton>
            ) : isInIframe ? (
              <></>
            ) : (
              <HeaderButton
                onClick={() => navigate(`/customer/map?${urlSearchParams}`)}
              >
                <PlaceIcon />
                Search on map
              </HeaderButton>
            )}
          </Header>
          {showFilterSummary && <ActiveFilterPills />}
          {isMapView ? (
            <>
              <EventFiltersBroker
                albumUrlKey={album_name}
                galleryEventUrlKey={gallery_event_name}
                event_page={undefined}
              />
              <GalleryMap />
            </>
          ) : (
            <DesktopEventList
              album_name={replaceDashesWithSpaces(album_name)}
              gallery_event_name={replaceDashesWithSpaces(gallery_event_name)}
            />
          )}
        </div>
      </Shell>
      <EventFiltersFlyout
        open={filterFlyoutActive}
        onClose={handleFilterFlyoutClose}
        onShowResults={handleFilterFlyoutShowResults}
        isMapView={isMapView}
      />
    </ViewPort>
  );
};

export default MobileEvents;

import filter from "lodash/filter";
import head from "lodash/head";
import get from "lodash/get";
import map from "lodash/map";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { makeStyles, Button } from "@material-ui/core";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import SideBar from "../../layout/sidebar";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import PhotoArtTopBar from "./topbar/PhotoArtTopBar";
import { css, Global } from "@emotion/react";
import style from "./styles";
import { useIsMobile } from "../../actions/ui";
import { CurrencyValue } from "../../v2_components/CurrencyValue";
import { photoArtGalleryList } from "../../v2_actions/photo_art_gallery";
import { briefGalleryListForPhotoArtTab } from "../../v2_actions/brief_gallery";
import { TrolleyPhotographCarousel } from "../../v2_components/TrolleyPhotographCarousel";

import LoadingSpinner from "../components/LoadingSpinner";
import { AddToTrolleyButton } from "../../v2_components/AddToTrolleyButton";

import { PhotoArtMorePhotos } from "../../v2_components/PhotoArtMorePhotos";

const useStyles = makeStyles(style);

const global_styles = css`
.MuiAvatar-circular: {
  width: 95px;
  height: 95px;
}
.selectedPhotographLabel: {
  justifyContent: 'space-around',
  alignItems:'baseline',
}
#mobileTrolleyPhotographCarousel #primaryPhotoArtImage {
  display: none;
}
`;
const INCREMENT_NUM_ITEMS_PER_PAGE = 9;
const DEFAULT_NUM_ITEMS_PER_PAGE = 10;

const PhotographerPhotoArtGalleryPage = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { galleryId } = useParams();
  const selected_gallery = useSelector(() =>
    photoArtGalleryList.getObject(galleryId),
  );
  const photographer_id = get(selected_gallery, "photographer");
  const photographer_name = get(selected_gallery, "photographer_name");
  const [showMethod, setShowMethod] = useState(false);
  const [width] = useState(window.innerWidth);
  const [showSideBar, setShowSideBar] = useState(false);
  const galleries = useSelector(() =>
    briefGalleryListForPhotoArtTab.getFilteredGalleries(),
  );
  const loadingGalleries = useSelector(() =>
    briefGalleryListForPhotoArtTab.isLoading(),
  );
  const totalNumitems = briefGalleryListForPhotoArtTab.getPagination();
  const galleries_excluding_selected = filter(
    galleries,
    (gallery) => gallery.id !== galleryId,
  );
  const photograph = head(get(selected_gallery, ["photographs"]));
  const [photoIsFavorite, setPhotoIsFavorite] = useState(false);
  const [numItemsPerPage, setNumItemsPerPage] = useState(
    DEFAULT_NUM_ITEMS_PER_PAGE,
  );
  const gallery = selected_gallery;
  const photo = head(get(selected_gallery, ["photographs"]));
  const photoTags = get(selected_gallery, "tags");
  const photoId = get(photo, "id");
  const photoPrice = get(photo, "price");

  useEffect(() => {
    if (
      get(briefGalleryListForPhotoArtTab.getPagination(), "items_per_page") ===
        numItemsPerPage &&
      get(briefGalleryListForPhotoArtTab.getFilter(), "photographer_id") ===
        photographer_id
    ) {
      return;
    }

    dispatch(briefGalleryListForPhotoArtTab.invalidateList());
    dispatch(briefGalleryListForPhotoArtTab.clearListFilter());
    dispatch(
      briefGalleryListForPhotoArtTab.updateListFilter({
        photographer: photographer_id,
        gallery_type: "photo_art",
      }),
    );
    dispatch(
      briefGalleryListForPhotoArtTab.updatePaginationNumItemsPerPage(
        numItemsPerPage,
      ),
    );
    dispatch(briefGalleryListForPhotoArtTab.fetchListIfNeeded());
  }, [photographer_id, numItemsPerPage]);

  const updateNumItemsPerPage = () => {
    const increasedNumItemsPerPage =
      numItemsPerPage + INCREMENT_NUM_ITEMS_PER_PAGE;
    setNumItemsPerPage(increasedNumItemsPerPage);
  };

  const renderCarousel = () => {
    return (
      <TrolleyPhotographCarousel
        style={classes.photoArtPageCarouselStyle}
        isPhotoArtGalleryPage={true}
        photograph={photograph}
      />
    );
  };

  const renderMobilePhotograph = () => {
    return (
      <div>
        <div>
          <img
            className={classes.primaryPhotoArtPhotograph}
            key={0}
            src={get(photograph, ["gallery_size_file_info", "download_url"])}
          />
        </div>
      </div>
    );
  };

  const renderPhotographerBio = () => {
    const photographerProfile = get(
      selected_gallery,
      "photographer_profile",
      "",
    );
    return (
      <Grid item xs={12}>
        <h3 className={classes.photoArtHeaders}>
          About the Photographer: {photographer_name}
        </h3>
        <List>
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar
                sx={{ width: 90, height: 90 }}
                className={classes.photographerAvatar}
                alt={photographer_name}
                src="/static/images/avatar/1.jpg"
              />
            </ListItemAvatar>
            <ListItemText
              className={classes.photographerBio}
              secondary={
                <>
                  <p>
                    {photographer_name} {photographerProfile}
                  </p>
                </>
              }
            />
          </ListItem>
        </List>
      </Grid>
    );
  };

  const renderPhotographInfo = () => {
    return (
      <Grid item xs={12} md={7.5}>
        <h3 className={classes.photoArtHeaders}>About the Artwork</h3>
        <p className={classes.photographInfoDescription}>
          {get(selected_gallery, "description")}
        </p>
      </Grid>
    );
  };

  const onClickPhotographTag = (tag) => {
    navigate(`/customer/photo_art/?search=${tag}`);
  };

  const renderPhotographTags = () => {
    const splitPhotoTags = photoTags && photoTags.split(",");
    return (
      <Grid item xs={12} md={3.5} className={classes.photographTagsWrapper}>
        <h4>Tags</h4>
        {splitPhotoTags &&
          map(splitPhotoTags, (x) => {
            return (
              <a
                onClick={() => onClickPhotographTag(x)}
                className={classes.photographTags}
              >
                {x},
              </a>
            );
          })}
      </Grid>
    );
  };

  const renderPhotoIsFavorite = () => {
    return (
      <p>
        {!photoIsFavorite && (
          <Button
            variant="text"
            onClick={() => setPhotoIsFavorite(true)}
            endIcon={<FavoriteBorderIcon />}
          ></Button>
        )}
        {photoIsFavorite && (
          <Button
            variant="text"
            className={classes.photoIsFavorite}
            onClick={() => setPhotoIsFavorite(false)}
            endIcon={<FavoriteIcon />}
          ></Button>
        )}
      </p>
    );
  };

  const renderMobilePhotoArtDetail = () => {
    return (
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
        pt={4}
        px={2}
      >
        <Grid item xs={6} className={classes.mobilePhotoArtSessionNameWrapper}>
          <div>
            <h4>{get(selected_gallery, "session_name")}</h4>
          </div>
        </Grid>
        <Grid item xs={6} className={classes.mobilePhotoArtSessionNameWrapper}>
          <div className={classes.photoArtPhotographerName}>
            <PhotoCameraIcon className={classes.photoArtCameraIcon} />
            <h4>{photographer_name}</h4>
          </div>
        </Grid>
        <Grid item xs={3} className={classes.mobilePhotoArtPriceWrapper}>
          {gallery.gallery_price && (
            <p className={classes.mobilePhotoArtPriceWrapper}>
              from{" "}
              <CurrencyValue
                value={get(selected_gallery, ["gallery_price", "base_price"])}
                country={gallery.gallery_price.country}
              />
            </p>
          )}
        </Grid>
        <Grid item xs={0.1}>
          <p className={classes.mobilePhotoIsFavorite}>
            {renderPhotoIsFavorite()}
          </p>
        </Grid>
        <Grid
          item
          xs={8.9}
          className={classes.mobilePhotoArtAddToTrolleyWrapper}
        >
          <div className={classes.mobilePhotoArtAddToTrolleyButton}>
            <AddToTrolleyButton
              pid={photoId}
              variant="text"
              gallery={selected_gallery}
            />
          </div>
        </Grid>
      </Grid>
    );
  };

  const renderDesktopPhotoArtDetail = () => {
    return (
      <Stack
        direction="row"
        justifyContent="space-around"
        alignItems="baseline"
        spacing={2}
        mt={{ xs: 5, md: 0 }}
        className={classes.selectedPhotographLabel}
      >
        <h3>{get(selected_gallery, "session_name")}</h3>
        {gallery.gallery_price && (
          <p>
            from{" "}
            <CurrencyValue
              value={get(selected_gallery, ["gallery_price", "base_price"])}
              country={gallery.gallery_price.country}
            />
          </p>
        )}
        {renderPhotoIsFavorite()}
        <p>
          <AddToTrolleyButton
            pid={photoId}
            variant="text"
            gallery={selected_gallery}
          />
        </p>
      </Stack>
    );
  };

  const renderLeftPane = () => {
    const gallery = selected_gallery;
    const photo = head(get(selected_gallery, ["photographs"]));
    const photoId = get(photo, "id");
    const photoPrice = get(photo, "price");
    return (
      <>
        <Grid
          className={classes.photoArtLeftPane}
          item
          xs={12}
          md={7}
          sx={{ width: "20px" }}
        >
          {!isMobile && renderDesktopPhotoArtDetail()}
          {isMobile && renderMobilePhotoArtDetail()}
          {!isMobile && renderCarousel()}
          {isMobile && renderMobilePhotograph()}
        </Grid>
        <Grid item md={0.5}></Grid>
      </>
    );
  };

  const renderRightPane = () => {
    return (
      <Grid item xs={12} sm={8} md={4}>
        <Box pt={4} className={classes.trolleyFormWrapper}>
          {renderPhotographerBio()}
          {isMobile && renderCarousel()}
        </Box>
        <Grid container component="aside">
          {renderPhotographInfo()}
          <Grid item xs={0.5}></Grid>
          {renderPhotographTags()}
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <PhotoArtTopBar
        showSideBar={showSideBar}
        setShowSideBar={setShowSideBar}
        galleryId={galleryId}
        galleryName={`${photographer_name}'s PhotoArt`}
        photoGrapherId={photographer_id}
        photoGrapherName={photographer_name}
        showMethod={showMethod}
        setShowMethod={setShowMethod}
        width={width}
        variant="photo_art_gallery_view"
        purchaseEntireGaleryEnabled={false}
      />
      <Global styles={global_styles} />
      <SideBar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
      <div
        onContextMenu={(e) => {
          e.preventDefault();
        }}
        className={!showSideBar ? classes.nonMennuWrapper : classes.menuWrapper}
        style={{ marginTop: !isMobile ? 60 : 0 }}
      >
        <div
          className={classes.contentContainer + " gallery-content-container"}
        >
          <div className={classes.content}>
            <Grid container component="main">
              {renderLeftPane()}
              {renderRightPane()}
              {loadingGalleries && <LoadingSpinner />}
              <PhotoArtMorePhotos
                totalNumitems={totalNumitems}
                numItemsPerPage={numItemsPerPage}
                loadingGalleries={loadingGalleries}
                updateNumItemsPerPage={updateNumItemsPerPage}
                galleries_excluding_selected={galleries_excluding_selected}
              />
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
};

export default PhotographerPhotoArtGalleryPage;

import rightImg from "../../assets/images/background/signup.png";
// eslint-disable-next-line
export default (theme) => ({
  root: {
    minHeight: "100%",
    width: "60%",
    margin: "auto",
    marginTop: 100,
    border: "2px solid",
    padding: theme.spacing(3),
  },
  menuWrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    paddingTop: 65,
    position: "relative",
    [theme.breakpoints.up("lg")]: {
      paddingTop: 65,
      paddingLeft: 300,
    },
  },
  nonMennuWrapper: {
    position: "relative",
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  content: {
    flex: "1 1 auto",
    height: "calc(100vh - 65px)",
    overflowY: "scroll",
  },
  mobileroot: {
    marginTop: 60,
    height: `calc(100vh - 60px)`,
    width: "98%",
    margin: "auto",
  },
  selectLabel: {
    paddingLeft: "18.5px",
  },
  logo: {
    fontFamily: "Aguafina Script",
    fontSize: 60,
    fontWeight: "400",
  },
  bigtext: {
    fontFamily: "Quicksand",
    fontSize: 30,
  },
  smalltext: {
    fontFamily: "Asap",
    fontSize: 25,
    fontWeight: "400",
    color: "#989eb1",
  },
  label: {
    fontSize: 20,
    color: "#0c59cf",
  },
  button: {
    background: "#0c59cf !important",
    fontFamily: "Asap",
    fontSize: 25,
    color: "white",
    textTransform: "capitalize",
    width: "50%",
  },
  subtitle: {
    color: "#989eb1",
    fontSize: 20,
    cursor: "pointer",
  },
  subcolortitle: {
    color: "#0c59cf",
    fontSize: 20,
    cursor: "pointer",
  },
  rightside: {
    backgroundImage: `url(${rightImg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  imgtitle: {
    fontSize: 25,
    color: "black",
    padding: 25,
  },
  textField: {
    width: "100%",
  }
});

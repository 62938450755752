import get from "lodash/get";
import {
  setTrolleyID,
  clearTrolleyID,
} from "../actions/trolley";

import { getCustomValue, setCustomValue } from "./custom_value";
import { CUSTOM_VALUE_NUM_TROLLEY_PHOTOGRAPHS } from "./custom_value";
import { ItemList } from "../orm";
import { makeLogger } from "../utils/o11y/logger";
import { sentryApi } from "../utils/o11y/sentryApi";
import { http } from "../lib";

const logger = makeLogger({
  enabled: true,
  label: "TrolleyList",
  color: "green",
});

export class TrolleyList extends ItemList {
  getEntityKey() {
    return "customer/trolley";
  }

  getOrCreateActiveTrolleyId({ gallery, onDone }) {
    return async (dispatch, getState) => {
      const trolley_id = getState().trolleyReducer.trolley_id
      if (!trolley_id) {
        dispatch(this.createTrolley({ gallery })).then((res) => {
          dispatch(setTrolleyID(res.id))
          if (onDone) {
            onDone(res.id);
          }
        });
      } else {
        onDone(trolley_id);
      }
    };
  }

  refetchTrolley() {
    return async (dispatch, getState) => {
      dispatch(
        this.getOrCreateActiveTrolleyId({
          onDone: (trolley_id) => {
            // // I think this might be causing an infinite loop
            // dispatch(this.invalidateObject(trolley_id))
            // ///

            dispatch(this.ensureObjectLoaded(trolley_id));
          },
        }),
      );
    };
  }

  tryVoucherCode({ code, onStaleCodeError }) {
    return async (dispatch, getState) => {
      logger.debug("tryVoucherCode", code);

      const trolley_id = getState().trolleyReducer.trolley_id
      if (!trolley_id) {
        return null;
      }

      await dispatch(this.invalidateObject(trolley_id));

      let responseStatus = 0;

      try {
        let [json, response] = await http.get(
          "customer/trolley/add_voucher_to_current_trolley/",
          {
            trolley_id: trolley_id,
            voucher_code: code,
          },
        );

        logger.log("VOUCHER", { json, response });
        responseStatus = response?.status;
      } catch (e) {
        logger.error("VOUCHER ERROR", { e });
        responseStatus = 500;

        sentryApi.breadcrumb({
          category: "trolley",
          level: "info",
          message: "voucher_api_server_error",
          data: {
            trolley_id,
          },
        });
      }

      if (responseStatus === 200) {
        logger.log("VOUCHER", "voucher_api_code_applied");
        sentryApi.breadcrumb({
          category: "trolley",
          level: "info",
          message: "voucher_api_code_applied",
          data: {
            trolley_id,
          },
        });
        sessionStorage.setItem("vouchercode", code);
      } else if (responseStatus === 400) {
        logger.log("VOUCHER", "voucher_api_bad_voucher");
        onStaleCodeError();
        sentryApi.breadcrumb({
          category: "trolley",
          level: "info",
          message: "voucher_api_bad_voucher",
          data: {
            trolley_id,
          },
        });
      } else if (responseStatus === 500) {
        logger.log("VOUCHER", "server_eror");
        sentryApi.error("voucher_api_server_voucher", { trolley_id });
      } else {
        logger.log("VOUCHER", "unknown");
      }

      await dispatch(this.ensureTrolleyLoaded());
    };
  }

  ensureTrolleyLoaded() {
    return async (dispatch, getState) => {
      dispatch(
        this.getOrCreateActiveTrolleyId({
          onDone: (trolley_id) => {
            dispatch(this.ensureObjectLoaded(trolley_id)).then((json) => {
              const trolley = json[0];
              if (
                trolley &&
                getCustomValue({
                  state: this.getState(),
                  name: CUSTOM_VALUE_NUM_TROLLEY_PHOTOGRAPHS,
                }) === undefined
              ) {
                logger.log(
                  this.getEntityKey(),
                  "custom value: initialising",
                  trolley.number_of_photos,
                );
                dispatch(
                  setCustomValue({
                    name: CUSTOM_VALUE_NUM_TROLLEY_PHOTOGRAPHS,
                    value: trolley.number_of_photos,
                  }),
                );
              }
            });
          },
        }),
      );
    };
  }

  resetTrolley(onDone) {
    return async (dispatch, getState) => {
      const trolley_id = getState().trolleyReducer.trolley_id
      dispatch(
        setCustomValue({
          name: CUSTOM_VALUE_NUM_TROLLEY_PHOTOGRAPHS,
          value: 0,
        }),
      );
      dispatch(clearTrolleyID())
      if (trolley_id) {
        dispatch(this.deleteObject(trolley_id)).then(onDone);
      } else {
        if (onDone) {
          onDone();
        }
      }
    };
  }

  trySetTrolleyCustomer() {
    return async (dispatch, getState) => {
      dispatch(
        this.getOrCreateActiveTrolleyId({
          onDone: (trolley_id) => {
            // we rely on the api to set the customer automatically when authenticated
            const trolley = this.getObject(trolley_id);
            if (!trolley.customer) {
              dispatch(this.saveObject({ id: trolley_id })).then(() => {
                dispatch(this.ensureTrolleyLoaded());
              });
            }
          },
        }),
      );
    };
  }

  createTrolley({ gallery }) {
    return trolleyList.saveNewObject({ gallery: get(gallery, "id") });
  }
}

export const trolleyList = new TrolleyList("trolley_default");

import { ItemList } from '../orm'

export class PhotoArtGalleryList extends ItemList {

    getEntityKey() {
        return "customer/photo_art_gallery"
    }
}

export const photoArtGalleryList = new PhotoArtGalleryList("photo_art_gallery_default")

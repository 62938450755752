import isEmpty from "lodash/isEmpty";
import last from "lodash/last";
import first from "lodash/first";
import map from "lodash/map";
import without from "lodash/without";
import includes from "lodash/includes";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Grid,
  Card,
  Typography,
  makeStyles,
  Button,
  IconButton,
} from "@material-ui/core";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import SideBar from "../../layout/sidebar";
import TopBar from "./topbar";
import Photos from "./photos";
import style from "./styles";
import { useIsMobile } from "../../actions/ui";
import { purchasedTrolleyList } from "../../v2_actions/purchased_trolley";

const useStyles = makeStyles(style);

const PurchasedTrolley = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const purchased_trolleys = useSelector(() =>
    purchasedTrolleyList.getVisibleObjects(),
  );
  const [showSideBar, setShowSideBar] = useState(false);
  const [changeIcon, setChangeIcon] = useState({});
  const [purchasedId, setPurchasedId] = useState();
  const [width, setWidth] = useState(window.innerWidth);
  const [expandedPurchasedTrolleyIds, setExpandedPurchasedTrolleyIds] =
    useState([]);
  const [purchasedTrolleyData, setPurchasedTrolleyData] = useState("");

  useEffect(() => {
    dispatch(purchasedTrolleyList.invalidateList());
    dispatch(purchasedTrolleyList.updatePaginationNumItemsPerPage(100));
    dispatch(purchasedTrolleyList.fetchListIfNeeded());
  }, []);

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  useEffect(() => {
    onExpandPurchasedTrolley(first(purchased_trolleys));
  }, [isEmpty(purchased_trolleys)]);

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  const onExpandPurchasedTrolley = (purchased_trolley) => {
    const localExpandedPurchasedTrolleyIds = Object.assign(
      [],
      expandedPurchasedTrolleyIds,
    );
    if (purchased_trolley) {
      localExpandedPurchasedTrolleyIds.push(purchased_trolley.id);
      setExpandedPurchasedTrolleyIds(localExpandedPurchasedTrolleyIds);
    }
  };

  const onCollapsePurchasedTrolley = (purchased_trolley) => {
    const localExpandedPurchasedTrolleyIds = without(
      expandedPurchasedTrolleyIds,
      purchased_trolley.id,
    );
    setExpandedPurchasedTrolleyIds(localExpandedPurchasedTrolleyIds);
  };

  const isPurchasedTrolleyExpanded = (purchased_trolley) => {
    return includes(expandedPurchasedTrolleyIds, purchased_trolley.id);
  };

  const isPurchasedTrolleyCollapsed = (purchased_trolley) => {
    return !isPurchasedTrolleyExpanded(purchased_trolley);
  };

  const renderPurchasedTrolleyDesktop = (purchased_trolley) => {
    return (
      <Box display="flex" width="100%">
        {isPurchasedTrolleyCollapsed(purchased_trolley) && (
          <IconButton
            onClick={() => onExpandPurchasedTrolley(purchased_trolley)}
          >
            <ArrowRightIcon className={classes.icon} />
          </IconButton>
        )}

        {isPurchasedTrolleyExpanded(purchased_trolley) && (
          <IconButton
            onClick={() => onCollapsePurchasedTrolley(purchased_trolley)}
          >
            <ArrowDropDownIcon className={classes.icon} />
          </IconButton>
        )}

        <Card className={width > 750 ? classes.card : classes.mobilecard}>
          <Typography variant="h6">
            Date: {first(purchased_trolley.gallery_name.split(","))}
          </Typography>
        </Card>
      </Box>
    );
  };

  const renderPurchasedTrolleyMobile = (purchased_trolley) => {
    return (
      <Box display="flex" width="100%">
        <Card className={classes.mobilecard}>
          <Typography style={{ fontSize: 16 }}>
            Date: {first(purchased_trolley.gallery_name.split(","))}
            &nbsp; Photographer:{" "}
            {last(purchased_trolley.gallery_name.split(","))}
          </Typography>

          {isPurchasedTrolleyCollapsed(purchased_trolley) && (
            <IconButton
              onClick={() => onExpandPurchasedTrolley(purchased_trolley)}
            >
              <ArrowRightIcon className={classes.whiteicon} />
            </IconButton>
          )}

          {isPurchasedTrolleyExpanded(purchased_trolley) && (
            <IconButton
              onClick={() => onCollapsePurchasedTrolley(purchased_trolley)}
            >
              <ArrowDropDownIcon className={classes.whiteicon} />
            </IconButton>
          )}
        </Card>
      </Box>
    );
  };

  const renderPurchasedTrolley = (purchased_trolley) => {
    return (
      <Grid
        container
        spacing={3}
        key={`${purchased_trolley.id}-purchased-grid`}
      >
        <Grid item xs={!isMobile ? 9 : 12}>
          {width > 750 && renderPurchasedTrolleyDesktop(purchased_trolley)}
          {width <= 750 && renderPurchasedTrolleyMobile(purchased_trolley)}
        </Grid>
        {!isMobile && (
          <Grid item xs={3}>
            <Box display="flex" flexDirection="column">
              <Typography>
                Photographer: {purchased_trolley.photographer_name}
              </Typography>
              <Button color="default" className={classes.checkoutBtn}>
                Book a Private Session
              </Button>
            </Box>
          </Grid>
        )}
        {isPurchasedTrolleyExpanded(purchased_trolley) && (
          <Grid item xs={12}>
            <Photos purchased_trolley={purchased_trolley} />
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <>
      <TopBar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
      <SideBar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
      <div
        className={!showSideBar ? classes.nonMennuWrapper : classes.menuWrapper}
        style={{ paddingTop: !isMobile ? 65 : 0 }}
      >
        <div
          className={classes.contentContainer}
          onContextMenu={(e) => {
            e.preventDefault();
          }}
        >
          <div className={classes.content}>
            <Box p={3}>{map(purchased_trolleys, renderPurchasedTrolley)}</Box>
          </div>
        </div>
      </div>
    </>
  );
};

export default PurchasedTrolley;

import head from "lodash/head";
import get from "lodash/get";
import React from "react";
import { MarkdownText } from "./MarkdownText";
import { Description } from "./Description";
import { printPriceConfigurationList } from "../../v2_actions/print_price_configuration";

export const PrintTypeDescription = ({ print_type_id }) => {
  const print_type =
    printPriceConfigurationList.getPrintTypeById(print_type_id);
  const print_type_description = get(head(print_type), "description");

  return (
    <>
      <Description
        title={"Print Type"}
        description={[
          get(head(print_type), "name"),
          <MarkdownText markdownText={print_type_description} />,
        ]}
      />
    </>
  );
};

// eslint-disable-next-line
export default () => ({
  root: {
    position: "fixed",
    top: "0",
    left: "0",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    width: "100vw!important",
    height: "100dvh!important",
    zIndex: 999,
  },
  progress: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "50%",
  },
});

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Modal, Button, makeStyles, TextField } from "@material-ui/core";
import PhotoSearchMap from "./googlemap/PhotoSearchMap";
import styles from "./styles";
import { setShowModal } from "../../actions/map";
import { setShowReteralModal } from "../../actions/user";
import { useIsMobile } from "../../actions/ui";

const useStyles = makeStyles(styles);

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    background: "white",
    transform: `translate(-${left}%, -${top}%)`,
  };
}

export const GoogleGalleryMap = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const [modalStyle] = useState(getModalStyle);
  const lat = parseFloat(localStorage.getItem("lat"));
  const lng = parseFloat(localStorage.getItem("lng"));
  const [width, setWidth] = useState(window.innerWidth);

  const showModal = false;
  const showReteralModal = false;

  const mapLink = useSelector((state) => state.mapReducer.mapLink);
  const [showSideBar, setShowSideBar] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  });

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  return (
    <>
      <div
        className={!showSideBar ? classes.nonMennuWrapper : classes.menuWrapper}
        style={{ marginTop: 0 }}
      >
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <PhotoSearchMap
              googleMapURL={mapLink}
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={
                <div
                  style={
                    width > 750
                      ? {
                          height: `calc(80vh)`,
                          position: "relative",
                        }
                      : {
                          height: `calc(100vh)`,
                          paddingBottom: "40vh",
                          position: "relative",
                          overflowY: "hidden",
                          touchAction: "none",
                        }
                  }
                  mapElement={<div style={{ height: `100%` }} />}
                />
              }
              mapElement={<div style={{ height: `100%` }} />}
            />
          </div>
        </div>
        <Modal
          open={showModal}
          onClose={(e) => {
            dispatch(setShowModal(false));
            dispatch(setShowReteralModal(false));
          }}
        >
          <div
            style={modalStyle}
            className={width > 750 ? classes.paper : classes.mobilepaper}
          >
            {showReteralModal ? (
              <Box
                display="flex"
                width="100%"
                justifyContent="center"
                flexDirection="column"
              >
                <h4>
                  Did anyone give you a referral code? (for eligibility into
                  draws and community discounts)
                </h4>
                <Box display="flex" alignItems="center">
                  <p>Referral Code:</p>
                  <TextField variant="standard" style={{ width: "80%" }} />
                </Box>
                <Box width="100%" display="flex" justifyContent="center" mt={3}>
                  <Button
                    className={
                      isMobile
                        ? classes.submitButton
                        : classes.mobilesubmitbutton
                    }
                  >
                    Submit Code
                  </Button>
                  <Box m={3}></Box>
                  <Button
                    className={
                      isMobile
                        ? classes.submitButton
                        : classes.mobilesubmitbutton
                    }
                    onClick={() => {
                      dispatch(setShowReteralModal(false));
                    }}
                  >
                    No Code/Cancel
                  </Button>
                </Box>
              </Box>
            ) : (
              <>
                <h3 id="simple-modal-title">How to Find Yourself:</h3>
                <Box p={1}>
                  <h4>
                    1. Zoom into the exact location on the map where you were
                    doing your activity.
                  </h4>
                  <h4>
                    2.If there are too many galleries narrow your search by date
                    and the filters provided at the top.
                  </h4>
                  <h4>
                    3. Click on a gallery picture or one in the list to "find
                    yourself".
                  </h4>
                  <h4>
                    4. You can also make use of our Face Recognition Technology
                    by updating your profile with a Foto of yourself.
                  </h4>
                </Box>
                <Box p={2} display="flex" justifyContent="center">
                  <Button
                    className={classes.submitButton}
                    onClick={() => {
                      dispatch(setShowModal(false));
                    }}
                  >
                    Got it
                  </Button>
                </Box>
              </>
            )}
          </div>
        </Modal>
      </div>
    </>
  );
};

import bgImg from "../../assets/images/background/howwork.jpg";
// eslint-disable-next-line
export default (theme) => ({
  root: {
    display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%",
  },
  menuWrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    paddingTop: 65,
    [theme.breakpoints.up("lg")]: {
      paddingTop: 65,
      paddingLeft: 300,
    },
  },
  nonMennuWrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  content: {
    flex: "1 1 auto",
    backgroundImage: `url(${bgImg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "calc(100vh - 65px)",
    overflowY: "scroll",
    paddingBottom: 175
  },

  button: {
    backgroundColor: "#0c59cf !important",
    textTransform: "capitalize",
    color: "white",
    fontSize: 20,
    fontWeight: "500",
    padding: 10,
    borderRadius: 16,
    width: 250,
  },
  textField: {
    background: "white",
    width: 300,
  },
  textArea: {
    color: "#0c59cf !important",
    background: "white",
    width: 500,
  },
  mobileTextArea: {
    background: "white",
    width: 350,
  },
});

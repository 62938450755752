import { briefGalleryList } from "../../v2_actions/brief_gallery";
import { galleryEventList } from "../../v2_actions/gallery_event";
import { albumListForEventTab } from "../../v2_actions/customer_album";
import { useDispatch } from "react-redux";
import { ItemList } from "../../orm";
import { makeLogger } from "../../utils/o11y/logger";
import { useRouteInfo } from "./useRouteInfo";

const logger = makeLogger({
  enabled: true,
  label: "useEventFiltersDispatcher",
  color: "orange",
});

export interface ListFilterArgs
  extends Record<string, string | undefined | boolean> {
  gallery_type: "event" | undefined;
  photographers?: string | undefined;
  any_field: string | undefined;
  shot_at_date_from: string | undefined;
  shot_at_date_to: string | undefined;
  country: string | undefined;
  north_east_lat?: string | undefined;
  north_east_lng?: string | undefined;
  south_west_lat?: string | undefined;
  south_west_lng?: string | undefined;
  /* simple metadata to attach to code calls to track 
  code origin to URL API calls in network. Just
  speeds up debugging where multiple API calls
  creep in */
  _callSource: string;
}

/**
 * Applies filter changes and determines whether to call event/album/galleries API
 * as a result. This marshals updates to prevent duplicate calls / prevent
 * calls where filters do not change
 * @returns
 */
export const useEventFiltersDispatcher = () => {
  const dispatch = useDispatch();
  const { isMapView } = useRouteInfo();

  const checkFilterAndMaybeFetch = (
    list: ItemList,
    filterArgs: Partial<ListFilterArgs>,
  ) => {
    const currentFilter = list.getFilter();
    const { _callSource, ...filterArgsPatch } = filterArgs;

    let filterChange = false;

    // figure out if the filterArgs should cause a fetch
    Object.keys(filterArgsPatch).forEach((filterArgKey) => {
      const filterArg = filterArgsPatch[filterArgKey];
      // logger.log(
      //   _callSource,
      //   filterArgKey,
      //   currentFilter[filterArgKey],
      //   filterArg,
      // );
      if (!filterArg && !currentFilter[filterArgKey]) {
        // both falsey, ignore
      } else if (currentFilter[filterArgKey]) {
        // arg already exists
        if (currentFilter[filterArgKey] !== filterArg) {
          filterChange = true;
          // logger.log("change", { _callSource, filterArgKey, next: filterArg, prev: currentFilter[filterArgKey] });
        }
      } else {
        // logger.log("new", { _callSource, filterArgKey, next: filterArg });
        // arg is new
        filterChange = true;
      }
    });

    // update filter args even if we don't fetch
    dispatch(list.updateListFilter(filterArgsPatch));

    if (filterChange) {
      logger.log("FETCHING", _callSource, { currentFilter, filterArgsPatch });
      // if(!filterArgsPatch.country && currentFilter.country) {
      //   debugger;
      // }
      dispatch(
        list.fetchListIfNeeded({
          callSource: _callSource,
          forceUpdate: undefined,
        }),
      );
    }
  };

  const galleryFilter = briefGalleryList.getFilter();
  const hasActiveFilters = [
    "activity_tags",
    "shot_at_date_to",
    "shot_at_date_from",
    "gallery_event",
    "photographers",
  ].some((filter) => !!galleryFilter[filter]);

  return {
    hasActiveFilters,
    filters: () => {
      // note this is ONLY returning the brief gallery filter.
      // if logic is added that alters the filters between API's
      // this may need to return gallery/album apis
      return {
        briefGalleryFilters: briefGalleryList.getFilter(),
      };
    },
    updateAllListFilters: (filterArgs: Partial<ListFilterArgs>) => {
      checkFilterAndMaybeFetch(albumListForEventTab, filterArgs);
      if (isMapView) {
        const { country, ...filtersRest } = filterArgs;
        // map page
        checkFilterAndMaybeFetch(galleryEventList, filtersRest);
        checkFilterAndMaybeFetch(briefGalleryList, filtersRest);
      } else {
        // gallery page
        checkFilterAndMaybeFetch(galleryEventList, filterArgs);
        checkFilterAndMaybeFetch(briefGalleryList, filterArgs);
      }
    },
    updateGalleryEventFilter: (filterArgs: Partial<ListFilterArgs>) => {
      checkFilterAndMaybeFetch(galleryEventList, filterArgs);
    },
    updateBriefGalleryFilter: (filterArgs: Partial<ListFilterArgs>) => {
      if (isMapView) {
        const { country, ...filtersRest } = filterArgs;
        // map page
        checkFilterAndMaybeFetch(galleryEventList, filtersRest);
        checkFilterAndMaybeFetch(briefGalleryList, filtersRest);
      } else {
        // gallery page
        checkFilterAndMaybeFetch(galleryEventList, filterArgs);
        checkFilterAndMaybeFetch(briefGalleryList, filterArgs);
      }
    },
    updateAlbumsFilter: (filterArgs: Partial<ListFilterArgs>) => {
      checkFilterAndMaybeFetch(albumListForEventTab, filterArgs);
    },
  };
};

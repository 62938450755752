import { ItemList } from "../orm";

class CustomerFacialRecognition extends ItemList {
  getEntityKey() {
    return "customer/facial_recognition";
  }

  indexFaces(photograph) {
    return this.saveNewObject({
      action: "index",
      photograph_id: photograph.id,
    });
  }

  indexForCustomer(fileInfoId, customerEmail) {
    return this.saveNewObject({
      action: "index_customer",
      file_info_id: fileInfoId,
      customer_email: customerEmail,
    });
  }

  // note event_id was added as last param to support previous calls
  searchForCustomer(gallery_id, customer_face_token, event_id) {
    return this.saveNewObject({
      action: "search_customer",
      gallery_id,
      event_id,
      customer_face_token,
    });
  }

  setCustomerFacialRecognitionFaceToken(faceToken) {
    if (!faceToken) {
      sessionStorage.removeItem("customer_facial_recognition_face_token");
    } else {
      sessionStorage.setItem(
        "customer_facial_recognition_face_token",
        faceToken,
      );
    }
  }

  getCustomerFacialRecognitionFaceToken(faceToken) {
    return sessionStorage.getItem("customer_facial_recognition_face_token");
  }
}

export const customerFacialRecognition = new CustomerFacialRecognition(
  "customer_facial_recognition__default",
);

import map from "lodash/map";
import get from "lodash/get";
import React from "react";
import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";

import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import {
  formatSessionNameForURL,
  replaceDashesWithSpaces,
} from "../../v2_actions/nav";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { galleryEventDropdownOptions } from "../../v2_actions/dropdown_options";
import { makeLogger } from "../../utils/o11y/logger";
import { styles } from "./styles";
import { useCountryDetection } from "../hooks/useCountryDetection";
import { useEventFiltersDispatcher } from "../hooks/useEventFiltersDispatcher";
import { useGalleryFilterSearchParams } from "../hooks/useGalleryFilterSearchParams";
import { useReduxStateReconcilation } from "../hooks/useReduxStateReconcilation";

const logger = makeLogger({
  enabled: true,
  label: "FilterGalleryEvent",
  color: "pink",
});

export default function FilterGalleryEvent() {
  useReduxStateReconcilation();
  const eventPageCtx = useEventFiltersDispatcher();
  const navigate = useNavigate();
  const { gallery_event_name } = useParams();
  const [anchorEl, setAnchorEl] = useState(null);
  const [galleryEventOption, setGalleryEventOption] = useState(null);
  const open = Boolean(anchorEl);
  const galleryEventOptions = galleryEventDropdownOptions.getAsOptions();
  const [, , searchParams] = useGalleryFilterSearchParams();
  const { countryCode, persistCountry } = useCountryDetection();

  useEffect(
    function resetUxOnUrlChange() {
      if (!gallery_event_name) {
        setGalleryEventOption(null);
      }
    },
    [gallery_event_name],
  );

  logger.log(
    "render()",
    galleryEventOption,
    galleryEventOptions,
    gallery_event_name,
  );

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCheckboxClicked = (ev) => {
    logger.log(ev);
    if (!ev.target.checked) {
      setAnchorEl(null);
      logger.log("Reset gallery_event filter");
      eventPageCtx.updateAllListFilters({
        gallery_type: "event",
        gallery_event: undefined,
        _callSource: "FilterGalleryEvent_check",
      });
      // for events filter, we'll redirect
      navigate(`/customer/events/?${searchParams}`);
    }
  };

  const handleMenuItemClick = (option) => {
    setGalleryEventOption(option);
    if (option) {
      navigate(
        `/customer/events/${formatSessionNameForURL(get(option, "label"))}?${searchParams}`,
      );
    } else {
      navigate(`/customer/events/?${searchParams}`);
    }
    setAnchorEl(null);

    logger.log("FX: galleryEventOption", option);

    persistCountry(undefined);

    eventPageCtx.updateAllListFilters({
      gallery_type: "event",
      gallery_event: option ? get(option, "value") : "",
      country: undefined,
      _callSource: "FilterGalleryEvent_menuclick",
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const filterLabel = [
    galleryEventOption
      ? get(galleryEventOption, "label")
      : replaceDashesWithSpaces(gallery_event_name),
  ]
    .filter(Boolean)
    .concat("All")
    .shift();

  const isFilterActive = !!gallery_event_name;

  return (
    <div>
      <Typography
        button
        sx={styles.filterListTitle}
        onClick={handleClickListItem}
      >
        Event/School
      </Typography>

      <List component="nav" sx={styles.filterList}>
        <ListItem
          button
          sx={styles.filterListItem}
          aria-expanded={open ? "true" : undefined}
        >
          <Checkbox
            checked={isFilterActive}
            size="small"
            onChange={handleCheckboxClicked}
          />
          <ListItemText
            onClick={handleClickListItem}
            primary=""
            sx={styles.filterListItemText}
            secondary={<ListItemText primary={filterLabel} />}
          />
        </ListItem>
      </List>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          role: "listbox",
        }}
      >
        <MenuItem value={null} onClick={() => handleMenuItemClick(null, 0)}>
          All
        </MenuItem>
        {map(galleryEventOptions, (option, index) => (
          <MenuItem
            key={get(option, "value")}
            disabled={get(galleryEventOption, "value") === get(option, "value")}
            selected={get(galleryEventOption, "value") === get(option, "value")}
            onClick={() => handleMenuItemClick(option, index)}
          >
            {get(option, "label")}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

import includes from "lodash/includes";
import reverse from "lodash/reverse";
import filter from "lodash/filter";
import sortBy from "lodash/sortBy";
import union from "lodash/union";
import first from "lodash/first";
import slice from "lodash/slice";
import size from "lodash/size";
import head from "lodash/head";
import get from "lodash/get";
import map from "lodash/map";
import { ItemList } from "../orm";
import moment from "moment";
import { CUSTOMER_UI_MAP_PAGE_PATH } from "./routes";
import { DATE_FILTER_OPTIONS } from "./date";
import { makeLogger } from "../utils/o11y/logger";

const logger = makeLogger({
  enabled: true,
  label: "GalleryList",
  color: "purple",
});

export const NUM_PHOTOS_PER_GALLERY_PAGE_WHEN_SHOWING_SHOW_MORE_BUTTON = 15;
export const NUM_PHOTOS_PER_GALLERY_PAGE_AFTER_SEARCHING = 48; // maximum photos to request from backend at a time

const MAP_PLACEHOLDER_IMAGE_URL = null;
const MIN_CHARACTERS_FOR_FILTER = 1;

export class GalleryList extends ItemList {
  getEntityKey() {
    return "customer/gallery";
  }

  getGalleryAvatarUrl(gallery) {
    const photograph_id =
      gallery.map_photograph || get(head(gallery.photographs), "id");
    if (!photograph_id) {
      return MAP_PLACEHOLDER_IMAGE_URL;
    }
    const photograph = head(
      filter(gallery.photographs, (x) => x.id === photograph_id),
    );
    if (!photograph) {
      return MAP_PLACEHOLDER_IMAGE_URL;
    }
    const download_url = get(
      photograph,
      ["avatar_size_file_info", "download_url"],
      MAP_PLACEHOLDER_IMAGE_URL,
    );
    return download_url;
  }

  getFilteredGalleries() {
    return this.getVisibleObjects();
  }
  getFilteredEventGalleries() {
    const galleries = this.getVisibleObjects();
    return filter(galleries, (gallery) => gallery.gallery_event !== null);
  }

  trySetFilter(newFilter) {
    return async (dispatch, getState) => {
      const existingFilter = this.getFilter();
      const mergedFilter = Object.assign({}, existingFilter, newFilter);
      logger.log("trySetFilter", this, {
        mergedFilter,
        existingFilter,
        newFilter,
      });

      if (JSON.stringify(existingFilter) === JSON.stringify(mergedFilter)) {
        return;
      }
      dispatch(this.updateListFilter(mergedFilter));

      if (
        get(mergedFilter, "calendar_filter") &&
        get(mergedFilter, "calendar_filter") ===
          get(first(DATE_FILTER_OPTIONS), "value")
      ) {
        dispatch(
          this.updatePaginationNumItemsPerPage(
            NUM_PHOTOS_PER_GALLERY_PAGE_WHEN_SHOWING_SHOW_MORE_BUTTON,
          ),
        );
      } else {
        dispatch(
          this.updatePaginationNumItemsPerPage(
            NUM_PHOTOS_PER_GALLERY_PAGE_AFTER_SEARCHING,
          ),
        );
      }
      dispatch(
        this.fetchListIfNeeded({
          callSource: `tsf_GalleryList__${this.listKey}`,
        }),
      );
    };
  }

  trySetFilter_Deprecated(filterSaveList, defaultTime, clearTime) {
    return async (dispatch, getState) => {
      let shot_at_dates =
        moment(filterSaveList.filterDateRange.startDate).format(
          "YYYY-MM-DD HH:mm:ss",
        ) === defaultTime[0] ||
        moment(filterSaveList.filterDateRange.endDate).format(
          "YYYY-MM-DD HH:mm:ss",
        ) === defaultTime[1]
          ? undefined
          : `${moment(filterSaveList.filterDateRange.startDate).format(
              "YYYY-MM-DD",
            )},${moment(filterSaveList.filterDateRange.endDate).format(
              "YYYY-MM-DD",
            )}`;

      const photographer =
        filterSaveList.filterPhotoGrapher === 0 ||
        filterSaveList.filterPhotoGrapher === "All Photographers"
          ? undefined
          : filterSaveList.filterPhotoGrapher;
      const activity_tags =
        filterSaveList.filterSportTag === "All Sports"
          ? undefined
          : filterSaveList.filterSportTag;
      const shot_at_time =
        clearTime === false
          ? moment(filterSaveList.filterTime).format("HH:mm:ss")
          : undefined;
      const shot_at_date_between_two_dates = shot_at_dates;

      const newFilter = {
        photographer,
        activity_tags,
        shot_at_time,
        shot_at_date_between_two_dates,
      };

      const existingFilter = this.getFilter();
      if (
        newFilter.photographer !== existingFilter.photographer ||
        newFilter.activity_tags !== existingFilter.activity_tags ||
        newFilter.shot_at_time !== existingFilter.shot_at_time ||
        newFilter.shot_at_date_between_two_dates !==
          existingFilter.shot_at_date_between_two_dates
      ) {
        dispatch(this.updateListFilter(newFilter));
        dispatch(this.updatePaginationNumItemsPerPage(10000));
        dispatch(
          this.fetchListIfNeeded({
            forceUpdate: newFilter,
            callSource: "trySetFilter_Deprecated_GalleryList",
          }),
        );
      }
    };
  }
}

export const galleryList = new GalleryList("gallery_default");

export const constructGalleryFilter = ({
  shot_at_date_to,
  shot_at_date_from,
  any_field,
  genre_tags,
  map_bounds,
  gallery_type,
  gallery_event,
  photographers,
  activity_tags,
  has_gallery_event,
  filters_were_touched,
  exclude_galleries_with_an_album,
}) => {
  const newFilter = {};

  if (includes(window.location.href, CUSTOMER_UI_MAP_PAGE_PATH)) {
    if (any_field) {
      newFilter.any_field = any_field;
      if (any_field.length <= MIN_CHARACTERS_FOR_FILTER) {
        newFilter.any_field = undefined;
      }
    }
  } else {
    newFilter.any_field = any_field;
  }

  if (exclude_galleries_with_an_album) {
    newFilter.exclude_galleries_with_an_album = exclude_galleries_with_an_album;
  }

  if (activity_tags) {
    newFilter.activity_tags = activity_tags;
  }

  if (photographers) {
    newFilter.photographers = photographers;
  }

  if (gallery_event) {
    newFilter.gallery_event = gallery_event;
  }

  if (has_gallery_event !== undefined) {
    newFilter.has_gallery_event = has_gallery_event;
  }

  if (gallery_type) {
    newFilter.gallery_type = gallery_type;
  }

  if (genre_tags) {
    newFilter.genre_tags = genre_tags;
  }

  if (shot_at_date_from !== undefined) {
    if (shot_at_date_from === null) {
      newFilter.shot_at_date_from = null;
    } else {
      newFilter.shot_at_date_from = shot_at_date_from.format();
    }
  }

  if (shot_at_date_to !== undefined) {
    if (shot_at_date_to === null) {
      newFilter.shot_at_date_to = null;
    } else {
      newFilter.shot_at_date_to = shot_at_date_to.format();
    }
  }

  if (map_bounds) {
    newFilter.north_east_lat = roundLargerCoordinate(
      map_bounds.getNorthEast().lat(),
    );
    newFilter.north_east_lng = roundLargerCoordinate(
      map_bounds.getNorthEast().lng(),
    );
    newFilter.south_west_lat = roundSmallerCoordinate(
      map_bounds.getSouthWest().lat(),
    );
    newFilter.south_west_lng = roundSmallerCoordinate(
      map_bounds.getSouthWest().lng(),
    );
    // this.map_bounds = map_bounds // I think not needed, leaving here for now in case I'm wrong

    // never send country with map coords
    newFilter.country = undefined;
  }

  newFilter.filters_were_touched = filters_were_touched;

  return newFilter;
};

export const mergeGalleriesAndGalleryEvents = ({
  galleries,
  galleryEvents,
}) => {
  const merged = union(
    map(galleries, (x) => Object.assign({}, x, { type: "gallery" })),
    map(galleryEvents, (x) => Object.assign({}, x, { type: "gallery_event" })),
  );

  const merged_and_sorted = sortBy(
    merged,
    (x) =>
      (x.type === "gallery" && x.shot_at_datetime) ||
      (x.type === "gallery_event" && x.most_recent_shot_at_datetime),
  );

  const sorted_and_reversed = reverse(merged_and_sorted);

  return sorted_and_reversed;
};

export const mergeGalleriesAndAlbums = ({ galleries, albums }) => {
  const merged = union(
    map(galleries, (x) => Object.assign({}, x, { type: "gallery" })),
    map(albums, (x) => Object.assign({}, x, { type: "albums" })),
  );

  const merged_and_sorted = sortBy(
    merged,
    (x) =>
      (x.type === "gallery" && x.shot_at_datetime) ||
      (x.type === "albums" && x.most_recent_shot_at_datetime),
  );

  const sorted_and_reversed = reverse(merged_and_sorted);

  return sorted_and_reversed;
};

export const addHighResForMapPhotographSrc = (ev, mapPhotograph) =>
  (ev.target.src = get(head(mapPhotograph), [
    "gallery_size_file_info",
    "download_url",
  ]));

export const getBufferSizeFromASinglePhoto = (
  photos,
  numPhotosWhenShowingShowMoreButton,
) => {
  const numPhotos = size(photos);
  const bufferSize = numPhotos - numPhotosWhenShowingShowMoreButton || 0;
  const indexStartingFromZero = numPhotosWhenShowingShowMoreButton - 1;
  const lastIndexOfBuffer = numPhotos - Math.max(indexStartingFromZero, 0);
  return map(slice(photos, bufferSize, lastIndexOfBuffer), (photo) =>
    get(photo, "id"),
  );
};

function roundLargerCoordinate(coord) {
  return Math.ceil(coord * 10) / 10;
}

function roundSmallerCoordinate(coord) {
  return Math.floor(coord * 10) / 10;
}

import map from "lodash/map";
import get from "lodash/get";
import React from "react";
import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import Typography from "@mui/material/Typography";

import { DATE_FILTER_OPTIONS_FILTERED } from "../../v2_actions/date";
import { briefGalleryList } from "../../v2_actions/brief_gallery";
import { useGalleryFilterSearchParams } from "../hooks/useGalleryFilterSearchParams";
import { useReduxStateReconcilation } from "../hooks/useReduxStateReconcilation";
import { styles } from "./styles";
import { makeLogger } from "../../utils/o11y/logger";
import { getDateLabel } from "../../v2_components/eventpage/eventPageUtils";

const logger = makeLogger({
  enabled: true,
  label: "FilterDate",
  color: "pink",
});

export default function FilterDate() {
  useReduxStateReconcilation();
  const [galleryFilterSearchParams, syncGalleryFilterSearchParam] =
    useGalleryFilterSearchParams();
  const [muiMenuAnchor, setMuiMenuAnchor] = React.useState(null);
  const isMenuOpen = Boolean(muiMenuAnchor);
  const calendar_filter = get(briefGalleryList.getFilter(), "calendar_filter");

  const calendarFilterFromSearchParam = galleryFilterSearchParams.date;

  const handleClickListItem = (event) => {
    setMuiMenuAnchor(event.currentTarget);
  };

  const handleMenuItemClick = (option, index) => {
    syncGalleryFilterSearchParam({ date: option?.value });
    setMuiMenuAnchor(null);
  };
  const handleCheckboxClicked = (ev) => {
    if (!ev.target.checked) {
      setMuiMenuAnchor(null);
      syncGalleryFilterSearchParam({ date: "" });
    }
  };

  const handleClose = () => {
    setMuiMenuAnchor(null);
  };

  logger.log({
    DATE_FILTER_OPTIONS_FILTERED,
    dateFilterValue: calendarFilterFromSearchParam,
    calendar_filter,
  });

  const filterLabel = getDateLabel(calendarFilterFromSearchParam);

  const isFilterActive = !!calendarFilterFromSearchParam;

  return (
    <div>
      <Typography
        button
        sx={styles.filterListTitle}
        onClick={handleClickListItem}
      >
        Date
      </Typography>
      <List component="nav" sx={styles.filterList}>
        <ListItem
          button
          sx={styles.filterListItem}
          aria-expanded={isMenuOpen ? "true" : undefined}
        >
          <Checkbox
            checked={isFilterActive}
            size="small"
            onChange={handleCheckboxClicked}
          />
          <ListItemText
            onClick={handleClickListItem}
            primary=""
            sx={styles.filterListItemText}
            secondary={<ListItemText primary={filterLabel} />}
          />
        </ListItem>
      </List>
      <Menu
        anchorEl={muiMenuAnchor}
        open={isMenuOpen}
        onClose={handleClose}
        MenuListProps={{
          role: "listbox",
        }}
      >
        <MenuItem
          value={undefined}
          disabled={calendarFilterFromSearchParam === undefined}
          selected={calendarFilterFromSearchParam === undefined}
          onClick={(event) => handleMenuItemClick(null, 0)}
        >
          Recent
        </MenuItem>
        {map(DATE_FILTER_OPTIONS_FILTERED, (option, index) => (
          <MenuItem
            key={get(option, "value")}
            disabled={calendarFilterFromSearchParam === get(option, "value")}
            selected={calendarFilterFromSearchParam === get(option, "value")}
            onClick={(event) => handleMenuItemClick(option, index)}
          >
            {get(option, "label")}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}


// eslint-disable-next-line
export default (theme) => ({
  root: {
    display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%",
  },
  menuWrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    paddingTop: 65,
    [theme.breakpoints.up("lg")]: {
      paddingTop: 65,
      paddingLeft: 300,
    },
    height: "calc(100vh - 65px)",
  },
  nonMennuWrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    height: "100vh",
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  content: {
    flex: "1 1 auto",
    height: "100%",
    overflow: "auto",
  },
  card: {
    display: "flex",
    alignItems: "center",
    padding: 15,
    color: "white",
    width: "100%",
    background:
      "linear-gradient(to right, rgb(1, 72, 182) 0%, rgb(0, 24, 56) 99%)",
    border: "1px solid #000000",
    borderRadius: 10,
    fontSize: 20,
  },
  mobilecard: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    padding: 10,
    color: "white",
    background:
      "linear-gradient(to right, rgb(1, 72, 182) 0%, rgb(0, 24, 56) 99%)",
    height: 50,
  },
  checkoutBtn: {
    textTransform: "capitalize",
    color: "black",
    border: "1px solid black",
    borderRadius: 10,
    textAlign: "left",
    width: 200,
    backgroundColor: "#80808036 !important",
  },
  checkoutmobileBtn: {
    backgroundColor: "#72bb53 !important",
    textTransform: "capitalize",
    color: "white",
    border: "1px solid black",
    borderRadius: 10,
    fontWeight: "400",
    fontSize: 20,
    padding: 12,
  },
  orderBtn: {
    textTransform: "capitalize",
    color: "black",
    border: "1px solid black",
    borderRadius: 10,
    textAlign: "left",
    width: 200,
    backgroundColor: "#80808036 !important",
    height: 60,
  },
  summaryCard: {
    float: "right",
    width: 400,
    padding: 10,
    border: "1px solid black",
  },
  mobileSummaryCard: {
    width: 345,
    padding: 10,
    border: "3px solid black",
    borderRadius: 20,
  },
  photoCard: {
    border: "2px solid black",
    padding: 15,
    paddingLeft: 40,
    paddingRight: 50,
    margin: 20,
  },
  table: {
    zIndex: 99,
    padding: 10,
    width: "100%",
    "& .MuiTableCell-root": {
      border: "1px solid #c0c0c0",
      padding: 10,
      height: "100%",
    },
  },
  icon: {
    width: 45,
    height: 45,
    color: "black",
  },
  sicon: {
    width: 30,
    height: 30,
    color: "black",
  },
  whiteicon: {
    width: 30,
    height: 30,
    color: "white",
  },
  avatar: {
    width: 45,
    height: 45,
  },
  text: {
    fontSize: 16,
  },
  button: {
    height: 40,
    textTransform: "capitalize",
    color: "#0c59cf",
  },
  paper: {
    position: "absolute",
    background: "white",
    width: 700,
    border: "2px solid #0c59cf !important",
    color: "#0c59cf",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  mobilePaper: {
    position: "absolute",
    background: "white",
    width: 350,
    border: "2px solid #0c59cf !important",
    color: "#0c59cf",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  submitbutton: {
    marginTop: 50,
    backgroundColor: "#0c59cf !important",
    textTransform: "capitalize",
    color: "white",
    fontSize: 20,
    fontWeight: "500",
    padding: 10,
    width: 250,
  },
  progressWrapper: {
    width: "100%",
    height: `calc(100vh - 110px)`,
  }
});

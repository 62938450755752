// eslint-disable-next-line
export default (theme) => ({
  root: {},
  avatar: {
    width: 60,
    height: 60,
  },
  logo: {
    fontFamily: "Aguafina Script",
    fontSize: 60,
    fontWeight: "400",
    color: "black",
  },
  middleiconbtn: {
    width: 30,
    height: 30,
    color: "black",
  },
  text: {
    color: "#606060",
  },
  box: {
    borderRadius: 20,
  },
  inputRoot: {
    color: "black",
    fontFamily: "Roboto Mono",
    backgroundColor: "white",
    border: "1px solid",
    padding: "8px !important",
    borderRadius: 20,
  },
  greybtn: {
    background: "#b3b3ba !important",
    fontSize: 16,
    color: "white",
    height: 30,
    textTransform: "capitalize",
    padding: 20,
    borderRadius: 20,
  },
  iconbtn: {
    background: "white !important",
    width: 40,
    height: 40,
  },
  icon: {
    color: "#0c59cf",
    width: 40,
    height: 40,
  },
  desktopCard: {
    padding: 10,
    color: "#0c59cf",
    width: 550,
    overflowY: "scroll",
  },
  mobileCard: {
    padding: 10,
    color: "#0c59cf",
    width: "100%",
    zIndex: 99,
    overflowY: "scroll",
    height: "100%",
  },
  galleryTitle: {
    color: "black",
    fontSize: 16,
    fontWeight: "500",
  },
  cardItem: {
    display: "flex",
    paddingTop: 10,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#607d8bde",
    },
  },
  popupWrapper: {
    bottom: 0,
    left: 0,
    margin: 10,
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 450px)",
    justifyContent: "space-between",
    alignItems: "center",
    zIndex: 9,
  },
  mobilePopupWrapper: {
    height:"40vh",
    overflowY: "scroll",
    // position: "absolute",
    zIndex: 9,
    bottom: 0,
    left: 0,
  },
  searchPlaceWrapper: {
    top: 30,
    left:"50%",
    transform: `translate(-50%, -30px)`,
    width: "50%",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    height:60,
    zIndex: 105
  },
  mobileSearchPlaceWrapper: {
    top: 40,
    left:"50%",
    width:"80%",
    transform: `translate(-50%, -30px)`,
    display: "flex",
    position: "absolute",
    zIndex: 105,
    [theme.breakpoints.up('md')]: {
      top: -30,
    },
    '& input': {
      position: 'relative',
      left: '30%',
      width: '50%',
      '&::placeholder': {
        fontWeight: 400,
        fontSize: '16px !important',
      }
    },
    '& svg': {
      left: '25%',
      position: 'absolute'
    },
  },
  searchInput: {
    color: '#444444',
    textAlign: 'center',
    boxSizing: `border-box`,
    width: `100%`,
    height: `50px`,
    padding: `0 12px`,
    fontSize: `14px`,
    outline: `none`,
    textOverflow: `ellipses`,
    borderRadius: 20,
    zIndex: 99999,
    '&::before': {
      border: 'none'
    }
  },
  mapHoverGroup: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    top: 0,
    right: 70,
    justifyContent: "space-between",
    zIndex: 9,
  },
  mapIconGroup: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    top: 10,
    right: 10,
    height: "20vh",
    justifyContent: "space-between",
    zIndex: 9,
  },
  mapMobileIconGroup: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    top: 10,
    right: 10,
    height: "20vh",
    justifyContent: "space-between",
    zIndex: 99999,
  },
  paper: {
    position: "absolute",
    background: "white",
    width: 750,
    border: "2px solid #0c59cf !important",
    color: "#0c59cf",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: "left",
  },
  mobilepaper: {
    position: "absolute",
    background: "white",
    width: 360,
    border: "2px solid #0c59cf !important",
    color: "#0c59cf",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: "center",
  },
  passwordModalWrapper: {
    width: 500,
    position: "absolute",
    background: "white",
    border: "0px solid #0c59cf !important",
    borderRadius: 20,
    color: "#0c59cf",
    padding: theme.spacing(3, 6),
    textAlign: "left",
  },
  mobilePasswordModalWrapper: {
    position: "absolute",
    background: "white",
    width: 360,
    borderRadius: 20,
    border: "0px solid #0c59cf !important",
    color: "#0c59cf",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: "center",
  },
  submitButton: {
    width: 200,
    height: 35,
    fontSize: 18,
    color: "white",
    borderRadius: 10,
    border: '#0c59cf',
    textTransform: "capitalize",
    backgroundColor: "#0c59cf !important",
  },
  backButton: {
    color: '#7a7a7a',
    borderRadius: 10,
    width: 200,
    height: 35,
    border: '#ebebeb',
    background: '#ebebeb',
    fontSize: 18,
    marginRight: 5,
    textTransform: "capitalize",
    [theme.breakpoints.down('sm')]: {
      marginBottom: 10
    },
  },
  buttonText: {
    fontSize: 16,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    }
  },
  passwordModalHeading: {
    fontSize: 16,
    color: '#000',
    fontWeight: 800,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      textAlign: 'left'
    }
  },
  passwordModalBody: {
    fontSize: 16,
    fontWeight: 400,
    color: '#606060',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      textAlign: 'left'
    }
  },
  passwordModalCloseIcon: {
    top: 8,
    right: 8,
    position: 'absolute',
  },
  passwordModalInputLabel: {
    margin: 0,
    fontSize: 16,
    fontWeight: 800,
    color: '#0c59cf',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      textAlign: 'left'
    }
  },
  passwordVisibilityIcon: {
    bottom: 0,
    position: 'relative'
  },
  passwordVisibilityIconError: {
    bottom: 10,
    position: 'relative'
  },
  passwordInputField: {
    '& div': {
      fontSize: 16,
      width: 400
    },
    [theme.breakpoints.down('sm')]: {
      '& div': {
        fontSize: 12,
        width: 290
      }
    }
  }
})

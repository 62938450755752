import map from "lodash/map";
import size from "lodash/size";
import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  Card,
  Grid,
  Box,
  Typography,
  Button,
  makeStyles,
  IconButton,
  Hidden,
  Modal,
  CircularProgress,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import CheckIcon from "@material-ui/icons/Check";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import OrderTabel from "./table";
import style from "./styles";
import "react-lazy-load-image-component/src/effects/blur.css";
import { purchasedTrolleyPhotographList } from "../../v2_actions/purchased_trolley_photograph";
import { useIsMobile } from "../../actions/ui";
const useStyles = makeStyles(style);

function getProgressStyle() {
  return {
    top: `50%`,
    left: `55%`,
    transform: `translate(-50%, -50%)`,
    zIndex: 999,
  };
}

function getModalStyle() {
  const left = 50;
  return {
    top: `400px`,
    left: `${left}%`,
    transform: `translate(-400px, -${left}%)`,
  };
}

const CircularProgressWithLabel = (props) => {
  const [modalStyle] = useState(getProgressStyle);
  return (
    <Box position="absolute" style={modalStyle}>
      <Box position="relative" display="inline-flex">
        <CircularProgress
          variant="determinate"
          {...props}
          style={{ color: green[500], width: 100, height: 100, zIndex: 999 }}
        />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            variant="h6"
            component="div"
            color="secondary"
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

const Photos = ({ purchased_trolley }) => {
  const classes = useStyles();
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const [modalStyle] = useState(getModalStyle);
  const [progress, setProgress] = useState(0);
  const [orderModal, setOrderModal] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [showSpinner, setShowSpinner] = useState(true);
  const imageLoadedIndex = useRef(0);

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  });

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  const onDownloadPhotograph = ({ purchased_trolley_photograph }) => {
    dispatch(
      purchasedTrolleyPhotographList.onDownloaded(
        purchased_trolley_photograph.id,
      ),
    );
    let a = document.createElement("a");
    a.href = purchased_trolley_photograph.photograph_download_url;
    a.download = purchased_trolley_photograph.photograph.photo_name;

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const onImageLoaded = () => {
    imageLoadedIndex.current += 1;
    if (
      imageLoadedIndex.current >=
      size(purchased_trolley.purchased_trolley_photographs)
    ) {
      setShowSpinner(false);
      imageLoadedIndex.current = 0;
    }
  };

  return (
    <Box position="relative">
      {showSpinner && (
        <CircularProgress
          style={{
            position: "absolute",
            top: "100px",
            left: "50%",
            width: 60,
            height: 60,
            color: green[500],
            zIndex: 999,
          }}
        />
      )}

      {showSpinner && <CircularProgressWithLabel value={progress} />}

      {map(
        purchased_trolley.purchased_trolley_photographs,
        (purchased_trolley_photograph) => {
          return (
            <div key={`${purchased_trolley_photograph.id}-purchasedphoto`}>
              <Hidden mdDown>
                <Card className={classes.photoCard}>
                  <Grid container spacing={3}>
                    <Grid item xs={!isMobile ? 9 : 12}>
                      <Box position="relative" width="50%">
                        <LazyLoadImage
                          alt=""
                          src={
                            purchased_trolley_photograph.photograph_download_url
                          }
                          effect="blur"
                          width="100%"
                          height="auto"
                          style={{ padding: 5, objectFit: "cover" }}
                          afterLoad={onImageLoaded}
                        />
                        <Box
                          style={{
                            position: "absolute",
                            right: 10,
                            top: 10,
                            backgroundColor: "white",
                            border: "1px solid",
                            borderRadius: 10,
                          }}
                        >
                          <IconButton
                            onClick={() =>
                              onDownloadPhotograph({
                                purchased_trolley_photograph,
                              })
                            }
                          >
                            <CloudDownloadIcon className={classes.sicon} />
                          </IconButton>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box
                        display="flex"
                        flexDirection="column"
                        style={{ paddingLeft: 35 }}
                      >
                        <Typography>
                          Foto ID:{" "}
                          {purchased_trolley_photograph.photograph.short_ref}
                        </Typography>
                        <Box display="flex" alignItems="center">
                          <Button
                            color="default"
                            className={classes.checkoutBtn}
                            onClick={() =>
                              onDownloadPhotograph({
                                purchased_trolley_photograph,
                              })
                            }
                          >
                            Download
                          </Button>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          {purchased_trolley_photograph.downloaded > 0 && (
                            <IconButton>
                              <CheckIcon />
                            </IconButton>
                          )}
                        </Box>
                        {/* &nbsp;&nbsp;&nbsp;&nbsp;
                              <Button
                                color="default"
                                className={classes.orderBtn}
                                onClick={() => setOrderModal(true)}
                              >
                                Order Prints
                              </Button> */}
                      </Box>
                    </Grid>
                  </Grid>
                </Card>
              </Hidden>
              <Hidden lgUp>
                <Box position="relative">
                  <LazyLoadImage
                    alt=""
                    src={purchased_trolley_photograph.photograph_download_url}
                    afterLoad={onImageLoaded}
                    effect="blur"
                    width="100%"
                    height="auto"
                    style={{ padding: 5, objectFit: "cover" }}
                  />
                  <Box
                    style={{
                      position: "absolute",
                      right: 10,
                      top: 10,
                      backgroundColor: "white",
                      border: "1px solid",
                      borderRadius: 10,
                    }}
                  >
                    <IconButton
                      onClick={() =>
                        onDownloadPhotograph({ purchased_trolley_photograph })
                      }
                    >
                      <CloudDownloadIcon className={classes.sicon} />
                    </IconButton>
                  </Box>
                </Box>
              </Hidden>
              <Modal open={orderModal} onClose={(e) => setOrderModal(false)}>
                <div
                  style={modalStyle}
                  className={!isMobile ? classes.paper : classes.mobilePaper}
                >
                  <OrderTabel
                    setOrderModal={setOrderModal}
                    photoId={purchased_trolley_photograph.photograph.id}
                    photographer_ref={purchased_trolley.photographer}
                  />
                </div>
              </Modal>
            </div>
          );
        },
      )}
    </Box>
  );
};

export default Photos;

import * as React from "react";
import moment from "moment";
import Box from "@mui/material/Box";
import { createTheme } from "@mui/material/styles";

const defaultTheme = createTheme();
const DEFAULT_NUM_PHOTOS_TO_INCREMENT_BY_INFINITE_SCROLL = 10000;
const DEFAULT_MILLISECONDS_TO_REVEAL_HIGH_RES_PHOTOS = 15000;

const photographTimeTakenBox = {
  left: 50,
  bottom: 10,
  height: 40,
  width: 100,
  fontSize: 16,
  color: "#fff",
  fontWeight: 500,
  display: "flex",
  borderRadius: 20,
  alignItems: "center",
  position: "absolute",
  justifyContent: "center",
  backgroundColor: "rgb(96 96 96 / 50%)",
};

export default function GalleryPhotographTimeTakenAtOverlay({ photograph }) {
  return (
    <Box sx={photographTimeTakenBox}>
      <div>
        <p>
          <span>
            {moment(photograph.photograph_taken_at).utc().format("HH:mm a")}
          </span>
        </p>
      </div>
    </Box>
  );
}

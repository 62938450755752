import React from "react";
import { NavButton, NavButtonProps } from "bossfotos-design-system";
import HelpIcon from "@mui/icons-material/Help";
import { BOSSFOTOS_INFO_WEBSITE } from "../../v2_actions/routes";

const HelpButton = (props: NavButtonProps) => {
  const handleHelpClick = () => {
    window.open(`${BOSSFOTOS_INFO_WEBSITE}/info-centre/`);
  };

  return (
    <NavButton onClick={handleHelpClick}>
      <HelpIcon />
    </NavButton>
  );
};

export default HelpButton;

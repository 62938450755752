import { ItemList } from "../orm";

class PurchasedTrolleyPhotographList extends ItemList {
  getEntityKey() {
    return "customer/purchased_trolley_photograph";
  }

  onDownloaded(purchased_trolley_photograph_id) {
    return async (dispatch, getState) => {
      dispatch(
        this.saveObject({
          id: purchased_trolley_photograph_id,
          action: "downloaded",
        }),
      );
    };
  }
}

export const purchasedTrolleyPhotographList =
  new PurchasedTrolleyPhotographList("purchased_trolley_photograph_default");

import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux";
import { PaymentGateway, TrolleyState } from "../types/backend";

import { authenticatedTrolleyList } from "../v2_actions/authenticated_trolley";
import { countryList } from "../v2_actions/country";
import isEmpty from "lodash/isEmpty";
import { photographerList } from "../v2_actions/photographer";
import { trolleyPhotographList } from "../v2_actions/trolley_photograph";
import { getTrolleyID } from "../actions/trolley";

const useTrolley = (): TrolleyState => {
  const dispatch = useDispatch();
  // @ts-ignore
  const trolley_id = useSelector((state) => getTrolleyID(state))
  const trolley = useSelector(() => authenticatedTrolleyList.getObject(trolley_id));
  const isLoading = !!authenticatedTrolleyList.isLoading() || isEmpty(trolley);
  const photographer = photographerList.getPhotographerForId(
    trolley?.photographer,
  );
  const photographListIsSaving = trolleyPhotographList.getIsSavingObject();
  const photographListIsDeleting = trolleyPhotographList.getIsDeletingObject();

  const photographerCountry = countryList.getCountryForId(
    photographer?.country,
  );

  const payment_gateway = countryList.getPaymentGateway(
    trolley?.country,
  ) as PaymentGateway;

  useEffect(() => {
    dispatch(authenticatedTrolleyList.ensureObjectLoaded(trolley_id))
  }, [trolley_id])

  return {
    isLoading,
    isUpdating: isLoading || photographListIsSaving || photographListIsDeleting,
    trolley,
    photographer,
    photographListIsSaving,
    photographListIsDeleting,

    photographerCountry,

    totalPriceOwingByCustomer: trolley?.total_price_owing_by_customer,
    totalPriceOwingByCustomerInCents:
      trolley?.total_price_owing_by_customer * 100,

    paymentGateway: payment_gateway,
  };
};

export default useTrolley;

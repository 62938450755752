import { getRandomBackgroundColor } from "../utils/color";

export const TombstoneContainer = ({ children, ref, ...props }) => <div 
  ref={ref} 
  {...props} 
  style={{  
    display:"flex",
    flexDirection:"row",
    gap: "20px",
    marginTop:"20px",
  }}
  className="tombstone-container">{children}</div>;

export const Tombstone = ({ bgColor, isMobile, ...props }) => <div {...props} 
  style={{ 
    marginBottom: "1rem",
    width: isMobile ? "100%" : "calc(33.3333% - 13.3333px)",
    height:"60%",
    aspectRatio: "180 / 120",
    display:"block",
    borderRadius:"2px",
    backgroundColor: getRandomBackgroundColor() 
  }} className="tombstone"></div>;
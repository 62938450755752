import { get } from 'lodash'
import {
    USER_LOGGED_IN,
    ANNOUNCE_LOGGING_IN_USER,
    ANNOUNCE_USER_NOT_LOGGED_IN,
    SET_CURRENCY_SYMBOL,
} from '../v2_actions/auth'

const initialState = {
    token: null,
    user: null,
    isLoading: false,
    isLoggedIn: false,
    API_BASE: get(window, 'LOCAL_SETTINGS.API_V2_BASE', '/'),
    CurrencySymbol:"$"
}

export default function auth(state = initialState, action) {
    switch (action.type) {
        case ANNOUNCE_LOGGING_IN_USER:
            return Object.assign({}, state, {isLoading: true, })
        case ANNOUNCE_USER_NOT_LOGGED_IN:
            return Object.assign({}, state, {token: null, user: null, isLoading: false, hasCheckedLogin: true, isLoggedIn: false})
        case USER_LOGGED_IN:
            return Object.assign({}, state, {
                user: action.user,
                isLoading: false,
                isLoggedIn: true,
                hasCheckedLogin: true,
                token: action.token,
            })
        case SET_CURRENCY_SYMBOL:
            return {...state,  CurrencySymbol:action.payload }
        default:
            return state
    }
}

import size from "lodash/size";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import React, { useEffect, useState } from "react";
import Slide from "@mui/material/Slide";
import Stack from "@mui/material/Stack";
import {
  getMobMaxWidth,
  getMobMaxWidthPixels,
  getTabMaxWidthPixels,
  getTabMinWidthPixels,
} from "./layout/mobile";
import { useDispatch, useSelector } from "react-redux";

import { GalleryCard } from "./GalleryCard";
import { GalleryEventCard } from "./GalleryEventCard";
import { GalleryFilter } from "./GalleryFilter";
import { GalleryLayout } from "./layout/GalleryLayout";
import { GalleryMap } from "./GalleryMap";
import LoadingSpinner from "../views/components/LoadingSpinner";
import { SeeMoreButton } from "./SeeMoreButton";
import { briefGalleryList } from "../v2_actions/brief_gallery";
import { galleryEventList } from "../v2_actions/gallery_event";
import { makeStyles } from "@material-ui/core";
import { mergeGalleriesAndGalleryEvents } from "../v2_actions/gallery";
import { setGalleryNavBackButtonUrl } from "../v2_actions/nav";
import { useIsMobile } from "../actions/ui";

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    marginLeft: "40px",
    marginTop: "15px",
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      marginTop: "60px",
      marginLeft: "0px",
      flexDirection: "column",
      height: "70vh",
      width: "100%",
      justifyContent: "flex-start",
      overflowY: "scroll",
    },
  },
  mobMainBar: {
    display: "none",
    width: "100%",
    zIndex: "1",
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      display: "block",
      zIndex: "1",
    },
  },
  mainMenuBar: {
    display: "flex",
    backgroundColor: "#2197F3",
    paddingTop: "0px",
    paddingLeft: "0px",
    paddingRight: "0px",
    paddingBottom: "0px",
    fontSize: "18px",
    color: "white",
    justifyContent: "space-between",
  },
  barBtn: {
    backgroundColor: "transparent",
    border: "none",
    color: "white",
    fontSize: "16px",
    fontWeight: "500",
    cursor: "pointer",
    padding: "10px",
    paddingLeft: "30px",
    paddingRight: "30px",
    width: "50%",
  },
  barWithGallery: {
    borderBottom: "3px solid #000",
    color: "white",
  },
  barWithoutGallery: {
    borderBottom: "none",
    color: "purple",
  },
  mapContainer: {
    width: "38%",
    [`@media only screen and (min-width:${getMobMaxWidthPixels()})`]: {
      top: 50,
      position: "relative",
    },
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      width: "100%",
    },
  },
  galleryContainer: {
    width: "62%",
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      width: "100%",
    },
  },
  mobiMapButton: {
    bottom: 5,
    width: "100%",
    height: 105,
    padding: "15px 0px 5px",
    background: "#fff",
    position: "fixed",
    textAlign: "center",
  },
  mobiMapButtonText: {
    fontSize: 16,
    margin: "20px 0px 0px",
  },
  noGalleriesDiv: {
    width: "100%",
    height: "100vh",
    padding: "22px",
  },
  noGalleriesDivText: {
    fontSize: 16,
    [`@media only screen and (min-width:${getMobMaxWidthPixels()})`]: {
      marginLeft: 20,
    },
  },
  galleryPageSeeMoreButton: {
    gridColumn: "1 / -1",
  },
  cardMainSec: {
    display: "inline-grid",
    gridTemplateColumns: "auto auto auto",
    gridColumnGap: "20px",
    gridRowGap: "0px",
    flexWrap: "wrap",
    justifyContent: "space-between",
    height: "80vh",
    overflowY: "scroll",
    marginLeft: "20px",
    [`@media only screen and  (min-width:${getTabMinWidthPixels()})and (max-width: ${getTabMaxWidthPixels()})`]:
      {
        width: "55%",
      },
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      marginLeft: "0px",
      display: "grid",
      gridTemplateColumns: "101%",
      gridRowGap: "20px",
      width: "100%",
      height: "auto",
      marginTop: "10px",
      overflowY: "hidden",
    },
  },
}));

const GalleryPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const [showGallery, setShowGallery] = useState(true);
  const [showMap, setShowMap] = useState(true);
  const isMobileWidth = window.innerWidth <= getMobMaxWidth();
  const galleries = useSelector(() => briefGalleryList.getFilteredGalleries());
  const galleryEvents = useSelector(() => galleryEventList.getVisibleObjects());
  const isBriefGalleryListLoading = useSelector(() =>
    briefGalleryList.isLoading(),
  );
  const isGalleryEventListLoading = useSelector(() =>
    galleryEventList.isLoading(),
  );
  const mergedGalleryAndGalleryEvents = mergeGalleriesAndGalleryEvents({
    galleries,
    galleryEvents,
  });

  useEffect(() => {
    setGalleryNavBackButtonUrl();
    dispatch(briefGalleryList.fetchListIfNeeded({ callSource: "GalleryPage" }));
  }, []);

  useEffect(() => {
    if (isMobileWidth) {
      setShowGallery(false);
    }
  }, []);

  const onStartingLocationSearch = () => {
    if (isMobileWidth) {
      setShowGallery(false);
      setShowMap(true);
    }
  };

  return (
    <GalleryLayout
      activeTab="map"
      onStartingLocationSearch={onStartingLocationSearch}
    >
      {isBriefGalleryListLoading && isGalleryEventListLoading && (
        <LoadingSpinner />
      )}
      <div className={classes.main}>
        <div className={classes.mobMainBar}>
          <div className={classes.mainMenuBar}>
            <button
              className={[
                `${classes.barBtn}
                              ${showGallery ? classes.barWithGallery : classes.barWithoutGallery}`,
              ]}
              onClick={() => [setShowGallery(true), setShowMap(false)]}
            >
              Gallery View
            </button>
            <button
              className={[
                `${classes.barBtn}
                              ${showGallery ? classes.barWithoutGallery : classes.barWithGallery}`,
              ]}
              onClick={() => [setShowMap(true), setShowGallery(false)]}
            >
              Map View
            </button>
          </div>
        </div>

        {showMap && (
          <Slide direction="right" in={true} mountOnEnter>
            <div className={classes.mapContainer}>
              <GalleryMap />
            </div>
          </Slide>
        )}
        {showGallery && (
          <Slide direction="left" in={true} mountOnEnter>
            <div className={classes.galleryContainer}>
              {!isMobile && (
                <GalleryFilter
                  onStartingLocationSearch={onStartingLocationSearch}
                />
              )}
              {!isGalleryEventListLoading &&
                !isBriefGalleryListLoading &&
                isEmpty(galleries) &&
                isEmpty(galleryEvents) && (
                  <Stack
                    spacing={2}
                    direction="column"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    className={classes.noGalleriesDiv}
                  >
                    <h2 className={classes.noGalleriesDivText}>
                      No galleries match your search.
                    </h2>
                    <p>
                      Please position the map to where the photos were taken.
                      Please contact
                      <a href="mailto:info@bossfotos.com">
                        {" "}
                        info@bossfotos.com{" "}
                      </a>
                      if you cannot find your photos.
                    </p>
                  </Stack>
                )}
              <div className={classes.cardMainSec}>
                {map(mergedGalleryAndGalleryEvents, function (item, index) {
                  return (
                    <div key={index}>
                      {item.type === "gallery_event" && (
                        <GalleryEventCard
                          title={item.name}
                          gallery_event_name={item.name}
                          image={get(item, [
                            "event_photograph",
                            "gallery_size_file_info",
                            "download_url",
                          ])}
                        />
                      )}
                      {item.type === "gallery" && (
                        <GalleryCard
                          gallery={item}
                          gallery_id={item.id}
                          title={item.gallery_name}
                          image={item.gallery_photo_url}
                        />
                      )}
                    </div>
                  );
                })}
                {(!isMobile || (isMobile && !showMap)) &&
                  size(galleries) > 0 && (
                    <div className={classes.galleryPageSeeMoreButton}>
                      <SeeMoreButton list={briefGalleryList} />
                    </div>
                  )}
              </div>
            </div>
          </Slide>
        )}
        {showMap && isMobileWidth && size(galleries) > 0 && (
          <div className={classes.mobiMapButton}>
            <button onClick={() => [setShowMap(false), setShowGallery(true)]}>
              Go to Gallery View
            </button>
            <p className={classes.mobiMapButtonText}>
              {size(galleries)}{" "}
              {size(galleries) === 1 ? "gallery" : "galleries"} showing
            </p>
          </div>
        )}
      </div>
    </GalleryLayout>
  );
};

export default GalleryPage;

import React from "react";
import styles from "./Header.module.css";

/**
 * Header
 * @returns
 */
const Header = ({
  children,
  ...props
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>) => {
  return (
    <div {...props} className={styles.header}>
      {children}
    </div>
  );
};

export default Header;

import {
  DisplayMode,
  deriveDisplayMode,
  mapDateRange,
} from "../eventpage/eventPageUtils";
import {
  ListFilterArgs,
  useEventFiltersDispatcher,
} from "./useEventFiltersDispatcher";

import { briefGalleryList } from "../../v2_actions/brief_gallery";
import { makeLogger } from "../../utils/o11y/logger";
import { photographerDropdownOptions } from "../../v2_actions/dropdown_options";
import { useCountryDetection } from "./useCountryDetection";
import { useEffect } from "react";
import { useGalleryFilterSearchParams } from "./useGalleryFilterSearchParams";
import { useIsMobile } from "../../actions/ui";
import { useRouteInfo } from "./useRouteInfo";

const logger = makeLogger({
  enabled: true,
  label: "useEventFiltersBroker",
  color: "#FF0077",
});

type useEventFilterDispatchProps = {
  /* gallery event name from URL param, if defined */
  galleryEventUrlKey: string | undefined;
  /* album name from URL param, if defined */
  albumUrlKey: string | undefined;
  /* public_url, if defined */
  event_page: string | undefined;
};

/**
 * Responsible for centralised control of events page
 * API calls. All inputs (user, URL, etc) that affect
 * API filter parameters should go through this, which
 * should only be invoked once.
 *
 * Downstream components can still read state and wait
 * for loading states to update
 */
export const useEventFiltersBroker = ({
  galleryEventUrlKey,
  albumUrlKey,
  event_page,
}: useEventFilterDispatchProps) => {
  const eventPageCtx = useEventFiltersDispatcher();
  const [searchParams] = useGalleryFilterSearchParams();
  const { isMapView } = useRouteInfo();
  const isMobile = useIsMobile();
  const { getPreferredCountryApiId } = useCountryDetection();
  const displayMode = deriveDisplayMode(galleryEventUrlKey, albumUrlKey);
  const isMapFilterActive = briefGalleryList.isMapFilterActive();
  const currentFilter = briefGalleryList.getFilter();

  // maybe resolve photographer ...
  const photographerOptions = photographerDropdownOptions.getAsOptions();
  const maybePhotographerOption = photographerOptions.find(
    (opt) => opt.label === searchParams.photographers,
  );
  const photographer =
    maybePhotographerOption?.value ?? (undefined as string | undefined);
  // maybe resolve date ...
  const { from, to } = mapDateRange(searchParams.date);

  const resetCountryFilterIfViewingGallery = !galleryEventUrlKey;
  // maybe resolve country ...
  const country =
    event_page || resetCountryFilterIfViewingGallery
      ? undefined
      : getPreferredCountryApiId();

  useEffect(
    function maybeTriggerFilterUpdates() {
      // include for album and gallery views only
      const hasGalleryEvent =
        !galleryEventUrlKey && !albumUrlKey ? true : undefined;

      const baseFilter: ListFilterArgs = {
        gallery_type: undefined,
        gallery_event: !!galleryEventUrlKey ? galleryEventUrlKey : undefined,
        gallery_event_name: !!galleryEventUrlKey
          ? galleryEventUrlKey
          : undefined,
        album: albumUrlKey,
        has_gallery_event: hasGalleryEvent,
        any_field: searchParams.any,
        activity_tags: searchParams.activity,
        shot_at_date_from: from,
        shot_at_date_to: to,
        photographers: photographer,
        country: country,
        private_event_token: '',
        public_or_direct_url: '',
        _callSource: `useEventFiltersBroker____${displayMode}`,
      };

      if (!isMapView && !isMobile) {
        // if moving away from mapview on desktop, reset the map filters
        // this means only mobile currently "carries" any set map filters across
        // to the gallery view
        baseFilter.north_east_lat = undefined;
        baseFilter.north_east_lng = undefined;
        baseFilter.south_west_lat = undefined;
        baseFilter.south_west_lng = undefined;
      }

      if (!isMapView && isMobile) {
        // if moving away from mapview on mobile, clear
        // country and apply coords IF coords have been set
        if (isMapFilterActive) {
          baseFilter.country = undefined;
          baseFilter.north_east_lat = currentFilter.north_east_lat;
          baseFilter.north_east_lng = currentFilter.north_east_lng;
          baseFilter.south_west_lat = currentFilter.south_west_lat;
          baseFilter.south_west_lng = currentFilter.south_west_lng;
        }
      }

      if (isMapView) {
        // if visiting the map view, clear country
        baseFilter.country = undefined;
      }

      logger.log("FX:", baseFilter);

      eventPageCtx.updateAlbumsFilter({
        ...baseFilter,
        exclude_galleries_with_an_album: true,
        _callSource: `useEventFilterDispatch____${displayMode}__album`,
      });
      const briefGalleryPayload = {
        ...baseFilter,
        exclude_galleries_with_an_album:
          displayMode === DisplayMode.GalleryEvent ||
          displayMode === DisplayMode.Gallery,
      };
      const galleryEventPayload = {
        ...baseFilter,
        exclude_galleries_with_an_album: true,
      };
      const galleryCallsIdentical =
        JSON.stringify(galleryEventPayload) ===
        JSON.stringify(briefGalleryPayload);
      eventPageCtx.updateBriefGalleryFilter({
        ...briefGalleryPayload,
        _callSource: `useEventFilterDispatch____${displayMode}__briefgallery`,
      });
      // Not sure about this, leaving off for the time being
      // if (!galleryCallsIdentical) {
      eventPageCtx.updateGalleryEventFilter({
        ...galleryEventPayload,
        _callSource: `useEventFilterDispatch____${displayMode}__galleryevent`,
      });
      // }
    },
    [
      galleryEventUrlKey,
      albumUrlKey,
      country,
      searchParams.any,
      searchParams.date,
      searchParams.photographers,
      searchParams.activity,
    ],
  );
};

export const EventFiltersBroker = ({
  galleryEventUrlKey,
  albumUrlKey,
  event_page,
}: useEventFilterDispatchProps) => {
  useEventFiltersBroker({
    galleryEventUrlKey,
    albumUrlKey,
    event_page,
  });

  return <></>;
};

import get from "lodash/get";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { CurrencyValue } from "../../v2_components/CurrencyValue";
import {
  Box,
  makeStyles,
  Typography,
  Button,
  Modal,
  Avatar,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import SideBar from "../../layout/sidebar";
import TopBar from "./topbar";
import ModalForm from "./modal";
import style from "./styles";
import { setMailStatus } from "../../actions/bookboss";
import { useIsMobile } from "../../actions/ui";
import { photographerList } from "../../v2_actions/photographer";
import { galleryList } from "../../v2_actions/gallery";
import GalleryPhotographs from "../../v2_components/GalleryPhotographs";

const useStyles = makeStyles(style);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${left}%, -${top}%)`,
  };
}

const BookBoss = ({ match }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const mailInfo = useSelector((state) => state.bookbossReducer.message);
  const [modalStyle] = useState(getModalStyle);
  const [invitModal, setInviteModal] = useState(false);
  const [showSideBar, setShowSideBar] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [bookInviteModal, setBookInviteModal] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  const url_filter = new window.URLSearchParams(window.location.search);
  const url_filter_photographer_id = url_filter.get("photographer") || null;
  const photographer = useSelector(() =>
    photographerList.getPhotographerForId(url_filter_photographer_id),
  );
  const showcase_gallery = useSelector(() =>
    galleryList.getObject(photographer.showcase_gallery),
  );

  useEffect(() => {
    if (mailInfo) {
      setOpenSnackBar(true);
      setInviteModal(false);
      setBookInviteModal(false);
    }
    // eslint-disable-next-line
  }, [mailInfo]);

  useEffect(() => {
    if (invitModal === true || bookInviteModal === true) {
      if (!photographer) {
        dispatch(setMailStatus("Please select a photographer!"));
        setOpenSnackBar(true);
        setInviteModal(false);
        setBookInviteModal(false);
      }
    }
    // eslint-disable-next-line
  }, [invitModal, bookInviteModal]);

  useEffect(() => {
    if (url_filter_photographer_id) {
      dispatch(photographerList.ensureObjectLoaded(url_filter_photographer_id));
    }
  }, [url_filter_photographer_id]);

  useEffect(() => {
    dispatch(galleryList.ensureObjectLoaded(photographer.showcase_gallery));
  }, [get(photographer.showcase_gallery)]);

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  });

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  const goMap = () => {
    navigate(`/customer/events?photographers=${url_filter_photographer_id}`);
  };

  return (
    <>
      <TopBar
        showSideBar={showSideBar}
        setShowSideBar={setShowSideBar}
        photoGrapher={photographer}
      />
      <SideBar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
      <div
        className={!showSideBar ? classes.nonMennuWrapper : classes.menuWrapper}
        style={{ paddingTop: !isMobile ? 65 : 0 }}
      >
        <Snackbar
          open={openSnackBar}
          anchorOrigin={{ vertical: "center", horizontal: "center" }}
          autoHideDuration={2000}
          onClose={() => {
            setOpenSnackBar(false);
            dispatch(setMailStatus(false));
          }}
          style={{ height: "100vh" }}
        >
          <Alert
            onClose={() => {
              setOpenSnackBar(false);
              dispatch(setMailStatus(false));
            }}
            severity="success"
          >
            {mailInfo}
          </Alert>
        </Snackbar>
        <div className={classes.contentContainer}>
          <div className={!isMobile ? classes.content : classes.mobileContent}>
            <Box
              width={
                width > 1600
                  ? "80%"
                  : width > 1440
                    ? "75%"
                    : width > 1024
                      ? "70%"
                      : !isMobile
                        ? "65%"
                        : width > 500
                          ? "60%"
                          : "100%"
              }
              height={!isMobile ? "calc(100vh - 100px)" : null}
              style={{ overflowY: isMobile ? "scroll" : null }}
              m={!isMobile ? 1 : 0}
            >
              <Typography
                className={
                  !isMobile ? classes.typography : classes.mobileTypography
                }
                variant="h6"
                style={{ paddingLeft: !isMobile ? 10 : 20 }}
              >
                {photographer.last_name}'s Showcase Gallery
              </Typography>
              {showcase_gallery && (
                <div>
                  <GalleryPhotographs
                    gallery_id={showcase_gallery.id}
                    heroPhotoEnabled={true}
                  />
                </div>
              )}
            </Box>
            <Box
              width={
                width > 1600
                  ? "20%"
                  : width > 1440
                    ? "25%"
                    : width > 1024
                      ? "30%"
                      : !isMobile
                        ? "35%"
                        : width > 500
                          ? "40%"
                          : "100%"
              }
              display="flex"
              flexDirection="column"
              alignItems="center"
              m={!isMobile ? 1 : 0}
              p={!isMobile ? 2 : 0}
            >
              <Box m={1}>
                <Typography className={classes.typography} variant="p">
                  You can invite photographers to attend your activities
                </Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                p={1}
              >
                <Button
                  className={classes.button}
                  onClick={() => setInviteModal(true)}
                >
                  Invite Photographers
                </Button>
              </Box>
              <Box m={1}>
                <Typography className={classes.typography} variant="p">
                  OR you can book a private session directly with a specific
                  photographer.
                </Typography>
              </Box>
              {photographer && (
                <Box
                  display="flex"
                  justifyContent="center"
                  p={2}
                  flexDirection="column"
                  alignItems="center"
                >
                  <Box>
                    <Avatar
                      src={photographer.profile_photo}
                      alt=""
                      style={{ width: 150, height: 150 }}
                    />
                  </Box>
                  <Box width="100%" m={1}>
                    <Typography className={classes.typographyprofile}>
                      {photographer.first_name} {photographer.last_name}
                    </Typography>
                    <Typography className={classes.typographyprice}>
                      {photographer && photographer.price_info && (
                        <>
                          {photographer.last_name}'s Rate is &nbsp
                          <CurrencyValue
                            value={photographer.price_info.hourly_rate}
                            country={photographer.price_info.country}
                          />{" "}
                          per hour
                        </>
                      )}
                    </Typography>
                  </Box>
                  <Box p={1} width="100%">
                    <Button
                      className={classes.button}
                      onClick={() => setBookInviteModal(true)}
                    >
                      Book a Private Session
                    </Button>
                  </Box>
                  <Box p={1} width="100%">
                    <Button
                      className={classes.viewbutton}
                      onClick={() => goMap(photographer.id)}
                    >
                      View {photographer.last_name}'s Galleries
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          </div>
        </div>
      </div>
      {photographer && (
        <>
          <Modal open={invitModal} onClose={(e) => setInviteModal(false)}>
            <div
              style={modalStyle}
              className={!isMobile ? classes.paper : classes.mobilePaper}
            >
              <ModalForm
                title="Invite photographers for a session"
                filterInfo={photographer}
                classes={classes}
                setAnyModal={setInviteModal}
                flag={0}
              />
            </div>
          </Modal>
          <Modal
            open={bookInviteModal}
            onClose={(e) => setBookInviteModal(false)}
          >
            <div
              style={modalStyle}
              className={!isMobile ? classes.paper : classes.mobilePaper}
            >
              <ModalForm
                title="Book a private session with"
                filterInfo={photographer}
                classes={classes}
                setAnyModal={setBookInviteModal}
                flag={1}
              />
            </div>
          </Modal>
        </>
      )}
    </>
  );
};

export default BookBoss;

import get from "lodash/get";
import map from "lodash/map";
import size from "lodash/size";
import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Snackbar } from "@material-ui/core";
import { GalleryPasswordModal } from "./GalleryPasswordModal";
import { briefGalleryList } from "../../../v2_actions/brief_gallery";
import { constructGalleryFilter } from "../../../v2_actions/gallery";
import { galleryEventList } from "../../../v2_actions/gallery_event";
import {
  setMapState,
  getMapState,
  getGoogleInitArgs,
  getForcedNewMapState,
  clearForcedNewMapState,
  flyToMyLocation,
} from "../../../v2_actions/map";
import {
  GoogleMap,
  MarkerClusterer,
  Marker,
  InfoWindow,
  useJsApiLoader,
} from "@react-google-maps/api";
import MuiAlert from "@material-ui/lab/Alert";

import PlaceSearch from "./placesearch";
import { getMobMaxWidth } from "../../layout/mobile";
import { formatSessionNameForURL } from "../../../v2_actions/nav";
import { useReduxStateReconcilation } from "../../hooks/useReduxStateReconcilation";
import { makeLogger } from "../../../utils/o11y/logger";

const logger = makeLogger({
  enabled: true,
  label: "PhotoSearchMap",
  color: "green",
});

const MAP_SAVE_TIMEOUT_MILLISECONDS = 1000;

const mapContainerStyle = {
  width: "100%",
  zIndex: 9999,
  height: "calc(98vh - 120px)",
};

const mobiMapContainerStyle = {
  width: "100%",
  height: "70vh",
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const markerClustererCalculator = (markers) => {
  return {
    index: 1,
    text: markers.length,
  };
};

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    background: "white",
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const mapType = ["hybrid", "roadmap", "satellite", "terrain", "styled_map"];

const PhotoSearchMap = ({ key }) => {
  useReduxStateReconcilation();
  const [modalStyle] = useState(getModalStyle);
  const refMap = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [localMapState, setLocalMapState] = useState(getMapState());
  const mapStateSaveTimeout = useRef(null);
  const [mapInstance, setMapInstance] = useState(null);
  const [showGalleryPasswordModal, setShowGalleryPasswordModal] =
    useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [openPermissionSnackBar, setOpenPermissionSnackBar] = useState(false);
  const [selectedGallery, setSelectedGallery] = useState(null);
  const [hoveredGallery, setHoveredGallery] = useState(null);
  const galleries = useSelector(() => briefGalleryList.getFilteredGalleries());
  const galleryEvents = useSelector(() => galleryEventList.getVisibleObjects());
  const [mapTypeInd, setMapTypeInd] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const [visibleGalleries, setVisibleGalleries] = useState([]);
  const isLoaded = get(useJsApiLoader(getGoogleInitArgs()), "isLoaded");
  const forcedNewMapState = useSelector((state) => getForcedNewMapState(state));
  const isDragging = useRef(false);
  const isMobileWidth = window.innerWidth <= getMobMaxWidth();

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  });

  useEffect(() => {
    if (localMapState.is_default) {
      dispatch(flyToMyLocation({}));
    }
  }, [get(localMapState, "is_default")]);

  useEffect(() => {
    logger.log("forcedNewMapState", forcedNewMapState);
    if (!forcedNewMapState.lat) {
      return;
    }
    setLocalMapState(forcedNewMapState);

    if (forcedNewMapState.bounds && mapInstance) {
      mapInstance.fitBounds(forcedNewMapState.bounds);
      setLocalMapState(
        Object.assign({}, localMapState, {
          lat: forcedNewMapState.bounds.getCenter().lat(),
          lng: forcedNewMapState.bounds.getCenter().lng(),
        }),
      );
    }

    dispatch(clearForcedNewMapState());
  }, [JSON.stringify(forcedNewMapState)]);

  useEffect(() => {
    const checkGeolocationPermission = async () => {
      if (!get(navigator, ["permissions", "query"])) {
        setOpenPermissionSnackBar(true);
      } else {
        const permissionStatus = await navigator.permissions.query({
          name: "geolocation",
        });
        const permissionRes = get(permissionStatus, "state", "denied");

        if (permissionRes === "denied") {
          setOpenPermissionSnackBar(true);
        }
      }
    };
    checkGeolocationPermission();
  }, []);

  useEffect(() => {
    processMapState();
  }, [mapInstance?.getBounds()]);

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    if (refMap) {
      handleBoundsChanged();
    }
  }, [refMap]);

  const handleBoundsChanged = () => {
    if (!mapInstance) {
      return;
    }
    if (isDragging.current) {
      return;
    }
  };

  const handleDragStart = () => {
    isDragging.current = true;
  };

  const handleDragEnd = () => {
    isDragging.current = false;
    processMapState();
  };

  const handleZoomChanged = () => {
    if (!mapInstance) {
      return;
    }
    // if (zoom <= 4) {
    //     setErrorMessage("You can't zoom out any more!")
    //     setOpenSnackBar(true)
    // }
    processMapState();
  };

  const processMapState = () => {
    if (!mapInstance) {
      logger.log("processMapState (!)");
      return;
    }
    const center = mapInstance.getCenter();
    const bounds = mapInstance.getBounds();
    const zoom = mapInstance.zoom;

    logger.log("processMapState", { center, bounds, zoom });

    const map_state = {
      lat: center && center.lat(),
      lng: center && center.lng(),
      zoom,
      bounds,
    };

    if (JSON.stringify(map_state) === JSON.stringify(localMapState)) {
      return;
    }

    setLocalMapState(map_state);

    if (mapStateSaveTimeout.current) {
      window.clearTimeout(mapStateSaveTimeout.current);
      mapStateSaveTimeout.current = null;
    }
    mapStateSaveTimeout.current = window.setTimeout(() => {
      setMapState(map_state);
      const constructedFilter = constructGalleryFilter({ map_bounds: bounds });

      logger.log("processMapState -> dispatch filter:", constructedFilter);

      dispatch(briefGalleryList.trySetFilter(constructedFilter));
      dispatch(galleryEventList.trySetFilter(constructedFilter));
    }, MAP_SAVE_TIMEOUT_MILLISECONDS);
  };

  const onMapLoaded = React.useCallback((mapInstance) => {
    setMapInstance(mapInstance);
  });

  const onSelectGallery = (gallery) => {
    setSelectedGallery(gallery);

    if (gallery.has_gallery_password) {
      setShowGalleryPasswordModal(true);
    } else {
      navigate(
        `/customer/gallery/${formatSessionNameForURL(gallery.session_name)}/${
          gallery.db_id
        }`,
      );
    }
  };

  const onSelectGalleryEvent = (galleryEvent) => {
    navigate(`/customer/events/${formatSessionNameForURL(galleryEvent.name)}`);
  };

  const renderGalleryEventMarkers = () => {
    return (
      <>
        {map(galleryEvents, (galleryEvent) => {
          const related_gallery_to_gallery_event = get(
            galleryEvent,
            "best_gallery",
          );
          const position = {
            lat: related_gallery_to_gallery_event.lat,
            lng: related_gallery_to_gallery_event.lng,
          };
          const isHovered =
            get(hoveredGallery, "id") === related_gallery_to_gallery_event.id;
          return (
            <div key={`${related_gallery_to_gallery_event.id}-keymarker`}>
              <Marker
                key={`${related_gallery_to_gallery_event.id}-keymarker`}
                position={position}
                zIndex={isHovered ? 9999 : 0}
                icon={{
                  url: get(galleryEvent, [
                    "event_photograph",
                    "avatar_size_file_info",
                    "download_url",
                  ]),
                  labelOrigin: new window.google.maps.Point(30, -50),
                }}
                labelAnchor={isHovered ? { x: 55, y: 75 } : { x: 50, y: 50 }}
                labelStyle={{ borderRadius: "50%", border: "3px solid white" }}
                onClick={() => onSelectGalleryEvent(galleryEvent)}
                onMouseOver={() =>
                  setHoveredGallery(related_gallery_to_gallery_event)
                }
                onMouseOut={() => setHoveredGallery(null)}
              />

              {isHovered && (
                <InfoWindow
                  position={position}
                  onCloseClick={() => setHoveredGallery(null)}
                >
                  <div>
                    <p>{galleryEvent.name}</p>
                  </div>
                </InfoWindow>
              )}
            </div>
          );
        })}
      </>
    );
  };

  const renderGalleryMarkers = () => {
    return (
      <>
        {map(galleries, function (gallery) {
          const position = { lat: gallery.lat, lng: gallery.lng };
          const isHovered = get(hoveredGallery, "id") === gallery.id;
          return (
            <div key={gallery.id}>
              <Marker
                key={`${gallery.id}-keymarker`}
                position={position}
                zIndex={isHovered ? 9999 : 0}
                icon={{
                  url: get(gallery, "avatar_photo_url", " "),
                  labelOrigin: new window.google.maps.Point(30, -50),
                }}
                labelAnchor={isHovered ? { x: 55, y: 75 } : { x: 50, y: 50 }}
                labelStyle={{ borderRadius: "50%", border: "3px solid white" }}
                onClick={() => onSelectGallery(gallery)}
                onMouseOver={() => setHoveredGallery(gallery)}
                onMouseOut={() => setHoveredGallery(null)}
              />

              {isHovered && (
                <InfoWindow
                  position={position}
                  onCloseClick={() => setHoveredGallery(null)}
                >
                  <div>
                    <p>{gallery.gallery_name}</p>
                  </div>
                </InfoWindow>
              )}
            </div>
          );
        })}
      </>
    );
  };

  const showPasswordModal = () => {
    return (
      <GalleryPasswordModal
        gallery={selectedGallery}
        onClose={() => setShowGalleryPasswordModal(false)}
        onCorrect={navigate(
          `/customer/gallery/${formatSessionNameForURL(
            selectedGallery.session_name,
          )}/${selectedGallery.db_id}`,
        )}
        onWrong={function () {
          setOpenSnackBar(true);
          setErrorMessage("Wrong Gallery Password!");
        }}
      />
    );
  };

  return (
    <div key={key}>
      <Snackbar
        open={openSnackBar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={2000}
        onClose={() => {
          setOpenSnackBar(false);
        }}
      >
        <Alert
          onClose={() => {
            setOpenSnackBar(false);
          }}
          severity="error"
        >
          {errorMessage}
        </Alert>
      </Snackbar>

      {/*     <Snackbar */}
      {/*       anchorOrigin={{ */}
      {/*         vertical: 'bottom', */}
      {/*         horizontal: 'center', */}
      {/*       }} */}
      {/*       open={openPermissionSnackBar} */}
      {/*       autoHideDuration={6000} */}
      {/*       onClose={() => setOpenPermissionSnackBar(false)} */}
      {/*       message="We do not have permission to use your location." */}
      {/* //support.google.com/maps/?hl=en&ampauthuser=0&ampp=browser_lp */}
      {/*       action={ */}
      {/*         <React.Fragment> */}
      {/*           <Button color="primary" size="small" onClick={() => window.open('https://support.google.com/maps/answer/2839911')}> */}
      {/*             LEARN MORE */}
      {/*           </Button> */}
      {/*           <IconButton size="small" aria-label="close" color="inherit" onClick={() => setOpenPermissionSnackBar(false)}> */}
      {/*             <CloseIcon fontSize="small" /> */}
      {/*           </IconButton> */}
      {/*         </React.Fragment> */}
      {/*       } */}
      {/*     /> */}
      <PlaceSearch
        key="googleMapPlace"
        mapTypeInd={mapTypeInd}
        curCenter={{ lat: localMapState.lat, lng: localMapState.lng }}
        setMapTypeInd={setMapTypeInd}
        galleryCount={size(visibleGalleries)}
      />

      {!isLoaded && <div>Loading...</div>}

      {isLoaded && localMapState.lat && (
        <GoogleMap
          mapContainerStyle={
            isMobileWidth ? mobiMapContainerStyle : mapContainerStyle
          }
          onLoad={onMapLoaded}
          key="googleMap"
          ref={refMap}
          zoom={localMapState.zoom}
          center={{ lat: localMapState.lat, lng: localMapState.lng }}
          onZoomChanged={handleZoomChanged}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          onBoundsChanged={handleBoundsChanged}
          options={{
            streetViewControl: false,
            mapTypeId: mapType[mapTypeInd],
            labels: true,
            mapTypeControl: false,
            fullscreenControl: false,
            disableDefaultUI: true,
          }}
        >
          {size(galleries) > 0 && (
            <MarkerClusterer
              averageCenter
              calculator={markerClustererCalculator}
              enableRetinaIcons
              gridSize={30}
            >
              {renderGalleryMarkers}
            </MarkerClusterer>
          )}

          {size(galleryEvents) > 0 && (
            <MarkerClusterer
              averageCenter
              calculator={markerClustererCalculator}
              enableRetinaIcons
              gridSize={30}
            >
              {renderGalleryEventMarkers}
            </MarkerClusterer>
          )}
        </GoogleMap>
      )}

      {showGalleryPasswordModal && showPasswordModal()}
    </div>
  );
};

export default PhotoSearchMap;

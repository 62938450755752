import React from "react";
import styles from "./NavInline.module.css";

/**
 * NavInline
 * @returns
 */
const NavInline = ({ children }: React.PropsWithChildren<{}>) => {
  return <nav className={styles.navinline}>{children}</nav>;
};

export default NavInline;

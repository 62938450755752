// eslint-disable-next-line
export default (theme) => ({
  root: {
    display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%",
  },
  menuWrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    paddingTop: 65,
    [theme.breakpoints.up("lg")]: {
      paddingTop: 65,
      paddingLeft: 300,
    },
  },
  nonMennuWrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  content: {
    flex: "1 1 auto",
    height: "calc(100vh - 65px)",
    overflowY: "scroll",
    padding: 20,
  },
  pharagraph: {
    padding: 35,
    paddingTop: 0,
    color: "#0c59cf",
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 1.3,
    textAlign: "justify",
  },
});

import React from 'react'
import Grid from "@mui/material/Grid"
import { BaseLayout } from './BaseLayout'
import TopBar from './ShoppingTrolleyNavBar'

export const MainTrolleyLayout = ({ children, authenticated_trolley, active_trolley_photograph }) => {
    return (
      <div style={{ overflowY: "scroll", height: "100vh" }}>
        <BaseLayout>
          <TopBar authenticated_trolley={authenticated_trolley} active_trolley_photograph={active_trolley_photograph}/>
          <Grid container component="main" sx={{ height: '100vh' }}>
            {children}
          </Grid>
        </BaseLayout>
      </div>
    )
}

import {
  BossFotosLogo,
  Inline,
  NavInline,
  NavItem,
  Navigation,
} from "bossfotos-design-system";

import { Strong } from "bossfotos-design-system";
import MenuButton from "../../v3_components/navigation/MenuButton";
import HelpButton from "../../v3_components/navigation/HelpButton";

export const NavBar = ({ onBackClicked }: { onBackClicked?: () => void }) => {
  // const navigate = useNavigate();

  const handleBackClick = () => {
    onBackClicked && onBackClicked();
  };

  return (
    <>
      <Navigation>
        <Inline style={{ flexBasis: "50%" }}>
          <MenuButton />
          <NavItem hiddensm={true}>
            <div title="BossFotos" style={{ height: "28px" }}>
              <BossFotosLogo height={24} variant="navbar" />
            </div>
          </NavItem>
        </Inline>
        <NavInline>
          <Strong>My Purchases</Strong>
        </NavInline>
        <Inline style={{ flexBasis: "50%" }} justifyContent="right">
          <HelpButton />
        </Inline>
      </Navigation>
    </>
  );
};

import React from "react"

const MAX_NUM_CHARACTERS_TO_FIT_IN_FILTER = 14

export const DropFilterText = ({ text, default_text, icon }) => {
    const truncateText = (text) => {
      if (text.length > MAX_NUM_CHARACTERS_TO_FIT_IN_FILTER) {
        return text.slice(0, 10).concat('...')
      }
      return text
    }

    return (
      <>
        <i className={icon}></i>
        <p>
          {text && truncateText(text) || default_text }
        </p>
      </>
    )
}
/* eslint-disable @typescript-eslint/no-unused-expressions */

import { BreakPoints } from "./primitives/useResponsiveBreakpoints";
import { CSSProperties } from "react";

export type ResponsiveVariant<T> = {
  variant?: T;
  variantsm?: T;
  variantmd?: T;
  variantlg?: T;
  variantxl?: T;
  variant2xl?: T;
} 

export type PropMixins = {
  /** forces element to occupy container width */
  fill?: boolean;
  /** determines whether to strip elements of padding/margin to allow layout control by flex box */
  flexContain?: boolean;
  /** vertically aligns content in center */
  alignItems?: CSSProperties["alignItems"];
  /** horizontally aligns content in center */
  justifyContent?: CSSProperties["justifyContent"];
  flexGrow?: React.CSSProperties["flexGrow"];

  collapsePadding?: true;
  
  width?: React.CSSProperties["width"];
  widthmd?: React.CSSProperties["width"];
  widthlg?: React.CSSProperties["width"];
  widthxl?: React.CSSProperties["width"];
  width2xl?: React.CSSProperties["width"];

  style?: React.CSSProperties;
  stylesm?: React.CSSProperties;
  stylemd?: React.CSSProperties;
  stylelg?: React.CSSProperties;
  stylexl?: React.CSSProperties;
  style2xl?: React.CSSProperties;
  
  visiblesm?: boolean;
  visibleMd?: boolean;
  visiblelg?: boolean;
  visiblexl?: boolean;
  visible2xl?: boolean;
  
  hiddensm?: boolean;
  hiddenmd?: boolean;
  hiddenlg?: boolean;
  hiddenxl?: boolean;
  hidden2xl?: boolean;
};

export const resolveVariant = <T>(props: ResponsiveVariant<T>, activeBreakpoint?: string) => {
  let variant = props.variant;
  
  if (activeBreakpoint === BreakPoints.sm && props.variantsm) {
    variant = props.variantsm;
  }
  
  if (activeBreakpoint === BreakPoints.md && props.variantmd) {
    variant = props.variantmd;
  }
  
  if (activeBreakpoint === BreakPoints.lg && props.variantlg) {
    variant = props.variantlg;
  }
  
  if (activeBreakpoint === BreakPoints.xl && props.variantxl) {
    variant = props.variantxl;
  }
  
  if (activeBreakpoint === BreakPoints["2xl"] && props.variant2xl) {
    variant = props.variant2xl;
  }

  return variant;
}

export const cssStyles = (
  { flexContain, fill, collapsePadding, style, ...rest }: PropMixins,
  activeBreakpoint?: string
) => {
  let styles: CSSProperties = { ...style, ...rest  };


  if (fill) {
    styles.width = "100%";
  }

  if (flexContain) {
    styles.margin = "0px";
    styles.padding = "0px";
    styles.minWidth = "auto";
  }

  if (collapsePadding) {
    styles.paddingLeft = "0px";
    styles.paddingRight = "0px";
  }
  
  if (activeBreakpoint === BreakPoints.sm) {
    rest.visiblesm && (styles.display = "block");
    rest.hiddensm && (styles.display = "none");

    if (rest.stylesm) {
      styles = {
        ...styles,
        ...rest.stylesm,
      };
    }
  }
  if (activeBreakpoint === BreakPoints.md) {
    if (rest.widthmd) {
      styles.width = rest.widthmd;
    }
    rest.visibleMd && (styles.display = "block");
    rest.hiddenmd && (styles.display = "none");
  
    if (rest.stylemd) {
      styles = {
        ...styles,
        ...rest.stylemd,
      };
    }
  }
  if (activeBreakpoint === BreakPoints.lg) {
    if (rest.widthlg) {
      styles.width = rest.widthlg;
    }
    rest.visiblelg && (styles.display = "block");
    rest.hiddenlg && (styles.display = "none");
  
    if (rest.stylelg) {
      styles = {
        ...styles,
        ...rest.stylelg,
      };
    }
  }
  if (activeBreakpoint === BreakPoints.xl) {
    if (rest.widthxl) {
      styles.width = rest.widthxl;
    }
    rest.visiblexl && (styles.display = "block");
    rest.hiddenxl && (styles.display = "none");
  
    if (rest.stylexl) {
      styles = {
        ...styles,
        ...rest.stylexl,
      };
    }
  }
  if (activeBreakpoint === BreakPoints["2xl"]) {
    if (rest.width2xl) {
      styles.width = rest.width2xl;
    }
    rest.visible2xl && (styles.display = "block");
    rest.hidden2xl && (styles.display = "none");
  
    if (rest.style2xl) {
      styles = {
        ...styles,
        ...rest.style2xl,
      };
    }
  }
  return styles;
};

import rightImg from "../../assets/images/background/login.png";
// eslint-disable-next-line
export default (theme) => ({
  root: {
    paddingTop: 24,
    paddingBottom: 24,
  },
  ipadroot: {
    width: "90%",
    paddingTop: 24,
    paddingBottom: 24,
  },
  mobileroot: {
    minHeight: "100%",
    width: "98%",
    margin: "auto",
  },
  scrollContainer: {
    height:"100vh",
    overflowY:"scroll",
  },
  selectLabel: {
    paddingLeft: "18.5px",
  },
  logo: {
    fontSize: 60,
    fontWeight: "400",
  },
  logo_img: {
    right: 5,
    width: 301,
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "auto",
      right: 0
    }
  },
  bigtext: {
    fontSize: 26,
    color: "#35424a",
    marginBottom: "10px"
  },
  smalltext: {
    fontSize: 16,
    fontWeight: "400",
    color: "#989eb1",
  },
  label: {
    fontSize: 16,
    color: "#0c59cf",
    fontWeight: 600,
  },
  signInButtonWrapper: {
    textAlign: "center",
  },
  button: {
    background: "#0c59cf !important",
    color: "white",
    textTransform: "capitalize",
    width: 280,
    height: 40,
  },
  submitBtnText: {
    color: "#000",
    textTransform: "capitalize",
  },
  forgotPasswordbutton: {
    background: "#0c59cf !important",
    color: "white",
    margin: "20px 0px",
    textAlign: "center",
    textTransform: "capitalize",
    width: 280,
    height: 40,
  },
  loginBtnText: {
    fontSize: 17,
    fontWeight: 600
  },
  subtitle: {
    color: "#989eb1",
    fontSize: 16,
    textTransform: "capitalize",
    marginTop: 20,
    cursor: "pointer",
  },
  createAccountWrapper: {
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      display: "inline-grid",
      textAlign: "center",
      width: "100%",
      marginTop: "50px"
    },
  },
  createAccountLink: {
    color: "#0c59cf",
    cursor: "pointer",
    fontSize: 18,
    fontWeight: 600
  },
  createAccountText: {
    fontSize: 18,
    color: "#35424a",
    paddingRight: 10,
  },
  rightside: {
    backgroundImage: `url(${rightImg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  imgtitle: {
    fontSize: 25,
    color: "white",
    padding: 25,
  },
  blacktitle: {
    fontSize: 16,
    color: "black",
    margin: "15px 0px 0px"
  },
  gridContainer: {
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      marginTop: "20px"
    }
  },
  button: {
    background: "#0c59cf !important",
    color: "white",
    textTransform: "capitalize",
    width: 280,
    height: 40,
  },  
  disabledButton: {
    background: "#bfbfbf",
    color: "white",
    textTransform: "capitalize",
    width: 280,
    height: 40,
  },
  loginBtnText: {
    fontSize: 17,
    color: "#fff"
  },
  disabledLoginBtnText: {
    fontSize: 17,
    color: "#000"
  },
  spacer: {
    height: 60
  }
})

import { useLocation } from "react-router-dom";

/**
 * Basic abstraction of route patterns to determine app behaviour
 * @returns
 */
export const useRouteInfo = () => {
  const { pathname } = useLocation();

  const eventsUrlPath = pathname.endsWith("/")
    ? pathname.substring(0, pathname.length - 1)
    : pathname;

  const isMapView = eventsUrlPath.startsWith("/customer/map");

  return {
    isMapView,
    eventsUrlPath,
  };
};

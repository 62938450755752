import { SET_CUSTOM_VALUE } from "../v2_actions/custom_value";

const initialState = {};

export default function customValueReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CUSTOM_VALUE:
      return Object.assign({}, state, { [action.name]: action.value });
    default:
      return state;
  }
}

import { useSelector } from "react-redux";

/**
 * Empty useSelector call to force redux state updates
 * to propogate to lists. This wouldn't be necessary but
 * the ORM's mechanism of "attaching" to redux means state
 * updates are not automatically propogated.
 *
 * Ideally the ORM is remediated then this can be removed.
 */
export const useReduxStateReconcilation = () => {
  useSelector(() => ({})); // force redux state reconcile
};

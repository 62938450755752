export const selectPostion = (mapInfo) => ({
  type: "MAPINFO/SELECTMARKER",
  mapInfo,
});

export const saveZoom = (savedZoom) => ({
  type: "MAPINFO/SAVEZOOM",
  savedZoom,
});

export const setShowModal = (showModal) => ({
  type: "MAPINFO/SHOWMODAL",
  showModal,
})

export const setShowSearchBox = (flag) => ({
  type: "MAPINFO/SHOWSEARCHBOX",
  flag,
})

export const setClearTime = (clearTime) => ({
  type: "MAPINFO/SETMAPCLEARTIME",
  clearTime,
});
import React from 'react'
import { MainLayout } from './MainLayout'

export const PhotoArtLayout = ({children, activeTab}) => {
    return (
        <MainLayout activeTab={activeTab}>
          {children}
        </MainLayout>
    )
}

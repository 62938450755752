import filter from "lodash/filter";
import head from "lodash/head";
import get from "lodash/get";
import { ItemList } from "../orm";
import {
  NUM_PHOTOS_PER_GALLERY_PAGE_WHEN_SHOWING_SHOW_MORE_BUTTON,
  NUM_PHOTOS_PER_GALLERY_PAGE_AFTER_SEARCHING,
} from "./gallery";

class GalleryEventList extends ItemList {
  getEntityKey() {
    return "customer/gallery_event";
  }

  trySetFilter(newFilter) {
    return async (dispatch, getState) => {
      const existingFilter = this.getFilter();
      if (JSON.stringify(existingFilter) === JSON.stringify(newFilter)) {
        return;
      }
      dispatch(this.updateListFilter(newFilter));
      if (get(newFilter, "shot_at_date_from")) {
        dispatch(
          this.updatePaginationNumItemsPerPage(
            NUM_PHOTOS_PER_GALLERY_PAGE_AFTER_SEARCHING,
          ),
        );
      } else {
        dispatch(
          this.updatePaginationNumItemsPerPage(
            NUM_PHOTOS_PER_GALLERY_PAGE_WHEN_SHOWING_SHOW_MORE_BUTTON,
          ),
        );
      }
      dispatch(
        this.fetchListIfNeeded({
          callSource: `tsf_GalleryEventList__${this.listKey}`,
        }),
      );
    };
  }

  async getGalleryEventLogoForGalleryName(gallery_name) {
    const galleryEvents = this.getVisibleObjects();
    const galleryEvent = head(
      filter(galleryEvents, (x) => x.name === gallery_name),
    );
    return await get(galleryEvent, [
      "event_photograph",
      "gallery_size_file_info",
      "download_url",
    ]);
  }

  setPasswordBypassForGalleryEvent(gallery_event) {
    sessionStorage.setItem(`gallery_event_id_${gallery_event}`, true);
  }

  getPasswordBypassStatusForGalleryEventGallery(gallery_event) {
    return sessionStorage.getItem(`gallery_event_id_${gallery_event}`);
  }
}

export const galleryEventList = new GalleryEventList("gallery_event_default");
export const galleryEventListForEventUrl = new GalleryEventList(
  "gallery_event_for_event_url_default",
);
export const galleryEventListForPhotoArtTab = new GalleryEventList(
  "gallery_event_for_photo_art_tab_default",
);

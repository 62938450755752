import React from "react";
import styles from "./Flyout.module.css";

const defaultPosition = { transform: "translateY(100%)" };
const openPosition = { transform: "translateY(0)" };

/**
 * Flyout
 * @returns
 */
const Flyout = ({
  open,
  children,
}: React.PropsWithChildren<{ open: boolean }>) => {
  return (
    <div
      className={styles.flyout}
      style={open ? openPosition : defaultPosition}
    >
      {children}
    </div>
  );
};

export default Flyout;

import { ItemList } from '../orm'


class PhotographList extends ItemList {

    getEntityKey() {
        return 'customer/photograph'
    }

}

export const basePhotographList = new PhotographList('base_photograph_match_default')
export const photographList = new PhotographList('probable_photograph_match_default')
export const possiblePhotographMatchList = new PhotographList('possible_photograph_match_default')

import { ChangeEventHandler, useRef, useState } from "react";
import {
  Header,
  InputTextContainer,
  InputTextInner,
  NavButton,
} from "bossfotos-design-system";
import { useJsApiLoader, StandaloneSearchBox } from "@react-google-maps/api";
import { forceNewMapState, getGoogleInitArgs } from "../../v2_actions/map";

import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { makeLogger } from "../../utils/o11y/logger";
import { useDispatch } from "react-redux";

const logger = makeLogger({
  enabled: true,
  label: "SearchBarLocation",
  color: "blue",
});

const DEFAULT_TIMEOUT_TO_CHANGE_LOCATION_FILTER_CHANGE = 700;

/**
 * Searches via address to trigger lat/long based search
 * @returns
 */
export const SearchBarLocation = () => {
  const [inputVal, setInputVal] = useState("");
  const dispatch = useDispatch();
  const inputUncontrolled = useRef<HTMLInputElement>(null);
  const [googleSearchBox, setGoogleSearchBox] =
    useState<google.maps.places.SearchBox>();
  // @ts-ignore
  const { isLoaded } = useJsApiLoader(getGoogleInitArgs());

  // TODO: may need to react to other state
  const isSearchActive = inputVal !== "";

  const handleSearchClick = () => {
    // TODO: trigger search lookup
  };

  const handleClearSearch = () => {
    logger.log("handleClearSearch", inputUncontrolled);
    if (inputUncontrolled.current) {
      inputUncontrolled.current.value = "";
    }
  };

  const handleAnyFieldInputTextChanged: ChangeEventHandler<HTMLInputElement> = (
    e,
  ) => {
    logger.log(e.target.value);
    setInputVal(e.target.value);
  };

  const onPlacesChanged = () => {
    const places = googleSearchBox?.getPlaces();
    logger.log("onPlacesChanged", places);

    if (!places) return;

    const place = places[0];
    const loc = place?.geometry?.location;
    const lat = loc?.lat();
    const lng = loc?.lng();
    const bounds = place?.geometry?.viewport;
    dispatch(forceNewMapState({ lat, lng, bounds, zoom: undefined }));
  };

  return (
    <>
      {isLoaded ? (
        <StandaloneSearchBox
          onLoad={(s) => setGoogleSearchBox(s)}
          onPlacesChanged={onPlacesChanged}
        >
          <Header>
            <InputTextContainer>
              <InputTextInner
                ref={inputUncontrolled}
                onChange={handleAnyFieldInputTextChanged}
                hasIconRight={true}
                placeholder="Search by location"
              />
              {isSearchActive ? (
                <NavButton onClick={handleClearSearch} variant="input">
                  <ClearIcon />
                </NavButton>
              ) : (
                <NavButton onClick={handleSearchClick} variant="input">
                  <SearchIcon />
                </NavButton>
              )}
            </InputTextContainer>
          </Header>
        </StandaloneSearchBox>
      ) : (
        <Header>
          <InputTextContainer>
            <InputTextInner
              disabled={true}
              onChange={() => {}}
              hasIconRight={true}
              placeholder="Loading ..."
            />
          </InputTextContainer>
        </Header>
      )}
    </>
  );
};
// <AddressSearch onChanged={ onLocationFilterChanged }/>

import { GalleryList } from "./gallery";

class BriefGalleryList extends GalleryList {
  getEntityKey() {
    return "customer/brief_gallery";
  }

  isMapFilterActive() {
    const { north_east_lat, north_east_lng, south_west_lat, south_west_lng } =
      this.getFilter();

    return (
      !!north_east_lat &&
      !!north_east_lng &&
      !!south_west_lat &&
      !!south_west_lng
    );
  }
}

export const briefGalleryList = new BriefGalleryList("brief_gallery_default");
export const briefGalleryListForPhotoArtTab = new BriefGalleryList(
  "brief_gallery_list_for_photo_art_tab_default",
);

import { ta } from "date-fns/locale"
import moment from "moment"
import {
    getPriceByPid,
    getCountryDefaultValue,
    getAllPhotoGrapher
} from "../apis/galleryapi"

export const setAllGalleryAllList = (galleryAllList) => ({
    type: "GALLERY/SETGALLERYLIST",
    galleryAllList,
})

export const setGalleryInfoList = (galleryInfoList) => ({
    type: "GALLERY/SETGALLERYINFOLIST",
    galleryInfoList,
})

export const setFilterGalleryAllList = (galleryFilterList) => ({
    type: "GALLERY/SETGALLERYFILTERLIST",
    galleryFilterList,
})

export const setBoundsAction = (latlngList) => ({
    type: "GALLERY/SETFILTERLATLNG",
    latlngList,
})

export const setAllPhotoGrapher = (photoGrapherAllList) => ({
    type: "GALLERY/SETPHOTOGRAPHERLIST",
    photoGrapherAllList,
})

export const setAllPhotosByGid = (allPhotosByGid) => ({
    type: "GALLERY/SETALLPHOTOSBYGID",
    allPhotosByGid,
})

export const setZoom = (zoom) => ({
    type: "GALLERY/SETZOOM",
    zoom,
})

export const setFilterSaveList = (filterSaveList) => ({
    type: "GALLERY/SETFILTERSAVELIST",
    filterSaveList,
})

export const setGeneralInfo = (generalInfo) => ({
    type: "GALLERY/SETGENERALINFO",
    generalInfo,
})

export const saveFilterList = (saveFilterList) => ({
    type: "GALLERY/SAVEFILTERLIST",
    saveFilterList,
})

export const setDateTimeDiff = (diffDateTime) => ({
    type: "GALLERY/SETDIFFDATTIME",
    diffDateTime,
})

export const setPriceByGid = (priceByGid) => ({
    type: "GALLERY/SETPRICEBYGID",
    priceByGid,
})

export const setPriceByPid = (priceByPid) => ({
    type: "GALLERY/SETPRICEBYPID",
    priceByPid,
})

export const setCountryDefaultValue = (defaultValue) => ({
    type: "GALLERY/SETCOUNTRYDEFAULTVALUE",
    defaultValue,
})

export const getPriceByphotoGrapherId = (pid) => {
    return (dispatch) => {
        getPriceByPid(pid).then((res) => {
            dispatch(setPriceByPid(res.data.results))
        })
    }
}

export const getDefaultPrice = (country) => {
    return (dispatch) => {
        getCountryDefaultValue(country).then((res) => {
            dispatch(setCountryDefaultValue(res.data.results))
        })
    }
}

export const getAllPhotoGrapherAction = () => {
    return (dispatch) => {
      getAllPhotoGrapher().then((res) => {
        const jsonData = res.data;
        dispatch(setAllPhotoGrapher(jsonData))
      })
    }
  }
import map from "lodash/map";
import get from "lodash/get";
import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";

import { useReduxStateReconcilation } from "../hooks/useReduxStateReconcilation";
import { activityTagsDropdownOptions } from "../../v2_actions/dropdown_options";
import { useGalleryFilterSearchParams } from "../hooks/useGalleryFilterSearchParams";
import { getActivityLabel } from "../../v2_components/eventpage/eventPageUtils";
import { styles } from "./styles";
import { makeLogger } from "../../utils/o11y/logger";

const logger = makeLogger({
  enabled: true,
  label: "FilterActivity",
  color: "pink",
});

export default function FilterActivity() {
  useReduxStateReconcilation();
  const [galleryFilterSearchParams, syncGalleryFilterSearchParam] =
    useGalleryFilterSearchParams();
  const [muiMenuAnchor, setMuiMenuAnchor] = React.useState(null);
  const isMenuOpen = Boolean(muiMenuAnchor);
  const activityTagOptions = activityTagsDropdownOptions.getAsOptions();
  const activityFilterFromSearchParam = galleryFilterSearchParams.activity;

  logger.log({ activityTagOptions, activityFilterFromSearchParam });

  const handleClickListItem = (event) => {
    setMuiMenuAnchor(event.currentTarget);
  };

  const handleCheckboxClicked = (ev) => {
    if (!ev.target.checked) {
      setMuiMenuAnchor(null);
      syncGalleryFilterSearchParam({ activity: "" });
    }
  };

  const handleMenuItemClick = (option, index) => {
    const activity_tags = option?.value;
    syncGalleryFilterSearchParam({ activity: activity_tags });
    setMuiMenuAnchor(null);
  };

  const handleClose = () => {
    setMuiMenuAnchor(null);
  };

  const filterLabel = getActivityLabel(activityFilterFromSearchParam);

  const isFilterActive = !!activityFilterFromSearchParam;

  return (
    <div>
      <Typography
        button
        sx={styles.filterListTitle}
        onClick={handleClickListItem}
      >
        Activity
      </Typography>
      <List component="nav" sx={styles.filterList}>
        <ListItem
          button
          sx={styles.filterListItem}
          aria-expanded={isMenuOpen ? "true" : undefined}
        >
          <Checkbox
            checked={isFilterActive}
            size="small"
            onChange={handleCheckboxClicked}
          />
          <ListItemText
            onClick={handleClickListItem}
            primary=""
            sx={styles.filterListItemText}
            secondary={<ListItemText primary={filterLabel} />}
          />
        </ListItem>
      </List>
      <Menu
        anchorEl={muiMenuAnchor}
        open={isMenuOpen}
        onClose={handleClose}
        MenuListProps={{
          role: "listbox",
        }}
      >
        <MenuItem
          value={undefined}
          disabled={activityFilterFromSearchParam === undefined}
          selected={activityFilterFromSearchParam === undefined}
          onClick={(event) => handleMenuItemClick(null, 0)}
        >
          All
        </MenuItem>
        {map(activityTagOptions, (option, index) => (
          <MenuItem
            key={get(option, "value")}
            disabled={activityFilterFromSearchParam === get(option, "value")}
            selected={activityFilterFromSearchParam === get(option, "value")}
            onClick={(event) => handleMenuItemClick(option, index)}
          >
            {get(option, "label")}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

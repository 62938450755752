import React, { useState, useEffect } from "react"
import {
  Grid,
  TextField,
  Box,
  Button,
  CircularProgress,
} from "@material-ui/core"
import { green } from "@material-ui/core/colors"
import { makeStyles } from "@material-ui/core/styles"
import TopBar from "./topbar"
import SideBar from "../../layout/sidebar"
import CustomSnackBar from "../components/SnackBar"
import { sendMailApi } from "../../apis/bookboss"
import styles from "./styles"
import { useIsMobile } from "../../actions/ui"

const useStyles = makeStyles(styles)

const Feedback = () => {
  const classes = useStyles()
  const isMobile = useIsMobile()
  const [showSideBar, setShowSideBar] = useState(false)
  const [feedback, setFeedback] = useState("")
  const userEmail = localStorage.getItem("auth_email")
  const userName = localStorage.getItem("auth_name")
  const [showSpinner, setShowSpinner] = useState(false)
  const [snackValue, setSnackValue] = React.useState({
    openSnackBar: false,
    message: "",
    type: "",
  })
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    window.addEventListener("resize", updateWidth)
    return () => window.removeEventListener("resize", updateWidth)
  })

  const updateWidth = () => {
    setWidth(window.innerWidth)
  }

  const sendQuestion = () => {
    if (!feedback) {
      setSnackValue({
        ...snackValue,
        openSnackBar: true,
        message: "Please fill the Question field!",
        type: "error",
      })
    } else {
      setShowSpinner(true)
      const sendMailData = {
        email: userEmail,
        customer_name: userName,
        mail_type: "feedback",
        mail_content: feedback,
      }
      sendMailApi(sendMailData).then((res) => {
        setFeedback("")
        setShowSpinner(false)
        setSnackValue({
          ...snackValue,
          openSnackBar: true,
          message: res.data,
          type: "success",
        })
      })
    }
  }

  return (
    <div style={{ position: "releative" }}>
      {showSpinner && (
        <CircularProgress
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            width: 60,
            height: 60,
            color: green[500],
            zIndex: 999,
          }}
        />
      )}
      <CustomSnackBar snackValue={snackValue} setSnackValue={setSnackValue} />
      <TopBar
        showSideBar={showSideBar}
        setShowSideBar={setShowSideBar}
        width={width}
      />
      <SideBar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
      <div
        className={!showSideBar ? classes.nonMennuWrapper : classes.menuWrapper}
        style={{ paddingTop: !isMobile ? 65 : 0 }}
      >
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Grid container spacing={3}>
              <Grid item xs={12} style={{ zIndex: 99 }}>
                <Box display="flex" justifyContent="center">
                  <TextField
                    variant="standard"
                    multiline
                    className={
                      !isMobile ? classes.textArea : classes.mobileTextArea
                    }
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                    placeholder="We welcome any Feedback. Please submit any thoughts you have about the website or service"
                    inputProps={{
                      style: {
                        padding: 5,
                        height: !isMobile ? 500 : 400,
                        width: !isMobile ? 800 : 500,
                        color: "#0c59cf",
                      },
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} style={{ zIndex: 99 }}>
                <Box display="flex" justifyContent="center">
                  <Button className={classes.button} onClick={sendQuestion}>
                    Submit Feedback
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Feedback

import get from "lodash/get";
import size from "lodash/size";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Grid, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box, LinearProgress, makeStyles } from "@material-ui/core";
import { useIsMobile } from "../actions/ui";
import { showError, showSuccess } from "../actions/Error";
import { PhotographUploader } from "./form/PhotographUploader";
import { basePhotographList } from "../v2_actions/customer_photograph";
import { customerFacialRecognition } from "../v2_actions/customer_facial_recognition";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    fontSize: 15,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  facialRecognitionImage: {
    width: 47,
    height: 47,
  },
  bibNumberIllustration: {
    display: "grid",
    textAlign: "center",
    alignContent: "center",
    border: "dotted 1px #000",
  },
  indexingInProgressMessage: {
    textAlign: "center",
  },
  matchedFaceIdsText: {
    textAlign: "center",
  },
  photographWrapper: {
    bottom: 10,
    width: 180,
    height: 100,
    position: "relative",
    border: "dashed 1px #000",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  facialRecognitionText: {
    fontSize: 16,
    color: "#000",
    [theme.breakpoints.up("sm")]: {
      fontSize: 12,
    },
  },
  uploadSelfieButton: {
    height: 31,
    width: 189,
    "& p": {
      fontSize: 16,
      fontWeight: 600,
      textTransform: "capitalize",
      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
      },
    },
    [theme.breakpoints.down("sm")]: {
      height: 28,
      width: 150,
    },
  },
  closeIcon: {
    color: "#000",
    cursor: "pointer",
    position: "absolute",
    fontSize: "20px !important",
    margin: "-5px 0px 0px 15px",
  },
}));

const Item = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  color: "blue",
}));

export default function FacialRecognition({
  gallery,
  clearFiltersWasClicked,
  setClearFiltersWasClicked,
  setExpandActiveMobileTabFilter,
  setIsFacialRecognitionFilterActive,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const galleryId = get(gallery, "id");
  const [preview, setPreview] = useState();
  const [matchedFaceIds, setMatchedFaceIds] = useState([]);
  const [indexingInProgress, setIndexingInProgress] = useState(false);
  const [fileUploadsInProgress, setFileUploadsInProgress] = useState({});
  const isGalleryIndexed = get(gallery, "indexed_for_facial_recognition");
  const [isPhotographUploading, setIsPhotographUploading] = useState(false);
  const [galleryIndexingInProgress, setGalleryIndexingInProgress] =
    useState(false);
  const [
    customerSelfieFileInfoPhotograph,
    setCustomerSelfieFileInfoPhotograph,
  ] = useState(null);

  useEffect(() => {
    if (clearFiltersWasClicked) clearFacialRecognition();
    setClearFiltersWasClicked(false);
  }, [clearFiltersWasClicked]);

  const onIndexCustomer = (file_info) => {
    setGalleryIndexingInProgress(true);
    setIsFacialRecognitionFilterActive(true);
    setIndexingInProgress(true);
    setCustomerSelfieFileInfoPhotograph(file_info);
    const on_done = (json) => {
      if (json.status === "success") {
        onSearchCustomer(json.face_token);
        showSuccess("Indexed Customer", json.face_token);
        customerFacialRecognition.setCustomerFacialRecognitionFaceToken(
          "customer_face_token",
          get(json, "face_token"),
        );
      } else {
        showError("Failed", json.error_message);
      }
    };
    dispatch(
      customerFacialRecognition.indexForCustomer(get(file_info, "id")),
    ).then(on_done);
  };

  const clearFacialRecognition = () => {
    setPreview(null);
    setExpandActiveMobileTabFilter(false);
    setCustomerSelfieFileInfoPhotograph(null);
    dispatch(basePhotographList.clearListFilter());
    customerFacialRecognition.setCustomerFacialRecognitionFaceToken(null);
  };

  const onIndexGalleryPhotographs = () => {
    if (!isGalleryIndexed) {
      const on_done = (json) => {
        if (json.status === "success") {
          //          showSuccess("Indexed Gallery")
        } else {
          //          showError("Failed", json.error_message)
        }
      };
      dispatch(customerFacialRecognition.indexGallery(galleryId)).then(on_done);
    }
  };

  const onSearchCustomer = (customer_face_token) => {
    const on_done = (json) => {
      setExpandActiveMobileTabFilter(false);
      if (json.status === "success") {
        showSuccess("Found photographs");
        setMatchedFaceIds(json.matching_photographs);
        dispatch(
          basePhotographList.updateListFilter({
            refresh_search: true,
            indexed_gallery_id: galleryId,
            customer_face_token: customer_face_token,
            number_of_facial_recognition_matches: size(matchedFaceIds),
          }),
        );
      } else {
        showError("Failed", json.error_message);
      }
      setGalleryIndexingInProgress(false);
    };
    dispatch(
      customerFacialRecognition.searchForCustomer(
        galleryId,
        customer_face_token,
      ),
    ).then(on_done);
  };

  const renderCustomerSelfieUploader = () => {
    return (
      <PhotographUploader
        maxFileLimit={1}
        preview={preview}
        gallery_id={galleryId}
        setPreview={setPreview}
        fileAnnotations={fileUploadsInProgress}
        disabled={customerSelfieFileInfoPhotograph}
        customButtonStyle={classes.uploadSelfieButton}
        clearFacialRecognition={clearFacialRecognition}
        setIsPhotographUploading={setIsPhotographUploading}
        onChange={(file_info) => onIndexCustomer(file_info)}
      />
    );
  };

  return (
    <div>
      <div>
        <div>
          <Stack direction={{ xs: "column", sm: "row" }}>
            <Stack
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{ margin: "auto" }}
            >
              <Item>
                {renderCustomerSelfieUploader()}
                <Box m={1}>
                  {(isPhotographUploading || galleryIndexingInProgress) && (
                    <LinearProgress />
                  )}
                </Box>
              </Item>
            </Stack>
          </Stack>
        </div>
      </div>
    </div>
  );
}

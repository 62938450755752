import { PropMixins, cssStyles } from "../mixins";

import React from "react";
import styles from "./Stack.module.css";
import { useResponsiveBreakpoints } from "./useResponsiveBreakpoints";

/**
 * Stack Layout Primitive Component
 * @returns
 */
const Stack = ({ children, ...rest }: React.PropsWithChildren<PropMixins>) => {
  const activeBreakpoint = useResponsiveBreakpoints();
  return <div className={styles.stack} style={cssStyles(rest, activeBreakpoint)}>{children}</div>;
};

export default Stack;

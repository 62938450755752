import {
  ActivityTagOption,
  CountryOption,
  GalleryEventOption,
  PhotographerOption,
} from "../../types/orm";
import {
  Button,
  Flyout,
  Footer,
  Header,
  HeaderButton,
  HeaderTitle,
  HorizontalExpansionContainer,
  List,
  ListButton,
  ListItem,
  ListItemLabel,
} from "bossfotos-design-system";
import {
  getIsEventIframe,
  getIsPhotographerIframe,
  isWindowInIframe,
} from "../../v2_actions/nav";

import { DATE_FILTER_OPTIONS_NEW } from "../../v2_actions/date";
import { EventsFiltersExpander } from "./EventsFiltersExpander";
import { EventsFiltersListOptions } from "./EventsFiltersListOptions";
import { activityTagsDropdownOptions } from "../../v2_actions/dropdown_options";
import { briefGalleryList } from "../../v2_actions/brief_gallery";
import { countryList } from "../../v2_actions/country";
import { galleryEventDropdownOptions } from "../../v2_actions/dropdown_options";
import { makeLogger } from "../../utils/o11y/logger";
import { photographerDropdownOptions } from "../../v2_actions/dropdown_options";
import { replaceDashesWithSpaces } from "../../v2_actions/nav";
import { useCountryDetection } from "../../v2_components/hooks/useCountryDetection";
import { useEventFiltersDispatcher } from "../../v2_components/hooks/useEventFiltersDispatcher";
import { useGalleryFilterSearchParams } from "../../v2_components/hooks/useGalleryFilterSearchParams";
import { useParams } from "react-router-dom";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

const logger = makeLogger({
  enabled: true,
  label: "EventFiltersFlyout",
  color: "red",
});

export type EditingFilters = {
  countryCode: string;
  photographers: string | undefined;
  activity: string | undefined;
  date: string | undefined;
  event: string | undefined;
};

export const EventFiltersFlyout = ({
  open,
  onClose,
  onShowResults,
  isMapView,
}: {
  open: boolean;
  onClose: () => void;
  onShowResults: (editedFilters: EditingFilters) => void;
  isMapView: boolean;
}) => {
  // note the abstraction here of EventFiltersFlyoutContents
  // ensures it's internal state is discarded/reset when
  // the flyout closes
  return (
    <Flyout open={open}>
      {open && (
        <EventFiltersFlyoutContents
          onShowResults={onShowResults}
          onClose={onClose}
          isMapView={isMapView}
        />
      )}
    </Flyout>
  );
};

/**
 * Filter Flyout Contents
 */
const EventFiltersFlyoutContents = ({
  onClose,
  onShowResults,
  isMapView,
}: {
  onClose: () => void;
  onShowResults: (editedFilters: EditingFilters) => void;
  isMapView: boolean;
}) => {
  const isMapFilterActive = briefGalleryList.isMapFilterActive();
  const [galleryFilterSearchParams] = useGalleryFilterSearchParams();
  const { gallery_event_name } = useParams();
  const isInIframe = isWindowInIframe();
  const isEventIframe = getIsEventIframe();
  const isPhotographerIframe = getIsPhotographerIframe();

  const eventPageCtx = useEventFiltersDispatcher();

  const galleryEventName = replaceDashesWithSpaces(gallery_event_name);

  const [filterExpanded, setFilterExpanded] = useState<string | undefined>();
  // separate bool to control flyout hide delayed
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const { countryCode, getPreferredCountryApiId } = useCountryDetection();

  const [editingFilter, setEditingFilters] = useState<EditingFilters>({
    countryCode: countryCode ?? "all",
    photographers: galleryFilterSearchParams.photographers,
    activity: galleryFilterSearchParams.activity,
    date: galleryFilterSearchParams.date,
    event: galleryEventName,
  });

  const activityTagOptions: ActivityTagOption[] = [
    { value: undefined, label: "All" },
    ...activityTagsDropdownOptions.getAsOptions(),
  ];
  const photographerOptions: PhotographerOption[] = [
    { value: undefined, label: "All" },
    ...photographerDropdownOptions.getAsOptions(),
  ];
  const galleryEventOptions: GalleryEventOption[] = [
    { value: undefined, label: "All" },
    ...galleryEventDropdownOptions.getAsOptions(),
  ];
  const countryOptions: CountryOption[] = (
    [...countryList.getObjects()] as { country_name: any; country_code: any }[]
  )
    .sort((a, b) => a.country_name.localeCompare(b.country_name))
    .map((c) => ({
      label: c.country_name,
      value: c.country_code,
    }));
  countryOptions.unshift({ label: "All", value: "all" });

  const handleCloseClick = () => {
    onClose();
    setFilterExpanded(undefined);
    setIsExpanded(false);
  };
  const handleShowResultsClick = () => {
    onShowResults(editingFilter);
    setFilterExpanded(undefined);
    setIsExpanded(false);
  };
  const handleExpandClick = (filter: string | undefined) => {
    const expanding = filter !== undefined;
    setIsExpanded(expanding);

    if (expanding) {
      // show immediately
      setFilterExpanded(filter);
    } else {
      // delay content hide
      setTimeout(() => setFilterExpanded(undefined), 200);
    }
  };

  const closeFilterList = () => {
    setIsExpanded(false);
    setTimeout(() => setFilterExpanded(undefined), 200);
  };

  const handleEventSelected = (gallery: GalleryEventOption) => {
    setEditingFilters((prev) => ({
      ...prev,
      countryCode: "all",
      event:
        gallery.label === editingFilter.event || gallery.label === "All"
          ? undefined
          : gallery.label,
    }));
    closeFilterList();
  };
  const handleCountrySelected = (country: CountryOption) => {
    setEditingFilters((prev) => ({
      ...prev,
      countryCode:
        country.value === editingFilter.countryCode
          ? "all"
          : country.value ?? "all",
    }));
    closeFilterList();
  };
  const handleDateSelected = (date: any) => {
    setEditingFilters((prev) => ({
      ...prev,
      date: date.value === editingFilter.date ? undefined : date.value,
    }));
    closeFilterList();
  };
  const handlePhotographerSelected = (photographer: PhotographerOption) => {
    setEditingFilters((prev) => ({
      ...prev,
      countryCode: "all",
      photographers:
        photographer.label === editingFilter.photographers ||
        photographer.label === "All"
          ? undefined
          : photographer.label,
    }));
    closeFilterList();
  };
  const handleActivitySelected = (activity: ActivityTagOption) => {
    setEditingFilters((prev) => ({
      ...prev,
      activity:
        activity.value === editingFilter.activity || activity.label === "All"
          ? undefined
          : activity.value,
    }));
    closeFilterList();
  };

  const showCountry = !isInIframe;
  const showEvent = !isEventIframe;
  const showPhotographer = !isPhotographerIframe;

  const handleClearFilters = () => {
    let clearArg: Partial<EditingFilters> = {
      activity: undefined,
      date: undefined,
    };

    if (!isEventIframe) {
      clearArg.event = undefined;
    }
    if (!isPhotographerIframe) {
      clearArg.photographers = undefined;
    }
    setEditingFilters((prev) => ({
      ...prev,
      ...clearArg,
    }));
  };
  const handleResetMapFilter = () => {
    eventPageCtx.updateAllListFilters({
      north_east_lat: undefined,
      north_east_lng: undefined,
      south_west_lat: undefined,
      south_west_lng: undefined,
      country: getPreferredCountryApiId(),
    });
  };
  logger.log({
    galleryFilterSearchParams,
    galleryEventName,
    countryCode,
    editingFilter,
  });

  return (
    <>
      <Header>
        <HeaderButton style={{ flexBasis: "20%" }} onClick={handleClearFilters}>
          Clear
        </HeaderButton>
        <HeaderTitle style={{ flexBasis: "60%" }}>Filters</HeaderTitle>
        <HeaderButton style={{ flexBasis: "20%" }} onClick={handleCloseClick}>
          <CloseIcon />
        </HeaderButton>
      </Header>
      <HorizontalExpansionContainer expanded={isExpanded}>
        <div>
          {showCountry && !isMapView && (
            <>
              {isMapFilterActive ? (
                <ListItem>
                  <ListButton fill={true} onClick={handleResetMapFilter}>
                    <ListItemLabel>Location set by map</ListItemLabel>
                    <ListItemLabel align="right" size="small">
                      Reset
                    </ListItemLabel>
                  </ListButton>
                  <ListButton variant="icon" onClick={handleResetMapFilter}>
                    <RestartAltIcon />
                  </ListButton>
                </ListItem>
              ) : (
                <EventsFiltersExpander
                  label="Country"
                  list={countryOptions}
                  selectedValue={editingFilter.countryCode}
                  onClick={() => handleExpandClick("Country")}
                />
              )}
            </>
          )}
          <EventsFiltersExpander
            label="Date"
            list={DATE_FILTER_OPTIONS_NEW}
            selectedValue={editingFilter.date}
            onClick={() => handleExpandClick("Date")}
          />
          <EventsFiltersExpander
            label="Sport"
            list={activityTagOptions}
            selectedValue={editingFilter.activity}
            onClick={() => handleExpandClick("Sport")}
          />
          {showPhotographer && (
            <EventsFiltersExpander
              label="Photographer"
              list={photographerOptions}
              selectedValue={editingFilter.photographers}
              onClick={() => handleExpandClick("Photographer")}
            />
          )}
          {showEvent && !isMapView && (
            <EventsFiltersExpander
              label="Events"
              list={galleryEventOptions}
              selectedValue={editingFilter.event}
              onClick={() => handleExpandClick("Events")}
            />
          )}
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <ListItem align="left">
            <ListButton
              variant="icon"
              onClick={() => handleExpandClick(undefined)}
            >
              <ArrowBackIosNewIcon />
            </ListButton>
            <ListItemLabel heading={true} align="center">
              {filterExpanded}
            </ListItemLabel>
            <ListButton variant="icon" />
          </ListItem>
          <List>
            {filterExpanded === "Events" && (
              <EventsFiltersListOptions
                listType={filterExpanded}
                list={galleryEventOptions}
                onSelected={handleEventSelected}
                selectedValue={editingFilter.event}
              />
            )}
            {filterExpanded === "Country" && (
              <EventsFiltersListOptions
                listType={filterExpanded}
                list={countryOptions}
                onSelected={handleCountrySelected}
                selectedValue={editingFilter.countryCode}
              />
            )}
            {filterExpanded === "Date" && (
              <EventsFiltersListOptions
                listType={filterExpanded}
                list={DATE_FILTER_OPTIONS_NEW}
                onSelected={handleDateSelected}
                selectedValue={editingFilter.date}
              />
            )}
            {filterExpanded === "Photographer" && (
              <EventsFiltersListOptions
                listType={filterExpanded}
                list={photographerOptions}
                onSelected={handlePhotographerSelected}
                selectedValue={editingFilter.photographers}
              />
            )}
            {filterExpanded === "Sport" && (
              <EventsFiltersListOptions
                listType={filterExpanded}
                list={activityTagOptions}
                onSelected={handleActivitySelected}
                selectedValue={editingFilter.activity}
              />
            )}
          </List>
        </div>
      </HorizontalExpansionContainer>
      <Footer>
        <Button fill={true} onClick={handleShowResultsClick}>
          Show results
        </Button>
      </Footer>
    </>
  );
};

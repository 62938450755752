import React, { useState } from 'react'
import { NavBar } from './NavBar'
import { ContextMenu } from './ContextMenu'
import { BaseLayout } from './BaseLayout'

export const MainLayout = ({
  children,
  activeTab,
  isInIframe
}) => {

  const [showContextMenu, setShowContextMenu] = useState(false)

  return (
    <BaseLayout>
      {!isInIframe &&
       <NavBar
         activeTab={ activeTab }
         setShowContextMenu={ setShowContextMenu }
       />
      }
      <ContextMenu
        showContextMenu={ showContextMenu }
        setShowContextMenu={ setShowContextMenu }
      />
      { children }
    </BaseLayout>
  )
}

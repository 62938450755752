import React, { useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import size from "lodash/size";
import isEmpty from "lodash/isEmpty";
import {
  Box,
  Divider,
  Drawer,
  List,
  Typography,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import ContextMenuItem from "../../v2_components/layout/ContextMenuItem";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useNavigate } from "react-router";
import WhiteLogoImg from "../../assets/logo/white_logo.png";
import { setShowModal } from "../../actions/map";
import {
  clearAuthentication,
  isLoggedIn,
} from "../../v2_actions/auth";
import { getUserFirstName } from "../../actions/user";
import { CUSTOM_VALUE_NUM_TROLLEY_PHOTOGRAPHS, setCustomValue } from "../../v2_actions/custom_value"
import { itemFirstPart, itemSecondPart, itemThirdPart } from "../../routes";

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 250,
    background:
      "linear-gradient(to right, rgb(0, 45, 113) 0%, rgb(106, 157, 234) 100%)",
    color: "white",
  },
  desktopDrawer: {
    width: 300,
    height: "100%",
    background: "white",
    color: "#0c59cf",
    border: "2px solid #0c59cf",
    borderTop: "none",
  },
  iconbtn: {
    width: 25,
    height: 25,
    color: "#0c59cf",
  },
  logo: {
    fontFamily: "Aguafina Script",
    fontSize: 40,
    fontWeight: "400",
    color: "#0c59cf",
    textAlign: "center",
  },
  mobileLogo: {
    padding: 20,
    fontFamily: "Aguafina Script",
    fontSize: 40,
    fontWeight: "400",
    color: "white",
    textAlign: "center",
  },
  name: {
    paddingLeft: 15,
    paddingRight: 20,
  },
}));

export const ContextMenu = ({ showSideBar, setShowSideBar }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authId = localStorage.getItem("auth_id");
  const auth_email = localStorage.getItem("auth_email");
  const is_staff = localStorage.getItem("is_staff");
  const authName = localStorage.getItem("auth_name");
  const sidebarRef = useRef(null);
  const isUserLoggedIn = useSelector((state) => isLoggedIn(state))
  const loggedInUserDisplayName = useSelector((state) => getUserFirstName(state))

  const logOut = () => {
    dispatch(setShowModal(true));
    setShowSideBar(false);
    dispatch(clearAuthentication());
    dispatch(setCustomValue({ name: CUSTOM_VALUE_NUM_TROLLEY_PHOTOGRAPHS, value: 0 }))
    navigate(`/customer/events`);
  };

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowSideBar(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  useOutsideAlerter(sidebarRef);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box
        display="flex"
        flexDirection="column"
        style={{ cursor: "pointer", paddingRight: 0 }}
      >
        {isUserLoggedIn && (
          <>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box
                display="flex"
                alignItems="center"
                style={{ paddingLeft: 20 }}
              >
                <AccountCircleIcon
                  className={classes.iconbtn}
                  style={{ color: "white" }}
                />
                <Typography className={classes.name} variant="h6">
                  {loggedInUserDisplayName}
                </Typography>
              </Box>
              <IconButton onClick={() => setShowSideBar(false)}>
                <ArrowBackIosIcon
                  className={classes.iconbtn}
                  style={{ color: "white" }}
                />
              </IconButton>
            </Box>
            <Typography
              variant="body2"
              style={{ marginLeft: 60 }}
              onClick={logOut}
            >
              Sign Out
            </Typography>
          </>
        )}
        {isUserLoggedIn === false && (
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <IconButton onClick={() => setShowSideBar(false)}>
              <ArrowBackIosIcon
                className={classes.iconbtn}
                style={{ color: "white" }}
              />
            </IconButton>
          </Box>
        )}
      </Box>
      {authId && is_staff === "false" && (
        <Divider style={{ backgroundColor: "white" }} />
      )}
      <Box p={1}>
        <List>
          {itemFirstPart.map((item) => (
            <ContextMenuItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
              onClick={() => setShowSideBar(false)}
              openInNewBrowserTab={item.openInNewBrowserTab}
              openAsWindowLocation={item.openAsWindowLocation}
            />
          ))}
        </List>
      </Box>
      <Divider style={{ backgroundColor: "white" }} />

      <Box p={1}>
        <List>
          {itemSecondPart.map((item) => (
            <ContextMenuItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
              onClick={() => setShowSideBar(false)}
              openInNewBrowserTab={item.openInNewBrowserTab}
              openAsWindowLocation={item.openAsWindowLocation}
            />
          ))}
        </List>
      </Box>
      <Divider style={{ backgroundColor: "white" }} />

      <Box p={1}>
        <List>
          {itemThirdPart.map((item) => (
            <ContextMenuItem
              href={item.href}
              key={item.title}
              title={item.title}
              onClick={() => setShowSideBar(false)}
              openInNewBrowserTab={item.openInNewBrowserTab}
              openAsWindowLocation={item.openAsWindowLocation}
            />
          ))}
        </List>
      </Box>
      <img
        src={WhiteLogoImg}
        alt="/"
        style={{ width: "75%", height: "auto", marginLeft: 25 }}
      />
    </Box>
  );

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.mobileDrawer }}
      variant="temporary"
      open={showSideBar}
    >
      <div ref={sidebarRef}>{content}</div>
    </Drawer>
  );
};

export default ContextMenu;

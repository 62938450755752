import { ItemList } from '../orm'
import moment from "moment"

class GalleryPassword extends ItemList {
    getEntityKey() {
        return "customer/gallery/check_password"
    }

    checkPassword({gallery_id, password}) {
        return this.saveNewObject({gallery_id, password})
    }

}

export const galleryPassword = new GalleryPassword("gallery_password_default")

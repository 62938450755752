// eslint-disable-next-line
export default (theme) => ({
  root: {
    display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%",
  },
  menuWrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    paddingTop: 65,
    position: "relative",
    [theme.breakpoints.up("lg")]: {
      paddingTop: 65,
      paddingLeft: 300,
    },
  },
  nonMennuWrapper: {
    position: "relative",
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  content: {
    display: "flex",
    flex: "1 1 auto",
    height: "calc(100vh - 65px)",
    overflowY: "scroll",
    padding: 20,
    justifyContent: "center",
  },
  mobileContent: {
    display: "flex",
    flex: "1 1 auto",
    padding: 20,
    justifyContent: "center",
    flexDirection: "column-reverse",
    overflowY: "scroll",
  },
  dekProfile: {
    padding: 5,
  },
  typography: {
    color: "#0c59cf",
    textAlign: "justify",
    fontSize: 17,
  },
  mobileTypography: {
    color: "#0c59cf",
    textAlign: "center",
    textTransform: "uppercase",
    fontSize: 20,
  },
  typographyprice: {
    color: "#0c59cf",
    textAlign: "center",
    fontSize: 16,
    padding: 10
  },
  typographyprofile: {
    color: "#0c59cf",
    textAlign: "justify",
    fontSize: 16,
    // border: "1px solid #0078d430",
    padding: 2,
  },
  button: {
    backgroundColor: "#0c59cf !important",
    textTransform: "capitalize",
    color: "white",
    fontSize: 18,
    fontWeight: "600",
    width: "100%",
    borderRadius: 15,
    boxShadow: "0px 5px 10px rgba(248, 95, 106, 0.22)",
  },
  viewbutton: {
    backgroundColor: "#3d8af7 !important",
    textTransform: "capitalize",
    color: "white",
    fontSize: 18,
    fontWeight: "600",
    width: "100%",
    borderRadius: 15,
    boxShadow: "0px 5px 10px rgba(248, 95, 106, 0.22)",
  },
  paper: {
    position: "absolute",
    background: "white",
    width: 600,
    border: "2px solid #0c59cf !important",
    color: "#0c59cf",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    zIndex: 100,
  },
  mobilePaper: {
    position: "absolute",
    background: "white",
    width: 375,
    height: "100vh",
    border: "2px solid #0c59cf !important",
    color: "#0c59cf",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    zIndex: 100,
  },
  submitBtn: {
    color: "white",
    width: 150,
    textTransform: "capitalize",
    backgroundColor: "#0c59cf !important",
  },
});

import get from "lodash/get";
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { customerImageWithCutoutList } from "../v2_actions/customer_image_with_cutouts";
import { cutoutImageList } from "../v2_actions/cutout_image";
import clsx from "clsx";

const useStyles = makeStyles({
  baseContainer: {
    position: "relative",
  },
  baseCutoutImage: {},
  baseFittedImage: {
    position: "absolute",
    top: "0px",
    left: "0px",
  },
  loadingImage: {
    position: "absolute",
    top: "0px",
    left: "0px",
    display: "flex",
    alignItems: "center",
    height: "100%",
    width: "100%",
    justifyContent: "center",
    opacity: 0.3,
    fontSize: "30px",
    fontStyle: "bold",
  },
});

export const PreviewImageWithCutout = ({
  imageWithCutoutId,
  photograph,
  onClose,
  imageClass,
  enableLoadingMessage,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const imageWithCutout = useSelector(
    () =>
      imageWithCutoutId &&
      customerImageWithCutoutList.getObject(imageWithCutoutId),
  );
  const cutoutImage = useSelector(() =>
    cutoutImageList.getCutoutImage({ imageWithCutout, photograph }),
  );
  const isLoadingCutout =
    !get(cutoutImage, "id") ||
    (imageWithCutoutId && !get(imageWithCutout, "id"));

  useEffect(() => {
    if (imageWithCutoutId) {
      dispatch(
        customerImageWithCutoutList.ensureObjectLoaded(imageWithCutoutId),
      );
    }
    if (get(photograph, "id")) {
      dispatch(
        cutoutImageList.ensureCutoutImageLoaded({
          imageWithCutout,
          photograph,
        }),
      );
    }
  }, [imageWithCutoutId, get(imageWithCutout, "id"), get(photograph, "id")]);

  return (
    <div className={clsx(classes.baseContainer, imageClass)}>
      <img
        className={clsx(classes.baseCutoutImage, imageClass)}
        src={get(imageWithCutout, ["image_file_info", "download_url"])}
      />
      <>
        {cutoutImage && (
          <img
            className={clsx(classes.baseFittedImage, imageClass)}
            src={get(cutoutImage, ["fitted_image_file_info", "download_url"])}
          />
        )}
      </>

      {photograph && isLoadingCutout && enableLoadingMessage && (
        <div className={clsx(classes.loadingImage)}>Processing...</div>
      )}
    </div>
  );
};

import React from "react";
import clsx from "clsx";
import { NavLink as RouterLink } from "react-router-dom";
import { Button, Hidden, ListItem, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  item: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: "white",
    fontSize: 18,
    fontWeight: "400",
    justifyContent: "flex-start",
    letterSpacing: 0,
    padding: "10px 8px",
    textTransform: "none",
    width: "100%",
  },
  icon: {
    width: 30,
    height: 30,
    color: "white",
  },
  mobileIcon: {
    width: 30,
    height: 30,
    color: "white",
  },
  title: {
    marginRight: "auto",
    color: "white",
  },
  mobiletitle: {
    marginRight: "auto",
    color: "white",
  },
  active: {
    color: "#0c59cf",
    "& $title": {
      fontWeight: "#0c59cf",
    },
    "& $icon": {
      color: "#0c59cf",
    },
  },
}));

const ContextMenuItem = ({
  href,
  title,
  className,
  icon: Icon,
  openInNewBrowserTab,
  openAsWindowLocation,
  ...rest
}) => {
  const classes = useStyles();
    
  const renderContextMenuItemButtonContent = () => {
    return (
      <>
        <Hidden mdDown>
          {Icon && <Icon className={classes.icon} size="20" />}
          &nbsp;&nbsp;&nbsp;
          <span className={classes.title}>{title}</span>
        </Hidden>
        <Hidden lgUp>
          {Icon && <Icon className={classes.mobileIcon} size="20" />}
          &nbsp;&nbsp;&nbsp;
          <span className={classes.mobiletitle}>{title}</span>
        </Hidden>
      </>
    );
  };

  return (
    <ListItem
      className={clsx(classes.item, className)}
      disableGutters
      {...rest}>
      {!openAsWindowLocation && openInNewBrowserTab && (
        <Button
          href={href}
          target="_blank"
          className={classes.button}
        >
          {renderContextMenuItemButtonContent()}
        </Button>
      )}
      {!openAsWindowLocation && !openInNewBrowserTab && (
        <Button
          to={href}
          component={RouterLink}
          className={classes.button}
          >
          {renderContextMenuItemButtonContent()}
        </Button>
      )}
      {openAsWindowLocation && (
        <Button
            className={classes.button}
            onClick={() => window.location.href = window.location.origin + href}
        >
          {renderContextMenuItemButtonContent()}
        </Button>
      )}

      
    </ListItem>
  );
};

export default ContextMenuItem;

import {
  Button,
  Inline,
  InputTextContainer,
  InputTextInner,
  ModalContent,
  ModalCover,
  ModalFooter,
  ModalWrapper,
  Stack,
} from "bossfotos-design-system";
import { useState } from "react";
import { useIsMobile } from "../../actions/ui";

export const VoucherCodeModal = ({
  code,
  onClose,
}: {
  code: string;
  onClose: (code?: string | undefined) => void;
}) => {
  const [editingCode, setEditingCode] = useState(code);
  const isMobile = useIsMobile();

  const handleCodeChanged = (code: string) => {
    setEditingCode(code);
  };
  /*
   */
  return (
    <ModalCover tabIndex={0}>
      <ModalWrapper>
        <ModalContent>
          <Stack
            style={{
              width: isMobile ? "300px" : "400px",
              padding: "20px",
              paddingBottom: "50px",
              textAlign: "center",
            }}
          >
            <h3>Enter Voucher Code:</h3>
            <InputTextContainer>
              <InputTextInner
                style={{
                  border: "solid 2px black",
                  borderRadius: "25px",
                  padding: "10px 16px",
                  height: "inherit",
                }}
                autoFocus={true}
                value={editingCode}
                onChange={(e) => handleCodeChanged(e.target.value)}
                hasIconRight={true}
                placeholder=""
              />
            </InputTextContainer>
          </Stack>
          <ModalFooter>
            <Inline fill={true}>
              <Button fill={true} onClick={() => onClose()} variant="secondary">
                Cancel
              </Button>
              <Button fill={true} onClick={() => onClose(editingCode)}>
                Submit
              </Button>
            </Inline>
          </ModalFooter>
        </ModalContent>
      </ModalWrapper>
    </ModalCover>
  );
};

export default VoucherCodeModal;

import React from "react";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useIsMobile } from "../actions/ui";

export const ScrollToTopButton = ({ handleScrollToTop }) => {
  const isMobile = useIsMobile();
  const scrollToTopButtonStylingMobile = {
    zIndex: 9,
    float: "right",
    position: "fixed",
    top: "90dvh",
    right: "10px",
  };
  const scrollToTopButtonStylingDesktop = {
    zIndex: 9,
    float: "right",
    position: "fixed",
    top: "90dvh",
    right: "20px",
  };

  return (
    <Box
      sx={
        isMobile
          ? scrollToTopButtonStylingMobile
          : scrollToTopButtonStylingDesktop
      }
    >
      <Fab color="primary" aria-label="add" onClick={handleScrollToTop}>
        <ArrowUpwardIcon />
      </Fab>
    </Box>
  );
};

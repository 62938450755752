import React from "react";
import styles from "./Navigation.module.css";

/**
 * Navigation Bar
 * @returns
 */
const Navigation = ({ children }: React.PropsWithChildren<{}>) => {
  return <nav className={styles.navigation}>{children}</nav>;
};

export default Navigation;
import get from "lodash/get";
import size from "lodash/size";

export const ANNOUNCE_GENERAL_API_ERROR = "ANNOUNCE_GENERAL_API_ERROR";

export function announceGeneralApiError(error) {
  if (size(error) === 0) {
    error = "General api error";
  }

  return {
    type: ANNOUNCE_GENERAL_API_ERROR,
    error,
  };
}

export function announceClearGeneralApiError() {
  return {
    type: ANNOUNCE_GENERAL_API_ERROR,
    errir: null,
  };
}

export function getGeneralApiError(state) {
  return get(state, ["orm_general_reducer", "error"]);
}

export function hasGeneralApiError(state) {
  return Boolean(getGeneralApiError(state));
}

import { PropMixins, cssStyles } from "../mixins";

import React from "react";
import styles from "./Inline.module.css";
import { useResponsiveBreakpoints } from "./useResponsiveBreakpoints";

/**
 * Inline Layout Primitive Component
 * @returns
 */
const Inline = ({
  children,
  ...rest
}: React.PropsWithChildren<PropMixins>) => {
  const activeBreakpoint = useResponsiveBreakpoints();
  
  return (
    <div className={styles.inline} style={cssStyles(rest, activeBreakpoint)}>
      {children}
    </div>
  );
};

export default Inline;

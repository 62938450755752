import indexOf from "lodash/indexOf";
import last from "lodash/last";
import map from "lodash/map";
import get from "lodash/get";
import React from "react";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import HomeIcon from "@mui/icons-material/Home";
import { css, Global } from "@emotion/react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Badge from "@mui/material/Badge";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
} from "@material-ui/core";
import { useIsMobile } from "../../actions/ui";
import { setMessage } from "../../actions/trolley";
import LogoImg from "../../assets/logo/black_logo.png";
import { getCheckoutNavBackButtonUrl } from "../../v2_actions/nav";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 65,
    padding: "0px 25px",
    [theme.breakpoints.down("md")]: {
      padding: "0px 18px",
    },
    "& .MuiToolbar-root": {
      borderBottom: "1px solid #d6d6d6",
      "& button": {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },
  iconbtn: {
    width: 27,
    height: 24,
    color: "black",
  },
  backbtn: {
    marginRight: 18,
  },
  homebtn: {
    marginLeft: 15,
    marginTop: 4,
    "& svg": {
      fontSize: 27,
    },
  },
  cartbtn: {
    paddingTop: 4,
    "& svg": {
      fontSize: 27,
      [theme.breakpoints.down("md")]: {
        fontSize: 23,
      },
    },
  },
  logo: {
    paddingRight: 25,
  },
  middletitle: {
    paddingTop: 10,
    fontSize: 18,
    [theme.breakpoints.down("md")]: {
      marginRight: 10,
      marginBottom: 20,
    },
  },
  checkoutBtn: {
    height: 35,
    width: 120,
    position: "relative",
    bottom: 1,
    backgroundColor: "#1464f6",
    textTransform: "capitalize",
    color: "white",
    border: "1px solid #1464f6",
    borderRadius: 8,
    fontWeight: "400",
    fontSize: 20,
    padding: 12,
    float: "right",
  },
  checkoutBtnBadge: {
    "& svg": {
      fontSize: 20,
    },
  },
  checkoutmobileBtn: {
    height: 40,
    width: 250,
    backgroundColor: "#72bb53 !important",
    textTransform: "capitalize",
    color: "white",
    border: "1px solid black",
    borderRadius: 10,
    fontWeight: "400",
    fontSize: 20,
    padding: 12,
  },
}));

const global_styles = css`
  @media (min-width: 600px) {
    .MuiToolbar-regular {
      min-height: 48px;
    }
    .MuiAppBar-positionFixed {
      height: 48px;
    }
  }
  .MuiButton-contained:hover {
    background-color: #1464f6;
  }
`;

const TopBar = ({
  className,
  checkOut,
  active_trolley_photograph,
  authenticated_trolley,
  galleryId,
  ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { trolley_photograph_id } = useParams();
  const customerId = localStorage.getItem("auth_id");
  const trolley_photos = get(authenticated_trolley, "trolley_photographs");
  const indexOfTrolleyPhotograph = indexOf(
    map(trolley_photos, (x) => x.id),
    trolley_photograph_id,
    0,
  );
  const lastTrolleyPhotograph = get(last(trolley_photos), "id");

  const goToCheckoutPage = () => {
    navigate(`/customer/shopping/${get(authenticated_trolley, "id")}`);
  };

  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
      color="white"
      position={!isMobile ? "fixed" : "relative"}
    >
      <Global styles={global_styles} />
      <Toolbar>
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          alignItems="center"
        >
          <Box
            display="flex"
            justifyContent="space-evenly"
            alignItems="center"
            //              width="15%"
          >
            <IconButton
              onClick={() => {
                dispatch(setMessage(false));
                navigate(getCheckoutNavBackButtonUrl());
              }}
              pt={"18px"}
            >
              <ArrowBackIcon className={[classes.iconbtn, classes.backbtn]} />
            </IconButton>
            <Hidden mdDown>
              <RouterLink to={`/customer`}>
                <img
                  className={classes.logo}
                  src={LogoImg}
                  alt="/"
                  className="logo_img_map"
                />
              </RouterLink>
            </Hidden>
            {!isMobile && (
              <IconButton
                className={classes.homebtn}
                onClick={() => {
                  dispatch(setMessage(false));
                  navigate("/customer/events");
                }}
              >
                <HomeIcon className={classes.iconbtn} />
              </IconButton>
            )}
          </Box>
          <Box ml={{ xs: 0, md: "49%", lg: "55%" }}>
            <h1 className={classes.middletitle}>Purchase Options</h1>
          </Box>
          <Box display="flex" alignItems="center" className={classes.cartbtn}>
            <>
              <Badge
                badgeContent={get(authenticated_trolley, "number_of_photos")}
                color="error"
              >
                <ShoppingCartIcon />
              </Badge>
            </>
            {/* {trolley_photograph_id === lastTrolleyPhotograph &&
               <>
                 <Button
                   variant="contained"
                   onClick={goToCheckoutPage}
                   className={classes.checkoutBtn}
                   endIcon={
                     <Badge badgeContent={get(authenticated_trolley, "number_of_photos")} color="error" className={classes.checkoutBtnBadge}>
                       <ShoppingCartIcon/>
                     </Badge>}>
                   Checkout
                 </Button>
               </>
              } */}
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};

export default TopBar;

import get from "lodash/get";
import React from "react";
import TextField from "@material-ui/core/TextField";
import { Error } from "./Error";

export const InputField = ({
  name,
  placeholder,
  formProps,
  inputProps,
  ...props
}) => {
  const {
    register,
    formState: { errors },
  } = formProps;
  const error = get(errors, name);

  return (
    <>
      <TextField
        {...register(name)}
        fullWidth
        variant="standard"
        placeholder={placeholder}
        {...props}
        InputProps={
          inputProps || {
            style: {
              height: 50,
            },
          }
        }
      />
      <Error>{get(error, "message")}</Error>
    </>
  );
};

import React from "react";
import styles from "./NavItem.module.css";
import { cssStyles, PropMixins } from "../mixins";
import { useResponsiveBreakpoints } from "../primitives/useResponsiveBreakpoints";

/**
 * NavItem
 * @returns
 */
const NavItem = ({
  children,
  ...props
}: React.PropsWithChildren<{}> & PropMixins) => {
  const activeBreakpoint = useResponsiveBreakpoints();
  return (
    <nav
      className={styles.navitem}
      style={{
        ...cssStyles(props, activeBreakpoint),
      }}
    >
      {children}
    </nav>
  );
};

export default NavItem;

const initialState = {
  defaultPhotographerEmail: "info@foodlinkmarket.co.za",
  message: false,
};

export default function UserReducer(state = initialState, action) {
  switch (action.type) {
    case "BOOKBOSS/SENDMAIL":
      return {
        ...state,
        message: action.message,
      };
    default:
      return state;
  }
}

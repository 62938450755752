import { ACTION_FORCE_MAP_STATE } from '../v2_actions/map'

const initialState = {
    mapInfo: { lat: null, lng: null },
    showModal: false,
    showSearchBox: false,
    clearTime: true,
    mapLink: `https://maps.googleapis.com/maps/api/js?key=${window.LOCAL_SETTINGS.GOOGLE_MAP_KEY}&v=3.exp&libraries=geometry,drawing,places`,
    forcedNewMapState: null
}


export default function Gallery(state = initialState, action) {
  switch (action.type) {
    case "MAPINFO/SELECTMARKER":
      return {
        ...state,
        mapInfo: action.mapInfo,
      };
    case "MAPINFO/SHOWMODAL":
      return {
        ...state,
        showModal: action.showModal,
      };
    case "MAPINFO/SHOWSEARCHBOX":
      return {
        ...state,
        showSearchBox: action.flag,
      };
    case "MAPINFO/SETMAPCLEARTIME":
      return {
        ...state,
        clearTime: action.clearTime,
      };
  case ACTION_FORCE_MAP_STATE:
      return Object.assign({}, state, {forcedNewMapState: {lat: action.lat, lng: action.lng, zoom: action.zoom, bounds: action.bounds}})
    default:
      return state;
  }
}

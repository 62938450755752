import { ItemList } from "../orm";

class DropdownOptions extends ItemList {
  constructor(dropdown_name) {
    super(`customer_options__${dropdown_name}`);
    this.url = `customer/dropdown_options/${dropdown_name}`;
  }

  getEntityKey() {
    return this.url;
  }
}

export const countryDropdownOptions = new DropdownOptions("countries");
export const sportTagsDropdownOptions = new DropdownOptions("sport_tags");
export const activityTagsDropdownOptions = new DropdownOptions(
  "get_all_activity_tags",
);
export const genreTagsDropdownOptions = new DropdownOptions("genre_tags");
export const sessionNameDropdownOptions = new DropdownOptions("session_names");
export const photographerDropdownOptions = new DropdownOptions("photographers");
export const galleryEventDropdownOptions = new DropdownOptions(
  "gallery_events",
);

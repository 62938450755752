import last from "lodash/last";
import isEmpty from "lodash/isEmpty";
import head from "lodash/head";
import get from "lodash/get";
import first from "lodash/first";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {
  CUSTOM_VALUE_OPEN_DIGITAL_PRICES_DIALOG,
  getCustomValue,
  setCustomValue,
} from "../../v2_actions/custom_value";
import {
  Convert12hrTimeTo24hrTime,
  DEFAULT_TIME_END_12HR,
  DEFAULT_TIME_END_24HR,
  DEFAULT_TIME_START_12HR,
  DEFAULT_TIME_START_24HR,
} from "../../v2_actions/time";
import { Global, css } from "@emotion/react";
import {
  basePhotographList,
  photographList,
} from "../../v2_actions/customer_photograph";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useLayoutEffect, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import BibNumber from "../../v2_components/BibNumber";
import CachedIcon from "@mui/icons-material/Cached";
import FaceIcon from "@mui/icons-material/Face";
import ThirtyFpsSelectIcon from "@mui/icons-material/ThirtyFpsSelect";
import Looks6Icon from "@mui/icons-material/Looks6";
import FacialRecognition from "../../v2_components/FacialRecognition";
import FilterFramesIcon from "@mui/icons-material/FilterFrames";
import GalleryPhotographsLayout from "./GalleryPhotographsLayout";
import LaptopIcon from "@mui/icons-material/Laptop";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import RangeSlider from "../../v2_components/form/TimeRangeSlider";
import ReusableTabs from "../../v2_components/TabPanel";
import SideBar from "../../layout/sidebar";
import TopBar from "../../views/galleryphotographs/topbar";
import { galleryList } from "../../v2_actions/gallery";
import { makeLogger } from "../../utils/o11y/logger";
import { makeStyles } from "@material-ui/core";
import moment from "moment";
import style from "../../views/galleryphotographs/styles";
import { trolleyList } from "../../v2_actions/trolley";
import { useIsMobile } from "../../actions/ui";
import DigitalPricesDialog from "../../views/galleryphotographs/DigitalPricesDialog";

const logger = makeLogger({
  enabled: true,
  label: "GalleryPhotographsParent2",
  color: "blue",
});

const useStyles = makeStyles(style);

const global_styles = css`
  div.MuiAccordion-root {
    font-size: 12px;
    background: #f4f4f4;
  }
  div.MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: inherit;
  }
  div.MuiAccordion-root {
    box-shadow: none;
  }
  div.MuiAccordionSummary-root {
    padding-left: 0px;
    background: #f4f4f4;
  }
  div.MuiAccordionSummary-content {
    left: 35px;
    position: absolute;
  }
  div.MuiAccordionSummary-expandIconWrapper {
    left: 0px;
    position: absolute;
  }
  div.MuiAccordionSummary-expandIconWrapper svg {
    width: 25px;
    height: 24px;
  }
`;

const GalleryPhotographsParent2 = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const isMobile = useIsMobile();
  const { galleryId } = useParams();
  const [tabValue, setTabValue] = useState(0);
  const [width] = useState(window.innerWidth);
  const [bibNumber, setBibNumber] = useState();
  const [showMethod, setShowMethod] = useState(false);
  const [isFacialRecognitionFilterActive, setIsFacialRecognitionFilterActive] =
    useState(false);
  const [showSideBar, setShowSideBar] = useState(false);
  const [submittedBibNumber, setSubmittedBibNumber] = useState();
  const [accordianExpanded, setAccordianExpanded] = useState(false);
  const galleries = useSelector(() => galleryList.getVisibleObjects());
  const photographs = useSelector(() => photographList.getVisibleObjects());
  const gallery = head(galleries);
  const customerFaceToken = get(
    basePhotographList.getFilter(),
    "customer_face_token",
  );

  const digitalPricesSentinel = useRef();
  const openDigitalPricesDialog = useSelector(
    (state) =>
      getCustomValue({
        state,
        name: CUSTOM_VALUE_OPEN_DIGITAL_PRICES_DIALOG,
      }) || false,
  );
  const setOpenDigitalPricesDialog = (sentinelIsInView) => {
    dispatch(
      setCustomValue({
        name: CUSTOM_VALUE_OPEN_DIGITAL_PRICES_DIALOG,
        value: !sentinelIsInView,
      }),
    );
  };

  useLayoutEffect(() => {
    const scrollContainer = document.querySelector(".gallery-scroll-container");
    const digitalPricesObserver = new IntersectionObserver(
      ([entry]) => {
        setOpenDigitalPricesDialog(entry.isIntersecting);
      },
      { root: document, rootMargin: "1000px 0px" },
    );
    digitalPricesObserver.observe(digitalPricesSentinel.current);
    return () => digitalPricesObserver.disconnect();
  }, [digitalPricesSentinel]);

  const [showClearFiltersButton, setShowClearFiltersButton] = useState(false);
  const [clearFiltersWasClicked, setClearFiltersWasClicked] = useState(false);
  const [bibNumberFilterHasInput, setBibNumberFilterHasInput] = useState(false);
  const [expandActiveMobileTabFilter, setExpandActiveMobileTabFilter] =
    useState(true);
  const [
    mayPerformBibNumberIndexingOfGalleryPhotographs,
    setMayPerformBibNumberIndexingOfGalleryPhotographs,
  ] = useState(false);
  const [
    mayPerformFacialRecognitionIndexingOfGalleryPhotographs,
    setMayPerformFacialRecognitionIndexingOfGalleryPhotographs,
  ] = useState(false);
  const [timeOfActivity, setTimeOfActivity] = useState({
    start: DEFAULT_TIME_START_12HR,
    end: DEFAULT_TIME_END_12HR,
  });

  const [timeOfActivity24HourFormat, setTimeOfActivity24HourFormat] = useState({
    start: DEFAULT_TIME_START_24HR,
    end: DEFAULT_TIME_END_24HR,
  });

  const minTimeOfActivity = get(
    first(get(gallery, "photographs")),
    "photograph_taken_at",
  );
  const maxTimeOfActivity = get(
    last(get(gallery, "photographs")),
    "photograph_taken_at",
  );
  const galleryPhotographFiltersArePristine =
    !customerFaceToken &&
    !bibNumber &&
    timeOfActivity24HourFormat.start === DEFAULT_TIME_START_24HR &&
    timeOfActivity24HourFormat.end === DEFAULT_TIME_END_24HR;

  useEffect(() => {
    if (galleryId) {
      dispatch(
        galleryList.updateListFilter({
          db_id: galleryId,
        }),
      );
      dispatch(
        galleryList.fetchListIfNeeded({
          callSource: "GalleryPhotographsParent2",
        }),
      );
      submitFilters("FX:galleryId");
    }
  }, [galleryId]);

  useEffect(() => {
    logger.log("FX customerFaceToken", {
      galleryId,
      customerFaceToken,
      timeOfActivity,
      photographsEmpty: isEmpty(photographs),
      galleryPhotographFiltersArePristine,
    });
    if (customerFaceToken) {
      submitFilters("FX:customerFaceToken");
    }
  }, [customerFaceToken]);

  useEffect(() => {
    dispatch(trolleyList.invalidateList());
    dispatch(trolleyList.ensureTrolleyLoaded());
    return () => {
      setMayPerformFacialRecognitionIndexingOfGalleryPhotographs(false);
      clearFilters();
    };
  }, []);

  useEffect(() => {
    const bibNumberIsPristine = !submittedBibNumber;
    const timeOfActivityIsPristine =
      timeOfActivity24HourFormat.start === DEFAULT_TIME_START_24HR &&
      timeOfActivity24HourFormat.end === DEFAULT_TIME_END_24HR;
    const facialRecognitionIsPristine = !customerFaceToken;
    if (
      bibNumberIsPristine &&
      timeOfActivityIsPristine &&
      facialRecognitionIsPristine &&
      !isFacialRecognitionFilterActive
    ) {
      setShowClearFiltersButton(false);
    } else {
      setShowClearFiltersButton(true);
    }
  }, [
    timeOfActivity24HourFormat,
    submittedBibNumber,
    isFacialRecognitionFilterActive,
    customerFaceToken,
  ]);

  useEffect(() => {
    if (isFacialRecognitionFilterActive) {
      setTimeOfActivity({
        start: DEFAULT_TIME_START_12HR,
        end: DEFAULT_TIME_END_12HR,
      });
    }
  }, [isFacialRecognitionFilterActive, customerFaceToken]);

  logger.log("render()", customerFaceToken);

  const submitFilters = async (source) => {
    logger.log("submitFilters", { source, customerFaceToken });
    await dispatch(photographList.invalidateList());
    await dispatch(photographList.clearListFilter());
    let filter = {};
    if (customerFaceToken || bibNumber) {
      filter = {
        time_end: get(timeOfActivity24HourFormat, "end", "23:59"),
        time_start: get(timeOfActivity24HourFormat, "start", "0:00"),
        bib_number: bibNumber,
        probable_customer_face_token: customerFaceToken,
        gallery_db_id: get(gallery, "gallery_event")
          ? null
          : get(gallery, "db_id"),
        gallery_event_id: get(gallery, "gallery_event")
          ? get(gallery, "gallery_event")
          : null,
        shot_at_before: moment(get(gallery, "shot_at_datetime"))
          .add(2, "days")
          .format(),
        shot_at_after: moment(get(gallery, "shot_at_datetime"))
          .subtract(2, "days")
          .format(),
      };
    } else {
      filter = {
        gallery_db_id: galleryId,
        time_end: get(timeOfActivity24HourFormat, "end", "23:59"),
        time_start: get(timeOfActivity24HourFormat, "start", "0:00"),
        probable_customer_face_token: undefined,
      };
    }
    submitFiltersSend(filter);
  };
  const submitFiltersSend = async (filter) => {
    logger.log("submitFiltersSend", filter);
    await dispatch(photographList.updateListFilter(filter));
    await dispatch(
      photographList.fetchListIfNeeded({
        callSource: "GalleryPhotographsParent2",
      }),
    );
  };

  const timeChangeHandler = (time) => setTimeOfActivity(time);

  const onChangeBibNumber = (event) => setBibNumber(event.target.value);

  const onSubmitBibNumber = () => {
    setBibNumberFilterHasInput(true);
    setSubmittedBibNumber(bibNumber);
    setExpandActiveMobileTabFilter(false);

    submitFilters("onSubmitBibNumber");
  };

  const handleTabChange = (event, newValue) => setTabValue(newValue);

  const triggerFacialRecognitionPhotographIndexing = () => {
    setMayPerformFacialRecognitionIndexingOfGalleryPhotographs(true);
  };

  const triggerBibGalleryPhotographIndexing = () => {
    setMayPerformBibNumberIndexingOfGalleryPhotographs(true);
  };

  const changeCompleteHandler = (time) => {
    !isMobile && setTimeOfActivity24HourFormat(Convert12hrTimeTo24hrTime(time));
  };

  const handleAccordianTabChange = (panel) => (event, isExpanded) => {
    setAccordianExpanded(isExpanded ? panel : false);
  };

  const onClickSetTime = () => {
    setTimeOfActivity24HourFormat(Convert12hrTimeTo24hrTime(timeOfActivity));
    setExpandActiveMobileTabFilter(false);
    submitFilters("onClickSetTime");
  };

  const onCancelTimeOfActivityFilter = () => {
    setTimeOfActivity({
      start: DEFAULT_TIME_START_12HR,
      end: DEFAULT_TIME_END_12HR,
    });
    setTimeOfActivity24HourFormat({
      start: DEFAULT_TIME_START_24HR,
      end: DEFAULT_TIME_END_24HR,
    });
    setExpandActiveMobileTabFilter(false);
  };

  const clearFilters = async () => {
    logger.log("clearFilters");
    setBibNumber("");
    setIsFacialRecognitionFilterActive(false);
    setSubmittedBibNumber("");
    setClearFiltersWasClicked(true);
    setTimeOfActivity({
      start: DEFAULT_TIME_START_12HR,
      end: DEFAULT_TIME_END_12HR,
    });
    setTimeOfActivity24HourFormat({
      start: DEFAULT_TIME_START_24HR,
      end: DEFAULT_TIME_END_24HR,
    });
    setExpandActiveMobileTabFilter(false);
    await dispatch(photographList.clearListFilter());
    await dispatch(basePhotographList.clearListFilter());
    await dispatch(photographList.invalidateList());
    await submitFiltersSend({
      gallery_db_id: galleryId,
      time_end: get(timeOfActivity24HourFormat, "end", "23:59"),
      time_start: get(timeOfActivity24HourFormat, "start", "0:00"),
      probable_customer_face_token: undefined,
    });
  };
  const renderFacialRecognitionFilter = () => {
    return (
      <Stack direction={{ xs: "column", sm: "row" }}>
        <FacialRecognition
          gallery={gallery}
          setIsFacialRecognitionFilterActive={
            setIsFacialRecognitionFilterActive
          }
          clearFiltersWasClicked={clearFiltersWasClicked}
          setClearFiltersWasClicked={setClearFiltersWasClicked}
          expandActiveMobileTabFilter={expandActiveMobileTabFilter}
          setExpandActiveMobileTabFilter={setExpandActiveMobileTabFilter}
          mayPerformFacialRecognitionIndexingOfGalleryPhotographs={
            mayPerformFacialRecognitionIndexingOfGalleryPhotographs
          }
          setMayPerformFacialRecognitionIndexingOfGalleryPhotographs={
            setMayPerformFacialRecognitionIndexingOfGalleryPhotographs
          }
        />
      </Stack>
    );
  };

  const renderBibNumberFilter = () => {
    return (
      <BibNumber
        gallery={gallery}
        bibNumber={bibNumber}
        onSubmitBibNumber={onSubmitBibNumber}
        onChangeBibNumber={onChangeBibNumber}
        mayPerformBibNumberIndexingOfGalleryPhotographs={
          mayPerformBibNumberIndexingOfGalleryPhotographs
        }
      />
    );
  };

  const renderTimeOfActivityFilter = () => {
    // logger.log("renderTimeOfActivityFilter", {
    //   timeOfActivity,
    //   minTimeOfActivity,
    //   maxTimeOfActivity,
    // });
    return (
      <Stack direction={{ xs: "column", sm: "row" }}>
        <RangeSlider
          value={timeOfActivity}
          minValue={minTimeOfActivity}
          maxValue={maxTimeOfActivity}
          onClickSetTime={onClickSetTime}
          handleChange={timeChangeHandler}
          isFacialRecognitionFilterActive={isFacialRecognitionFilterActive}
          changeCompleteHandler={changeCompleteHandler}
          onClickCancelRangeSlider={onCancelTimeOfActivityFilter}
        />
      </Stack>
    );
  };

  const renderDigitalPrices = () => {
    return <Typography>do renderDigitalPrices</Typography>;
  };

  const renderPrintingAndFramingPrices = () => {
    return <Typography>do renderPrintingAndFramingPrices</Typography>;
  };

  const sideMenuFilters = [
    {
      icon: <PersonSearchIcon />,
      for_facial_recognition: true,
      heading: "Facial Recognition",
      body: renderFacialRecognitionFilter(),
    },
    {
      icon: <Looks6Icon />,
      for_bib_recognition: true,
      heading: "Bib number",
      body: renderBibNumberFilter(),
    },
    {
      icon: <AccessTimeIcon />,
      heading: "Time of Activity",
      body: renderTimeOfActivityFilter(),
    },
  ];

  const sideMenuPricing = [
    {
      icon: <LaptopIcon />,
      heading: "Digital Prices",
      body: renderDigitalPrices(),
    },
    {
      icon: <FilterFramesIcon />,
      heading: "Printing & Framing",
      body: renderPrintingAndFramingPrices(),
    },
  ];

  const mobileTabPanelColumns = [
    {
      icon: <AccessTimeIcon />,
      title: "Activity Time",
    },
    {
      icon: <FaceIcon />,
      title: "Facial Recognition",
    },
    {
      icon: <ThirtyFpsSelectIcon />,
      title: "Bib Number",
    },
  ];

  const renderMobileGalleryPhotographsParentView = () => {
    return (
      <Box>
        <ReusableTabs
          scrollButtons
          variant="scrollable"
          columns={mobileTabPanelColumns}
          expandActiveMobileTabFilter={expandActiveMobileTabFilter}
          setExpandActiveMobileTabFilter={setExpandActiveMobileTabFilter}
          rows={[
            renderTimeOfActivityFilter(),
            renderFacialRecognitionFilter(),
            renderBibNumberFilter(),
            <p>Other Filters</p>,
          ]}
        />
      </Box>
    );
  };

  return (
    <>
      <Global styles={global_styles} />
      <SideBar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
      <div
        onContextMenu={(e) => {
          e.preventDefault();
        }}
        className={
          !showSideBar
            ? `${classes.nonMennuWrapper} ${classes.nonMennuWrapperForGalleryPhotographs}`
            : classes.menuWrapper
        }
      >
        <div className={classes.contentContainer + " gallery-scroll-container"}>
          <div className={classes.content}>
            {isMobile && (
              <>
                <TopBar
                  gallery={gallery}
                  showSideBar={showSideBar}
                  setShowSideBar={setShowSideBar}
                  galleryId={galleryId}
                  showMethod={showMethod}
                  setShowMethod={setShowMethod}
                  width={width}
                />
                {renderMobileGalleryPhotographsParentView()}
              </>
            )}
            <div
              ref={digitalPricesSentinel}
              style={{
                height: "1px",
                width: "0px",
              }}
            ></div>
            <Box className={classes.galleryPhotographsLayout}>
              <GalleryPhotographsLayout
                gallery={gallery}
                enableTrolleyIcon={true}
                galleryPhotographFiltersArePristine={
                  galleryPhotographFiltersArePristine
                }
                mobiClearFiltersButton={
                  <Button
                    px={5}
                    pb={1}
                    onClick={clearFilters}
                    startIcon={<CachedIcon />}
                    className={classes.mobiClearFiltersButton}
                  >
                    <p>Clear filters</p>
                  </Button>
                }
              />
            </Box>
            {isMobile && openDigitalPricesDialog && (
              <DigitalPricesDialog
                gallery={gallery}
                openDigitalPricesDialog={openDigitalPricesDialog}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default GalleryPhotographsParent2;

import React from "react";
import { makeStyles } from "@material-ui/core";
import {
  getMobMaxWidthPixels,
  getTabMinWidthPixels,
  getTabMaxWidthPixels,
} from "./layout/mobile";
import { GoogleGalleryMap } from "./map/GoogleGalleryMap";

const useStyles = makeStyles({
  map: {
    position: "sticky",
    top: "0",
    height: "50vh",
    zIndex: "99",
    [`@media only screen and (min-width:${getTabMinWidthPixels()})and (max-width: ${getTabMaxWidthPixels()})`]:
      {
        width: "50%",
      },
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      zIndex: "0",
      width: "100%",
      marginLeft: "1px",
      position: "sticky",
    },
    "& p": {
      position: "absolute",
      marginTop: "10px",
      backgroundColor: "white",
      zIndex: "1",
      left: "50%",
      fontSize: "15px",
      transform: "translateX(-50%)",
      paddingTop: "5px",
      paddingBottom: "5px",
      color: "gray",
      paddingLeft: "20px",
      paddingRight: "20px",
      [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
        fontSize: "11px !important",
      },
    },
  },
});

export const GalleryMap = () => {
  const classes = useStyles();

  return (
    <div className={classes.map}>
      <GoogleGalleryMap />
    </div>
  );
};

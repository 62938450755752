import * as React from 'react'
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'

export const LinearIndeterminateProgressLoader = () => {
  return (
    <Box
      mt={ 2 }
      sx={{ width: '100%' }}
    >
      <LinearProgress />
    </Box>
  )
}
import get from "lodash/get";
import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import { makeStyles } from "@material-ui/core";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { getMobMaxWidthPixels } from "./layout/mobile";
import { CurrencyValue } from "./CurrencyValue";
import { AddToTrolleyButton } from "./AddToTrolleyButton";
import { useIsMobile } from "../actions/ui";

const useStyles = makeStyles({
  card: {
    cursor: "pointer",
    position: "relative",
    "& img": {
      width: "240px",
      height: "200px",
      margin: "1px",
      padding: "0px",
      objectFit: "cover",
    },
    marginBottom: "20px",
    [`@media only screen and (min-width:${getMobMaxWidthPixels()})`]: {
      height: "200px",
      "& img": {
        border: "solid #c4c4c4",
      },
    },
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      width: "100%",
      marginBottom: "0px",
      "& img": {
        width: "100%",
        marginLeft: "-0.5px",
        height: "100%",
        objectFit: "contain",
        border: "transparent",
      },
    },
  },
  card_with_no_image: {
    background: "#c4c4c4",
    width: "240px",
    height: "200px",
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      width: "100%",
    },
  },
  coming_soon_text: {
    top: 50,
    fontSize: 16,
    width: "100%",
    textAlign: "center",
    position: "absolute",
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {},
  },
  photo_art_card: {
    width: "100%",
    height: "100%",
    margin: "auto",
    display: "flex",
    aspectRatio: "3/2",
    overflow: "hidden",
    textAlign: "center",
    marginBottom: "0px",
    justifyContent: "center",
    "& img": {
      width: "100%",
      height: "100%",
      maxWidth: "100%",
      maxHeight: "100%",
      objectFit: "cover",
      border: "none",
    },
  },
  eventsCardWrapper: {
    margin: "auto",
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      width: "100%",
      height: "100%",
      minHeight: "100%",
      maxHeight: "100%",
      aspectRatio: "10/8",
      "& img": {
        border: "2px solid #c4c4c4",
        width: "100%",
        height: "100%",
        maxWidth: "100%",
        maxHeight: "100%",
        objectFit: "cover",
      },
      "&.cardtype-gallery-event img": {
        objectFit: "contain",
      },
    },
  },
  title: {
    backgroundColor: "white",
    position: "absolute",
    width: "200px",
    textAlign: "center",
    fontSize: "13px",
    border: "2px solid",
    borderRadius: "10px",
    bottom: "15px",
    margin: "0px 20px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    left: 0,
    right: 0,
    margin: "auto",
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      width: "calc(100% - 40px)",
      position: "absolute",
      "& p": {
        fontSize: "12px!important",
      },
    },
  },
  albumTitle: {
    color: "#fff",
    backgroundColor: "#0c58d3",
    position: "absolute",
    width: "200px",
    textAlign: "center",
    fontSize: "13px",
    border: "2px solid",
    borderRadius: "10px",
    top: 15,
    margin: "0px 20px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    left: 0,
    right: 0,
    margin: "auto",
    "& p": {
      fontWeight: 800,
      padding: "0px 2px",
    },
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      width: "calc(100% - 40px)",
      position: "absolute",
    },
  },
  innerLabelWrapper: {
    position: "absolute",
    width: "90%",
    margin: "auto",
    justifyContent: "space-between",
    alignItems: "baseline",
    zIndex: 9,
    top: 20,
  },
  photoIsFavoriteWrapper: {
    color: "#fff",
    textAlign: "center",
    borderRadius: "20px",
    padding: "3px 5px 0px",
    cursor: "pointer",
    backgroundColor: "rgb(96 96 96 / 50%)",
  },
  photoIsFavorite: {
    color: "red",
  },
  galleryPriceWrapper: {
    width: 115,
    color: "#fff",
    textAlign: "center",
    borderRadius: "20px",
    backgroundColor: "rgb(96 96 96 / 50%)",
  },
  shoppingCartButtonWrapper: {
    color: "#fff",
    textAlign: "center",
    borderRadius: "20px",
    padding: "3px 5px 0px",
    cursor: "pointer",
    backgroundColor: "rgb(96 96 96 / 50%)",
  },
  defaultSrc: {
    width: "100%",
    border: "none",
    height: "100%",
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "contain",
  },
});

export const GalleryCardWrapper = ({
  tab,
  image,
  title,
  gallery,
  onClick,
  albumTitle,
  photo_art_gallery_view,
  cardType,
}) => {
  const classes = useStyles();
  const isMobile = useIsMobile();
  const [imageHasLoaded, setImageHasLoaded] = useState(false);
  const [photoIsFavorite, setPhotoIsFavorite] = useState(false);
  const desktopConditionToRenderCardWithNoImage = !image;
  const mobileConditionToRenderCardWithNoImage = !imageHasLoaded && isMobile;

  const onImageHasLoaded = (e) => {
    if (e.target) {
      setImageHasLoaded(true);
    }
  };

  const renderInnerLabel = () => {
    return (
      <Stack direction="row" className={classes.innerLabelWrapper}>
        <div className={classes.photoIsFavoriteWrapper}>
          {!photoIsFavorite && (
            <FavoriteBorderIcon onClick={() => setPhotoIsFavorite(true)} />
          )}
          {photoIsFavorite && (
            <FavoriteIcon
              className={classes.photoIsFavorite}
              onClick={() => setPhotoIsFavorite(false)}
            />
          )}
        </div>
        {gallery.gallery_price && (
          <div className={classes.galleryPriceWrapper}>
            <p>
              from{" "}
              <CurrencyValue
                value={get(gallery, ["gallery_price", "base_price"])}
                country={gallery.gallery_price.country}
              />
            </p>
          </div>
        )}
        <div className={classes.shoppingCartButtonWrapper}>
          <AddToTrolleyButton
            variant="icon"
            gallery={gallery}
            pid={get(gallery, "first_gallery_photograph_ref")}
          />
        </div>
      </Stack>
    );
  };

  return (
    <>
      <div
        onClick={onClick}
        className={`${classes.card} cardtype-${cardType ?? "unknown"} ${tab === "events" ? classes.eventsCardWrapper : ""} ${tab === "photo_art_genre" ? classes.photo_art_card : ""} ${tab === "photo_art_belonging_to_genre" ? classes.photo_art_card : ""} ${mobileConditionToRenderCardWithNoImage ? classes.card_with_no_image : ""} ${desktopConditionToRenderCardWithNoImage ? classes.card_with_no_image : ""}`}
      >
        {!image && <p className={classes.coming_soon_text}>Coming Soon</p>}
        {photo_art_gallery_view && renderInnerLabel()}
        {!isMobile && image && <img src={image} draggable="false" />}
        {isMobile && image && (
          <img
            src={image}
            draggable="false"
            onLoad={(e) => onImageHasLoaded(e)}
          />
        )}
        {tab !== "photo_art_belonging_to_genre" && title && (
          <div className={classes.title}>
            <p>{title}</p>
          </div>
        )}
        {tab !== "photo_art_belonging_to_genre" && albumTitle && (
          <div className={classes.albumTitle}>
            <p>{albumTitle}</p>
          </div>
        )}
      </div>
    </>
  );
};

import size from "lodash/size";
import get from "lodash/get";
import { ItemList } from "../orm";
import { showError } from "../actions/Error";

class CustomerImageWithCutoutList extends ItemList {
  getEntityKey() {
    return "customer/customer_image_with_cutout";
  }

  getCutoutAsPoints = (imageWithCutout) => {
    return [
      {
        x: get(imageWithCutout, "top_left_pixel_x", 0),
        y: get(imageWithCutout, "top_left_pixel_y", 0),
      },
      {
        x: get(imageWithCutout, "top_right_pixel_x", 0),
        y: get(imageWithCutout, "top_right_pixel_y", 0),
      },
      {
        x: get(imageWithCutout, "bottom_right_pixel_x", 0),
        y: get(imageWithCutout, "bottom_right_pixel_y", 0),
      },
      {
        x: get(imageWithCutout, "bottom_left_pixel_x", 0),
        y: get(imageWithCutout, "bottom_left_pixel_y", 0),
      },
    ];
  };

  getCutoutFromPoints = (points) => {
    if (size(points) < 4) {
      showError("Please select a quadrilateral", "A closed shape of points");
    }

    return {
      top_left_pixel_x: points[0].x,
      top_left_pixel_y: points[0].y,
      top_right_pixel_x: points[1].x,
      top_right_pixel_y: points[1].y,
      bottom_right_pixel_x: points[2].x,
      bottom_right_pixel_y: points[2].y,
      bottom_left_pixel_x: points[3].x,
      bottom_left_pixel_y: points[3].y,
    };
  };
}

export const customerImageWithCutoutList = new CustomerImageWithCutoutList(
  "customer_image_with_cutout__default",
);

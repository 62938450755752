export const styles = {
  filterListTitle: { fontSize: "14px", fontWeight: "normal" },
  filterList: { bgcolor: "background.paper", padding: "0px", marginTop: "4px" },
  filterListItem: {
    border: "solid 1px #ddd",
    borderRadius: "3px",
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingTop: 0,
    fontSize: "12px",
    marginBottom: "10px",
  },
  filterListItemText: {
    marginTop: "0px",
    marginBottom: "0px",
    paddingTop: "4px",
    paddingBottom: "4px",
    "& *": {
      fontSize: "0.85rem!important",
    },
  },
};

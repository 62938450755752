import { ItemList } from '../orm'

class RevolutTrolley extends ItemList {
    getEntityKey() {
        return "customer/purchased_trolley/prepare_revolut_order"
    }

    prepareOrder({trolley_id}) {
        return this.saveNewObject({trolley:trolley_id})
    }
    
}

export const revolutTrolley = new RevolutTrolley("revolut_trolley_default")



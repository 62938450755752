import React from "react"
import {
  Box,
  Grid,
  Typography,
  makeStyles,
  FormLabel,
  Checkbox,
  Avatar,
} from "@material-ui/core";
import style from "../views/editprofile/styles";

const useStyles = makeStyles(style)

export const FacialRecognitionTechnology = () => {
  const classes = useStyles()

  return (
      <Grid container>
        <Grid item xs>
            <FormLabel className={classes.label}>
              Facial Recognition Technology
            </FormLabel>
          </Grid>
          <Grid item md={12} xs={12}>
            <Box htmlFor="upload-photo">
              <label>
                <input
                  style={{ display: "none" }}
                  id="upload-photo"
                  name="upload-photo"
                  type="file"
                />
                <div
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    color: "#d1d6db",
                    fontSize: 20,
                  }}
                >
                  {" "}
                  &nbsp;&nbsp;&nbsp; Drag and Drop A picture of yourself here
                </div>
              </label>
              <p>
                Uploading a picture will help our Facial Recognition Software
                find pictures of you.
              </p>
              <Box display="flex" alignItems="center">
                <Checkbox />
                <Typography>
                  I want BossFotos to use facial recognition software to help
                  find Fotos of me
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
  )
}
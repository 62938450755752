import get from "lodash/get";
import moment from "moment";

export const DEFAULT_TIME_END_24HR = "23:59";
export const DEFAULT_TIME_START_24HR = "0:00";
export const DEFAULT_TIME_END_12HR = "11:59pm";
export const DEFAULT_TIME_START_12HR = "12:00am";

const AmToPm = (am_time) => moment(am_time, ["h:mm A"]).format("HH:mm");

export const convertDBTimeTo12HrTime = (dbTime) =>
  moment(dbTime).utc().format("LT");

export const displayTimeAs12hourHHMM = (time) =>
  moment(time, ["h:mm A"]).format("hh:mm A");

export const subtractMinutesToTime = (minutes = 10, time) =>
  moment(time).subtract(moment.duration(minutes, "minutes"));

export const addMinutesToTime = (minutes = 10, time) =>
  moment(time).add(moment.duration(minutes, "minutes"));

export const Convert12hrTimeTo24hrTime = (start_and_end_time_object) => {
  let confirmedTimeStart;
  let confirmedTimeEnd;
  const time = start_and_end_time_object;
  if (get(time, ["start", "am_pm"])) {
    const timeStart = `${time.start.hours}.${time.start.minutes}${time.start.am_pm}`;
    const timeEnd = `${time.end.hours}.${time.end.minutes}${time.end.am_pm}`;
    confirmedTimeStart = AmToPm(timeStart);
    confirmedTimeEnd = AmToPm(timeEnd);
  } else {
    confirmedTimeStart = AmToPm(start_and_end_time_object.start);
    confirmedTimeEnd = AmToPm(start_and_end_time_object.end);
  }

  return { start: confirmedTimeStart, end: confirmedTimeEnd };
};

import { combineReducers } from "redux";
import userReducer from "./userReducer";
import authReducer from "./auth";
import mapReducer from "./mapReducer";
import galleryReducer from "./galleryReducer";
import trolleyReducer from "./trolleyReducer";
import bookbossReducer from "./bookbossReducer";
import customValueReducer from "./customValueReducer";
import { ORM_LIST_KEY, ORM_OBJECT_KEY } from '../orm'
import orm_list_reducer from '../orm/orm_list_reducer'
import orm_general_reducer from '../orm/orm_general_reducer'
import orm_objects_reducer from '../orm/orm_objects_reducer'

const appReducer = combineReducers({
    [ORM_OBJECT_KEY]: orm_objects_reducer,
    [ORM_LIST_KEY]: orm_list_reducer,
    orm_general_reducer,
    userReducer,
    authReducer,
    mapReducer,
    galleryReducer,
    trolleyReducer,
    bookbossReducer,
    customValueReducer
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;

import * as React from "react";

import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

import { css, Global } from "@emotion/react";
import { makeStyles } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import LoadingSpinner from "../views/components/LoadingSpinner";

const useStyles = makeStyles((theme) => ({
  alertDialogHeading: {
    color: "#000",
    fontWeight: 800,
    textAlign: "center",
    [theme.breakpoints.down("md")]: {},
  },
}));

const global_styles = css`
  h2.MuiDialogTitle-root {
    font-size: 16px;
    font-weight: 800;
  }
  div.MuiDialog-paperScrollPaper {
    border-radius: 15px;
    border: solid #000 1px;
  }
`;

export default function AlertDialog({
  body,
  heading,
  actions,
  openAlertDialog,
  setOpenAlertDialog,
  allowClose = true,
  disablePortal = true,
}) {
  const classes = useStyles();

  const handleClose = () => {
    allowClose && setOpenAlertDialog(false);
  };

  return (
    <div>
      <Global styles={global_styles} />
      <Dialog
        keepMounted
        onClose={handleClose}
        open={openAlertDialog}
        disablePortal={disablePortal}
      >
        <DialogTitle className={classes.alertDialogHeading}>
          {heading}
          {allowClose && (
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                top: 8,
                right: 8,
                position: "absolute",
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </DialogTitle>
        {body && (
          <DialogContent>
            {!allowClose && <LoadingSpinner />}
            <DialogContentText>{body}</DialogContentText>
          </DialogContent>
        )}
        {actions && <DialogActions>{actions}</DialogActions>}
      </Dialog>
    </div>
  );
}

import upperCase from "lodash/upperCase";
import axios from "axios";

const makeHeader = () => {
  let token = localStorage.getItem("token");
  let config = {
    headers: {
      Authorization: "Token " + token,
      "X-BOSSFOTOS-AUTHENTICATION-TOKEN": "Token " + token,
    },
  };
  return config;
};

export const getPriceByPid = async (pid) => {
  return await axios.get(
    window.LOCAL_SETTINGS.API_BASE +
      "/api/getpricebycustomer/get_price_by_pid/?pid=" +
      pid,
    makeHeader(),
  );
};

export const getCountryDefaultValue = async (country) => {
  return await axios.get(
    window.LOCAL_SETTINGS.API_BASE +
      "/api/getpricebycustomer/get_country_value/?country=" +
      upperCase(country),
    makeHeader(),
  );
};

export const getAllPhotoGrapher = async () => {
  return await axios.get(
    window.LOCAL_SETTINGS.API_BASE + "/api/getallphotographer/",
    makeHeader(),
  );
};

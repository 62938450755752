import React from "react"
import Grid from "@mui/material/Grid"
import Dialog from "@mui/material/Dialog"
import Button from "@mui/material/Button"
import { css, Global } from "@emotion/react"
import Typography from "@mui/material/Typography"
import { makeStyles} from "@material-ui/core"
import DialogTitle from "@mui/material/DialogTitle"
import DialogActions from "@mui/material/DialogActions"

const useStyles = makeStyles(theme =>({
  dialogActionWrapper: {
    textAlign: "center",
  },
  actionTextWrapper: {
    [theme.breakpoints.up("md")]: {
      height: 45
    }
  },
  actionText: {
    color: "#0c59cf",
    textAlign: "center",
    fontSize: "16px",
    padding: 10
  }
}))

const global_styles = css`
h2.MuiDialogTitle-root {
  text-align: center;
  color: #000 !important;
}
.MuiPaper-rounded {
  @media only screen and (min-width: 768px) {
     min-width: 640px;
  }
}
button.MuiButton-containedPrimary {
   width: 221px;
   height: 42px;
   margin: 20px 0px;
   font-weight: 800;
   @media only screen and (max-width: 768px) {
       margin: 10px 0px 20px;
   }
}
`

export const DialogPrompt = ({ handleClose,
                               title,
                               paragraph,
                               txt_one,
                               txt_two,
                               btn_one_txt,
                               btn_two_txt
                             }) =>
{
  
  const classes = useStyles()
  
  return (
    <div>
      <Global styles={global_styles} />
      <Dialog open={true} className={classes.dialogPrompt}>
        <DialogTitle className={classes.dialogTitle}>{title}</DialogTitle>
        <DialogActions>
          <Grid container>
            <Grid item md={6} className={classes.dialogActionWrapper}>
              <div className={classes.actionTextWrapper}>
                <Typography variant="p" className={classes.actionText}>
                  No. I would like to customise my printing selections for each photo
                </Typography>
              </div>
              <Button variant="contained"
                      className={classes.actionBtn}
                      value={false}
                      onClick={handleClose}>{btn_one_txt}
              </Button>
            </Grid>
            <Grid item md={6} className={classes.dialogActionWrapper}>
              <div className={classes.actionTextWrapper}>
                <Typography variant="p" className={classes.actionText}>
                  Yes. Copy my selection to all photographs and proceed to check out
                </Typography>
              </div>
              <Button variant="contained"
                      className={classes.actionBtn}
                      value={true}
                      onClick={handleClose}>{btn_two_txt}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  )
}

import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { LazyLoadImage } from "react-lazy-load-image-component"
import TopBar from "./topbar"
import SideBar from "../../layout/sidebar"
import styles from "./styles"
import testImg1 from "../../assets/images/background/7.png"
import testImg2 from "../../assets/images/background/6.png"
import testImg3 from "../../assets/images/background/8.png"
import testImg4 from "../../assets/images/background/10.png"
import { Button, Divider, Hidden } from "@material-ui/core"
import { useNavigate } from "react-router"
import { useIsMobile } from "../../actions/ui"

const useStyles = makeStyles(styles)

const Howwork = () => {
  const classes = useStyles()
    const navigate = useNavigate()
    const isMobile = useIsMobile()
    
  const [showSideBar, setShowSideBar] = useState(false)

  return (
    <div>
      <TopBar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
      <SideBar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
      <div
        className={!showSideBar ? classes.nonMennuWrapper : classes.menuWrapper}
        style={{ paddingTop: !isMobile ? 65 : 0 }}
      >
        <div className={classes.content}>
          <Divider
            style={{ backgroundColor: "black", height: 2, marginBottom: 10 }}
          ></Divider>
          <Hidden mdDown>
            <div className="responsive">
              <div className="gallery">
                <LazyLoadImage alt="" src={testImg1} effect="blur" />
              </div>
              <div className="desc1">
                <div className="card1">
                  We have photographers working around the clock to get amazing
                  photos of you. You are probably here because you have seen our
                  photographers in action or heard about us from your friends.
                </div>
              </div>
            </div>
            <div className="responsive">
              <div className="desc2">
                <div className="card2">
                  The photographers are passionate about their trade and come
                  with top equipment. They know how to capture your best moves
                  and account for lighting and landscape
                </div>
              </div>
              <div className="gallery">
                <LazyLoadImage
                  alt=""
                  src={testImg2}
                  width="100%"
                  height="100%"
                  effect="blur"
                />
              </div>
            </div>
            <div className="responsive" style={{ marginTop: 200 }}>
              <div className="gallery">
                <LazyLoadImage
                  alt=""
                  src={testImg3}
                  width="100%"
                  height="100%"
                  effect="blur"
                />
              </div>
              <div className="desc1">
                <div className="card1">
                  To find your photos, go to our home page and zoom into the
                  location where the photographer captured you. Galleries will
                  appear in the circles. Just double click on the gallery and
                  photos of your session will appear. You can refine your search
                  by time or photographer. Go Find Yourself in the gallery.
                </div>
              </div>
            </div>
            <div className="responsive">
              <div className="desc2">
                <div className="card2">
                  The photographers set their own prices for the photos. Add
                  photos you like to your trolley and check out. Download your
                  photos and you are good to go. You can order prints of your
                  photos as well.
                </div>
              </div>
              <div className="gallery">
                <LazyLoadImage
                  alt=""
                  src={testImg4}
                  width="100%"
                  height="100%"
                  effect="blur"
                />
              </div>
            </div>
            <div className="responsive" style={{ marginTop: 100 }}>
              <Button
                className={classes.button}
                onClick={() => navigate(`/customer/map`)}
              >
                Find Yourself
              </Button>
            </div>
          </Hidden>
          <Hidden lgUp>
            <div className="responsive">
              <div className="gallery">
                <img src={testImg1} alt=" " />
              </div>
            </div>
            <div className="responsive">
              <div className="card1">
                We have photographers working around the clock to get amazing
                Fotos of you. You are probably here because you have seen our
                photographers in action or heard about us from your friends.
              </div>
            </div>
            <div className="responsive">
              <div className="gallery">
                <img src={testImg2} alt=" " />
              </div>
            </div>
            <div className="responsive">
              <div className="card1">
                The Photographers are passionate about their trade and come with
                top equipment. They know how to capture your best moves and
                account for lighting and landscape
              </div>
            </div>
            <div className="responsive">
              <div className="gallery">
                <img src={testImg3} alt=" " />
              </div>
            </div>
            <div className="responsive">
              <div className="card1">
                To find your Fotos, go to our home page and zoom into the
                location where the photographer captured you. Galleries will
                appear in the circles. Just double click on the gallery and
                Fotos of your session will appear. You can refine your search by
                time or Photographer. Go Find Yourself in the gallery.
              </div>
            </div>
            <div className="responsive">
              <div className="gallery">
                <img src={testImg4} alt=" " />
              </div>
            </div>
            <div className="responsive">
              <div className="card1">
                The bosses set their own prices for the Fotos. Add Fotos you
                like to your trolley and check out. Download your Fotos and you
                are good to go. You may order prints of your Fotos as well.
              </div>
            </div>
            <div className="responsive" style={{ marginTop: 50 }}>
              <Button
                className={classes.button}
                onClick={() => navigate(`/customer/map`)}
              >
                Find Yourself
              </Button>
            </div>
          </Hidden>
        </div>
      </div>
    </div>
  )
}

export default Howwork

import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import isObject from "lodash/isObject";
import forEach from "lodash/forEach";
import isomorphicFetch from "isomorphic-fetch";
import * as Sentry from "@sentry/browser";
import { store } from "../index"

export const CLEAR_AUTH_TOKEN = "CLEAR_AUTH_TOKEN";

const isoFetch: typeof fetch = isomorphicFetch;

const fetchCustom = async (url: string, config: RequestInit | undefined) => {
  return await Sentry.startSpan(
    { op: "http.client", name: `${config?.method} ${url}` },
    async (span) => {
      const parsedURL = new URL(url, window.location.origin);
      span.setAttribute("http.request.method", config?.method);
      span.setAttribute("server.address", parsedURL.hostname);
      span.setAttribute("server.port", parsedURL.port || undefined);
      const response = await isoFetch(url, config);
      span.setAttribute("http.response.status_code", response.status);
      span.setAttribute(
        "http.response_content_length",
        Number(response.headers.get("content-length")),
      );
      // A good place to set other span attributes
      return response;
    },
  );
};

export function duplicate_AuthToken() {
  return store.getState().authReducer.token
}

export function duplicate_ClearAuthentication() {
  return {
    type: CLEAR_AUTH_TOKEN,
  };
}

export function getHeaders(
  state?: unknown,
  customHeaders?: HeadersInit,
  args?: any,
) {
  let headers: HeadersInit = {};
  const { include_content_type } = args || {};
  if (include_content_type !== false) {
    headers["Content-type"] = "application/json;charset=UTF-8";
  }
  if (duplicate_AuthToken()) {
    headers["X-BOSSFOTOS-AUTHENTICATION-TOKEN"] =
      "Token " + duplicate_AuthToken();
  }
  // headers['pragma'] = 'no-cache'
  headers["cache-control"] = "no-cache";
  return Object.assign({}, headers, customHeaders);
}

function addQueryParams(url: string, params: any) {
  const url_params = new window.URLSearchParams();
  forEach(params, (v, k) => url_params.set(k, v));
  return `${url}?${url_params.toString()}`;
}

export function absUrl(url: string) {
  // @ts-ignore
  const apiBaseUrl = window.LOCAL_SETTINGS.API_V2_BASE;
  return apiBaseUrl + url;
}

export function put(state: unknown, url: string, data: unknown) {
  // TODO state not being used - remove from function
  return post(state, url, data, "PUT");
}

export function post(
  state: unknown,
  url: string,
  data: unknown,
  method = "POST",
  params = {},
) {
  // TODO state not being used - remove from function
  try {
    if (isObject(data)) {
      data = JSON.stringify(data);
    }

    const headers = getHeaders();
    url = addQueryParams(absUrl(url + "/"), params);

    let res = fetchCustom(url, {
      method: method,
      headers: headers,
      body: data as BodyInit | null | undefined,
    });

    return res.then(handleResponse);
  } catch (error) {
    throw error;
  }
}

export function postBlob(url: string, blob: any, method = "POST", params = {}) {
  try {
    const headers = getHeaders();
    delete headers["Content-type"];

    if (!url.startsWith("http")) {
      url = addQueryParams(absUrl(url + "/"), params);
    }

    let res = fetchCustom(url, {
      method: method,
      headers: headers,
      body: blob,
    });

    return res.then(handleResponse);
  } catch (error) {
    throw error;
  }
}

export function patch(state: any, url: string, data?: any, params = {}) {
  return post(state, url, data, "PATCH", params);
}

export function get(url: string, params?: any, custom_headers?: any) {
  let headers = getHeaders(custom_headers);

  if (params) {
    url = absUrl(url + serialize(params));
  } else {
    url = absUrl(url);
  }

  let res = fetchCustom(url, {
    method: "GET",
    headers: headers,
  });

  return res.then(handleResponse);
}

export function del(state: any, url: string, params?: any) {
  // TODO state not being used - remove from function
  return post(state, url, params, "DELETE");
}

function handleMaintenanceMode() {
  window.location.reload();
}

function handleResponse(response: Response) {
  let contentType = response.headers.get("content-type");
  const xPagination = response.headers.get("x-pagination");
  const pagination = parsePagination(xPagination);
  const isJson = contentType && contentType.indexOf("application/json") !== -1;
  const isSuccessful = response.status < 400;

  if (response.status == 401) {
    duplicate_ClearAuthentication();
  }
  if (response.status === 503) {
    handleMaintenanceMode();
  }

  if (isJson) {
    if (isEmpty(pagination)) {
      return Promise.all([response.json(), response, isSuccessful, {}]);
    } else {
      let payload = response.json();
      return Promise.all([payload, response, isSuccessful, pagination]);
    }
  } else {
    return Promise.all([response.text(), response, isSuccessful, {}]);
  }
}

function parsePagination(pagination_string: string | null) {
  let context: Record<string, number> = {};
  if (pagination_string !== null && pagination_string.indexOf(";") !== -1) {
    let items = pagination_string.split(";");
    map(items, function (item) {
      let value = item.split("=");
      context[value[0]] = parseInt(value[1], 10);
    });
  }
  return context;
}

function serialize(obj: any) {
  return (
    "?" +
    Object.keys(obj)
      .reduce(function (a, k) {
        if (obj[k] !== null && obj[k] !== undefined) {
          a.push(k + "=" + encodeURIComponent(obj[k]));
        }
        return a;
      }, [] as string[])
      .join("&")
  );
}

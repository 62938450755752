import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Controller } from "react-hook-form"
import { countryList } from "../v2_actions/country"
import { getMobMaxWidthPixels } from './layout/mobile'
import { MaterialTextInput } from "./form/MaterialTextInput"
import { MaterialDropdown } from "./form/MaterialDropdown"
import { Grid, makeStyles, FormLabel } from "@material-ui/core"

const useStyles = makeStyles({
  formGrid:{
      width:'95%',
  },
  formLabel: {
    color: "#0c59cf", 
    fontSize: "20px"
  }
})

export const CustomerProfileFields = ({
  customerProfileList,
  formProps,
  control,
}) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const countries = useSelector(() => countryList.getCountriesAsOptions())

  useEffect(() => {
    dispatch(countryList.loadAllCountries())
    dispatch(customerProfileList.invalidateList())
    dispatch(customerProfileList.fetchListIfNeeded())
  }, [])

  return (
    <Grid container className={classes.formGrid}>
      <Grid item xs>
        <Grid container spacing={5}>
          <Grid item md={12} xs={12}>
            <Controller
              name="first_name"
              control={control}
              render={({ field }) => (
                <MaterialTextInput
                  {...field}
                  form={formProps}
                  field_label="First Name"
                  placeholder="First Name"
                />
              )}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <Controller
              name="last_name"
              control={control}
              render={({ field }) => (
                <MaterialTextInput
                  {...field}
                  form={formProps}
                  field_label="Last Name"
                  placeholder="Last Name"
                />
              )}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <MaterialTextInput
                  {...field}
                  form={formProps}
                  type="email"
                  field_label="Email Address"
                  placeholder="Email Address"
                />
              )}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <Controller
              name={"country"}
              control={control}
              render={({ field }) => (
                <MaterialDropdown
                  form={formProps}
                  {...field}
                  options={countries}
                  field_label="Your Location/Shipping Address"
                  option_label={"country_name"}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
import {
  AppBar,
  Box,
  IconButton,
  Snackbar,
  Toolbar,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Global, css } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { CheckoutButton } from "../../../v2_components/CheckoutButton";
import Grid from "@mui/material/Grid";
import MuiAlert from "@material-ui/lab/Alert";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import PriceModal from "./pricemodal";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import clsx from "clsx";
import { goBackFromGalleryPage } from "../../../v2_actions/nav";
import { photoArtGalleryList } from "../../../v2_actions/photo_art_gallery";
import { setMessage } from "../../../actions/trolley";
import { setZoom } from "../../../actions/gallery";
import styles from "./styles";
import { trolleyList } from "../../../v2_actions/trolley";
import { useGalleryFilterSearchParams } from "../../../v2_components/hooks/useGalleryFilterSearchParams";
import { useIsMobile } from "../../../actions/ui";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(styles);

const global_styles = css`
  header.MuiAppBar-root {
    height: 60px;
    background: #fff;
  }
  .logo_img_map {
    padding-top: 0px;
  }
  .MuiToolbar-root {
    margin: auto;
    min-height: 45px;
    height: 45px;
    width: 100%;
    @media screen and (min-width: 480px) {
      width: 96%;
      border-bottom: #aaa solid 1px;
    }
  }
  span.MuiBadge-root {
    display: block;
    float: right;
  }
  .checkoutButton {
    float: right;
  }
  .checkoutButton button {
    padding: 0px;
  }

  .iconbtn {
    width: 27;
    height: 24;
    color: "black";
  }
  .backbtn {
    margin-right: 18;
  }
  div.MuiToolbar-regular {
    width: 100%;
  }
`;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const TopBar = ({
  width,
  variant,
  className,
  galleryId,
  showSideBar,
  galleryName,
  showMethod,
  setShowMethod,
  setShowSideBar,
  photoGrapherId,
  photoGrapherName,
  purchaseEntireGaleryEnabled = true,
  ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const [, , urlParams] = useGalleryFilterSearchParams();

  const gallery = useSelector(() => photoArtGalleryList.getObject(galleryId));

  const message = useSelector((state) => state.trolleyReducer.message);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    dispatch(trolleyList.ensureTrolleyLoaded());
  }, []);

  useEffect(() => {
    if (message) {
      setOpenSnackBar(true);
    }
  }, [message]);

  useEffect(() => {
    if (galleryId) {
      dispatch(photoArtGalleryList.ensureObjectLoaded(galleryId));
    }
  }, [galleryId]);

  const tryRenderSnackBar = () => {
    return (
      <Snackbar
        open={openSnackBar}
        anchorOrigin={{ vertical: "center", horizontal: "center" }}
        autoHideDuration={2000}
        onClose={() => setOpenSnackBar(false)}
        style={{ height: "calc(100vh - 90px)" }}
      >
        <Alert onClose={() => setOpenSnackBar(false)} severity="error">
          {message}
        </Alert>
      </Snackbar>
    );
  };

  const renderPhotographerName = () => {
    return (
      <Box className={classes.photoArtPhotographerNameWrapper}>
        <PhotoCameraIcon className={classes.photoArtCameraIcon} />
        <h3 className={classes.photoArtPhotographerName}>
          {gallery.photographer_name}
        </h3>
      </Box>
    );
  };

  const renderPhotographerPricing = () => {
    return (
      <Box width={"20%"} textAlign={"center"} display={"flex"}>
        <h3
          className={classes.photoArtPricingHeading}
          onClick={() => setOpenModal(true)}
        >
          Pricing
        </h3>
      </Box>
    );
  };

  const renderGalleryName = () => {
    return (
      <Box>
        {!isMobile && (
          <>
            <Typography className={classes.photo_art_header} variant="h5">
              Gallery: {galleryName}
            </Typography>
          </>
        )}
        {isMobile && (
          <>
            <Typography className={classes.mobiletypography}>
              Gallery: {galleryName}
            </Typography>
          </>
        )}
      </Box>
    );
  };

  const renderMobilePhotoArtTopBar = () => {
    return (
      <>
        <Stack
          direction={"column"}
          spacing={{ xs: 0 }}
          display={{ sm: "none" }}
          mt={5}
          className={classes.mobilePhotoArtTopBar}
        >
          <Stack
            direction={{ xs: "row", sm: "column" }}
            spacing={{ xs: 1, sm: 2, md: 12 }}
          >
            <IconButton
              className={classes.photoArtBackBtn}
              onClick={() => {
                dispatch(setZoom(0));
                dispatch(setMessage(false));
                goBackFromGalleryPage(navigate, urlParams);
              }}
            >
              <ArrowBackIcon className={classes.photoArtBackBtn} />
            </IconButton>
            {renderGalleryName()}
          </Stack>
          <Box className={classes.mobilePhotoArtTopBarCheckoutButton} pt={1}>
            <CheckoutButton variant={variant} />
          </Box>
          <div className={classes.mobilePhotoArtTopBarBorder}></div>
        </Stack>
      </>
    );
  };

  const renderDesktopPhotoArtTopBar = () => {
    return (
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        marginTop={{ xs: 1, md: 0 }}
        alignItems="center"
        className={clsx(classes.photoArtNavBar)}
        width="100%"
      >
        <Stack direction={"row"} spacing={{ xs: 0 }} display={{ sm: "none" }}>
          <Stack
            spacing={{ xs: 1, sm: 2, md: 12 }}
            direction={{ xs: "row", sm: "column" }}
          >
            {renderPhotographerPricing()}
            {renderPhotographerName()}
          </Stack>
          <Box pt={1}>
            <CheckoutButton variant={"photo_art_gallery_view"} />
          </Box>
        </Stack>
        {!isMobile && (
          <Box
            display="flex"
            sx={{ display: { xs: "none", md: "block" } }}
            alignItems="baseline"
            width={
              width > 1600
                ? "97%"
                : width > 1400
                  ? "65%"
                  : width > 500
                    ? "100%"
                    : "80%"
            }
          >
            <Grid container>
              <Grid item xs={0.5}>
                {!showMethod ? (
                  <IconButton
                    className={classes.photoArtBackBtn}
                    onClick={() => {
                      dispatch(setZoom(0));
                      dispatch(setMessage(false));
                      goBackFromGalleryPage(navigate, urlParams);
                    }}
                  >
                    <ArrowBackIcon className={classes.photoArtBackBtn} />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() => {
                      setShowMethod(false);
                    }}
                  >
                    <ArrowBackIcon className={classes.iconbtn} />
                  </IconButton>
                )}
              </Grid>
              <Grid item md={5.5} mt={0.2}>
                {renderGalleryName()}
              </Grid>
              <Grid item xs={2} mt={0.2}>
                {renderPhotographerName()}
              </Grid>
              <Grid item xs={2} mt={0.5}>
                {renderPhotographerPricing()}
              </Grid>
              <Grid item xs={2} className={classes.checkoutBtnBlock}>
                <CheckoutButton
                  style={classes.photoArtCheckoutBtn}
                  variant={"photo_art_gallery_view"}
                />
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
    );
  };

  return (
    <AppBar
      className={clsx(classes.photoArtRoot, classes.appBarRoot, className)}
      elevation={0}
      {...rest}
      color="default"
      height={"60px"}
      position={!isMobile ? "fixed" : "relative"}
    >
      <Global styles={global_styles} />
      {tryRenderSnackBar()}
      {!isMobile && <Toolbar>{renderDesktopPhotoArtTopBar()}</Toolbar>}
      {isMobile && <Toolbar>{renderMobilePhotoArtTopBar()}</Toolbar>}
      <PriceModal
        width={width}
        classes={classes}
        openModal={openModal}
        setOpenModal={setOpenModal}
        photoGrapherName={photoGrapherName}
      />
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};

export default TopBar;

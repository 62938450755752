import { useDispatch } from "react-redux";
import { useIsMobile } from "../actions/ui";
import {
  Button,
  Inline,
  ModalContent,
  ModalCover,
  ModalWrapper,
  Stack,
} from "bossfotos-design-system";
import { CurrencyValue } from "../v2_components/CurrencyValue";
import { useState } from "react";
import { trolleyPhotographList } from "../v2_actions/trolley_photograph";

type LowHighResPhotoPromptProps = {
  trolley: any;
  gallery: any;
  photo_id: string;
  handleClose: any;
  isUpdatingTrolley: boolean;
  trolleyPhotograph: any;
};
type FormatType = "low" | "high";

const HIGH_RES = "high-res";
const LOW_RES = "low-res";

const LowHighResPhotoPrompt = ({
  trolley,
  gallery,
  photo_id,
  handleClose,
  isUpdatingTrolley,
  trolleyPhotograph,
}: LowHighResPhotoPromptProps) => {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();

  const [formatChoice, setFormatChoice] = useState<FormatType>("high");

  const galleryBasePrice = gallery.gallery_price.base_price;
  const galleryLowResPrice = gallery.gallery_price.low_res_price;
  const discountedGalleryBasePrice =
    gallery.gallery_price.discounted_base_price_using_discount1_percentage;

  const isStillLoadingTrolleyPhotograph =
    !trolleyPhotograph || isUpdatingTrolley;

  const handleChoiceClicked = (choice: FormatType) => {
    setFormatChoice(choice);
    if (choice === "high") {
      handleProceedClicked(); // auto proceed
    }
  };

  const handleProceedClicked = () => {
    dispatch(
      trolleyPhotographList.saveObject({
        quality: formatChoice === "high" ? HIGH_RES : LOW_RES,
        trolley: trolley.id,
        copy_to_remaining_photographs: false,
        photo_delivery_type:
          formatChoice === "high" ? "digital_only" : "low_res_digital_only",
        id: trolleyPhotograph?.id,
      }),
    );
    handleClose();
  };

  return (
    <ModalCover tabIndex={0} onKeyUp={() => {}} style={{ zIndex: "100000" }}>
      <ModalWrapper>
        <ModalContent>
          <div
            style={{
              maxWidth: "300px",
              padding: "20px",
            }}
          >
            <Inline fill={true}>
              <Stack flexGrow={1} alignItems="center">
                <b>
                  <CurrencyValue
                    value={galleryLowResPrice}
                    country={gallery.gallery_price.country}
                    horizontal_spacing={false}
                  />
                </b>
                <Button
                  size="small"
                  fill={true}
                  onClick={() => handleChoiceClicked("low")}
                  variant={formatChoice === "low" ? "default" : "secondary"}
                  disabled={isStillLoadingTrolleyPhotograph}
                >
                  Low Res
                </Button>
              </Stack>
              <Stack flexGrow={1} alignItems="center">
                <b>
                  <CurrencyValue
                    value={galleryBasePrice}
                    country={gallery.gallery_price.country}
                    horizontal_spacing={false}
                  />
                </b>
                <Button
                  size="small"
                  fill={true}
                  onClick={() => handleChoiceClicked("high")}
                  variant={formatChoice === "high" ? "default" : "secondary"}
                  disabled={isStillLoadingTrolleyPhotograph}
                >
                  High Res
                </Button>
              </Stack>
            </Inline>
            <p>
              <center>
                <b>You may edit your selection in the trolley</b>
              </center>
            </p>
            <Inline justifyContent="flex-end">
              <Stack width={"50%"}></Stack>
              <Stack width={"50%"}>
                <Button
                  fill={true}
                  disabled={isStillLoadingTrolleyPhotograph}
                  onClick={handleProceedClicked}
                >
                  Proceed
                </Button>
              </Stack>
            </Inline>
          </div>
        </ModalContent>
      </ModalWrapper>
    </ModalCover>
  );
};

export default LowHighResPhotoPrompt;

import get from "lodash/get";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useIsMobile } from "../actions/ui";
import { useParams, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { Stack, TextField } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { LinearIndeterminateProgressLoader } from "./LinearIndeterminateProgressLoader";
import { customerBibNumberRecognition } from "../v2_actions/customer_bib_number_recognition";

const useStyles = makeStyles((theme) => ({
  submitBibNumber: {
    height: 40,
    width: 20,
    fontSize: 10,
    cursor: "pointer",
  },
}));

export default function BibNumber({
  gallery,
  bibNumber,
  onChangeBibNumber,
  onSubmitBibNumber,
  mayPerformBibNumberIndexingOfGalleryPhotographs,
}) {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { galleryId } = useParams();
  const [
    galleryBibNumberIndexingInProgress,
    setGalleryBibNumberIndexingInProgress,
  ] = useState(false);
  const isGalleryIndexedForBibRecognition = get(
    gallery,
    "indexed_for_bib_number_recognition",
  );

  useEffect(() => {
    if (
      isGalleryIndexedForBibRecognition === false &&
      mayPerformBibNumberIndexingOfGalleryPhotographs
    ) {
      onBibNumberIndexGalleryPhotographs();
    }
  }, [gallery, mayPerformBibNumberIndexingOfGalleryPhotographs]);

  const onBibNumberIndexGalleryPhotographs = () => {
    if (!isGalleryIndexedForBibRecognition) {
      setGalleryBibNumberIndexingInProgress(true);
      const on_done = (json) => {
        if (json.status !== "success") {
          console.error(get(json, "message"));
        }
        setGalleryBibNumberIndexingInProgress(false);
      };
      dispatch(customerBibNumberRecognition.indexGallery(galleryId)).then(
        on_done,
      );
    }
  };

  const renderError = () => {
    return (
      <div>There are no bib numbers to search for.</div>
    );
  }

  const renderSuccess = () => {
    return (
      <>
        <Stack
          spacing={1}
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <TextField
            size="small"
            variant="outlined"
            value={bibNumber}
            placeholder="Enter bib number"
            onChange={onChangeBibNumber}
            className={classes.bibNumberInput}
            autoComplete="bibnumber"
          />
          <ArrowForwardIosIcon
            onClick={onSubmitBibNumber}
            className={classes.submitBibNumber}
          />
        </Stack>
        {galleryBibNumberIndexingInProgress && (
          <div>
            <LinearIndeterminateProgressLoader />
            <small className={classes.indexingInProgressMessage}>
              Indexing gallery...
            </small>
          </div>
        )}
      </>
    );
  }
  
  if (isGalleryIndexedForBibRecognition) {
    return renderSuccess()
  } else {
    return renderError()
  }
}

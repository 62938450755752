import filter from "lodash/filter";
import map from "lodash/map";
import uniqBy from "lodash/uniqBy";
import head from "lodash/head";
import { ItemList } from "../orm";

class PrintTypeList extends ItemList {
  getEntityKey() {
    return "customer/print_type";
  }

  getPrintTypeById(print_type_id) {
    return this.getObject(print_type_id);
  }

  getGenericPrintType() {
    const print_type = uniqBy(
      map(
        filter(this.getVisibleObjects(), (x) => x.name === "Generic"),
        (x) => x,
      ),
      "name",
    );
    return head(print_type);
  }

  getDigitalOnlyPrintType() {
    const print_type = uniqBy(
      map(
        filter(this.getVisibleObjects(), (x) => x.name === "Digital only"),
        (x) => x,
      ),
      "name",
    );
    return head(print_type);
  }
}

export const printTypeList = new PrintTypeList("print_type_default");

import keys from "lodash/keys";
import map from "lodash/map";
import get from "lodash/get";
import { onGeneralServerError, onSubmissionError } from "./error";

export const handleSubmitResult = ({ res, on_ok, formProps }) => {
  const { setError } = formProps || {};

  if (!res) {
    setError("non_field_errors", { type: "server", message: "No response" });
  } else if (get(res, "errors")) {
    if (res.errors._error) {
      onGeneralServerError(res);
    } else {
      if (formProps) {
        onSubmissionError(res);
      } else {
        onGeneralServerError(res);
      }
    }

    if (setError) {
      map(keys(res.errors), function (field_name) {
        if (field_name === "_error" || field_name === "detail") {
          setError("non_field_errors", {
            type: "server",
            message: res.errors[field_name],
          });
        } else {
          const msg = res.errors[field_name];
          if (msg.non_field_errors) {
            setError("non_field_errors", {
              type: "server",
              message: msg.non_field_errors[0],
            });
          } else {
            setError(field_name, { type: "server", message: msg });
          }
        }
      });
    }
  } else {
    on_ok(res);
  }
};

import { ItemList } from '../orm'

class CustomerBibNumberRecognition extends ItemList {

  getEntityKey() {
    return 'customer/bib_number_recognition'
  }

  indexGallery(galleryId) {
    return this.saveNewObject({
      action: 'index_gallery',
      gallery_id: galleryId
    })
  }
}

export const customerBibNumberRecognition = new CustomerBibNumberRecognition('customer_bib_number_recognition__default');

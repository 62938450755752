import head from "lodash/head";
import get from "lodash/get";
import React, { useState, useEffect } from "react";
import * as yup from "yup";
import { makeStyles } from "@material-ui/core";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";

import { handleSubmitResultReactHookForm } from "../actions/form";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { countryList } from "../v2_actions/country";
import { getUserCountry, getUserId } from "../actions/user";
import { billingAddressList } from "../v2_actions/billing_address";
import { MaterialTextInput } from "./form/MaterialTextInput";

const formSchema = yup.object().shape();

const useStyles = makeStyles({
  shippingCountryField: {
    cursor: "pointer",
  },
  shippingAddressHeading: {
    color: "#003748",
  },
  shippingPriceText: {
    color: "#003748",
  },
});

export const ShippingAddressForm = ({ authenticated_trolley }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user_country_code = useSelector((state) => getUserCountry(state))
  const customer_id = useSelector((state) => getUserId(state))
  const countries = countryList.getVisibleObjects();
  const customer_billing_address = useSelector(() =>
    head(billingAddressList.getVisibleObjects()),
  );
  const customer_billing_address_country = useSelector(() =>
    countryList.getCountryForId(get(customer_billing_address, "country")),
  );
  const [countryChangeNoticeOpen, setCountryChangeNoticeOpen] = useState(false);

  const user_country = useSelector(() =>
    countryList.getCountryForCountryCode(user_country_code),
  );

  useEffect(() => {
    dispatch(countryList.loadAllCountries());
    dispatch(billingAddressList.fetchListIfNeeded());
  }, []);

  useEffect(() => {
    reset(customer_billing_address);
  }, [get(customer_billing_address, "id")]);

  const defaultValues = {
    customer: customer_id,
    town: get(customer_billing_address, "town"),
    country: get(customer_billing_address, "country"),
    province: get(customer_billing_address, "province"),
    postal_code: get(customer_billing_address, "postal_code"),
    contact_name: get(customer_billing_address, "contact_name"),
    contact_number: get(customer_billing_address, "contact_number"),
    street_address_one: get(customer_billing_address, "street_address_one"),
    street_address_two: get(customer_billing_address, "street_address_two"),
  };

  const formProps = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: defaultValues,
  });

  const {
    reset,
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = formProps;

  const onSave = (values) => {
    let on_ok = function (json) {
      navigate(`/customer/shopping/${get(authenticated_trolley, "id")}`);
    };

    values.country = get(user_country, "id");

    if (customer_billing_address) {
      values.id = customer_billing_address.id;
      return dispatch(billingAddressList.saveObject(values)).then((res) =>
        handleSubmitResultReactHookForm({ res, hook_form: formProps, on_ok }),
      );
    } else {
      return dispatch(billingAddressList.saveNewObject(values)).then((res) =>
        handleSubmitResultReactHookForm({ res, hook_form: formProps, on_ok }),
      );
    }
  };

  const handleShippingCountryNoticeOpen = () => {
    setCountryChangeNoticeOpen(true);
  };

  const handleShippingCountryNoticeClose = () => {
    setCountryChangeNoticeOpen(false);
  };

  const goToProfilePage = () => {
    setCountryChangeNoticeOpen(false);
    navigate("/customer/editprofile");
  };

  const renderShippingCountryNotice = () => {
    return (
      <Dialog open={countryChangeNoticeOpen}>
        <DialogTitle>Change shipping country?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Country details need to be changed in profile page. Do you want to
            continue to your profile?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleShippingCountryNoticeClose}>Close</Button>
          <Button onClick={goToProfilePage} autoFocus>
            Change Country
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderShippingForm = () => {
    return (
      <>
        <Typography
          className={classes.shippingAddressHeading}
          variant="h6"
          gutterBottom
          mb={4}
          component="h6"
        >
          Shipping Address
        </Typography>
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            md={6}
            className={classes.shippingCountryField}
            onClick={handleShippingCountryNoticeOpen}
          >
            {get(user_country, "country_name")}
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="province"
              control={control}
              render={({ field }) => (
                <MaterialTextInput
                  {...field}
                  form={formProps}
                  placeholder="Province"
                ></MaterialTextInput>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="postal_code"
              control={control}
              render={({ field }) => (
                <MaterialTextInput
                  {...field}
                  type="number"
                  form={formProps}
                  placeholder="Postal Code"
                ></MaterialTextInput>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="street_address_one"
              control={control}
              render={({ field }) => (
                <MaterialTextInput
                  {...field}
                  form={formProps}
                  placeholder="Street Address One"
                ></MaterialTextInput>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="street_address_two"
              control={control}
              render={({ field }) => (
                <MaterialTextInput
                  {...field}
                  form={formProps}
                  placeholder="Street Address Two"
                ></MaterialTextInput>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="town"
              control={control}
              render={({ field }) => (
                <MaterialTextInput
                  {...field}
                  form={formProps}
                  placeholder="Town"
                ></MaterialTextInput>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="contact_number"
              control={control}
              render={({ field }) => (
                <MaterialTextInput
                  {...field}
                  form={formProps}
                  placeholder="Contact Number"
                ></MaterialTextInput>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="contact_name"
              control={control}
              render={({ field }) => (
                <MaterialTextInput
                  {...field}
                  form={formProps}
                  placeholder="Contact Name"
                ></MaterialTextInput>
              )}
            />
          </Grid>
          <Grid item xs={12}></Grid>
        </Grid>
      </>
    );
  };

  const renderPrice = () => {
    return (
      <Typography
        className={classes.shippingPriceText}
        component="h6"
        variant="h6"
      >
        Shipping Price
        {/* <CurrencyValue value={get} country={} /> */}
      </Typography>
    );
  };

  const renderCheckoutButton = () => {
    const readyToSubmit = true;
    return (
      <>
        {readyToSubmit && (
          <>
            <Button
              sx={{ borderRadius: "50px" }}
              type="submit"
              variant="contained"
            >
              Checkout
            </Button>
          </>
        )}
        {!readyToSubmit && (
          <Button
            sx={{ borderRadius: "50px", padding: "5px 35px" }}
            disabled
            type="submit"
            variant="contained"
          >
            Checkout
          </Button>
        )}
      </>
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSave)}>
        <Grid container>{renderShippingForm()}</Grid>
        <Grid container>
          <Grid item xs>
            {renderPrice()}
          </Grid>
          <Grid item>{renderCheckoutButton()}</Grid>
        </Grid>
      </form>
      {renderShippingCountryNotice()}
    </>
  );
};

import React from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  Typography,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import HomeIcon from "@material-ui/icons/Home";
import LogoImg from "../../../assets/logo/black_logo.png";
import { useIsMobile } from "../../../actions/ui";;

const useStyles = makeStyles(() => ({
  root: {
    borderBottom: "2px solid",
    height: 65,
  },
  iconbtn: {
    width: 30,
    height: 30,
    color: "black",
  },
  logo: {
    fontFamily: "Aguafina Script",
    fontSize: 50,
    fontWeight: "400",
    color: "black",
  },
  middletitle: {
    fontSize: 30,
  },
  mobilemiddletitle: {
    fontSize: 18,
  },
}));

const TopBar = ({ className, showSideBar, setShowSideBar, ...rest }) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const isMobile = useIsMobile()

  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
      color="default"
      position={!isMobile ? "fixed" : "relative"}
    >
      <Toolbar>
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          alignItems="center"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="20%"
          >
            {!showSideBar && (
              <IconButton onClick={() => setShowSideBar(!showSideBar)}>
                <MenuIcon className={classes.iconbtn} />
              </IconButton>
            )}
            <Hidden mdDown>
              <RouterLink to={`/customer/map`}>
                <img src={LogoImg} alt="/" className="logo_img_map" />
              </RouterLink>
            </Hidden>
          </Box>
          <Box>
            <Hidden mdDown>
              <Typography className={classes.middletitle}>
                My Purchased Fotos
              </Typography>
            </Hidden>
            <Hidden lgUp>
              <Typography className={classes.mobilemiddletitle}>
                My Purchased Fotos
              </Typography>
            </Hidden>
          </Box>
          <IconButton onClick={() => navigate(`/customer/events`)}>
            <HomeIcon className={classes.iconbtn} />
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};

export default TopBar;

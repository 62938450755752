import React from "react";
import { makeStyles } from "@material-ui/core";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  readMoreBtnWrapper: {
    bottom: "47px",
    height: 50,
    position: "relative",
    background: "#fff",
    margin: "50px 0px 10px",
    "& button": {
      padding: "2px 30px",
    },
  },
  seeMoreButton: {
    margin: 0,
    fontSize: 16,
    fontWeight: 600,
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
}));

export const SeeMoreButton = ({ list }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const scrollUpPageAndOpenDateFilter = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    dispatch(
      list.updateListFilter({ show_gallery_filter_date_dropdown: true }),
    );
  };

  return (
    <Stack
      spacing={4}
      direction="row"
      alignItems="center"
      justifyContent="center"
      className={classes.readMoreBtnWrapper}
    >
      <Button
        variant="contained"
        onClick={scrollUpPageAndOpenDateFilter}
        endIcon={<KeyboardArrowDownIcon />}
      >
        <p className={classes.seeMoreButton}>Older Galleries</p>
      </Button>
    </Stack>
  );
};

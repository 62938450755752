import get from "lodash/get";
import { ItemList } from "../orm";

class CutoutImageList extends ItemList {
  getEntityKey() {
    return "cutout_image";
  }

  ensureCutoutImageLoaded({ imageWithCutout, photograph }) {
    if (!get(imageWithCutout, "id") || !get(photograph, "id")) {
      return { type: "noop" };
    }
    const id = this.getId({ imageWithCutout, photograph });
    return this.ensureObjectLoaded(id);
  }

  getCutoutImage({ imageWithCutout, photograph }) {
    if (!get(imageWithCutout, "id") || !get(photograph, "id")) {
      return null;
    }
    const id = this.getId({ imageWithCutout, photograph });
    return this.getObject(id);
  }

  getId({ imageWithCutout, photograph }) {
    return `${get(imageWithCutout, "id")}_${get(photograph, "id")}`;
  }

  invalidateForCutout({ imageWithCutout }) {
    // easier just to invalidate them all
    return this.invalidateAllObjects();
  }
}

export const cutoutImageList = new CutoutImageList("cutout_image__default");

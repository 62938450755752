import { ItemList } from '../orm'

class GenreTagList extends ItemList {
    getEntityKey() {
        return "customer/genre_tag"
    }
  
    loadAllGenreTags() {
      return async (dispatch) => {
        dispatch(this.updatePaginationNumItemsPerPage(1000))
        dispatch(this.fetchListIfNeeded())
      }
    }

    trySetFilter(newFilter) {
      return async (dispatch, getState) => {
          const existingFilter = this.getFilter()
          if ( JSON.stringify(existingFilter) === JSON.stringify(newFilter) ) {
              return
          }
          dispatch(this.updateListFilter(newFilter))
          dispatch(this.updatePaginationNumItemsPerPage(1000))
          dispatch(this.fetchListIfNeeded())
      }
  }

}

export const genreTagList = new GenreTagList("genreTag_default")

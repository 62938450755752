import { ItemList } from '../orm'

class AlbumList extends ItemList {

  getEntityKey() {
    return "customer/album"
  }
}

export const albumList = new AlbumList("album_default")
export const albumListForEventTab = new AlbumList("album_for_event_tab_default")

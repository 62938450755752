import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { GalleryCard } from "./GalleryCard";
import { briefGalleryListForPhotoArtTab } from "../v2_actions/brief_gallery";
import LoadingSpinner from "../views/components/LoadingSpinner";
import { genreTagList } from "../v2_actions/gallery_genre";
import responsive from "../mobile";
import { GalleryCardWrapper } from "./GalleryCardWrapper";

const useStyles = makeStyles({
  photoArtGenreCard: {
    width: "95%",
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "20px",
    margin: "auto",
    [`@media only screen and (max-width:${responsive.getMobMaxWidthPixels()})`]:
      {
        gridTemplateColumns: "repeat(1, 1fr)",
        marginLeft: "0px",
        width: "100%",
        height: "auto",
        marginTop: "55px",
        overflowY: "hidden",
      },
  },
  noPhotoArtDiv: {
    marginLeft: "30px",
    [`@media only screen and (max-width:${responsive.getMobMaxWidthPixels()})`]:
      {
        marginTop: "70px",
      },
  },
});

export const PhotoArtList = ({ searchTerm, photo_art_genre_name }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const photo_art_pictures = useSelector(() =>
    briefGalleryListForPhotoArtTab.getFilteredGalleries(),
  );
  const photo_art_genres = useSelector(() => genreTagList.getVisibleObjects());
  const photo_art_filter_active = get(
    briefGalleryListForPhotoArtTab.getFilter(),
    "photo_art_filter_active",
  );
  const isPhotoArtListLoading = useSelector(() =>
    briefGalleryListForPhotoArtTab.isLoading(),
  );
  const [displayMode, setDisplayMode] = useState("photo_art_genre");

  useEffect(() => {
    if (photo_art_genre_name) {
      setDisplayMode("photo_art_belonging_to_genre");
      dispatch(
        briefGalleryListForPhotoArtTab.updateListFilter({
          genre_tags: photo_art_genre_name,
        }),
      );
    } else {
      setDisplayMode("photo_art_genre");
    }

    dispatch(briefGalleryListForPhotoArtTab.invalidateList());
    dispatch(
      briefGalleryListForPhotoArtTab.fetchListIfNeeded({
        callSource: "PhotoArtList",
      }),
    );
    dispatch(genreTagList.updatePaginationNumItemsPerPage(1000));
    dispatch(genreTagList.loadAllGenreTags());
  }, [photo_art_genre_name]);

  useEffect(() => {
    if (photo_art_filter_active) {
      setDisplayMode("photo_art_belonging_to_genre");
    } else {
      setDisplayMode("photo_art_genre");
      navigate("/customer/photo_art/");
    }
  }, [photo_art_filter_active]);

  const renderPhotoArtPictures = () => {
    return (
      <>
        {map(photo_art_pictures, (photo_art_picture, index) => (
          <GalleryCard
            key={index}
            title={photo_art_picture.gallery_name}
            image={photo_art_picture.gallery_photo_url}
            gallery_id={photo_art_picture.id}
            gallery_type={"photo_art"}
            tab={"photo_art_belonging_to_genre"}
          />
        ))}
      </>
    );
  };

  const renderPhotoArtGenres = () => {
    return (
      <>
        {map(photo_art_genres, (photo_art_genre) => (
          <GalleryCardWrapper
            title={photo_art_genre.name}
            image={get(photo_art_genre, [
              "genre_image",
              "gallery_size_file_info",
              "download_url",
            ])}
            gallery_event_id={photo_art_genre.id}
            gallery_event_name={photo_art_genre.name}
            tab={"photo_art_genre"}
            onClick={() =>
              navigate(`/customer/photo_art/${photo_art_genre.name}`)
            }
            cardType={"photoart"}
          />
        ))}
      </>
    );
  };

  return (
    <>
      <div>
        {isEmpty(photo_art_pictures) === false && (
          <>
            {displayMode === "photo_art_belonging_to_genre" && (
              <div className={classes.photoArtGenreCard}>
                {renderPhotoArtPictures()}
              </div>
            )}
            {displayMode === "photo_art_genre" && (
              <div className={classes.photoArtGenreCard}>
                {renderPhotoArtGenres()}
              </div>
            )}
          </>
        )}
      </div>
      {isPhotoArtListLoading && <LoadingSpinner />}
      {!isPhotoArtListLoading && isEmpty(photo_art_pictures) && (
        <div className={classes.noPhotoArtDiv}>
          {isEmpty(photo_art_pictures) && (
            <h2>
              No photo art pictures match your filter. Try expanding the date
              filter or clearing other filters.
            </h2>
          )}
        </div>
      )}
    </>
  );
};

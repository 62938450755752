export const getMobMaxWidth = () => {
  const mob_width = 900
  return mob_width
}

export const getMobMaxWidthPixels = () => {
    const mob_width = `${getMobMaxWidth()}px` 
    return mob_width
      
}

export const getTabMinWidth = () => {
    const mob_width = 1000
    return mob_width
}

export const getTabMinWidthPixels = () => {
    const tab_min_width = `${getTabMaxWidth()}px` 
    return tab_min_width

}

export const getTabMaxWidth = () => {
    const mob_width = 1300
    return mob_width
}

export const getTabMaxWidthPixels = () => {
    const tab_max_width = `${getTabMaxWidth()}px` 
    return tab_max_width
      
}
import first from "lodash/first";
import get from "lodash/get";
import filter from "lodash/filter";
import map from "lodash/map";
import uniqBy from "lodash/uniqBy";
import head from "lodash/head";
import uniq from "lodash/uniq";
import { ItemList } from "../orm";

class PrintPriceConfigurationList extends ItemList {
  getEntityKey() {
    return "customer/print_price_configuration";
  }

  trySetFilter(newFilter) {
    return async (dispatch, getState) => {
      const existingFilter = this.getFilter();
      if (JSON.stringify(existingFilter) === JSON.stringify(newFilter)) {
        return;
      }
      dispatch(this.updateListFilter(newFilter));
      dispatch(this.updatePaginationNumItemsPerPage(1000));
      dispatch(this.fetchListIfNeeded());
    };
  }

  getPrintSizeNameForSelectedOptions = ({
    selectedPrintTypeOption,
    selectedPrintSizeOption,
  }) => {
    const priceOptions = this.getVisibleObjects();

    const priceOption = head(
      filter(
        priceOptions,
        (x) =>
          x.print_type.id === selectedPrintTypeOption &&
          x.print_size.id === selectedPrintSizeOption,
      ),
    );

    return get(priceOption, "print_size_name");
  };

  getPrintPriceForSelectedOptions = ({
    selectedPrintTypeOption,
    selectedPrintSizeOption,
    selectedPrintFrameOption,
    selectedPrintPaperOption,
  }) => {
    const priceOptions = this.getVisibleObjects();

    const printPrice = head(
      filter(
        priceOptions,
        (x) =>
          x.print_type.id === selectedPrintTypeOption &&
          x.print_size.id === selectedPrintSizeOption &&
          x.print_frame.id === selectedPrintFrameOption &&
          x.print_paper.id === selectedPrintPaperOption,
      ),
    );

    return printPrice;
  };

  getAvailableOptions = ({
    selectedPrintTypeOption,
    selectedPrintSizeOption,
    selectedPrintFrameOption,
    selectedPrintPaperOption,
  }) => {
    const res = {};
    const priceOptions = this.getVisibleObjects();

    res["availablePrintTypeOptions"] = map(
      uniqBy(
        map(priceOptions, (x) => x.print_type),
        "id",
      ),
      function (x) {
        return { value: x.id, label: x.name };
      },
    );

    res["availablePrintSizeOptions"] = map(
      uniqBy(
        map(
          filter(
            priceOptions,
            (x) => x.print_type.id === selectedPrintTypeOption,
          ),
          (x) => x.print_size,
        ),
        "id",
      ),
      function (x) {
        return { value: x.id, label: x.name };
      },
    );

    res["availablePrintFrameOptions"] = map(
      uniqBy(
        map(
          filter(
            priceOptions,
            (x) =>
              x.print_type.id === selectedPrintTypeOption &&
              x.print_size.id === selectedPrintSizeOption,
          ),
          (x) => x.print_frame,
        ),
        "id",
      ),
      function (x) {
        return { value: x.id, label: x.name };
      },
    );

    res["availablePrintPaperOptions"] = map(
      uniqBy(
        map(
          filter(
            priceOptions,
            (x) =>
              x.print_type.id === selectedPrintTypeOption &&
              x.print_size.id === selectedPrintSizeOption &&
              x.print_frame.id === selectedPrintFrameOption,
          ),
          (x) => x.print_paper,
        ),
        "id",
      ),
      function (x) {
        return { value: x.id, label: x.name };
      },
    );

    res["priceOfPrintConfiguration"] = head(
      map(
        uniq(
          map(
            filter(
              priceOptions,
              (x) =>
                x.print_type.id === selectedPrintTypeOption &&
                x.print_size.id === selectedPrintSizeOption &&
                x.print_frame.id === selectedPrintFrameOption &&
                x.print_paper.id === selectedPrintPaperOption,
            ),
            (x) => x.price,
          ),
        ),
      ),
    );

    return res;
  };

  getLowestPriceOfPrintConfiguration({
    selectedPrintTypeOption,
    selectedPrintSizeOption,
    selectedPrintFrameOption,
    selectedPrintPaperOption,
  }) {
    let lowestPrice;
    const priceOptions = this.getVisibleObjects();
    lowestPrice = get(first(priceOptions), "price");

    if (selectedPrintTypeOption) {
      lowestPrice = head(
        map(
          filter(
            priceOptions,
            (x) => x.print_type.id === selectedPrintTypeOption,
          ),
          (x) => x.price,
        ),
      );
    }

    if (selectedPrintTypeOption && selectedPrintSizeOption) {
      lowestPrice = head(
        map(
          filter(
            priceOptions,
            (x) =>
              x.print_type.id === selectedPrintTypeOption &&
              x.print_size.id === selectedPrintSizeOption,
          ),
          (x) => x.price,
        ),
      );
    }

    if (selectedPrintSizeOption && selectedPrintFrameOption) {
      lowestPrice = head(
        map(
          filter(
            priceOptions,
            (x) =>
              x.print_type.id === selectedPrintTypeOption &&
              x.print_size.id === selectedPrintSizeOption &&
              x.print_frame.id === selectedPrintFrameOption,
          ),
          (x) => x.price,
        ),
      );
    }

    if (selectedPrintFrameOption && selectedPrintPaperOption) {
      lowestPrice = head(
        map(
          filter(
            priceOptions,
            (x) =>
              x.print_type.id === selectedPrintTypeOption &&
              x.print_size.id === selectedPrintSizeOption &&
              x.print_frame.id === selectedPrintFrameOption &&
              x.print_paper.id === selectedPrintPaperOption,
          ),
          (x) => x.price,
        ),
      );
    }

    return lowestPrice;
  }

  getPrintTypeById(print_type_id) {
    const print_type = uniqBy(
      map(
        filter(
          this.getVisibleObjects(),
          (x) => x.print_type.id === print_type_id,
        ),
        (x) => x.print_type,
      ),
      "id",
    );
    return print_type;
  }
}

export const printPriceConfigurationList = new PrintPriceConfigurationList(
  "printPriceConfiguration_default",
);

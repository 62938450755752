import * as React from "react";

import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

import { useGalleryFilterSearchParams } from "../hooks/useGalleryFilterSearchParams";
import { useNavigate } from "react-router-dom";

const breadCrumbLinkStyling = { cursor: "pointer" };

export const EventPageBreadCrumbs = ({
  album_name,
  gallery_event_name,
  galleryEventNameForURL,
}) => {
  const navigate = useNavigate();
  const [galleryFilterSearchParams, , searchParams] =
    useGalleryFilterSearchParams();

  const isUserSearch = galleryFilterSearchParams.any !== undefined;

  const goToEventsPage = () => {
    navigate(`/customer/events/?${searchParams}`);
  };

  const goToEventPage = () => {
    navigate(`/customer/events/${galleryEventNameForURL}?${searchParams}`);
  };

  return (
    <div role="presentation">
      <Breadcrumbs>
        <Link
          underline="hover"
          sx={breadCrumbLinkStyling}
          onClick={goToEventsPage}
          color={gallery_event_name ? "inherit" : "text.primary"}
        >
          {isUserSearch ? "Search results" : "All Galleries"}
        </Link>
        {gallery_event_name && (
          <Link
            underline="hover"
            sx={breadCrumbLinkStyling}
            onClick={goToEventPage}
            color={album_name ? "inherit" : "text.primary"}
          >
            {gallery_event_name?.replace(/-/g, " ")}
          </Link>
        )}
        {album_name && (
          <Typography color="text.primary">
            {album_name?.replace(/-/g, " ")}
          </Typography>
        )}
      </Breadcrumbs>
    </div>
  );
};

import React, { ForwardedRef } from "react";

import styles from "./Cart.module.css";
import { PropMixins, cssStyles } from "../mixins";

export const CartContainer = ({ children }: React.PropsWithChildren<{}>) => {
  return (
    <div className={styles.cartcontainer}>
      <div className={styles.cartcontainerinner}>{children}</div>
    </div>
  );
};

export const CartContainerItems = ({
  children,
}: React.PropsWithChildren<{}>) => {
  return <div className={styles.cartcontaineritems}>{children}</div>;
};

export const CartContainerSummary = ({
  children,
}: React.PropsWithChildren<{}>) => {
  return <div className={styles.cartcontainersummary}>{children}</div>;
};

export const CartItem = ({
  children,
  ...props
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>) => {
  return (
    <div {...props} className={styles.cartitem}>
      {children}
    </div>
  );
};

export const CartItemThumb = ({ children, ...props }: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>) => {
  return <div {...props}  className={styles.cartitemthumb}>{children}</div>;
};

export const CartItemSumary = ({ children }: React.PropsWithChildren<{}>) => {
  return <div className={styles.cartitemsummary}>{children}</div>;
};

export const MicroSummaryItem = ({ children, ...rest }: React.PropsWithChildren<PropMixins>) => {
  return <div className={styles.microsummaryitem} style={cssStyles(rest)}>{children}</div>;
};

export const MicroSummaryItemDesc = ({
  children, ...rest
}: React.PropsWithChildren<PropMixins>) => {
  return <div className={styles.microsummaryitemdesc} style={cssStyles(rest)}>{children}</div>;
};

export const MicroSummaryItemAction = ({
  children,
}: React.PropsWithChildren<{}>) => {
  return <div className={styles.microsummaryitemaction}>{children}</div>;
};

export const CartPrice = ({ children }: React.PropsWithChildren<{}>) => {
  return <div className={styles.cartprice}>{children}</div>;
};

export const CartSummaryLine = ({
  children,
  primary,
}: React.PropsWithChildren<{ primary?: boolean }>) => {
  const classes = [styles.cartsummaryline];
  if (primary) {
    classes.push(styles.cartsummarylineprimary);
  }

  return <div className={classes.join(" ")}>{children}</div>;
};

import map from "lodash/map";
import head from "lodash/head";
import get from "lodash/get";
import first from "lodash/first";
import filter from "lodash/filter";
import React, { useEffect, useState } from "react";
import {
  activityTagsDropdownOptions,
  photographerDropdownOptions,
} from "../v2_actions/dropdown_options";
import { getIsPhotographerIframe, isWindowInIframe } from "../v2_actions/nav";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { AddressSearch } from "./AddressSearch";
import CalendarFilter from "./filter/CalendarFilter";
import Card from "@mui/material/Card";
import CustomCalendarFilter from "./filter/CustomCalendarDateFilter";
import { DATE_FILTER_OPTIONS } from "../v2_actions/date";
import { DropFilterText } from "./filter/DropFilterValue";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { SearchGalleryNameFilter } from "./filter/SearchGalleryNameFilter";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import { basePhotographList } from "../v2_actions/customer_photograph";
import { briefGalleryList } from "../v2_actions/brief_gallery";
import { constructGalleryFilter } from "../v2_actions/gallery";
import { forceNewMapState } from "../v2_actions/map";
import { galleryEventList } from "../v2_actions/gallery_event";
import { getMobMaxWidthPixels } from "./layout/mobile";
import { makeStyles } from "@material-ui/core";
import moment from "moment";
import { photographerList } from "../v2_actions/photographer";
import { shouldShowSearchBox } from "../v2_actions/map";
import { useIsMobile } from "../actions/ui";

const useStyles = makeStyles((theme) => ({
  subNav: {
    display: "flex",
    flexDirection: "column",
  },
  filterWrapper: {
    width: "100%",
  },
  filterHeading: {
    color: "#444444",
    fontWeight: 800,
  },
  select: {
    width: "310px",
    height: "50px",
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      width: "175px",
    },
  },
  list: {
    width: "300px",
  },
  subRoot: {
    bottom: 14,
    display: "flex",
    flexDirection: "column",
    position: "relative",
    backgroundColor: "white",
    zIndex: "99",
    [`@media only screen and (min-width:${getMobMaxWidthPixels()})`]: {
      margin: "auto",
    },
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      top: "45px",
      position: "relative",
      zIndex: "100",
    },
  },
  root: {
    left: 20,
    height: 30,
    width: 450,
    display: "flex",
    borderRadius: 5,
    padding: "18px 30px",
    justifyContent: "space-around",
    alignItems: "center",
    position: "relative",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "10px",
    //  transform:'translateX(-46%)',
    border: "1px solid gray",
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      marginTop: "0px",
      marginBottom: "0px",
      width: "95%",
      fontSize: "16px",
      border: "none",
      flexWrap: "wrap",
      height: "90px",
    },
  },
  search: {
    "& p": {
      fontWeight: "600",
      color: "#0F75BC",
    },

    cursor: "pointer",
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      display: "none",
    },
  },
  location: {
    "& i": {
      paddingRight: "5px",
      fontSize: "17px",
    },
    display: "flex",
    alignItems: "center",
    color: "gray",
    cursor: "pointer",
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      "& i": {
        paddingRight: "5px",
        paddingLeft: "7px",
        fontSize: "15px",
      },
      flex: "1 0 50%",
    },
  },
  calendar: {
    "& i": {
      paddingRight: "5px",
    },
    display: "flex",
    alignItems: "center",
    color: "gray",
    cursor: "pointer",
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      "& i": {
        paddingRight: "5px",
        paddingLeft: "7px",
        fontSize: "15px",
      },
      flex: "1 0 50%",
    },
  },
  sport: {
    "& i": {
      paddingRight: "5px",
    },
    display: "flex",
    alignItems: "center",
    color: "gray",
    cursor: "pointer",
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      "& i": {
        paddingRight: "5px",
        paddingLeft: "7px",
        fontSize: "15px",
      },
      flex: "1 0 50%",
      marginTop: "-14px",
    },
  },
  photo: {
    "& i": {
      paddingRight: "13px",
    },
    display: "flex",
    alignItems: "center",
    color: "gray",
    cursor: "pointer",
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      "& i": {
        paddingRight: "5px",
        paddingLeft: "7px",
        fontSize: "15px",
      },
      flex: "1 0 50%",
      marginTop: "-14px",
    },
  },
  dropDownCard: {
    display: "flex",
    justifyContent: "space-between",
    position: "absolute",
    transform: "translateX(-34%)",
    marginTop: "70px",
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      left: "10%",
      zIndex: "99",
      marginTop: "0px",
      left: 0,
      zIndex: 99,
      transform: "translateX(0px)",
      marginTop: 40,
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
      width: "100%",
    },
  },
  drop1: {
    marginRight: "20px",
    marginLeft: "20px",
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      marginTop: "0px",
      width: "160px",
    },
  },
  drop2: {
    marginLeft: "130px",
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      marginTop: "0px",
      marginLeft: "30px",
    },
  },
  drop3: {
    marginLeft: "290px",
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      marginLeft: 0,
      marginTop: 0,
    },
  },
  drop4: {
    marginLeft: "490px",
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      marginTop: "40px",
      marginRight: "0px",
      marginLeft: "0px",
    },
  },
  drop5: {
    marginLeft: "630px",
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      marginLeft: 0,
      marginTop: 0,
    },
  },
  card: {
    width: "330px",
    padding: "20px",
    paddingLeft: "5px",
    borderRadius: "10px",
    transition: "0.3s",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
    },
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      width: "200px",
    },
  },
  wideCard: {
    width: "500px",
    padding: "20px",
    paddingLeft: "5px",
    borderRadius: "10px",
    transition: "0.3s",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
    },
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      width: "100%",
    },
  },
  inputLabel: {
    fontSize: "13px",
    "& i": {
      marginRight: "3px",
    },
  },
  menuItem: {
    color: "gray",
  },
  dropFilterTop: {
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      marginTop: "1px",
    },
  },
  dropFilterRight: {
    "& p": {
      fontWeight: 800,
    },
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      left: "8px",
    },
  },
  dropFilterGeneral: {
    "& p": {
      fontWeight: 400,
    },
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      position: "relative",
      padding: "3px 10px",
    },
  },
  dropFilter: {
    color: "#0C58D3",
    height: "30px",
    paddingLeft: "10px",
    paddingRight: "10px",
    borderRadius: "25px",
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      height: "30px",
      borderRadius: "0px",
      borderRadius: "15px",
      padding: "3px 10px",
      position: "relative",
    },
    "& p": {
      color: "#0C58D3",
      position: "relative",
      left: "7px",
      fontWeight: 800,
    },
    "& i": {
      paddingRight: 5,
    },
  },
  searchDrop: {
    "& input": {
      borderRadius: "15px",
      border: "2px solid black",
      padding: "5px",
      paddingLeft: "30px",
      position: "relative",
      width: "250px",
      marginLeft: "10px",
    },
    "& i": {
      position: "absolute",
      zIndex: "1",
      marginTop: "8px",
      marginLeft: "17px",
    },
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      "& input": {
        width: "140px",
      },
      "& i": {
        fontSize: "13px",
      },
    },
  },
  addressSearchWrapper: {
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      width: "100%",
      marginLeft: 0,
      marginRight: 0,
      position: "absolute",
    },
  },
  addressSearch: {
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      right: 10,
      width: "100%",
      position: "relative",
    },
  },
  addressSearchCard: {
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      width: "100%",
    },
  },
}));

export const GalleryFilter = ({ onStartingLocationSearch, ...props }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const classes = useStyles();
  const { photographerNickName } = useParams();
  const shouldShowSearchPlace = useSelector((state) =>
    shouldShowSearchBox(state),
  );
  const [searchDrop, setSearchDrop] = useState(false);
  const [anyFieldFilter, setAnyFieldFilter] = useState(null);
  const [locationDrop, setLocationDrop] = useState(shouldShowSearchPlace);

  const [showCalendarDropdown, setshowCalendarDropdown] = useState(false);
  const [showCustomDateFilter, setShowCustomDateFilter] = useState(false);
  const calendar_filter = get(briefGalleryList.getFilter(), "calendar_filter");
  const [calenDropFilter, setCalenDropFilter] = useState(
    calendar_filter || "placeholder_date_filter_option",
  );
  const [dateFilterTouched, setDateFilterTouched] = useState(
    calendar_filter ? true : false,
  );
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);

  const [sportDrop, setSportDrop] = useState(false);
  const activity_tags = get(briefGalleryList.getFilter(), "activity_tags");
  const [sportDropFilter, setSportDropFilter] = useState(
    head(activity_tags) || null,
  );
  const activityTagOptions = activityTagsDropdownOptions.getAsOptions();

  const url_filter = new window.URLSearchParams(window.location.search);
  const url_filter_photographer_id = url_filter.get("photographer") || null;
  const url_path_photographer_id =
    photographerNickName &&
    photographerList.getPhotographerIdForNickname(photographerNickName);
  const [photographDrop, setPhotographDrop] = useState(false);
  const [filtersWereTouched, setFiltersWereTouched] = useState(false);

  const photographer_filter = get(
    briefGalleryList.getFilter(),
    "photographers",
  );
  const [photoDropFilter, setPhotoDropFilter] = useState(
    url_filter_photographer_id || head(photographer_filter) || null,
  );
  const photographerOptions = photographerDropdownOptions.getAsOptions();
  const show_gallery_filter_date_dropdown = useSelector(() =>
    get(briefGalleryList.getFilter(), "show_gallery_filter_date_dropdown"),
  );

  const isInIframe = isWindowInIframe();
  const isPhotographerIframe = getIsPhotographerIframe();

  useEffect(() => {
    dispatch(activityTagsDropdownOptions.updatePaginationNumItemsPerPage(1000));
    dispatch(activityTagsDropdownOptions.fetchListIfNeeded());
    dispatch(photographerDropdownOptions.updatePaginationNumItemsPerPage(1000));
    dispatch(photographerDropdownOptions.fetchListIfNeeded());
    dispatch(photographerList.loadAllPhotographers());
    dispatch(basePhotographList.invalidateList());
    dispatch(basePhotographList.clearListFilter());
  }, []);

  useEffect(() => {
    if (url_path_photographer_id) {
      setPhotoDropFilter(url_path_photographer_id);
      dispatch(
        briefGalleryList.updateListFilter({
          photographer_filter: url_path_photographer_id,
        }),
      );
    }
    if (url_filter_photographer_id) {
      setPhotoDropFilter(url_filter_photographer_id);
      dispatch(
        briefGalleryList.updateListFilter({
          photographer_filter: url_filter_photographer_id,
        }),
      );
    }
  }, [url_path_photographer_id]);

  useEffect(() => {
    const constructedFilter = constructGalleryFilter({
      gallery_type: "photos",
      has_gallery_event: false,
      any_field: anyFieldFilter,
      activity_tags: [sportDropFilter],
      photographers: [photoDropFilter],
      filters_were_touched: filtersWereTouched,
      shot_at_date_to: dateTo,
      shot_at_date_from: dateFrom,
    });

    if (show_gallery_filter_date_dropdown) {
      dispatch(
        briefGalleryList.updateListFilter({
          show_gallery_filter_date_dropdown: false,
        }),
      );
      setFiltersWereTouched(true);
      onShowDateFilter();
    }

    dispatch(galleryEventList.invalidateList());
    dispatch(briefGalleryList.trySetFilter(constructedFilter));
    dispatch(galleryEventList.trySetFilter(constructedFilter));
  }, [
    anyFieldFilter,
    sportDropFilter,
    calenDropFilter,
    photoDropFilter,
    dateFrom,
    dateTo,
    show_gallery_filter_date_dropdown,
  ]);

  useEffect(() => {
    switch (calenDropFilter) {
      case null:
        setDateFrom(null);
        setDateTo(null);
        break;
      case "last_24_hrs":
        setDateFrom(moment());
        setDateTo(moment());
        break;
      case "last_2_days":
        setDateFrom(moment().subtract(2, "days"));
        setDateTo(moment());
        break;
      case "last_7_days":
        setDateFrom(moment().subtract(7, "days"));
        setDateTo(moment());
        break;
      case "last_30_days":
        setDateFrom(moment().subtract(31, "days"));
        setDateTo(moment());
        break;
      case "last_90_days":
        setDateFrom(moment().subtract(90, "days"));
        setDateTo(moment());
        break;
      case "last_180_days":
        setDateFrom(moment().subtract(180, "days"));
        setDateTo(moment());
        break;
      case "last_365_days":
        setDateFrom(moment().subtract(365, "days"));
        setDateTo(moment());
        break;
      case "last_2_years":
        setDateFrom(moment().subtract(2, "years"));
        setDateTo(moment());
        break;
      case "last_3_years":
        setDateFrom(moment().subtract(3, "years"));
        setDateTo(moment());
        break;
      case "custom":
        // do nothing, wait for a manual date to be set
        break;
      default:
        if (calenDropFilter !== get(first(DATE_FILTER_OPTIONS), "value")) {
          console.error(`Unknown date filter option: ${calenDropFilter}`);
        }
        setDateFrom(null);
        setDateTo(null);
        break;
    }
    dispatch(
      briefGalleryList.updateListFilter({ calendar_filter: calenDropFilter }),
    );
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [calenDropFilter]);

  useEffect(() => {
    if (shouldShowSearchPlace) {
      onShowLocationSearch();
    } else {
      setLocationDrop(false);
    }
  }, [shouldShowSearchPlace]);

  const onDateFilterChanged = (e) => {
    const option = e.target.value;
    setCalenDropFilter(option);
    if (option === "custom") {
      setShowCustomDateFilter(true);
    }
    setshowCalendarDropdown(false);
  };

  const onCustomDateFilterDoneClicked = () => {
    setShowCustomDateFilter(false);
  };

  const onSportFilterChanged = (e) => {
    setSportDropFilter(e.target.value);
    setSportDrop(false);
  };

  const onPhotographerFilterChanged = (e) => {
    setPhotoDropFilter(e.target.value);
    setPhotographDrop(false);
  };

  const onAnyFieldFilterChanged = (e) => {
    setAnyFieldFilter(e.target.value);
  };

  const onLocationFilterChanged = (places) => {
    const place = head(places);
    const loc = get(place, ["geometry", "location"]);
    const lat = loc.lat();
    const lng = loc.lng();
    const bounds = get(place, ["geometry", "viewport"]);
    dispatch(forceNewMapState({ lat, lng, bounds }));
    setLocationDrop(false);
  };

  const onShowGeneralSearch = () => {
    setSearchDrop((prevState) => !prevState);
    setLocationDrop(false);
    setshowCalendarDropdown(false);
    setSportDrop(false);
    setPhotographDrop(false);
  };

  const onShowLocationSearch = () => {
    setLocationDrop((prevState) => !prevState);
    setSearchDrop(false);
    setshowCalendarDropdown(false);
    setSportDrop(false);
    setPhotographDrop(false);
    if (onStartingLocationSearch) {
      onStartingLocationSearch();
    }
  };

  const onShowDateFilter = () => {
    setshowCalendarDropdown((prevState) => !prevState);
    setLocationDrop(false);
    setSearchDrop(false);
    setSportDrop(false);
    setPhotographDrop(false);
    setDateFilterTouched(true);
  };

  const onShowSportFilter = () => {
    setSportDrop((prevState) => !prevState);
    setshowCalendarDropdown(false);
    setLocationDrop(false);
    setSearchDrop(false);
    setPhotographDrop(false);
  };

  const onShowPhotographerFilter = () => {
    setPhotographDrop((prevState) => !prevState);
    setSearchDrop(false);
    setLocationDrop(false);
    setshowCalendarDropdown(false);
    setSportDrop(false);
  };

  const renderDateFilter = () => {
    const firstDateFilterOption = get(head(DATE_FILTER_OPTIONS), "value");
    const conditionsToShowDateFilterIsSelected =
      calenDropFilter &&
      dateFilterTouched &&
      calenDropFilter !== firstDateFilterOption;

    return (
      <div
        onClick={onShowDateFilter}
        className={`${classes.dropFilterGeneral}
           ${classes.dropFilterRight}
           ${classes.calendar}
           ${conditionsToShowDateFilterIsSelected ? classes.dropFilter : ""}`}
      >
        <DropFilterText
          icon={"fas fa-calendar-week"}
          text={get(
            head(
              filter(
                DATE_FILTER_OPTIONS,
                (option) => option.value === calenDropFilter,
              ),
            ),
            "label",
          )}
        />
      </div>
    );
  };

  const renderSportFilter = () => {
    return (
      <div
        onClick={onShowSportFilter}
        className={`${classes.dropFilterGeneral}
           ${classes.sport}
           ${sportDropFilter ? classes.dropFilter : ""}
           ${sportDropFilter && photoDropFilter ? classes.dropFilterTop : ""}`}
      >
        <DropFilterText
          default_text={"Sport"}
          icon={"fas fa-running"}
          text={get(
            activityTagsDropdownOptions.getObject(sportDropFilter),
            "name",
          )}
        />
      </div>
    );
  };

  const renderPhotographerFilter = () => {
    if (isInIframe && isPhotographerIframe) {
      return <></>;
    }
    return (
      <div
        onClick={onShowPhotographerFilter}
        className={`${classes.dropFilterGeneral}
           ${classes.dropFilterRight}
           ${classes.photo}
           ${photoDropFilter ? classes.dropFilter : ""}
           ${sportDropFilter && photoDropFilter ? classes.dropFilterTop : ""}`}
      >
        <DropFilterText
          icon={"fas fa-camera"}
          default_text={"Photographer"}
          text={get(
            photographerDropdownOptions.getObject(photoDropFilter),
            "name",
          )}
        />
      </div>
    );
  };

  const renderMobileFilterGroup = () => {
    return (
      <div>
        <Stack
          direction="row"
          alignItems="center"
          className={classes.mobileEventFilterTop}
        >
          <div
            onClick={onShowLocationSearch}
            className={`${classes.dropFilterGeneral}
             ${classes.location}`}
          >
            <i className="fas fa-map-marker-alt"></i>
            <p>Location</p>
          </div>
          {renderDateFilter()}
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          className={classes.mobileEventFilterTop}
        >
          {renderSportFilter()}
          {renderPhotographerFilter()}
        </Stack>
      </div>
    );
  };
  const renderDesktopFilterGroup = () => {
    return (
      <Stack
        spacing={2}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        className={classes.filterWrapper}
      >
        <div className={classes.root}>
          <div>
            <h6 className={classes.filterHeading}>Filters</h6>
          </div>
          {/* <div className={ classes.search }  onClick={ onShowLocationSearch }>
              <p>{ anyFieldFilter || 'Search' }</p>
              </div> */}
          {renderDateFilter()}
          {renderSportFilter()}
          {renderPhotographerFilter()}
        </div>
        <div></div>
        <SearchGalleryNameFilter
          value={anyFieldFilter}
          handleChange={onAnyFieldFilterChanged}
        />
      </Stack>
    );
  };

  const renderFilterDrops = () => {
    return (
      <div
        className={`${classes.dropDownCard} ${classes.addressSearchWrapper}`}
      >
        {locationDrop && isMobile && (
          <div className={`${classes.drop1} ${classes.addressSearch}`}>
            <Card className={`${classes.card} ${classes.addressSearchCard}`}>
              <i className="fas fa-search"></i>
              <AddressSearch onChanged={onLocationFilterChanged} />
            </Card>
          </div>
        )}
        {showCalendarDropdown && (
          <div className={classes.drop3}>
            <CalendarFilter
              onDateFilterChanged={onDateFilterChanged}
              showCalendarDropdown={showCalendarDropdown}
            />
          </div>
        )}
        {showCustomDateFilter === true && calenDropFilter === "custom" && (
          <CustomCalendarFilter
            dateTo={dateTo}
            dateFrom={dateFrom}
            setDateTo={setDateTo}
            setDateFrom={setDateFrom}
            onCustomDateFilterDoneClicked={onCustomDateFilterDoneClicked}
          />
        )}
        {sportDrop && (
          <div className={classes.drop4}>
            <Card className={classes.card}>
              <FormControl sx={{ m: 1, minWidth: 80 }}>
                <InputLabel className={classes.inputLabel}>
                  <i className="fas fa-running"></i>
                  {get(
                    activityTagsDropdownOptions.getObject(sportDropFilter),
                    "name",
                  )}
                </InputLabel>
                <Select
                  open={sportDrop}
                  className={classes.select}
                  onChange={onSportFilterChanged}
                  label={get(
                    activityTagsDropdownOptions.getObject(sportDropFilter),
                    "name",
                    "Sport",
                  )}
                >
                  <MenuItem value={null} className={classes.menuItem}>
                    All
                  </MenuItem>
                  {map(activityTagOptions, (option, index) => (
                    <MenuItem
                      key={index}
                      value={option.value}
                      className={classes.menuItem}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Card>
          </div>
        )}
        {photographDrop && (
          <div className={classes.drop5}>
            <Card className={classes.card}>
              <FormControl sx={{ m: 1, minWidth: 80 }}>
                <InputLabel className={classes.inputLabel}>
                  <i className="fas fa-camera"></i>
                  Photographer
                </InputLabel>
                <Select
                  label="Photograph"
                  className={classes.select}
                  onChange={onPhotographerFilterChanged}
                  open={photographDrop}
                >
                  <MenuItem value={null} className={classes.menuItem}>
                    All
                  </MenuItem>
                  {map(photographerOptions, (option, index) => (
                    <MenuItem
                      key={index}
                      value={option.value}
                      className={classes.menuItem}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Card>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={classes.subRoot}>
      {renderFilterDrops()}
      {isMobile && renderMobileFilterGroup()}
      {!isMobile && renderDesktopFilterGroup()}
    </div>
  );
};

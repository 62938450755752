import keys from "lodash/keys";
import map from "lodash/map";
import { showError } from "./Error";

export const handleSubmitResultReactHookForm = ({ res, hook_form, on_ok }) => {
  if (res.errors) {
    const { setError } = hook_form;
    map(keys(res.errors), function (field_name) {
      hook_form.setError(field_name, {
        type: "custom",
        message: res.errors[field_name],
      });
    });
    showError("Failed to save");
  } else {
    on_ok(res);
  }
};

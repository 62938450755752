import moment from "moment";

let date = new Date();
// let pastDate = date.getDate() - 60;
let pastDate = date.getDate() - 30;
let firstDay = date.setDate(pastDate);
let lastDay = new Date();
// let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
// let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

const initialState = {
  galleryAllList: [],
  galleryInfoList: [{ id: 0, sporttag: "All Sports" }],
  photoGrapherAllList: [],
  galleryFilterList: false,
  allPhotosByGid: [],
  latlngList: [],
  filterSaveList: {
    filterPhotoGrapher: 0,
    filterSportTag: "All Sports",
    filterDateRange: {
      startDate: firstDay,
      endDate: lastDay,
    },
    filterTime: new Date(),
    latlngList: false,
    filterZoom: false,
    filterPosition: false,
  },
  generalInfo: {
    galleryId: null,
    galleryName: null,
    photoGrapherId: null,
    photoGrapherName: null,
    photoGrapherPhoto: null,
  },
  priceByGid: false,
  priceByPid: false,
  zoom: 0,
  diffDateTime: [
    moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
    moment(new Date(new Date().getDate() + 1)).format("YYYY-MM-DD HH:mm:ss"),
  ],
  defaultValue:false,
  CurrencySymbol:"$"
};

export default function Gallery(state = initialState, action) {
  switch (action.type) {
    case "GALLERY/SETGALLERYFILTERLIST":
      return {
        ...state,
        galleryFilterList: action.galleryFilterList,
      };
    case "GALLERY/SETGALLERYINFOLIST":
      return {
        ...state,
        galleryInfoList: action.galleryInfoList,
      };
    case "GALLERY/SETGALLERYLIST":
      return {
        ...state,
        galleryAllList: action.galleryAllList,
      };
    case "GALLERY/SETFILTERLATLNG":
      return {
        ...state,
        latlngList: action.latlngList,
      };
    case "GALLERY/SETPHOTOGRAPHERLIST":
      return {
        ...state,
        photoGrapherAllList: action.photoGrapherAllList,
      };
    case "GALLERY/SETALLPHOTOSBYGID":
      return {
        ...state,
        allPhotosByGid: action.allPhotosByGid,
      };
    case "GALLERY/SETZOOM":
      return {
        ...state,
        zoom: action.zoom,
      };
    case "GALLERY/SETDIFFDATTIME":
      return {
        ...state,
        diffDateTime: action.diffDateTime,
      };
    case "GALLERY/SETFILTERSAVELIST":
      return {
        ...state,
        filterSaveList: action.filterSaveList,
      };
    case "GALLERY/SETGENERALINFO":
      return {
        ...state,
        generalInfo: action.generalInfo,
      };
    case "GALLERY/SAVEFILTERLIST":
      return {
        ...state,
        saveFilterList: action.saveFilterList,
      };
    case "GALLERY/SETPRICEBYGID":
      return {
        ...state,
        priceByGid: action.priceByGid,
      };
    case "GALLERY/SETPRICEBYPID":
      return {
        ...state,
        priceByPid: action.priceByPid,
      };
    case "GALLERY/SETCOUNTRYDEFAULTVALUE":
      return {
        ...state,
        defaultValue: action.defaultValue,
      };
    case "SET_CURRENCY_SYMBOL": 
      return {...state,  CurrencySymbol:action.payload };
    default:
      return state;
  }
}

import map from "lodash/map";
import filter from "lodash/filter";
import head from "lodash/head";
import lowerCase from "lodash/lowerCase";
import get from "lodash/get";
import { ItemList } from "../orm";
import { DEFAULT_COUNTRY_CODE } from "../utils/detectCountryCode";

class CountryList extends ItemList {
  getEntityKey() {
    return "country";
  }

  loadAllCountries() {
    return async (dispatch, getState) => {
      dispatch(this.updatePaginationNumItemsPerPage(1000));
      dispatch(this.fetchListIfNeeded());
    };
  }

  getDefaultCountry() {
    return this.getCountryForCountryCode(DEFAULT_COUNTRY_CODE);
  }

  getAllCountryCodes() {
    return map(this.getAllObjects(), (country) => country.country_code);
  }

  getCountryForId(country_id) {
    return this.getObject(country_id);
  }

  getCountryForCountryCode(country_code) {
    return head(
      filter(
        this.getAllObjects(),
        (country) => country.country_code === lowerCase(country_code),
      ),
    );
  }

  getCurrencyCode(country_id) {
    const country = this.getCountryForId(country_id);
    return get(country, "currency_code");
  }

  getCurrencySymbol(country_id) {
    const country = this.getCountryForId(country_id);
    return get(country, "currency_symbol");
  }

  getCountryCode(country_id) {
    const country = this.getCountryForId(country_id);
    return lowerCase(get(country, "country_code"));
  }

  getPaymentGateway(country_id) {
    const country = this.getCountryForId(country_id);
    return lowerCase(get(country, "payment_gateway"));
  }

  findForCountryCode(country_code) {
    return head(
      filter(this.getObjects(), (x) => x.country_code === country_code),
    );
  }

  getCountriesAsOptions() {
    const countries = this.getVisibleObjects();
    return map(countries, function (x) {
      return {
        value: x.id,
        label: x.country_name,
      };
    });
  }
}

export const countryList = new CountryList("country_list_default");

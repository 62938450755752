import { ItemList } from "../orm";

class TrolleyProcessPayment extends ItemList {
  getEntityKey() {
    return "customer/purchased_trolley/process_payment";
  }

  pay({ trolley_id, gateway_payment_ref }) {
    return this.saveNewObject({ trolley_id: trolley_id, gateway_payment_ref });
  }
}

export const trolleyProcessPayment = new TrolleyProcessPayment(
  "trolley_process_payment_default",
);

import React from "react";
import styles from "./HeaderButton.module.css";

type HeaderButtonProps = Omit<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >,
  "className"
>;

/**
 * Header Button
 * @returns
 */
const HeaderButton = ({ children, ...props }: HeaderButtonProps) => {
  return (
    <button {...props} className={styles.headerbutton}>
      {children}
    </button>
  );
};

export default HeaderButton;

const enabledGlobally = window.location.hostname === "localhost";
const LOG_PREFIX = `%cBF:`;
const logStyle = (color) =>
  `color:white;background-color:${color};border-radius:3px;padding:2px 4px;margin-left:2px;`;

export const makeLogger = ({ enabled, label, color }) => ({
  log: (...args) =>
    enabledGlobally &&
    enabled &&
    console.log(`${LOG_PREFIX}${label}`, logStyle(color), ...args),
  debug: (...args) =>
    enabledGlobally &&
    enabled &&
    console.debug(`${LOG_PREFIX}${label}`, logStyle(color), ...args),
  warn: (...args) =>
    enabledGlobally &&
    enabled &&
    console.warn(`${LOG_PREFIX}${label}`, logStyle(color), ...args),
  error: (...args) =>
    enabledGlobally &&
    enabled &&
    console.error(`${LOG_PREFIX}${label}`, logStyle(color), ...args),
  trace: (...args) =>
    enabledGlobally &&
    enabled &&
    console.trace(`${LOG_PREFIX}${label}`, logStyle(color), ...args),
});

import React from "react"
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    error: {
        color: "red"
    }
}))

export const Error = ({children}) => {
    const classes = useStyles()
    if ( ! children ) {
        return null
    }
    return (
        <div className={classes.error}>
          {children}
        </div>
    )
}

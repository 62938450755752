import get from "lodash/get";
import React, { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import { useIsMobile } from "../../actions/ui";
import { getRandomBackgroundColor } from "../../utils/color";
import GalleryPhotographTimeTakenAtOverlay from "./GalleryPhotographTimeTakenAtOverlay";
import GalleryPhotographPriceBox from "./GalleryPhotographPriceBox";
import { FunctioningGalleryPhotographTrolleyIcon } from "./GalleryPhotographsFunctioningTrolleyIcon";

const photographGridWrapper = {
  height: "inherit",
  display: "grid",
  overflow: "visible",
  position: "relative",
  flexDirection: "column",
  boxShadow: 0,
  overflow: "auto",
  height: "maxContent",
};

const photographCardWrapper = {
  height: "inherit",
  display: "flex",
  overflow: "visible",
  position: "initial",
  gridColumn: 1,
  gridRow: 1,
  cursor: "pointer",
  flexDirection: "column",
  boxShadow: 0,
  overflow: "auto",
  height: "maxContent",
};
const photographCard = {
  top: 0,
  left: 0,
  width: "100%",
  backgroundSize: "contain",
  height: "inherit",
  position: "relative",
};

const priceAndTrolleyIconWrapper = {
  zIndex: "1",
  top: "50px",
  position: "absolute",
  padding: "0px 20px 0px 50px",
};

export default function GalleryPhotograph({
  trolley,
  gallery,
  photograph,
  isTrolleyEmpty,
  trolleyPhotographs,
  revealHighResPhotos,
  setSelectedImgId,
  setShowMethod,
  enableTrolleyIcon,
}) {
  const dispatch = useDispatch();
  const [bgColor] = useState(getRandomBackgroundColor());
  const isMobile = useIsMobile();
  const [showPhotographTimeTakenBox, setShowPhotographTimeTakenBox] =
    useState(false);
  const [canShowPhotographOverlays, setCanShowPhotographOverlays] =
    useState(false);

  const onSelectImage = useCallback((photographId) => {
    setSelectedImgId && setSelectedImgId(photographId);
    setShowMethod && setShowMethod(true);
  }, []);

  return (
    <Grid
      item
      key={photograph.id}
      xs={12}
      md={4}
      sx={photographGridWrapper}
      onMouseEnter={() => setShowPhotographTimeTakenBox(true)}
      onMouseLeave={() => setShowPhotographTimeTakenBox(false)}
    >
      {canShowPhotographOverlays && (
        <Box
          display="flex"
          width="100%"
          justifyContent="space-between"
          sx={priceAndTrolleyIconWrapper}
        >
          <GalleryPhotographPriceBox
            gallery={gallery}
            price={photograph.price}
            showPhotographTimeTakenBox={showPhotographTimeTakenBox}
          />
          <FunctioningGalleryPhotographTrolleyIcon
            showIcons
            gallery={gallery}
            enableTrolleyIcon={enableTrolleyIcon}
            photoId={get(photograph, "id")}
            isTrolleyEmpty={isTrolleyEmpty}
            selected={get(photograph, "selected")}
            price={get(photograph, "price")}
            photographer={get(gallery, "photographer")}
            trolley={trolley}
            trolleyPhotographs={trolleyPhotographs}
            trolleyPhotograph={get(photograph, "trolleyPhotograph")}
          />
        </Box>
      )}
      <Card sx={{ ...photographCardWrapper, backgroundColor: bgColor }}>
        <CardMedia
          component="img"
          sx={photographCard}
          onLoad={() => setCanShowPhotographOverlays(true)}
          image={get(photograph, ["gallery_size_file_info", "download_url"])}
          onClick={() => onSelectImage(get(photograph, "id"))}
          className={`image loaded`}
          src={get(photograph, ["gallery_size_file_info", "download_url"])}
          style={{
            padding: 0,
            width: "100%",
          }}
        />
      </Card>
      {!isMobile && showPhotographTimeTakenBox && (
        <GalleryPhotographTimeTakenAtOverlay photograph={photograph} />
      )}
    </Grid>
  );
}

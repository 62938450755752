import React from "react";
import styles from "./bossfotos.module.css";

/**
 * ThemeBossFotos
 * @returns
 */
const ThemeBossFotos = ({ children }: React.PropsWithChildren<{}>) => {
    return <div className={styles.theme}>{children}</div>;
};


export default ThemeBossFotos;

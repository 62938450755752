import { SET_TROLLEY_MESSAGE, SET_TROLLEY_ID, CLEAR_TROLLEY_ID } from '../actions/trolley'

const initialState = {
  message: false,
  trolley_id: null,
};

export default function TrolleyReducer(state = initialState, action) {
  switch (action.type) {
    case SET_TROLLEY_MESSAGE:
      return {
        ...state,
        message: action.message,
      };
    case SET_TROLLEY_ID:
      return {
        ...state,
        trolley_id: action.trolley_id,
      }
    case CLEAR_TROLLEY_ID:
      return {
        ...state,
        trolley_id: null,
      }
    default:
      return state;
  }
}

import { GalleryCardWrapper } from "./GalleryCardWrapper";
import React from "react";
import { formatSessionNameForURL } from "../v2_actions/nav";
import { useGalleryFilterSearchParams } from "./hooks/useGalleryFilterSearchParams";
import { useNavigate } from "react-router-dom";

export const AlbumCard = ({ tab, image, title, gallery_event_name }) => {
  const navigate = useNavigate();
  const [, , searchParams] = useGalleryFilterSearchParams();

  const onSelectedAlbum = () => {
    navigate(
      `/customer/events/${formatSessionNameForURL(
        gallery_event_name,
      )}/${formatSessionNameForURL(title)}?${searchParams}`,
    );
  };

  return (
    <>
      <GalleryCardWrapper
        tab={tab}
        image={image}
        albumTitle={title}
        onClick={onSelectedAlbum}
        cardType={"gallery-event"}
      />
    </>
  );
};

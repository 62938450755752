import {
  NavInline,
  Navigation,
  Shell,
  ViewPort,
} from "bossfotos-design-system";

import LoadingSpinner from "../../views/components/LoadingSpinner";
import { isWindowInIframe } from "../../v2_actions/nav";
import { BossFotosNavBarLogo } from "../BossFotosNavBarLogo";

function LoadingHorseShoe() {
  const isInIframe = isWindowInIframe();

  return (
    <ViewPort>
      <Shell>
        {!isInIframe && (
          <Navigation>
            <NavInline>
              <BossFotosNavBarLogo height={28} />
            </NavInline>
          </Navigation>
        )}
        <LoadingSpinner />
      </Shell>
    </ViewPort>
  );
}

export default LoadingHorseShoe;

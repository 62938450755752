import map from "lodash/map";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  tabTitle: {
    margin: 0,
    fontSize: 12,
    color: "#7a7a7a",
    textAlign: "center",
    textTransform: "capitalize",
  },
  tabColumnWrapper: {
    inlineSize: "100px",
    overflowWrap: "break-word",
    "& svg": {
      color: "#7a7a7a",
    },
  },
  tabRow: {
    height: 170,
    display: "grid",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
  },
  tabRowWrapper: {
    display: "grid",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
  },
}));

function TabPanel(props) {
  const {
    value,
    index,
    classes,
    activeTab,
    children,
    expandActiveMobileTabFilter,
    ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      className={classes.tabRowWrapper}
      {...other}
    >
      {value === index &&
        activeTab === index &&
        expandActiveMobileTabFilter && (
          <Box sx={{ p: 3 }} className={classes.tabRow}>
            <Typography>{children}</Typography>
          </Box>
        )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index, activeTab) {
  return index === activeTab ? { id: `active_tab` } : { id: `tab_${index}` };
}

export default function ReusableTabs({
  variant,
  columns,
  rows,
  expandActiveMobileTabFilter,
  setExpandActiveMobileTabFilter,
  scrollButtons = false,
}) {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [activeTab, setActiveTab] = useState(null);

  const handleTabClick = (index) => {
    setActiveTab(index);
    setExpandActiveMobileTabFilter(true);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setActiveTab(newValue);
  };

  useEffect(() => {
    if (activeTab) {
      setExpandActiveMobileTabFilter(true);
    }
  }, [activeTab]);

  useEffect(() => {
    if (!expandActiveMobileTabFilter) {
      setExpandActiveMobileTabFilter(false);
      setExpandActiveMobileTabFilter(false);
    }
  }, [expandActiveMobileTabFilter]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs centered value={value} onChange={handleChange}>
          {map(columns, (column, index) => (
            <Tab
              key={index}
              icon={column.icon}
              onClick={() => handleTabClick(index)}
              {...a11yProps(index, activeTab)}
              className={[
                classes.tabColumnWrapper,
                activeTab === index && classes.activetabColumnWrapper,
              ]}
              label={<p className={classes.tabTitle}>{column.title}</p>}
            />
          ))}
        </Tabs>
      </Box>
      {map(rows, (row, index) => (
        <TabPanel
          key={index}
          value={value}
          index={index}
          classes={classes}
          activeTab={activeTab}
          expandActiveMobileTabFilter={expandActiveMobileTabFilter}
        >
          {row}
        </TabPanel>
      ))}
    </Box>
  );
}

import get from "lodash/get";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CurrencyValue } from "../../v2_components/CurrencyValue.js";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import Collapse from "@mui/material/Collapse";
import { makeStyles } from "@material-ui/core";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { printPriceConfigurationList } from "../../v2_actions/print_price_configuration.js";
import { css, Global } from "@emotion/react";
import { trolleyPhotographList } from "../../v2_actions/trolley_photograph.js";

const useStyles = makeStyles((theme) => ({
  totalRow: {
    borderTop: "solid 1px #c0c0c0",
    borderBottom: "solid 1px #c0c0c0",
  },
}));

const global_styles = css`
  b {
    font-weight: 600;
  }
`;

export const PhotoCostSummary = ({
  trolley,
  photographer,
  trolley_photograph,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const photo_delivery_type = get(trolley_photograph, "photo_delivery_type");
  const printMarkupPercentage =
    1 + get(photographer, ["price_info", "print_markup_percentage"]) / 100;
  const trolleyPhotographLicensePrice = get(
    trolley_photograph,
    "photograph_license_price",
  );
  const trolleyPhotographBasePrice = get(trolley_photograph, "base_price");
  const trolleyPhotographLowResPrice = get(trolley_photograph, "low_res_price");
  const trolleyPhotographQuality = get(trolley_photograph, "quality");
  const customerPhotographPrice = get(
    trolley_photograph,
    "base_price_in_customer_currency",
  );
  const customerFx = get(
    trolley_photograph,
    "customer_conversion_rate_to_pound",
  );
  const photographerFx = get(
    trolley_photograph,
    "photographer_conversion_rate_to_pound",
  );
  const countryPrintConfigurationForPhoto = useSelector(() =>
    printPriceConfigurationList.getObject(
      get(trolley_photograph, "country_print_price"),
    ),
  );
  const printPriceWithMarkup =
    (photographerFx / customerFx) *
    (get(countryPrintConfigurationForPhoto, "price") * printMarkupPercentage);
  const isDigitalDeliveryType =
    trolleyPhotographList.isDigitalDeliveryType(photo_delivery_type);

  useEffect(() => {
    dispatch(printPriceConfigurationList.fetchListIfNeeded());
  }, []);

  const renderPhotoPrintCostBreakdown = () => {
    return (
      <>
        <TableRow>
          <TableCell>
            {trolleyPhotographLicensePrice === trolleyPhotographLowResPrice
              ? "Low Res Photo License:"
              : "High Res Photo License:"}
          </TableCell>
          <TableCell>
            <CurrencyValue
              country={get(photographer, "country")}
              value={trolleyPhotographLicensePrice}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            Frame:{" "}
            {get(countryPrintConfigurationForPhoto, ["print_frame", "name"])}
          </TableCell>
          <TableCell>
            <CurrencyValue
              country={get(photographer, "country")}
              value={get(countryPrintConfigurationForPhoto, "price")}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            Stock:{" "}
            {get(countryPrintConfigurationForPhoto, ["print_paper", "name"])}
          </TableCell>
          <TableCell>Included</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            Size: {get(countryPrintConfigurationForPhoto, "print_size_name")}
          </TableCell>
          <TableCell>Included</TableCell>
        </TableRow>
      </>
    );
  };

  return (
    <>
      <Global styles={global_styles} />
      <TableRow>
        <TableCell>
          <b>Selected Options</b>
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <RemoveIcon /> : <AddIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Table>
                <TableBody>
                  {isDigitalDeliveryType && (
                    <TableRow>
                      <TableCell>
                        {trolleyPhotographLicensePrice ===
                        trolleyPhotographLowResPrice
                          ? "Low Res Photo License:"
                          : "High Res Photo License:"}
                      </TableCell>
                      <TableCell>
                        <CurrencyValue
                          country={get(photographer, "country")}
                          value={trolleyPhotographLicensePrice}
                        />
                      </TableCell>
                    </TableRow>
                  )}
                  {!isDigitalDeliveryType && (
                    <>{renderPhotoPrintCostBreakdown()}</>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <TableRow className={classes.totalRow}>
        <TableCell>
          <b>Item Total</b>
        </TableCell>
        <TableCell>
          {get(trolley_photograph, "country_print_price") && (
            <>
              <CurrencyValue
                country={get(photographer, "country")}
                value={trolleyPhotographBasePrice + printPriceWithMarkup}
              />
            </>
          )}
          {!get(trolley_photograph, "country_print_price") && (
            <>
              <CurrencyValue
                country={get(photographer, "country")}
                value={trolleyPhotographLicensePrice}
              />
            </>
          )}
        </TableCell>
      </TableRow>
    </>
  );
};

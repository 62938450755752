// eslint-disable-next-line 
export default (theme) => ({
  root: {
    display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%",
  },
  menuWrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    marginTop: 65,
    height: "calc(100vh)",
    touchAction: "none",
    [theme.breakpoints.up("lg")]: {
      marginTop: 65,
      paddingLeft: 300,
    },
  },
  nonMennuWrapper: {
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 0)",
    flex: "1 1 auto",
    overflow: "hidden",
    touchAction: "none",
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  content: {
    flex: "1 1 auto",
    height: "100%",
    overflow: "auto",
  },
  bottom: {
    display: "flex",
    justifyContent: "center",
    width: "90%",
    margin: "auto",
  },
  logo: {
    fontFamily: "Aguafina Script",
    fontSize: 60,
    fontWeight: "400",
  },
  paper: {
    position: "absolute",
    background: "white",
    width: 750,
    border: "2px solid #0c59cf !important",
    color: "#0c59cf",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: "left",
  },
  mobilepaper: {
    position: "absolute",
    background: "white",
    width: 360,
    border: "2px solid #0c59cf !important",
    color: "#0c59cf",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: "left",
  },
  submitButton: {
    color: "white",
    width: 200,
    height: 50,
    fontSize: 18,
    textTransform: "capitalize",
    backgroundColor: "#0c59cf !important",
  },
  mobilesubmitbutton: {
    color: "white",
    width: 150,
    height: 50,
    fontSize: 14,
    textTransform: "capitalize",
    backgroundColor: "#0c59cf !important",
  }
});

import React, { CSSProperties } from "react";
import styles from "./NavButton.module.css";
import { cssStyles, PropMixins } from "../mixins";
import { useResponsiveBreakpoints } from "../primitives/useResponsiveBreakpoints";

const variantsStyles: Record<string, CSSProperties> = {
  default: {},
  input: {
    color: "var(--input-icon-color)",
  },
  expander: {
    height: "var(--expander-height)",
  },
};

type Variants = keyof typeof variantsStyles;

export type NavButtonProps = {
  variant?: Variants;
} & Omit<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >,
  "className"
> & PropMixins;

/**
 * Nav Button
 * @returns
 */
const NavButton = ({ variant, children, ...props }: NavButtonProps) => {
  const activeBreakpoint = useResponsiveBreakpoints();
  return (
    <button
      {...props}
      className={styles.navbutton}
      style={{ 
        ...variantsStyles[variant ?? "default"], 
        ...props.style,
        ...cssStyles(props, activeBreakpoint)
      }}
    >
      {children}
    </button>
  );
};

export default NavButton;

import {
  getIsEventIframe,
  getIsPhotographerIframe,
  isWindowInIframe,
} from "../../v2_actions/nav";

import DesktopEventPageMapButton from "../DesktopEventPageMapButton";
import FilterActivity from "./FilterActivity";
import FilterCountry from "./FilterCountry";
import FilterDate from "./FilterDate";
import FilterGalleryEvent from "./FilterGalleryEvent";
import FilterPhotographer from "./FilterPhotographer";
import Link from "@mui/material/Link";
import { makeLogger } from "../../utils/o11y/logger";
import { useEventFiltersDispatcher } from "../hooks/useEventFiltersDispatcher";
import { useNavigate } from "react-router-dom";
import { useReduxStateReconcilation } from "../hooks/useReduxStateReconcilation";

const logger = makeLogger({
  enabled: true,
  label: "DesktopEventPageFilter",
  color: "pink",
});

export default function DesktopEventPageFilter({ gallery_event_name }) {
  useReduxStateReconcilation();
  const eventPageCtx = useEventFiltersDispatcher();
  const navigate = useNavigate();
  const isInIframe = isWindowInIframe();
  const isEventIframe = getIsEventIframe();
  const isPhotographerIframe = getIsPhotographerIframe();

  const handleClearFiltersClick = () => {
    logger.log("Reset all filter");
    let clearArg = {
      gallery_type: "event",
      activity_tags: undefined,
      shot_at_date_from: undefined,
      shot_at_date_to: undefined,
      // never clear country or search with this
      // any_field: undefined,
      // country: undefined,
      _callSource: "DesktopEventPageFilter",
    };
    if (!isEventIframe) {
      clearArg.gallery_event = undefined;
    }
    if (!isPhotographerIframe) {
      clearArg.photographers = undefined;
    }
    logger.log("CLEARALL", { isEventIframe, clearArg, gallery_event_name });
    eventPageCtx.updateAllListFilters(clearArg);

    if (isEventIframe) {
      // preserve event if in event iframe
      navigate(`/customer/events/${gallery_event_name}`);
    } else {
      navigate(`/customer/events/`);
    }
  };

  // event -> no event filter, no country
  // photographer -> no photographer, no country

  const showCountry = !isInIframe;
  const showEvent = !isEventIframe;
  const showPhotographer = !isPhotographerIframe;

  logger.log({ isInIframe, showCountry, showEvent, showPhotographer });

  return (
    <>
      {!isInIframe && <DesktopEventPageMapButton />}
      {isInIframe && <div style={{ height: "10px" }}></div>}
      <h4>
        Filter by:
        {!isInIframe && (
          <Link
            size="small"
            sx={{
              cursor: "pointer",
              marginLeft: "24px",
              fontSize: "14px",
              fontWeight: "normal",
              ...(eventPageCtx.hasActiveFilters
                ? {}
                : { visibility: "hidden" }),
            }}
            onClick={handleClearFiltersClick}
          >
            clear all
          </Link>
        )}
      </h4>
      {showCountry && <FilterCountry />}
      {showEvent && <FilterGalleryEvent />}
      <FilterDate />
      <FilterActivity />
      {showPhotographer && <FilterPhotographer />}
    </>
  );
}

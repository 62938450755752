import isEmpty from "lodash/isEmpty";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { useIsMobile } from "../../actions/ui";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@material-ui/core";
import { fileInfoList } from "../../v2_actions/customer_file_info";

const useStyles = makeStyles((theme) => ({
  uploadButton: {
    width: 180,
    height: 25,
  },
  cancelButton: {
    width: 180,
    height: 25,
    backgroundColor: "#606060",
  },
  photographWrapper: {
    bottom: 20,
    width: 180,
    height: 100,
    margin: "auto",
    background: "#fff",
    cursor: "pointer",
    position: "relative",
    border: "dashed 1px #000",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
      marginTop: 20,
    },
  },
  facialRecognitionText: {
    fontSize: 16,
    color: "#000",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  uploadSelfieButton: {
    height: 25,
    "& p": {
      fontSize: 12,
      fontWeight: 600,
    },
  },
  closeIcon: {
    left: "80%",
    bottom: "80%",
    color: "#000",
    cursor: "pointer",
    position: "absolute",
    fontSize: "20px !important",
    margin: "-5px 0px 0px 15px",
  },
  previewImage: {
    marginTop: 10,
    height: "80px",
  },
}));

export const PhotographUploader = ({
  preview,
  onChange,
  disabled,
  setPreview,
  gallery_id,
  maxFileLimit,
  fileAnnotations,
  customButtonStyle,
  clearFacialRecognition,
  setIsPhotographUploading,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { open, getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFiles(acceptedFiles);
      setIsLoading(true);
      setIsPhotographUploading(true);
      dispatch(
        fileInfoList.upload(
          acceptedFiles[0],
          gallery_id,
          undefined,
          (file_info) => {
            onChange(file_info);
            setIsLoading(false);
            setIsPhotographUploading(false);
          },
        ),
      );
    },
  });

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => window.URL.revokeObjectURL(file.preview));
    },
    [files],
  );

  useEffect(() => {
    files.map((file) => setPreview(URL.createObjectURL(file)));
  }, [files]);

  const renderFacialRecognitionSelfiePreview = () => {
    return (
      <div onClick={open}>
        {preview && <img src={preview} className={classes.previewImage} />}
        {!preview && (
          <p className={classes.facialRecognitionText}>
            Upload a Selfie
            <br />
            for Facial Recognition
          </p>
        )}
      </div>
    );
  };

  const renderClearFacialRecognitionFilter = () => {
    return (
      <>
        <CloseIcon
          className={classes.closeIcon}
          onClick={clearFacialRecognition}
        />
      </>
    );
  };

  const renderCancelUploadButton = () => {
    return (
      <Button
        onClick={clearFacialRecognition}
        variant="contained"
        sx={{ backgroundColor: "#606060" }}
        className={`${customButtonStyle} ${classes.cancelButton}`}
      >
        <p>Cancel</p>
      </Button>
    );
  };

  const renderUploadButton = () => {
    return (
      <Button
        variant="contained"
        disabled={disabled || isLoading}
        className={`${classes.uploadButton} ${customButtonStyle}`}
        isValueSet={!isEmpty(files)}
      >
        <div {...getRootProps({ className: classes.dropzone })}>
          <input {...getInputProps()} />
          <p>
            {isLoading && "Uploading..."}
            {!isLoading && isEmpty(files) && "Upload a Selfie"}
            {!isLoading && !isEmpty(files) && "Upload a Selfie"}
          </p>
        </div>
      </Button>
    );
  };

  return (
    <>
      <div className={classes.photographWrapper}>
        {renderFacialRecognitionSelfiePreview(open)}
        {preview && renderClearFacialRecognitionFilter()}
      </div>
      {!isMobile && renderUploadButton()}
      {isMobile && (
        <Stack
          spacing={3}
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <span>{renderCancelUploadButton()}</span>
          <span>{renderUploadButton()}</span>
        </Stack>
      )}
    </>
  );
};

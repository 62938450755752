import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core";

import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import MuiAlert from "@material-ui/lab/Alert";
import { useIsMobile } from "../../../actions/ui";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { galleryPassword } from "../../../v2_actions/gallery_password";
import styles from "./styles";
const useStyles = makeStyles(styles);

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    background: "white",
    transform: `translate(-${top}%, -${left}%)`,
  };
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const GalleryPasswordModal = ({
  gallery,
  onClose,
  onWrong,
  onCorrect,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const isMobile = useIsMobile();
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [galleryPasswordGuess, setGalleryPasswordGuess] = useState(null);
  const [modalStyle] = useState(getModalStyle);
  const [passwordWrong, setPasswordWrong] = useState(false);
  const [formHelperText, setFormHelperText] = useState("");

  const onCheckValidPassword = () => {
    const on_checked = (json) => {
      if (json.status === "valid") {
        onCorrect();
      } else {
        setPasswordWrong(true);
        setFormHelperText("Incorrect password. Please try again.");
      }
    };
    dispatch(
      galleryPassword.checkPassword({
        gallery_id: gallery.id,
        password: galleryPasswordGuess,
      }),
    ).then(on_checked);
  };

  const handleKeyEvent = (e) => {
    if (e.keyCode === 13) {
      onCheckValidPassword();
    }
  };

  const goBack = () => {
    navigate("/customer/events");
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Modal open={true} onClose={onClose} onKeyUp={handleKeyEvent}>
      <div
        style={!isMobile ? modalStyle : { top: 100, left: 7 }}
        className={
          !isMobile
            ? classes.passwordModalWrapper
            : classes.mobilePasswordModalWrapper
        }
      >
        <h2 className={classes.passwordModalHeading}>Gallery Password</h2>
        <IconButton
          aria-label="close"
          onClick={goBack}
          sx={{
            right: 8,
            top: 8,
            position: "absolute",
          }}
        >
          <CloseIcon />
        </IconButton>
        <h3 className={classes.passwordModalBody}>
          This is not your user login password but the password
          {!isMobile && <br />}
          given to you by the event or photographer
        </h3>
        <p className={classes.passwordModalInputLabel}>Password</p>
        <Stack direction="row" alignItems="center" justifyContent="center">
          <TextField
            inputProps={{
              autoComplete: "off",
            }}
            variant="standard"
            error={passwordWrong}
            style={{ width: 400 }}
            autocomplete="off"
            className={classes.passwordInputField}
            helperText={formHelperText}
            value={galleryPasswordGuess}
            type={showPassword ? "text" : "password"}
            placeholder="Please enter the password given to you here"
            onChange={(e) => setGalleryPasswordGuess(e.target.value)}
          />
          <InputAdornment
            position="end"
            className={
              passwordWrong
                ? classes.passwordVisibilityIconError
                : classes.passwordVisibilityIcon
            }
          >
            <IconButton
              aria-label="toggle password visibility"
              onClick={(e) => setShowPassword(!showPassword)}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        </Stack>
        <Stack
          mt={3}
          alignItems="center"
          justifyContent="space-around"
          spacing={{ xs: 2, md: 5 }}
          direction={{ xs: "column", sm: "row" }}
        >
          {!isMobile && (
            <>
              <Button
                variant="outlined"
                onClick={goBack}
                startIcon={<ArrowBackIcon />}
                className={classes.backButton}
              >
                <p className={classes.buttonText}>Go Back</p>
              </Button>
              <Button
                onClick={onCheckValidPassword}
                className={classes.submitButton}
              >
                <p className={classes.buttonText}>Submit</p>
              </Button>
            </>
          )}
          {isMobile && (
            <>
              <Button
                onClick={onCheckValidPassword}
                className={classes.submitButton}
              >
                <p className={classes.buttonText}>Submit</p>
              </Button>
              <Button
                variant="outlined"
                onClick={goBack}
                startIcon={<ArrowBackIcon />}
                className={classes.backButton}
              >
                <p className={classes.buttonText}>Go Back</p>
              </Button>
            </>
          )}
        </Stack>
      </div>
    </Modal>
  );
};

import React, { useState } from 'react'
import {NotificationContainer} from 'react-notifications'
import 'react-notifications/lib/notifications.css'

export const BaseLayout = ({ children, activeTab }) => {

    return (
        <>
          <NotificationContainer />
          {children}
        </>
    )
}

import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import React, { useRef } from "react";
import Stack from "@mui/material/Stack";
import { makeStyles, Button } from "@material-ui/core";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useIsMobile } from "../actions/ui";
import { GalleryCard } from "./GalleryCard";
import { getMobMaxWidthPixels } from "./layout/mobile";

const useStyles = makeStyles((theme) => ({
  morePhotoArtCard: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "20px",
    margin: "auto",
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      gridTemplateColumns: "repeat(1, 1fr)",
      marginLeft: "0px",
      width: "100%",
      height: "auto",
      marginTop: "55px",
      overflowY: "hidden",
    },
  },
  scrollingButtons: {
    position: "absolute",
    marginTop: "30%",
    width: "100%",
    zIndex: 9,
  },
  scrollingWrapper: {
    overflowX: "scroll",
    overflowY: "hidden",
    scrollBehavior: "smooth",
    whiteSpace: "nowrap",
  },
  scrollingCard: {
    display: "inline-block",
    margin: "0px 20px",
  },
  scrollButton: {
    cursor: "pointer",
  },
  morePhotosFromPhotographer: {
    width: "96%",
    [theme.breakpoints.up("md")]: {
      margin: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  morePhotosFromPhotographerText: {
    [theme.breakpoints.down("sm")]: {
      padding: "0px 16px",
      width: "100%",
    },
  },
  loadMoreButtonWrapper: {
    marginBottom: "15px",
    textAlign: "center",
  },
}));

export const PhotoArtMorePhotos = ({
  galleries_excluding_selected,
  loadingGalleries,
  totalNumitems,
  updateNumItemsPerPage,
  numItemsPerPage,
}) => {
  const classes = useStyles();
  const isMobile = useIsMobile();
  const contentWrapper = useRef(null);

  const scrollMobileMorePhotosWrapper = (scrollOffset) => {
    contentWrapper.current.scrollLeft += scrollOffset;
  };

  const renderMorePhotosFromThisPhotographer = () => {
    return (
      <>
        {!loadingGalleries && isEmpty(galleries_excluding_selected) && (
          <p>None</p>
        )}
        <div className={classes.morePhotoArtCard}>
          {map(galleries_excluding_selected, (gallery) => (
            <GalleryCard
              key={gallery.id}
              title={gallery.gallery_name}
              image={gallery.gallery_photo_url}
              gallery={gallery}
              gallery_id={gallery.id}
              gallery_type="photo_art"
              tab="photo_art_belonging_to_genre"
              photo_art_gallery_view={true}
            />
          ))}
        </div>
        {totalNumitems.num_items >= numItemsPerPage && (
          <div className={classes.loadMoreButtonWrapper}>
            <Button
              size="large"
              variant="outlined"
              onClick={updateNumItemsPerPage}
              className={classes.loadMoreButton}
              startIcon={<ExpandMoreIcon />}
            >
              Load more
            </Button>
          </div>
        )}
      </>
    );
  };

  const renderMobileMorePhotosFromThisPhotographer = () => {
    return (
      <div className={classes.scrollingWrapper} ref={contentWrapper}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className={classes.scrollingButtons}
        >
          <ArrowBackIosIcon
            className={classes.scrollButton}
            onClick={() => scrollMobileMorePhotosWrapper(-150)}
          />
          <ArrowForwardIosIcon
            className={classes.scrollButton}
            onClick={() => scrollMobileMorePhotosWrapper(150)}
          />
        </Stack>
        {map(galleries_excluding_selected, (gallery) => (
          <div key={gallery.id} className={classes.scrollingCard}>
            <GalleryCard
              title={gallery.gallery_name}
              image={gallery.gallery_photo_url}
              gallery={gallery}
              gallery_id={gallery.id}
              gallery_type="photo_art"
              tab="photo_art_belonging_to_genre"
              photo_art_gallery_view={true}
            />
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className={classes.morePhotosFromPhotographer}>
      <h2 className={classes.morePhotosFromPhotographerText}>
        More from this photographer
      </h2>
      {!isMobile && renderMorePhotosFromThisPhotographer()}
      {isMobile && renderMobileMorePhotosFromThisPhotographer()}
    </div>
  );
};

import { v4 as uuidv4 } from 'uuid'
import { ItemList } from '../orm'

class CustomerGalleryViewCount extends ItemList {

  getEntityKey() {
    return 'customer/gallery_view_count'
  }

  getViewerRefFromLocalStorage() {
    let ref = localStorage.getItem('customer_view_count_ref')
    if ( ! ref ) {
      ref = uuidv4()
      localStorage.setItem('customer_view_count_ref', ref)
    }
    return ref
  }

  incrementGalleryViewCount(galleryId) {
    return this.saveNewObject({
      action: 'increment_gallery_view_count',
      gallery_id: galleryId,
      customer_view_count_ref: this.getViewerRefFromLocalStorage()
    })
  }
}


export const customerGalleryViewCount = new CustomerGalleryViewCount('customer_gallery_view_count__default');

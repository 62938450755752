import React, { MouseEventHandler, ReactNode, useRef, useState } from "react";

import styles from "./DropDown.module.css";
import { useOutsideClick } from "../hooks/useOutsideClick";
import { cssStyles, PropMixins } from "../mixins";
import { useResponsiveBreakpoints } from "../primitives/useResponsiveBreakpoints";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

export type DropDownOption = {
  label: ReactNode | string;
  value: string;
};

export const DropDown = ({
  selectedValue,
  options,
  renderDropDownOption,
  onSelected,
  disabled,
  isBold,
  ...rest
}: React.PropsWithChildren<{
  selectedValue: string;
  options: DropDownOption[];
  renderDropDownOption?: (option: DropDownOption) => ReactNode;
  onSelected: (options: DropDownOption) => void;
  disabled?: boolean;
  isBold?: boolean;
}> &
  PropMixins) => {
  const [showOptions, setShowOptions] = useState(false);
  const [dropDownCoords, setDropDownCoords] = useState<
    undefined | { x: number; y: number }
  >();
  const ref = useRef<HTMLDivElement>(null);
  const activeBreakpoint = useResponsiveBreakpoints();

  useOutsideClick({
    ref: ref,
    handler: () => {
      setShowOptions(false);
    },
  });

  const handleHeaderClick: MouseEventHandler<HTMLDivElement> = (e) => {
    if (showOptions) {
      setDropDownCoords(undefined);
    } else {
      const { left, height } = e.currentTarget.getBoundingClientRect();
      setDropDownCoords({
        x: left,
        y: e.currentTarget.offsetTop + height,
      });
    }
    setShowOptions((prev) => !prev);
  };
  const handleOptionClick = (option: DropDownOption) => {
    setShowOptions(false);
    onSelected(option);
  };

  const selectLabel =
    options.find((o) => o.value === selectedValue)?.label ?? "";

  return (
    <div
      className={
        disabled ? styles.dropdownWrapperDisabled : styles.dropdownwrapper
      }
      ref={ref}
      style={cssStyles(rest, activeBreakpoint)}
    >
      <div onClick={handleHeaderClick} className={styles.dropdownheader}>
        <div
          className={styles.dropdownheaderlabel}
          style={isBold ? { fontWeight: "bold" } : {}}
        >
          {selectLabel}
        </div>
        <div>
          {showOptions ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </div>
      </div>
      {showOptions &&
        /* style={{ left: dropDownCoords.x, top: dropDownCoords.y }} */
        dropDownCoords && (
          <div className={styles.dropdownoptions}>
            {options.map((option) => (
              <div
                key={option.value}
                className={
                  selectedValue === option.value
                    ? styles.dropdownoptiondisabled
                    : ""
                }
                onClick={() => handleOptionClick(option)}
              >
                {renderDropDownOption
                  ? renderDropDownOption(option)
                  : option.label}
              </div>
            ))}
          </div>
        )}
    </div>
  );
};

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  DatePicker,
  TimePicker,
} from "@material-ui/pickers";
import {
  Grid,
  TextField,
  Button,
  Box,
  Hidden,
  CircularProgress,
} from "@material-ui/core";
import moment from "moment";
import { green } from "@material-ui/core/colors";
import { sendMailAction } from "../../actions/bookboss";

const ModalFrom = ({ title, filterInfo, classes, setAnyModal, flag }) => {
  const dispatch = useDispatch();
  const userName = localStorage.getItem("auth_name");
  const userEmail = localStorage.getItem("auth_email");
  const [showSpinner, setShowSpinner] = useState(false);
  const [values, setValues] = useState({
    show_date: new Date(new Date().getFullYear(), 0, 1),
    show_time: new Date("2014-08-18T00:00:00"),
    date: "",
    time: "",
    place: "",
    sport: "",
    event_name: "",
    number_people: "",
    photographer_id: String(filterInfo.id),
    photorapher_name: `${filterInfo.first_name} ${filterInfo.last_name}`,
    email: userEmail,
    user_name: userName,
    photographer_email: filterInfo.email,
    mail_type: flag,
  });
  const mailInfo = useSelector((state) => state.bookbossReducer.message);

  useEffect(() => {
    if (mailInfo) setShowSpinner(false);
  }, [mailInfo]);

  const hanndleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const sendMail = () => {
    setShowSpinner(true);
    dispatch(sendMailAction(values));
  };

  return (
    <Box position="releative">
      {showSpinner && (
        <CircularProgress
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            width: 60,
            height: 60,
            color: green[500],
            zIndex: 999,
          }}
        />
      )}
      <p>
        {title} {flag === 1 && filterInfo && filterInfo.first_name}
      </p>
      <Grid container>
        <Grid item xs={4}>
          <p>Date:</p>
        </Grid>
        <Grid item xs={8}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              autoOk
              margin="normal"
              id="date-picker-dialog"
              name="date"
              value={values.show_date}
              style={{ width: "100%" }}
              onChange={(date) =>
                setValues({
                  ...values,
                  show_date: date,
                  date: moment(date).format("LL"),
                })
              }
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={4}>
          <p>Time:</p>
        </Grid>
        <Grid item xs={8}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <TimePicker
              autoOk
              margin="normal"
              id="time-picker"
              ampm={false}
              value={values.show_time}
              style={{ width: "100%" }}
              onChange={(time) =>
                setValues({
                  ...values,
                  show_time: time,
                  time: moment(time).format("LT"),
                })
              }
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={4}>
          <p>Place/Venue:</p>
        </Grid>
        <Grid item xs={8}>
          <TextField
            variant="standard"
            name="place"
            value={values.place}
            onChange={hanndleChange}
            style={{ width: "100%" }}
            placeholder="Where will you be"
          />
        </Grid>
        <Grid item xs={4}>
          <p>Sport/Activity:</p>
        </Grid>
        <Grid item xs={8}>
          <TextField
            variant="standard"
            name="sport"
            value={values.sport}
            onChange={hanndleChange}
            style={{ width: "100%" }}
            placeholder="What will you be doing"
          />
        </Grid>
        <Grid item xs={4}>
          <p>Event Name:</p>
        </Grid>
        <Grid item xs={8}>
          <TextField
            variant="standard"
            name="event_name"
            value={values.even_name}
            onChange={hanndleChange}
            style={{ width: "100%" }}
            placeholder="if applicable"
          />
        </Grid>
        <Grid item xs={4}>
          <p>Number of People:</p>
        </Grid>
        <Grid item xs={8}>
          <TextField
            variant="standard"
            name="number_people"
            value={values.number_people}
            onChange={hanndleChange}
            style={{ width: "100%" }}
            placeholder="joining you"
          />
        </Grid>
        {flag === 0 ? (
          <p style={{ fontSize: 14 }}>
            Note: This invite is just to inform photographers about an event or
            your private activity. Our photographers use this information about
            where best to go but neither party is obligated to be in attendance.
            Go to Book a Boss for a private booking
          </p>
        ) : (
          <p style={{ fontSize: 14 }}>
            Upon the photographer confirming their availability, you will be
            given a chance to "accept", after which you will pay to confirm your
            booking.
          </p>
        )}
        <Box display="flex" justifyContent="center" width="100%">
          <Button className={classes.submitBtn} onClick={sendMail}>
            Submit Request
          </Button>
          <Hidden lgUp>
            <Box m={2}></Box>
            <Button
              className={classes.submitBtn}
              onClick={() => setAnyModal(false)}
            >
              Cancel
            </Button>
          </Hidden>
        </Box>
      </Grid>
    </Box>
  );
};

export default ModalFrom;

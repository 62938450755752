import { CircularProgress } from "@material-ui/core";

export function LoadingAnimationCentered() {
  return (
    <CircularProgress
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        width: 60,
        height: 60,
        color: "green",
        zIndex: 999,
      }}
    />
  );
}

import React, { useState, useEffect } from "react";

const LOADING_MESSAGE_PARAGRAPH = [
  "This will take a few moments.",
  "Remember to use our Facial Recognition function to help you find all your photos instantly.",
  "There are big discounts for multiple purchases. Enjoy your pics!",
  "It looks like this is a very large gallery of over 1,000 photos so it could take a while, particularly if your wifi speed isn't fast.",
  "Hmm, this is definitely slower than usual, sorry for the delay! Please try to be  patient a little longer. If the gallery has not loaded in a short while we suggest  closing your browser and refreshing when you have decent connectivity.",
];
const LAST_PARAGRAPH = 4;
const TYPING_SPEED = 50;

export const Typewriter = () => {
  const [displayText, setDisplayText] = useState("");
  const [paragraphIndex, setParagraphIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(0);
  const [showCursor, setShowCursor] = useState(true);

  useEffect(() => {
    const type = () => {
      if (paragraphIndex < LOADING_MESSAGE_PARAGRAPH.length) {
        if (charIndex < LOADING_MESSAGE_PARAGRAPH[paragraphIndex].length) {
          const char =
            LOADING_MESSAGE_PARAGRAPH[paragraphIndex].charAt(charIndex);
          const newChar =
            paragraphIndex === LAST_PARAGRAPH
              ? `<span style="color: red;">${char}</span>`
              : `<span style="color: #000;">${char}</span>`;
          setDisplayText((prevText) => prevText + newChar);
          setCharIndex((prevIndex) => prevIndex + 1);
        } else {
          setDisplayText((prevText) => prevText + "<br><br>");
          setParagraphIndex((prevIndex) => prevIndex + 1);
          setCharIndex(0);
        }
      } else {
        setShowCursor(false);
      }
    };

    const typingTimeout = setTimeout(type, TYPING_SPEED);

    // Cleanup on unmount to prevent memory leaks
    return () => clearTimeout(typingTimeout);
  }, [LOADING_MESSAGE_PARAGRAPH, paragraphIndex, charIndex, TYPING_SPEED]);

  return (
    <div className="typewriter">
      <p
        id="typewriter-text"
        style={{ margin: "auto", textAlign: "center" }}
        dangerouslySetInnerHTML={{ __html: displayText }}
      />
      {showCursor && <span className="blinking-cursor">|</span>}
    </div>
  );
};

import size from "lodash/size";
import get from "lodash/get";
import { signIn, signUp, edifProfileApi } from "../apis/userapi";
import { announceUserLoggedIn } from "../v2_actions/auth";
import { trolleyList } from "../v2_actions/trolley";
import { authenticatedTrolleyList } from "../v2_actions/authenticated_trolley";
import { sentryApi } from "../utils/o11y/sentryApi";

export const CLEAR_USER = "USER/CLEARUSER"
export const USER_INFO = "USER/USERINFO"
export const SHOW_RETERAL_MODAL = "USER/SHOWRETERALMODAL"
export const SET_FORGOT_EMAIL = "USER/SETFORGOTEMAIL"
export const ERROR_MESSAGE = "USER/ERRORMESSAGE"

export const userInfo = (userInfo) => ({
  type: USER_INFO,
  userInfo,
});

export const setForgotEmail = (forgotEmail) => ({
  type: SET_FORGOT_EMAIL,
  forgotEmail,
});

export const setErrorMessage = (errorMessage) => ({
  type: ERROR_MESSAGE,
  errorMessage,
});

export const setShowReteralModal = (showReteralModal) => ({
  type: SHOW_RETERAL_MODAL,
  showReteralModal,
});

export const clearUser = () => ({
  type: CLEAR_USER,
})

export const signInAction = (
  data,
  trolley,
  generalInfo,
  country,
  navigate,
  trolley_id_from_url,
) => {
  sentryApi.breadcrumb({
    category: "login",
    level: "info",
    message: "start",
    data: {
      email: data.email,
      trolley_id_from_url,
    },
  });

  return (dispatch) => {
    signIn(data)
      .then((res) => {
        const token = res.data.token;
        const userInfoData = res.data.user;
        dispatch(announceUserLoggedIn(token, userInfoData));
        dispatch(authenticatedTrolleyList.trySetTrolleyCustomer());

        if (
          size(get(trolley, "trolley_photographs")) > 0 ||
          trolley_id_from_url
        ) {
          navigate(
            `/customer/shopping/${trolley_id_from_url || get(trolley, "id")}`,
          );
        } else {
          navigate(`/customer/events`);
        }

        dispatch(setShowReteralModal(false));
        dispatch(userInfo(userInfoData));

        sentryApi.breadcrumb({
          category: "login",
          level: "info",
          message: "success",
          data: {},
        });
      })
      .catch((error) => {
        const httpStatus = error?.response?.status ?? "unknown_http_status";
        const serverResponse = error?.response?.data ?? "no_server_response";

        if (httpStatus !== "401") {
          sentryApi.error("login_error", {
            error: error?.message ?? error?.toString(),
            httpStatus,
            serverResponse,
          });
        }

        let showMessage = "You have entered an invalid email or password";

        if (serverResponse === "user_not_found") {
          showMessage =
            "You are not an existing account holder. Please click on “Create Account” below to register with us";
        } else if (serverResponse === "invalid_credentials") {
          // use existing message
        }

        dispatch(
          setErrorMessage({
            showMessage,
            showMethod: true,
          }),
        );
      });
  };
};

export const eidfProfileAction = (data) => {
  return (dispatch) => {
    edifProfileApi(data).then((res) => {
      if (res.data.change_pass_flag === false) {
        dispatch(
          setErrorMessage({
            showMessage: "Incorrect old password!",
            showMethod: true,
          }),
        );
      } else {
        dispatch(
          setErrorMessage({
            showMessage: "Profile Update Successfully!",
            showMethod: false,
          }),
        );
        let userInfoData = res.data.results;
        const token = res.data.token;
        userInfoData.profile_photo =
          `${window.LOCAL_SETTINGS.API_BASE}/media/` +
          res.data.results.profile_photo;
        dispatch(userInfo(userInfoData));
        dispatch(announceUserLoggedIn(token, userInfoData));
      }
    });
  };
};

export const clearAllStorage = () => {
  window.localStorage.clear();
};

export const getUserInfo = (state) => {
  return state.userReducer.userInfo
}

export const getUserFirstName = (state) => {
  return getUserInfo(state)?.first_name
}

export const getUserLastName = (state) => {
  return getUserInfo(state)?.last_name
}

export const getUserEmail = (state) => {
  return getUserInfo(state)?.email
}

export const getUserId = (state) => {
  return getUserInfo(state)?.id
}

export const getUserCountry = (state) => {
  return getUserInfo(state)?.country
}

export const getErrorMessage = (state) => {
  return state.userReducer.errorMessage
}

export const getForgotEmail = (state) => {
  return state.userReducer.forgotEmail
}

export const getShowReteralModal = (state) => {
  return state.userReducer.showReteralModal
}

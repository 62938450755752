import React from "react";
import styles from "./Shell.module.css";

/**
 * Primary Layout Component
 * @returns
 */
const Shell = ({ children }: React.PropsWithChildren<{}>) => {
  return <div className={styles.shell}>{children}</div>;
};

export const ShellScrollContainer = ({ children }: React.PropsWithChildren<{}>) => {
  return <div className={styles.shellscrollcontainer}>{children}</div>;
};

export default Shell;

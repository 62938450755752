import React from 'react'
import { useParams } from 'react-router-dom'
import { makeStyles } from "@material-ui/core"
import { PhotoArtLayout } from './layout/PhotoArtLayout'
import { PhotoArtList } from './PhotoArtList'
import PhotoArtFilter from './PhotoArtFilter'
import { setGalleryNavBackButtonUrl } from '../v2_actions/nav'

const useStyles = makeStyles({
  heading: {
      marginTop: "100px",
      textAlign: "center"
  },
  photoArtListScrollWrapper: {
    height:'80vh',
    overflowY:'scroll'
  }
})

const PhotoArtPage = () => {
  const classes = useStyles()
  const { photo_art_genre_name } = useParams()
  const searchTerm = new URLSearchParams(window.location.search).get('search')
  setGalleryNavBackButtonUrl()
  
  return (
    <PhotoArtLayout activeTab='photoart'>
      <PhotoArtFilter
        searchTerm={ searchTerm }
        photo_art_genre_name={ photo_art_genre_name }
      />
      <div className={classes.photoArtListScrollWrapper}>
        <PhotoArtList photo_art_genre_name={ photo_art_genre_name }/>
      </div>
    </PhotoArtLayout>
  )
}

export default PhotoArtPage;
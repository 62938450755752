import React from "react";
import styles from "./Button.module.css";
import { PropMixins, cssStyles } from "../mixins";
import { useResponsiveBreakpoints } from "../primitives/useResponsiveBreakpoints";

type ButtonProps = Omit<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >,
  "className"
> & {
  variant?: "default" | "link" | "secondary";
  fill?: boolean;
  inline?: boolean;
  size?: "default" | "small";
} & PropMixins;

/**
 * Button
 * @returns
 */
const Button = ({
  children,
  fill,
  inline,
  size,
  variant,
  ...rest
}: ButtonProps) => {
  const activeBreakpoint = useResponsiveBreakpoints();
  const classes = [];
  if (variant === "link") {
    classes.push(styles.link);
  } else if (variant === "secondary") {
    classes.push(styles.secondary);
    classes.push(styles.button);
  } else {
    classes.push(styles.button);
  }

  if (fill) {
    classes.push(styles.buttonfill);
  }

  if (inline) {
    classes.push(styles.buttoninline);
  }

  const buttonSize = size ?? "default";

  classes.push(styles[`button-size-${buttonSize}`]);

  return (
    <button
      {...rest}
      className={classes.join(" ")}
      style={cssStyles(rest, activeBreakpoint)}
    >
      {children}
    </button>
  );
};

export default Button;

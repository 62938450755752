import React, { useState } from "react";
import { NavButton, NavButtonProps } from "bossfotos-design-system";
import MenuIcon from "@mui/icons-material/Menu";
import { ContextMenu } from "../../v2_components/layout/ContextMenu";

const MenuButton = (props: NavButtonProps) => {
  const [showContextMenu, setShowContextMenu] = useState(false);

  const handleMenuClick = () => {
    setShowContextMenu(true);
  };

  return (
    <>
      <ContextMenu
        showContextMenu={showContextMenu}
        setShowContextMenu={setShowContextMenu}
      />
      <NavButton {...props} onClick={handleMenuClick}>
        <MenuIcon fontSize="large" />
      </NavButton>
    </>
  );
};

export default MenuButton;

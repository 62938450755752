import {
  Box,
  BreakPoints,
  Button,
  DropDown,
  DropDownOption,
  H3,
  Inline,
  NavButton,
  ResponsiveContainer,
  RoundedImage,
  Shell,
  ShellScrollContainer,
  Stack,
  ViewPort,
} from "bossfotos-design-system";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { purchasedTrolleyPhotographList } from "../../v2_actions/purchased_trolley_photograph";
import ShareIcon from "@mui/icons-material/Share";
import { NavBar } from "./NavBar";
import { makeLogger } from "../../utils/o11y/logger";
import { purchasedTrolleyList } from "../../v2_actions/purchased_trolley";
import { useReduxStateReconcilation } from "../../v2_components/hooks/useReduxStateReconcilation";
import { useSelector } from "react-redux";
import { useThunkDispatch } from "../../hooks/useThunkDispatch";
import { Photograph } from "../../types/backend";
import { OverlayIcon } from "../../design-system/temp/OverlayIcon";
import CheckIcon from "@mui/icons-material/Check";
// import Gallery, { PhotoProps } from "react-photo-gallery";
import React from "react";
import { useIsMobile } from "../../actions/ui";
import PhotoAlbum, { Photo } from "react-photo-album";
import { parseISO, format } from "date-fns";
import { useSetPageTitle } from "../../hooks/usePageTitle";
import { LoadingAnimationCentered } from "../../v3_components/LoadingAnimationCentered";

const logger = makeLogger({
  enabled: true,
  label: "Purchased",
  color: "orange",
});

type PurchasedTrolleyPhoto = {
  id: string;
  purchased_trolley: string;
  price: number;

  country_print_price: null;
  downloaded: null;
  photo_delivery_type: string;
  photograph: Photograph;
  photograph_download_url: string;
  photograph_visible_filename: string;
  print_frame: null;
  print_paper: null;
  print_price_config: null;
  print_size: null;
  print_supplier: null;
  print_type: null;
  quality: string;
};

type PurchasedTrolley = {
  id: string;
  created_at: string;
  admin_charge: number;
  bulk_discount: number;
  commission: number;
  coupon_discount: number;
  customer: string;
  gallery: string;
  gallery_name: string;
  gross_total: null;
  number_of_photos: number;
  photographer: string;
  photographer_name: string;
  purchased_trolley_photographs: PurchasedTrolleyPhoto[];
  total_photograph_price: number;
  total_price_paid_by_customer: number;
};

const toReactPhotoGalleryInfo = (photo: PurchasedTrolleyPhoto) => {
  const res: Photo = {
    key: photo.id,
    src: photo.photograph_download_url,
    width: photo.photograph.gallery_size_file_info.width_pixels,
    height: photo.photograph.gallery_size_file_info.height_pixels,
    title: "",
  };
  return res;
};

const TrolleyPhotographs = ({
  photographs,
  selectedPhotos,
  onPhotoSelected,
  onDownload,
}: {
  photographs: PurchasedTrolleyPhoto[];
  selectedPhotos: Record<string, boolean>;
  onPhotoSelected: (photoId: string) => void;
  onDownload: (photoId: string) => void;
}) => {
  const isMobile = useIsMobile();

  return (
    <div
      style={{
        width: "100%",
        // height: "400px",
        overflow: "auto",
      }}
    >
      <PhotoAlbum
        layout="rows"
        photos={photographs.map(toReactPhotoGalleryInfo)}
        renderPhoto={(renderImageProps) => (
          <div
            style={{
              position: "relative",
              width: isMobile
                ? "100%"
                : renderImageProps.imageProps.style.width,
              height: isMobile
                ? "100%"
                : renderImageProps.imageProps.style.height,
            }}
          >
            <RoundedImage>
              <img
                loading="lazy"
                alt=""
                src={renderImageProps.photo.src}
                style={{
                  objectFit: "cover",
                  width: "100%",
                  boxSizing: "content-box",
                  aspectRatio: renderImageProps.imageProps.style.aspectRatio,
                }}
              />
            </RoundedImage>
            <div
              style={{
                position: "absolute",
                right: 7,
                top: 7,
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                padding: "5px",
              }}
            >
              <NavButton
                flexContain={true}
                onClick={() => onDownload(renderImageProps.photo.key ?? "")}
              >
                <OverlayIcon>
                  <FileDownloadIcon fontSize={isMobile ? "large" : "small"} />
                </OverlayIcon>
              </NavButton>

              {
                /* implement later */ false && (
                  <OverlayIcon>
                    <ShareIcon fontSize={isMobile ? "large" : "small"} />
                  </OverlayIcon>
                )
              }
              <NavButton
                hiddensm={true}
                flexContain={true}
                onClick={() =>
                  onPhotoSelected(renderImageProps.photo.key ?? "")
                }
              >
                <OverlayIcon
                  selected={!!selectedPhotos[renderImageProps.photo.key ?? ""]}
                >
                  <CheckIcon fontSize={isMobile ? "medium" : "small"} />
                </OverlayIcon>
              </NavButton>
            </div>
          </div>
        )}
      />
    </div>
  );
};

const PurchasedScreen = () => {
  const dispatch = useThunkDispatch();
  const isMobile = useIsMobile();
  useSetPageTitle(`My Purchases`);

  useEffect(() => {
    dispatch(purchasedTrolleyList.invalidateList());
    dispatch(purchasedTrolleyList.updatePaginationNumItemsPerPage(100));
    dispatch(purchasedTrolleyList.fetchListIfNeeded());
  }, []);

  const isLoading = purchasedTrolleyList.isLoading();

  const handleMenuClicked = () => {};

  if (isLoading) {
    return (
      <ViewPort>
        <Shell>
          <NavBar />
          <LoadingAnimationCentered />
        </Shell>
      </ViewPort>
    );
  }

  return <PurchasedInner />;
};

const PurchasedInner = () => {
  useReduxStateReconcilation();
  // @ts-ignore
  const message = useSelector((state) => state.trolleyReducer.message);
  const dispatch = useThunkDispatch();
  const navigate = useNavigate();
  const [selectedAll, setSelectedAll] = useState(true);
  const purchased_trolleys =
    purchasedTrolleyList.getVisibleObjects() as PurchasedTrolley[];
  const [expandedPurchasedTrolleyIds, setExpandedPurchasedTrolleyIds] =
    useState<string[]>([]);
  const [selectedPhotos, setSelectedPhotos] = useState<Record<string, boolean>>(
    {},
  );
  const isMobile = useIsMobile();
  const expandedTrolleyId =
    expandedPurchasedTrolleyIds.slice(0).shift() ??
    purchased_trolleys.slice(0).shift()?.id ??
    "";

  const expandedTrolley = purchased_trolleys.find(
    (t) => t.id === expandedTrolleyId,
  );

  logger.log({ purchased_trolleys, expandedTrolleyId, expandedTrolley });

  const onExpandPurchasedTrolley = (purchased_trolley: PurchasedTrolley) => {
    const localExpandedPurchasedTrolleyIds = Object.assign(
      [],
      expandedPurchasedTrolleyIds,
    );
    if (purchased_trolley) {
      localExpandedPurchasedTrolleyIds.push(purchased_trolley.id);
      //setExpandedPurchasedTrolleyIds(localExpandedPurchasedTrolleyIds);
      // one at a time?
      setExpandedPurchasedTrolleyIds([purchased_trolley.id]);
    }
  };

  const handleBackClicked = () => {
    navigate(-1);
  };
  const handleMenuClicked = () => {};
  const handleHomeClicked = () => {
    navigate("/customer/events/");
  };

  const handlePhotoSelected = (photoId: string) => {
    setSelectedPhotos({
      ...selectedPhotos,
      [photoId]: !selectedPhotos[photoId],
    });
  };

  const downloadPhotos = (photoIds: string[]) => {
    const photosToDownload: PurchasedTrolleyPhoto[] = [];
    purchased_trolleys?.forEach((trolley) => {
      trolley.purchased_trolley_photographs.forEach((photo) => {
        if (photoIds.includes(photo.id)) {
          photosToDownload.push(photo);
        }
      });
    });

    let div = document.createElement("div");
    document.body.appendChild(div);

    photosToDownload.forEach((photo) => {
      dispatch(purchasedTrolleyPhotographList.onDownloaded(photo.id));
      let a = document.createElement("a");
      a.href = photo.photograph_download_url;
      a.download = photo.photograph.photo_name;
      div.appendChild(a);
    });

    const downloadRecursion = () => {
      const link = div.querySelector("a");
      if (link) {
        link.click();
        window.setTimeout(() => {
          div.removeChild(link);
          downloadRecursion();
        }, 100);
      }
    };
    downloadRecursion();
  };

  const handleDownloadClicked = (photoId: string) => {
    downloadPhotos([photoId]);
  };

  const handleSelectAllClicked = () => {
    const selected = {
      ...selectedPhotos,
    };
    expandedTrolley?.purchased_trolley_photographs.forEach((photo) => {
      selected[photo.id] = selectedAll;
    });
    setSelectedPhotos(selected);
    setSelectedAll((prev) => !prev);
  };

  const handleDownloadAll = () => {
    const downloadingIds: string[] = [];
    Object.keys(selectedPhotos).forEach((key) => {
      const isSelected = selectedPhotos[key];
      if (isSelected) {
        downloadingIds.push(key);
      }
    });
    if (downloadingIds.length === 0) {
      alert("please select a photo to download");
    } else {
      downloadPhotos(downloadingIds);
    }
  };

  function trolleysToDropDownOption(
    purchased_trolleys: PurchasedTrolley[],
  ): DropDownOption[] {
    return purchased_trolleys.map((trolley) => ({
      label: format(parseISO(trolley.created_at), "dd MMMM yyyy, HH:mm"),
      value: trolley.id,
    }));
  }

  const handleContinueShoppingClick = async () => {
    navigate("/customer/events/");
  };

  return (
    <ViewPort>
      <Shell>
        <NavBar onBackClicked={handleBackClicked} />
        <ShellScrollContainer>
          <Box>
            <ResponsiveContainer
              breakpoints={{
                [BreakPoints.sm]: Stack,
                [BreakPoints.md]: Inline,
              }}
            >
              <Box
                width="auto"
                widthmd="50%"
                variantsm={"empty"}
                variantmd={"primary"}
              >
                <Stack>
                  <Inline alignItems="center">
                    <H3 hiddenmd={true} flexContain={true} fill={true}>
                      Date:
                    </H3>
                    <H3 hiddensm={true} flexContain={true} fill={true}>
                      Purchase Date:
                    </H3>
                    <div style={{ width: "80%" }}>
                      <DropDown
                        isBold={true}
                        options={trolleysToDropDownOption(purchased_trolleys)}
                        disabled={false}
                        onSelected={(option) => {
                          const selectedTrolley = purchased_trolleys.find(
                            (t) => t.id === option.value,
                          );
                          if (selectedTrolley)
                            onExpandPurchasedTrolley(selectedTrolley);
                        }}
                        selectedValue={expandedTrolleyId}
                        renderDropDownOption={(option) => {
                          return <>{option.label}</>;
                        }}
                      />
                    </div>
                  </Inline>
                  {expandedTrolley && (
                    <>
                      <Inline hiddensm={true} alignItems="center">
                        <Button
                          onClick={() => handleSelectAllClicked()}
                          variant={selectedAll ? "secondary" : "default"}
                          size="small"
                        >
                          Select All
                        </Button>
                      </Inline>
                      <TrolleyPhotographs
                        onDownload={handleDownloadClicked}
                        onPhotoSelected={handlePhotoSelected}
                        selectedPhotos={selectedPhotos}
                        photographs={
                          expandedTrolley.purchased_trolley_photographs
                        }
                      />
                      <Inline alignItems="center">
                        <H3 flexContain={true} fill={true}></H3>
                      </Inline>
                    </>
                  )}
                </Stack>
              </Box>
              {!isMobile && (
                <Box width="auto" widthmd="50%">
                  <Stack alignItems="center">
                    <Stack style={{ width: "320px" }}>
                      <Button onClick={() => handleDownloadAll()}>
                        {/* disabled={Object.keys(selectedPhotos).length === 0} */}
                        Download
                      </Button>
                      {
                        /* implement later */ false && (
                          <>
                            <Button variant="secondary">
                              Order Prints & Canvases
                            </Button>
                            <Button variant="secondary">Share Photos</Button>
                          </>
                        )
                      }
                      <Button
                        variant="secondary"
                        onClick={handleContinueShoppingClick}
                      >
                        Continue Shopping
                      </Button>
                    </Stack>
                  </Stack>
                </Box>
              )}
            </ResponsiveContainer>
          </Box>
        </ShellScrollContainer>
      </Shell>
    </ViewPort>
  );
};

export default PurchasedScreen;

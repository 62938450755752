import React from "react";
import mixinstyles from "../mixins.module.css";
import styles from "./ListButton.module.css";

type Alignment = "left" | "center" | "right";

type ListButtonProps = {
  variant?: "default" | "icon";
  fill?: boolean;
  align?: Alignment;
} & Omit<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >,
  "className"
>;

/**
 * ListButton
 * @returns
 */
const ListButton = ({
  fill,
  align,
  variant,
  children,
  ...props
}: ListButtonProps) => {
  return (
    <button
      {...props}
      style={props.style}
      className={`${styles.listbutton} ${styles["listbutton" + (variant ?? "default")]} ${fill ? mixinstyles.flexgrow2 : ""} ${mixinstyles["flexalignment" + (align ?? "center")]}`}
    >
      {children}
    </button>
  );
};

export default ListButton;

import filter from "lodash/filter";
import isEmpty from "lodash/isEmpty";
import head from "lodash/head";
import get from "lodash/get";
import { useState, useEffect } from "react";
import ReactGA from "react-ga";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";

import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
// import LowHighResPhotoPrompt from "../../v2_components/LowHighResPhotoPrompt";
import LowHighResPhotoPrompt from "../../v3_components/LowHighResPhotoPrompt";
import { useIsMobile } from "../../actions/ui";
import { trolleyList } from "../../v2_actions/trolley";
import {
  trolleyPhotographList,
  CROSS_CURRENCY_ERROR,
} from "../../v2_actions/trolley_photograph";
import {
  setCustomValue,
  CUSTOM_VALUE_OPEN_DIGITAL_PRICES_DIALOG,
  getCustomValue,
  CUSTOM_VALUE_NUM_TROLLEY_PHOTOGRAPHS,
} from "../../v2_actions/custom_value";
import { CheckoutButton } from "../CheckoutButton";

const DEFAULT_SHOPPING_CART_ICON_COLOR = "white";
const SELECTED_SHOPPING_CART_ICON_COLOR = "red";
const SELECTING_SHOPPING_CART_ICON_COLOR = "#ff5252";
const ONE_SELECTED_PHOTOGRAPH_IN_TROLLEY = 1;

const trolleyIcon = {
  width: "40px",
  height: "40px",
  backgroundColor: "rgb(96 96 96 / 50%)",
  overflow: "visible",
  fontSize: "1.5rem",
  textAlign: "center",
  transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  borderRadius: "50%",
  "&:active svg": {
    color: `${SELECTING_SHOPPING_CART_ICON_COLOR} !important`,
  },
};

export const FunctioningGalleryPhotographTrolleyIcon = ({
  photoId,
  price,
  gallery,
  trolley,
  customStyle,
  photographer,
  trolleyPhotographs,
  enableTrolleyIcon = false,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [isSelecting, setIsSelecting] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const createdNewTrolley = !isSelected && !isSelecting;
  const [isDeselecting, setIsDeselecting] = useState(false);
  const [trolleyIsEmpty, setTrolleyIsEmpty] = useState(false);
  const [isResettingTrolley, setIsResettingTrolley] = useState(false);
  const galleryLowResPrice = get(gallery, ["gallery_price", "low_res_price"]);
  const [tryShowLowHighResPhotoPrompt, setTryShowLowHighResPhotoPrompt] =
    useState(false);
  const [shoppingCartIconColor, setShoppingCartIconColor] = useState(
    DEFAULT_SHOPPING_CART_ICON_COLOR,
  );
  const [
    tryingToAddCrossPhotographerTrolleyPhotograph,
    setTryingToAddCrossPhotographerTrolleyPhotograph,
  ] = useState(false);

  const isUpdatingTrolley = useSelector(
    () =>
      trolleyPhotographList.getIsSavingObject() ||
      trolleyPhotographList.getIsDeletingObject(),
  );

  const numberOfSelectedPhotographsFromApi = useSelector((state) =>
    get(trolley, "number_of_photos", 0),
  );
  const numberOfSelectedPhotographsFromState = useSelector(
    (state) =>
      getCustomValue({ state, name: CUSTOM_VALUE_NUM_TROLLEY_PHOTOGRAPHS }) ||
      0,
  );

  const trolleyPhotograph = head(
    filter(trolleyPhotographs, (x) => x.photograph.id === photoId),
  );

  const photographIsInTrolley = Boolean(trolleyPhotograph);

  const waitingForApiCallAddingPhotographToTrolley =
    tryShowLowHighResPhotoPrompt &&
    numberOfSelectedPhotographsFromApi === 0 &&
    !get(trolleyPhotograph, "id");

  const conditionsToRenderLowResPromptAreMet =
    tryShowLowHighResPhotoPrompt &&
    trolleyIsEmpty &&
    numberOfSelectedPhotographsFromState === ONE_SELECTED_PHOTOGRAPH_IN_TROLLEY;

  useEffect(() => {
    if (photographIsInTrolley) {
      setIsSelected(true);
    }
  }, [trolleyPhotographs]);

  useEffect(() => {
    if (isSelecting) {
      setIsDeselecting(false);
      setShoppingCartIconColor(SELECTING_SHOPPING_CART_ICON_COLOR);
    }
    if (photographIsInTrolley) {
      setIsSelecting(false);
      setIsSelected(true);
      setShoppingCartIconColor(SELECTED_SHOPPING_CART_ICON_COLOR);
    }
    if (isDeselecting || createdNewTrolley) {
      setIsSelecting(false);
      setIsSelected(false);
      setShoppingCartIconColor(DEFAULT_SHOPPING_CART_ICON_COLOR);
    }
  }, [isDeselecting, isSelected, isSelecting]);

  useEffect(() => {
    if (!isUpdatingTrolley) {
      setIsDeselecting(false);
    }
  }, [isUpdatingTrolley]);

  useEffect(() => {
    if (isEmpty(get(trolley, "trolley_photographs"))) {
      setTrolleyIsEmpty(true);
    } else {
      setTrolleyIsEmpty(false);
    }
  }, [tryShowLowHighResPhotoPrompt]);

  const doesGalleryQualifyForAskingLowResQuestion = () => {
    return galleryLowResPrice !== null;
  };

  const onAddPhotographToNewTrolley = () => {
    setIsResettingTrolley(true);
    dispatch(trolleyList.resetTrolley());
    if (tryingToAddCrossPhotographerTrolleyPhotograph) {
      dispatch(
        trolleyList.resetTrolley(function () {
          dispatch(
            trolleyPhotographList.addPhotograph(
              tryingToAddCrossPhotographerTrolleyPhotograph,
            ),
          );
          setTryingToAddCrossPhotographerTrolleyPhotograph(null);
          setIsResettingTrolley(false);

          if (doesGalleryQualifyForAskingLowResQuestion()) {
            setTryShowLowHighResPhotoPrompt(true);
          }
        }),
      );
    }
  };

  const onCheckoutPreviousTrolley = () => {
    navigate("/customer/v2_trolley");
    setTryingToAddCrossPhotographerTrolleyPhotograph(null);
  };

  const hideLowHighResPhotoPrompt = () => {
    setTryShowLowHighResPhotoPrompt(false);
  };

  const openDigitalPricingDialog = () => {
    dispatch(
      setCustomValue({
        name: CUSTOM_VALUE_OPEN_DIGITAL_PRICES_DIALOG,
        value: true,
      }),
    );
  };

  const triggerAddedPhotoToTrolleyGoogleAnalyticsEvent = () => {
    ReactGA.event({
      value: 1,
      category: "Conversions",
      action: "Clicked Add a Photo to Trolley button",
    });
  };

  const onSelectPhotoForBuy = (photograph_id) => {
    if (isUpdatingTrolley || waitingForApiCallAddingPhotographToTrolley) {
      return;
    }
    if (isSelected === true) {
      setIsDeselecting(true);
      dispatch(trolleyPhotographList.removePhotograph(photograph_id));
    } else {
      setIsSelecting(true);
      const addPhotographToTrolley =
        trolleyPhotographList.canAddPhotographToTrolley(
          gallery,
          trolley,
          photographer,
        );
      if (get(addPhotographToTrolley, "can_add")) {
        dispatch(trolleyPhotographList.addPhotograph(photograph_id));
        triggerAddedPhotoToTrolleyGoogleAnalyticsEvent();

        if (doesGalleryQualifyForAskingLowResQuestion()) {
          setTryShowLowHighResPhotoPrompt(true);
        }
      } else {
        setIsSelecting(false);
        if (get(addPhotographToTrolley, "reason") === CROSS_CURRENCY_ERROR) {
          setTryingToAddCrossPhotographerTrolleyPhotograph(photograph_id);
        }
      }
    }
  };

  const tryRenderCrossPhotographerWarning = () => {
    return (
      <>
        <Dialog
          open={tryingToAddCrossPhotographerTrolleyPhotograph}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {
              "You cannot purchase from two different countries with one purchase."
            }
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Would you like to checkout with your original photos OR start a
              new shopping cart
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <CheckoutButton variant="dialog_checkout_button" />
            <Button onClick={onAddPhotographToNewTrolley}>New cart</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  const renderShoppingCartButton = () => {
    const isShoppingCardButtonDisabled =
      enableTrolleyIcon && (isSelecting || isDeselecting);
    return (
      <IconButton
        disableRipple
        sx={trolleyIcon}
        disabled={isShoppingCardButtonDisabled}
        onClick={(e) => onSelectPhotoForBuy(photoId)}
      >
        <ShoppingCartIcon style={{ color: shoppingCartIconColor }} />
      </IconButton>
    );
  };

  return (
    <Box sx={customStyle}>
      {conditionsToRenderLowResPromptAreMet && (
        <LowHighResPhotoPrompt
          trolley={trolley}
          gallery={gallery}
          photoId={photoId}
          isUpdatingTrolley={isUpdatingTrolley}
          trolleyPhotograph={trolleyPhotograph}
          handleClose={hideLowHighResPhotoPrompt}
        />
      )}
      {tryingToAddCrossPhotographerTrolleyPhotograph &&
        tryRenderCrossPhotographerWarning()}
      <Box
        display="flex"
        flexDirection="column"
        onMouseEnter={(e) => setAnchorEl(e.currentTarget)}
        onMouseLeave={() => setAnchorEl(null)}
      >
        {renderShoppingCartButton()}
      </Box>
    </Box>
  );
};

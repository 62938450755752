import { ItemList } from "../orm";

export const DEFAULT_COUNTRY_CODE = "au";

class PrintableAwsUploadSigninUrl extends ItemList {
  getEntityKey() {
    return "file_info/aws_upload_signin_url";
  }

  getUploadUrl({ file_name, gallery_id, event_id }) {
    return this.saveNewObject({
      file_name,
      gallery_id,
      event_id,
      country_code: DEFAULT_COUNTRY_CODE,
    });
  }
}

export const printableAwsUploadSigninUrl = new PrintableAwsUploadSigninUrl(
  "printable_aws_upload_signin_url",
);

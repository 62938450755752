import * as React from "react";
import LogoImg from "../../assets/logo/blue_logo.png";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  bossfotosLogo: {
    width: "200px",
    transform: "scale(1)",
    animation: "pulse 2s infinite",
  },
  "@keyframes pulse": {
    "0%": {
      transform: "scale(0.95)",
    },
    "70%": {
      transform: "scale(1)",
    },
    "100%": {
      transform: "scale(0.95)",
    },
  },
}));

export default function BossfotosLoadingImg() {
  const classes = useStyles();
  return <img src={LogoImg} className={classes.bossfotosLogo} />;
}

import axios from "axios";
 
const makeHeader = () => {
  let token = localStorage.getItem("token");
  let config = {
    headers: {
        Authorization: "Token " + token,
        'X-BOSSFOTOS-AUTHENTICATION-TOKEN': 'Token ' + token,
    },
  };
  return config;
};

export const getBookBossGallery = async (pid) => {
  return await axios.get(
    window.LOCAL_SETTINGS.API_BASE +
      "/api/bookboss/get_bookboss_gallery_photos/?pid=" +
      pid,
    makeHeader()
  );
};

export const sendMailApi = async (data) => {
  return await axios.post(
    window.LOCAL_SETTINGS.API_BASE +
      "/api/sendmail/send_help_feedback_mail_customer/",
    data,
    makeHeader()
  );
};

export const sendOrderMailApi = async (data) => {
  return await axios.post(
    window.LOCAL_SETTINGS.API_BASE + "/api/sendmail/send_order_mail/",
    data,
    makeHeader()
  );
};

export const sendInviteMailApi = async (data) => {
  return await axios.post(
    window.LOCAL_SETTINGS.API_BASE + "/api/sendmail/send_invite_mail/",
    data,
    makeHeader()
  );
};

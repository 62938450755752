import head from "lodash/head";
import get from "lodash/get";
import map from "lodash/map";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Hidden from "@material-ui/core/Hidden";
import { getMobMaxWidthPixels } from "./layout/mobile";
import { css, Global } from "@emotion/react";
import { briefGalleryListForPhotoArtTab } from "../v2_actions/brief_gallery";
import { constructGalleryFilter } from "../v2_actions/gallery";
import { photographerDropdownOptions } from "../v2_actions/dropdown_options";
import { genreTagList } from "../v2_actions/gallery_genre";

import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { createTheme } from "@material-ui/core/styles";
import { DropFilterText } from "./filter/DropFilterValue";
import { CheckoutButton } from "./CheckoutButton";

const useStyles = makeStyles({
  subNav: {
    display: "flex",
    flexDirection: "column",
  },
  select: {
    width: "310px",
    height: "50px",
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      width: "175px",
    },
  },
  list: {
    width: "300px",
  },
  subRoot: {
    display: "flex",
    flexDirection: "column",
    position: "sticky",
    top: "3.9rem",
    backgroundColor: "white",
    zIndex: "999",
    width: "95%",
    margin: "auto",
    paddingBottom: "10px",
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      top: "45px",
      position: "relative",
      zIndex: "100",
      width: "100%",
    },
  },
  photographerFilter: {
    width: "200px",
    borderRadius: "15px",
    border: "solid 2px gray",
    padding: "0px 15px",
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      width: "96%",
      padding: "0px 7px",
      margin: "6px auto",
    },
  },
  photographerFilterSelected: {
    backgroundColor: "#0C58D3",
  },
  root: {
    alignItems: "center",
    borderRadius: "15px",
    width: "100%",
    position: "relative",
    paddingLeft: "5px",
    paddingRight: "5px",
    margin: "20px auto 20px",
    height: "40px",
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      marginTop: "0px",
      marginBottom: "0px",
      fontSize: "16px",
      border: "none",
      flexWrap: "wrap",
      height: "90px",
    },
  },
  rootExpanded: {
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      marginBottom: "60px",
    },
  },
  search: {
    width: "150%",
    border: "solid 2px gray",
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      margin: "6px 0px",
      width: "100%",
      boxShadow: "none",
    },
  },
  photo: {
    "& i": {
      paddingRight: "13px",
    },
    display: "flex",
    alignItems: "center",
    height: "36px",
    color: "gray",
    cursor: "pointer",
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      "& i": {
        paddingRight: "15px",
        paddingLeft: "7px",
        fontSize: "15px",
      },
      flex: "1 0 50%",
    },
  },
  dropDownCard: {
    display: "flex",
    justifyContent: "space-between",
    position: "absolute",
    left: "5%",
    marginTop: "70px",
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      marginTop: "60px",
      left: "10%",
      transform: "translateX(-10%)",
    },
  },
  drop1: {
    marginRight: "0px",
    marginLeft: "0px",
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      marginTop: "0px",
      width: "160px",
    },
  },
  dropFilter: {
    color: "white",
    height: "30px",
    paddingLeft: "10px",
    paddingRight: "10px",
    borderRadius: "25px",
    backgroundColor: "#0C58D3",
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      height: "30px",
      borderRadius: "0px",
      borderRadius: "15px",
      position: "relative",
      padding: "0px 0px",
    },
    "& p": {
      color: "white",
      position: "relative",
      left: "7px",
      fontWeight: 600,
    },
  },
  card: {
    width: "330px",
    padding: "20px",
    paddingLeft: "5px",
    borderRadius: "10px",
    transition: "0.3s",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
    },
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      width: "200px",
    },
  },
  wideCard: {
    width: "500px",
    padding: "20px",
    paddingLeft: "5px",
    borderRadius: "10px",
    transition: "0.3s",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
    },
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      width: "100%",
    },
  },
  inputLabel: {
    fontSize: "13px",
    "& i": {
      marginRight: "3px",
    },
  },
  menuItem: {
    color: "gray",
  },
  dropFilterTop: {
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      marginTop: "1px",
    },
  },
  dropFilterRight: {
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      left: "8px",
    },
  },
  dropFilterGeneral: {
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      position: "relative",
      padding: "3px 0px",
      marginBottom: "0px",
    },
  },
  backBtn: {
    cursor: "pointer",
    fontSize: "20px",
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      width: "100%",
    },
  },
});

const theme = createTheme({
  overrides: {
    MuiGrid: {
      root: {
        MuiGrid: { paddingTop: "0px" },
      },
    },
  },
});

const global_styles = css`
  @media only screen and (min-width: 480px) {
    form.MuiPaper-root {
      box-shadow: none;
    }
  }
`;

function PhotoArtFilter({ searchTerm, photo_art_genre_name }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const [anyFieldFilter, setAnyFieldFilter] = useState(searchTerm || null);
  const [photographDrop, setPhotographDrop] = useState(false);
  const photographer_filter = get(
    briefGalleryListForPhotoArtTab.getFilter(),
    "photographers",
  );
  const [photoDropFilter, setPhotoDropFilter] = useState(
    head(photographer_filter) || "",
  );
  const photographerOptions = photographerDropdownOptions.getAsOptions();

  useEffect(() => {
    dispatch(briefGalleryListForPhotoArtTab.invalidateList());
    dispatch(briefGalleryListForPhotoArtTab.clearListFilter());
    dispatch(photographerDropdownOptions.updatePaginationNumItemsPerPage(1000));
    dispatch(photographerDropdownOptions.fetchListIfNeeded());
  }, []);

  useEffect(() => {
    const constructedFilter = constructGalleryFilter({
      gallery_type: "photo_art",
      any_field: [anyFieldFilter],
      photographers: [photoDropFilter],
    });
    dispatch(briefGalleryListForPhotoArtTab.invalidateList());
    dispatch(briefGalleryListForPhotoArtTab.trySetFilter(constructedFilter));
    dispatch(genreTagList.trySetFilter(constructedFilter));
  }, [anyFieldFilter, photoDropFilter]);

  const onPhotographerFilterChanged = (e) => {
    setPhotoDropFilter(e.target.value);
    setPhotographDrop(false);
  };

  const onAnyFieldFilterChanged = (e) => {
    setAnyFieldFilter(e.target.value);
  };

  useEffect(() => {
    if (anyFieldFilter) {
      dispatch(
        briefGalleryListForPhotoArtTab.updateListFilter({
          photo_art_filter_active: true,
        }),
      );
    } else if (photoDropFilter) {
      dispatch(
        briefGalleryListForPhotoArtTab.updateListFilter({
          photo_art_filter_active: true,
        }),
      );
    } else {
      dispatch(
        briefGalleryListForPhotoArtTab.updateListFilter({
          photo_art_filter_active: false,
        }),
      );
    }
  }, [anyFieldFilter, photoDropFilter]);

  const onShowPhotographerFilter = () => {
    setPhotographDrop((prevState) => !prevState);
  };

  const onBack = () => {
    setAnyFieldFilter("");
    setPhotoDropFilter(null);
    navigate("/customer/photo_art/");
    dispatch(
      briefGalleryListForPhotoArtTab.updateListFilter({
        photo_art_filter_active: false,
      }),
    );
  };

  const renderPhotographerDropFilter = () => {
    return (
      <div>
        <div
          className={`${classes.photographerFilter} ${photoDropFilter ? classes.photographerFilterSelected : ""}`}
        >
          <div
            className={`${classes.photo}
                           ${classes.dropFilterLeft}
                           ${classes.dropFilterGeneral}
                           ${photoDropFilter ? classes.dropFilter : ""}
                           ${photoDropFilter ? classes.dropFilterTop : ""}
                         `}
            onClick={onShowPhotographerFilter}
          >
            <DropFilterText
              icon={"fas fa-camera"}
              default_text={"Photographer"}
              text={get(
                photographerDropdownOptions.getObject(photoDropFilter),
                "name",
              )}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderSearchBar = () => {
    return (
      <div>
        <div>
          <Paper
            component="form"
            className={classes.search}
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              borderRadius: 50,
            }}
          >
            <IconButton sx={{ p: "4px" }} aria-label="menu">
              <SearchIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Keyword Search"
              onChange={onAnyFieldFilterChanged}
              value={anyFieldFilter}
              inputProps={{ "aria-label": "search" }}
            />
          </Paper>
        </div>
      </div>
    );
  };

  return (
    <div className={classes.subRoot}>
      <Global styles={global_styles} />
      <div className={classes.root}>
        <Grid
          container
          spacing={12}
          columns={{ xs: 12, sm: 8, md: 12 }}
          spacing={0}
        >
          <Hidden smDown>
            <Grid item xs={1} md={1}>
              <ArrowBackIcon
                sx={{ fontSize: "30px", marginTop: "5px", cursor: "pointer" }}
                onClick={onBack}
              />
            </Grid>
            <Grid item md={3}>
              {renderPhotographerDropFilter()}
            </Grid>
            <Grid item md={3}>
              {renderSearchBar()}
            </Grid>
            <Grid item xs={12} md={2}></Grid>
            <Grid item md={3}>
              <CheckoutButton variant="photo_art_tab_view" />
            </Grid>
          </Hidden>
          <Hidden smUp>
            <Grid item xs={12}>
              {renderSearchBar()}
            </Grid>
            <Grid item xs={12}>
              {renderPhotographerDropFilter()}
            </Grid>
          </Hidden>
        </Grid>
      </div>
      <div className={classes.dropDownCard}>
        {photographDrop && (
          <div className={classes.drop1}>
            <Card className={classes.card}>
              <FormControl sx={{ m: 1, minWidth: 80 }}>
                <InputLabel
                  id="demo-simple-select-helper-label"
                  className={classes.inputLabel}
                >
                  <i className="fas fa-camera"></i>
                  Photographer
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  label="Photograph"
                  inputProps={{ "aria-label": "Without label" }}
                  className={classes.select}
                  onChange={onPhotographerFilterChanged}
                  open={photographDrop}
                >
                  <MenuItem value={null} className={classes.menuItem}>
                    All
                  </MenuItem>
                  {map(photographerOptions, (option, index) => (
                    <MenuItem
                      key={index}
                      value={option.value}
                      className={classes.menuItem}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Card>
          </div>
        )}
      </div>
    </div>
  );
}

export default PhotoArtFilter;

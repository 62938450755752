import { ItemList } from "../orm";

class GalleryEventDirectAccessUrl extends ItemList {
  getEntityKey() {
    return "customer/gallery_event/is_using_direct_access_url";
  }

  isDirectAccessUrlValid(direct_access_url) {
    return async (dispatch, getState) => {
      dispatch(this.updateListFilter({ direct_access_url: direct_access_url }));
      dispatch(this.fetchListIfNeeded());
    };
  }
  setPasswordBypassForGalleryEvent(gallery_event_id) {
    sessionStorage.setItem(`gallery_event_id_${gallery_event_id}`, true);
  }

  getPasswordBypassStatusForGalleryEventGallery(gallery_event_id) {
    return sessionStorage.getItem(`gallery_event_id_${gallery_event_id}`);
  }
}

export const galleryEventDirectAccessUrl = new GalleryEventDirectAccessUrl(
  "gallery_event_direct_access_url_default",
);

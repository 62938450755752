import indexOf from "lodash/indexOf";
import size from "lodash/size";
import map from "lodash/map";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { css, Global } from "@emotion/react";
import { Box, Step, Stepper, StepLabel, makeStyles } from "@material-ui/core";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import AdjustIcon from "@mui/icons-material/Adjust";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";

const useStyles = makeStyles((theme) => ({
  stepper: {
    width: "100%",
  },
  stepLabel: {
    display: "block",
    cursor: "pointer",
  },
  stepLabelContainer: {
    whiteSpace: "nowrap",
    position: "relative",
  },
  stepLabelContainerInner: {
    marginTop: "5px",
    textAlign: "center",
    position: "absolute",
  },
  stepLabelText: {
    right: "24px",
    color: "#0c59cf",
    fontSize: "15px",
    position: "relative",
    [theme.breakpoints.up("md")]: {
      right: "20px",
    },
  },
  activeStep: {
    color: "#1da1f2",
  },
}));

const theme = createTheme({
  overrides: {
    MuiStepLabel: {
      root: {
        "&.Mui-disabled": { cursor: "pointer" },
      },
    },
    MuiSelect: { cursor: "pointer" },
  },
});

const global_styles = css`
  .Mui-checked span {
    font-weight: 600;
  }

  .MuiStepConnector-line {
    border-color: black;
  }
  .MuiStepper-root {
    padding: 0px;
  }
  .MuiStep-horizontal {
    padding-left: 0px;
    padding-right: 0px;
  }
  .MuiStepLabel-iconContainer {
    padding-right: 0px;
  }
  .MuiStepLabel-root.Mui-disabled,
  .Mui-disabled .MuiStepConnector-line {
    color: gray;
    border-color: gray;
  }
`;

export const TrolleyProgressBar = ({
  trolley_photographs,
  trolley_photograph_id,
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const photographs_as_pages = map(trolley_photographs, (x) => x.id);
  let active_step = useSelector(() =>
    indexOf(photographs_as_pages, trolley_photograph_id, 0),
  );
  const last_step = size(photographs_as_pages) + 1;

  const goToStep = (step) => {
    navigate(`/customer/v2_trolley/${step}`);
  };

  const goToShippingAddressPage = () => {
    navigate("/customer/shipping_address");
  };

  const ShippingStepIcon = (active_step) => {
    return (
      <div className="MuiStep-root MuiStep-horizontal">
        <span className="MuiStepLabel-root MuiStepLabel-horizontal makeStyles-stepLabel-66">
          <span className="MuiStepLabel-iconContainer">
            <svg
              className={`MuiSvgIcon-root MuiStepIcon-root ${active_step === -1 && `${classes.activeStep}`}`}
              focusable="false"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <LocalShippingIcon />
            </svg>
          </span>
        </span>
      </div>
    );
  };

  const TrolleyPhotographStepIcon = (active_step, index) => {
    return (
      <div className="MuiStep-root MuiStep-horizontal">
        <span className="MuiStepLabel-root MuiStepLabel-horizontal makeStyles-stepLabel">
          <svg
            className={`MuiSvgIcon-root MuiStepIcon-root ${active_step === index && `${classes.activeStep}`}`}
            focusable="false"
            viewBox="0 0 24 24"
            aria-hidden="true"
          >
            <AdjustIcon />
          </svg>
        </span>
      </div>
    );
  };

  return (
    <MuiThemeProvider theme={theme}>
      <Global styles={global_styles} />
      <Box mb={5} className={classes.stepper}>
        <Stepper activeStep={active_step >= 0 ? active_step : last_step}>
          {photographs_as_pages.map((step, index) => (
            <Step key={step} onClick={() => goToStep(step)}>
              <StepLabel
                className={classes.stepLabel}
                StepIconComponent={() =>
                  TrolleyPhotographStepIcon(active_step, index)
                }
              >
                {active_step === index && (
                  <div className={classes.stepLabelContainer}>
                    <div className={classes.stepLabelContainerInner}>
                      <b className={classes.stepLabelText}>
                        Photo {index + 1} Options
                      </b>
                    </div>
                  </div>
                )}
              </StepLabel>
            </Step>
          ))}
          <Step onClick={goToShippingAddressPage}>
            <StepLabel
              className={classes.stepLabel}
              StepIconComponent={() => ShippingStepIcon(active_step)}
            >
              {active_step === -1 && (
                <div className={classes.stepLabelContainer}>
                  <b className={classes.stepLabelText}>Shipping</b>
                </div>
              )}
            </StepLabel>
          </Step>
        </Stepper>
      </Box>
    </MuiThemeProvider>
  );
};

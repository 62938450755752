import { useSiteTheme } from "bossfotos-design-system";
import { BossFotosLogo } from "bossfotos-design-system";

export const BossFotosNavBarLogo = ({
  height,
}: {
  height: number | undefined;
}) => {
  const theme = useSiteTheme();

  return (
    <BossFotosLogo
      alt="BossFotos"
      height={height}
      variant={theme ? "white" : "navbar"}
    />
  );
};

import React, { useState, useRef, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import {
  Container,
  Box,
  Grid,
  makeStyles,
  FormLabel,
  IconButton,
  Typography,
  InputAdornment,
  Button,
  Snackbar,
} from "@material-ui/core"
import MuiAlert from "@material-ui/lab/Alert"
import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator"
import { useIsMobile } from "../../actions/ui"
import { getForgotEmail } from "../../actions/user"
import { setPasswordApi } from "../../apis/userapi"
import { userInfo } from "../../actions/user"
import LogoImg from "../../assets/logo/black_logo.png"
import LoadingSpinner from "../components/LoadingSpinner"
import style from "./styles"

const useStyles = makeStyles(style)

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const SetPassword = () => {
  const formRef = useRef()
  const classes = useStyles()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isMobile = useIsMobile()
  const [width, setWidth] = useState(window.innerWidth)
  const [password, setPassword] = useState("")
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [confirmPassword, setComfirmPassword] = useState("")
  const [verifycode, setVerifyCode] = useState("")
  const [hasSubmittedSuccessfully, setHasSubmittedSuccessfully] = useState(false)
  const [openSnackBar, setOpenSnackBar] = useState(false)
  const [message, setMessage] = useState("")
  const forgotEmail = useSelector((state) => getForgotEmail(state))

  useEffect(() => {
    window.addEventListener("resize", updateWidth)
    return () => window.removeEventListener("resize", updateWidth)
  })

  const updateWidth = () => {
    setWidth(window.innerWidth)
  }

  const handleSubmit = () => {
    setHasSubmittedSuccessfully(true)
    const sendData = {
      verify_code: verifycode,
      email: forgotEmail,
      password: password,
    }


    setPasswordApi(sendData)
      .then((res) => {
        if (res.data.status === "success") {
          const userInfoData = res.data.user
          if (!userInfoData.is_staff) {
            const token = res.data.token
            localStorage.setItem("auth_name", userInfoData.first_name)
            localStorage.setItem("auth_id", userInfoData.id)
            localStorage.setItem("token", token)
            localStorage.setItem("auth_email",userInfoData.email)
            dispatch(userInfo(userInfoData))
            setHasSubmittedSuccessfully(false)
            navigate("/customer/login")
          }
        } else {
          setOpenSnackBar(true)
          setHasSubmittedSuccessfully(false)
          setMessage("Please confirm your verification code in your mailbox!")
        }
      })
      .catch((error) => {
        if (error) {
          setOpenSnackBar(true)
          setHasSubmittedSuccessfully(false)
          setMessage(" Unable to log in with provided credentials!")
        }
      })
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      className={classes.scrollContainer}
    >
      <Box
        className={!isMobile ? classes.root : classes.mobileroot}
        title="Login"
      >
        <Snackbar
          open={openSnackBar}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          autoHideDuration={6000}
          onClose={() => setOpenSnackBar(false)}
        >
          <Alert onClose={() => setOpenSnackBar(false)} severity="error">
            {message}
          </Alert>
        </Snackbar>
        { hasSubmittedSuccessfully && <LoadingSpinner /> }
        <Container>
        <Grid container spacing={1} className={classes.gridContainer}>
            <Grid item xs={!isMobile ? 4 : 12}>
            <Box display="flex" justifyContent="left">
                    <img src={LogoImg} alt="/" className={classes.logo_img} />
              </Box>
              {width < 769 && (
                <Box display="flex" justifyContent="center">
                  <p className={classes.blacktitle}>Go Find Yourself</p>
                </Box>
              )}
              <Box mt={2}>
                <p className={classes.bigtext}>Reset Password</p>
              </Box>
              <Box mt={2}>
                <p className={classes.smalltext}>
                  A verification code has been sent to your registered email
                </p>
              </Box>
              <Box mt={5}>
                <ValidatorForm ref={formRef} onSubmit={handleSubmit}>
                  <Grid container spacing={5}>
                    <Grid item md={12} xs={12}>
                      <FormLabel className={classes.label}>
                        Verification Code
                      </FormLabel>
                      <TextValidator
                        fullWidth
                        type="number"
                        placeholder="Please insert your verification code"
                        value={verifycode}
                        onChange={(e) => setVerifyCode(e.target.value)}
                        variant="standard"
                        // validators={["isPasswordMatch", "required"]}
                        validators={["required"]}
                        errorMessages={[
                          // "password mismatch",
                          "this field is required",
                        ]}
                        InputProps={{
                          style: {
                            height: 50,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <FormLabel className={classes.label}>New Password</FormLabel>
                      <TextValidator
                        fullWidth
                        placeholder="New Password"
                        type={showPassword ? "text" : "password"}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        variant="standard"
                        validators={["required"]}
                        errorMessages={[
                          "this field is required"
                        ]}
                        InputProps={{
                          style: {
                            height: 50,
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={(e) => setShowPassword(!showPassword)}
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <FormLabel className={classes.label}>
                        Confirm New Password
                      </FormLabel>
                      <TextValidator
                        fullWidth
                        placeholder="Confirm new password"
                        type={showConfirmPassword ? "text" : "password"}
                        value={confirmPassword}
                        onChange={(e) => setComfirmPassword(e.target.value)}
                        variant="standard"
                        validators={["required"]}
                        errorMessages={[
                          "this field is required"
                        ]}
                        InputProps={{
                          style: {
                            height: 50,
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={(e) => setShowConfirmPassword(!showConfirmPassword)}
                              >
                                {showConfirmPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />

                    </Grid>
                    <Grid className={classes.signInButtonWrapper} item md={12} xs={12}>
                      <Button className={classes.button} type="submit">
                        <Typography className={classes.resetPasswordBtnText}>
                          Reset Password
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </ValidatorForm>
              </Box>
            </Grid>
            <Grid xs={1}></Grid>
                {!isMobile && (
                    <Grid item xs={6} style={{ paddingRight: 10 }}>
                      <Box className={classes.rightside}>
                        <Typography className={classes.imgtitle}>
                          <i>Find Yourself</i>
                        </Typography>
                      </Box>
                    </Grid>
                )}
              </Grid>
        </Container>
      </Box>
    </Box>
  )
}

export default SetPassword

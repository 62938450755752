export const DATE_FILTER_OPTIONS = [
  { value: "placeholder_date_filter_option", label: "Date" }, // needed for map view
  { value: "last_24_hrs", label: "Last 24hrs" },
  { value: "last_2_days", label: "Last 2 days" },
  { value: "last_7_days", label: "Last 7 days" },
  { value: "last_30_days", label: "Last 30 days" },
  { value: "last_90_days", label: "Last 90 days" },
  { value: "last_180_days", label: "Last 180 days" },
  { value: "last_365_days", label: "Last 365 days" },
  { value: "last_2_years", label: "Last 2 years" },
  { value: "last_3_years", label: "Last 3 years" },
];

// remove this for event filtering
export const DATE_FILTER_OPTIONS_FILTERED = DATE_FILTER_OPTIONS.filter(
  (item) => item.value !== "placeholder_date_filter_option",
);

export const DATE_FILTER_OPTIONS_NEW = [
  { value: undefined, label: "Recent" },
  ...DATE_FILTER_OPTIONS_FILTERED,
];

import React, { useEffect } from "react";
import styles from "./Modal.module.css";
import { PropMixins, cssStyles } from "../mixins";
import { useResponsiveBreakpoints } from "../primitives/useResponsiveBreakpoints";
import { createPortal } from "react-dom";
import { BreakPoints, ThemeBossFotos2024 } from "bossfotos-design-system";
import { useThemeContext } from "../themes/ThemeProvider";

/**
 * ModalCover
 * @returns
 */
export const ModalCover = ({
  children,
  ...rest
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> &
  PropMixins) => {
  const themeContext = useThemeContext();

  useEffect(() => {
    themeContext.setInjectable(
      <div {...rest} className={styles.modalcover} style={cssStyles(rest)}>
        {children}
      </div>
    );

    return () => {
      themeContext.setInjectable(undefined);
    };
  }, [children]);

  return <></>;
};

/**
 * ModalWrapper
 * @returns
 */
export const ModalWrapper = ({
  children,
  ...props
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>) => {
  const classes = [styles.modalwrapper];


  return (
    <div {...props} className={classes.join(' ')}>
      {children}
    </div>
  );
};

/**
 * ModalFormWrapper
 * @returns
 */
export const ModalFormWrapper = ({
  children,
  ...props
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>) => {
  return (
    <div {...props} className={styles.modalformwrapper}>
      {children}
    </div>
  );
};

/**
 * ModalContent
 * @returns
 */
export const ModalContent = ({
  children,
  ...props
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>) => {
  return (
    <div {...props} className={styles.modalcontent}>
      {children}
    </div>
  );
};

/**
 * ModalFooter
 * @returns
 */
export const ModalFooter = ({
  children,
  border,
  ...props
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & { border?: boolean } & PropMixins) => {
  const activeBreakpoint = useResponsiveBreakpoints();
  const css = [styles.modalfooter];
  if (border) {
    css.push(styles.modalfooterborder);
  }
  return (
    <div
      {...props}
      className={css.join(" ")}
      style={cssStyles(props, activeBreakpoint)}
    >
      {children}
    </div>
  );
};

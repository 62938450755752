import head from "lodash/head";
import get from "lodash/get";
import map from "lodash/map";
import filter from "lodash/filter";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Card from "@mui/material/Card";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import responsive from "../../mobile";
import { DATE_FILTER_OPTIONS } from "../../v2_actions/date";

const useStyles = makeStyles({
  select: {
    width: "310px",
    height: "50px",
    [`@media only screen and (max-width:${responsive.getMobMaxWidthPixels()})`]:
      {
        width: "175px",
      },
  },
  list: {
    width: "300px",
  },
  cardMainSec: {
    width: "60%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    height: "80vh",
    overflowY: "scroll",
    marginLeft: "20px",
    [`@media only screen and  (min-width:${responsive.getTabMinWidthPixels()})and (max-width: ${responsive.getTabMaxWidthPixels()})`]:
      {
        width: "55%",
      },
    [`@media only screen and (max-width:${responsive.getMobMaxWidthPixels()})`]:
      {
        display: (props) => (props.isGalleryShow ? "flex" : "none"),
        marginLeft: "0px",
        width: "100%",
        height: "auto",
        marginTop: "10px",
        overflowY: "hidden",
      },
    card: {
      width: "330px",
      padding: "20px",
      paddingLeft: "5px",
      borderRadius: "10px",
      transition: "0.3s",
      boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
      "&:hover": {
        boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
      },
      [`@media only screen and (max-width:${responsive.getMobMaxWidthPixels()})`]:
        {
          width: "200px",
        },
    },
    list: {
      width: "300px",
    },
    calendar: {
      "& i": {
        paddingRight: "13px",
      },
      display: "flex",
      alignItems: "center",
      color: "gray",
      cursor: "pointer",
      [`@media only screen and (max-width:${responsive.getMobMaxWidthPixels()})`]:
        {
          "& i": {
            paddingRight: "5px",
            paddingLeft: "7px",
            fontSize: "15px",
          },
          flex: "1 0 50%",
        },
    },
  },
});

function CalendarFilter({ props, onDateFilterChanged, showCalendarDropdown }) {
  const classes = useStyles(props);
  const [calenDropFilter, setCalenDropFilter] = useState("last_7_days");

  return (
    <Card className={classes.card}>
      <FormControl sx={{ m: 1, minWidth: 80 }}>
        <InputLabel className={classes.inputLabel}>
          <i className="fas fa-calendar-week"></i>
          {get(
            head(
              filter(
                DATE_FILTER_OPTIONS,
                (option) => option.value === calenDropFilter,
              ),
            ),
            "label",
            "Date Range",
          )}
        </InputLabel>
        <Select
          open
          className={classes.select}
          onChange={onDateFilterChanged}
          inputProps={{ "aria-label": "Without label" }}
          label={get(
            head(
              filter(
                DATE_FILTER_OPTIONS,
                (option) => option.value === calenDropFilter,
              ),
            ),
            "label",
            "Date Range",
          )}
        >
          {map(DATE_FILTER_OPTIONS, (option, index) => (
            <MenuItem
              key={index}
              value={option.value}
              className={classes.menuItem}
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Card>
  );
}

export default CalendarFilter;

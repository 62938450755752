import React from "react";
import NavButton from "../navigation/NavButton";
import FaIcon from "../fa-icon/FaIcon";
import styles from "./Expander.module.css";

/**
 * Expander Control
 * @returns
 */
const ExpanderControl = ({
  onExpand,
  children,
}: React.PropsWithChildren<{ onExpand: () => void }>) => {
  return (
    <div className={styles.expandercontrol}>
      <>{children}</>
      <NavButton onClick={onExpand} variant="expander">
        <FaIcon icon="fa-chevron-right" />
      </NavButton>
    </div>
  );
};

const HorizontalExpansionContainer = ({
  children,
  expanded,
}: React.PropsWithChildren<{ expanded: boolean }>) => {
  return (
    <div
      className={`${styles.horizontalexpansioncontainer} ${expanded && styles.horizontalexpansioncontainerexpanded}`}
    >
      <>{children}</>
    </div>
  );
};

export { ExpanderControl, HorizontalExpansionContainer };

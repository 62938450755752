import split from "lodash/split";
import join from "lodash/join";
import {
  CUSTOMER_UI_CHECKOUT_PATH,
  CUSTOMER_UI_EVENTS_PAGE_PATH,
  CUSTOMER_UI_MAP_PAGE_PATH,
  CUSTOMER_UI_SIGNUP_PATH,
} from "./routes";

export const isWindowInIframe = () => {
  if (window.LOCAL_SETTINGS.FORCE_IFRAME_BEHAVIOUR === true) {
    return true;
  }

  return window.location !== window.parent.location;
};

export const setIsPhotographerIframe = () => {
  if (sessionStorage.getItem("iframesource") === null) {
    //console.log("setiframe setIsPhotographerIframe");
    isWindowInIframe() &&
      sessionStorage.setItem("iframesource", "photographer");
  }
};

export const getIsPhotographerIframe = () => {
  return (
    isWindowInIframe() &&
    sessionStorage.getItem("iframesource") === "photographer"
  );
};

export const setIsEventIframe = () => {
  if (sessionStorage.getItem("iframesource") === null) {
    //console.log("setiframe setIsEventIframe");
    isWindowInIframe() && sessionStorage.setItem("iframesource", "event");
  }
};

export const getIsEventIframe = () => {
  return (
    isWindowInIframe() && sessionStorage.getItem("iframesource") === "event"
  );
};

export const setCheckoutNavBackButtonUrl = () => {
  sessionStorage.setItem("navBackButtonUrl", window.location.pathname);
};

export const setGalleryNavBackButtonUrl = () => {
  !isWindowInIframe() &&
    sessionStorage.setItem("galleryNavBackButtonUrl", window.location.pathname);
};

export const getCheckoutNavBackButtonUrl = () => {
  return (
    sessionStorage.getItem("navBackButtonUrl") || CUSTOMER_UI_MAP_PAGE_PATH
  );
};

export const goToCheckoutPage = (
  navigate,
  trolley,
  has_trolley_photographs,
  setShowPageRefreshButton,
  loggedInUserId,
) => {
  const isInIframe = isWindowInIframe();
  const bossfotosOriginDomain = window.location.origin;
  const activeTrolleyId = trolley?.id;

  isInIframe && setShowPageRefreshButton(true);
  !isInIframe && setCheckoutNavBackButtonUrl();

  if (!loggedInUserId) {
    if (isInIframe) {
      window.open(
        `${bossfotosOriginDomain}${CUSTOMER_UI_SIGNUP_PATH}/${activeTrolleyId}`,
        "_blank",
      );
    } else {
      navigate(CUSTOMER_UI_SIGNUP_PATH);
    }
  } else if (has_trolley_photographs) {
    if (isInIframe) {
      window.open(
        `${bossfotosOriginDomain}${CUSTOMER_UI_CHECKOUT_PATH(
          activeTrolleyId,
        )}`,
        "_blank",
      );
    } else {
      navigate(`${CUSTOMER_UI_CHECKOUT_PATH(trolley.id)}`);
    }
  }
};

export const fixScrollLockOnMuiPromptDisplay = () => {
  // https://mui.com/material-ui/api/modal/ prop=disableScrollLock
  // https://stackoverflow.com/questions/46840425/react-material-ui-prevent-body-scrolling-on-popover
  document.getElementsByTagName("body")[0].style = "overflow:scroll;";
};

export const getGalleryNavBackButtonUrl = () => {
  if (sessionStorage.getItem("galleryNavBackButtonUrl")) {
    return sessionStorage.getItem("galleryNavBackButtonUrl");
  }
  return CUSTOMER_UI_EVENTS_PAGE_PATH;
};

export const goBackFromGalleryPage = (navigate, urlParams) => {
  if (isWindowInIframe()) {
    navigate(-1);
    return;
  }
  navigate(`${getGalleryNavBackButtonUrl()}?${urlParams}`);
};

export const formatSessionNameForURL = (sessionName) =>
  sessionName?.replace(/[\/\\ %]/g, "-").replace(/-+$/, "") ?? "";

export const replaceDashesWithSpaces = (value) => join(split(value, "-"), " ");

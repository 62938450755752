import React from "react";
import styles from "./Indicator.module.css";

/**
 * IndicatorSmall
 * @returns
 */
export const IndicatorSmall = () => {
  return <div className={styles.indicatorsmall}></div>;
};

import {
  PropMixins,
  ResponsiveVariant,
  cssStyles,
  resolveVariant,
} from "../mixins";

import React from "react";
import styles from "./Box.module.css";
import { useResponsiveBreakpoints } from "../primitives/useResponsiveBreakpoints";

type Variant = "default" | "primary" | "empty";

/**
 * Box Component
 * @returns
 */
const Box = ({
  children,
  ...rest
}: React.PropsWithChildren<
  {
    spacing?: boolean;
  } & PropMixins &
    ResponsiveVariant<Variant>
>) => {
  const activeBreakpoint = useResponsiveBreakpoints();
  let resolvedVariant = resolveVariant(rest, activeBreakpoint);
  let classes = [styles.box, styles[`box-${resolvedVariant ?? "default"}`]];

  if (resolvedVariant === "empty") {
    classes = [];
  }

  return (
    <div
      className={classes.join(" ")}
      style={cssStyles(rest, activeBreakpoint)}
    >
      {children}
    </div>
  );
};

export default Box;

import React from "react";
import Card from "@mui/material/Card";
import { makeStyles } from "@material-ui/core";
import { getMobMaxWidthPixels } from "../layout/mobile";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import TextFieldDatePicker from "./TextFieldDatePicker";

const useStyles = makeStyles({
  drop3: {
    marginLeft: "290px",

    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      margin: "auto",
    },
  },
  card: {
    width: "250px",
    padding: "20px",
    borderRadius: "10px",
    transition: "0.3s",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
    },
  },
  submitBtn: {
    position: "relative",
    right: 10,
  },
});

function CustomCalendarDateFilter({
  props,
  dateTo,
  dateFrom,
  setDateTo,
  setDateFrom,
  onCustomDateFilterDoneClicked,
}) {
  const classes = useStyles(props);

  return (
    <div className={classes.drop3}>
      <Card className={classes.card}>
        <Stack
          my={1}
          direction="row"
          spacing={3}
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item xs={2} sx={{ width: 40 }}>
            From:
          </Grid>
          <Grid item xs={10} sx={{ width: "100%" }}>
            <TextFieldDatePicker
              name="dateFrom"
              value={dateFrom}
              onChange={setDateFrom}
            />
          </Grid>
        </Stack>
        <Stack
          my={1}
          direction="row"
          spacing={3}
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item xs={2} sx={{ width: 30 }}>
            To:
          </Grid>
          <Grid item xs={10} sx={{ width: "100%" }}>
            <TextFieldDatePicker
              name="dateTo"
              value={dateTo}
              onChange={setDateTo}
            />
          </Grid>
        </Stack>
        <Button
          className={classes.submitBtn}
          onClick={() => onCustomDateFilterDoneClicked()}
        >
          Done
        </Button>
      </Card>
    </div>
  );
}

export default CustomCalendarDateFilter;

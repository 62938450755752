import get from "lodash/get";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { CurrencyValue } from "../CurrencyValue";
import { useSelector, useDispatch } from "react-redux";
import { trolleyPhotographList } from "../../v2_actions/trolley_photograph";

export const TrolleyPhotographPrice = ({
  user_country,
  photographer,
  photo_delivery_type,
  priceOfPrintConfiguration,
}) => {
  let photographerPrice;
  let customerPrice;
  const dispatch = useDispatch();
  const { trolley_photograph_id } = useParams();
  const printMarkupPercentage =
    1 + get(photographer, ["price_info", "print_markup_percentage"]) / 100;
  const active_trolley_photograph = useSelector(() =>
    trolleyPhotographList.getObject(trolley_photograph_id),
  );
  const photographPrice = get(active_trolley_photograph, "base_price");
  const lowResPhotographPrice = get(active_trolley_photograph, "low_res_price");
  const customerPhotographPrice = get(
    active_trolley_photograph,
    "base_price_in_customer_currency",
  );
  const customerLowResPhotographPrice = get(
    active_trolley_photograph,
    "low_res_price_in_customer_currency",
  );
  const customerFx = get(
    active_trolley_photograph,
    "customer_conversion_rate_to_pound",
  );
  const photographerFx = get(
    active_trolley_photograph,
    "photographer_conversion_rate_to_pound",
  );
  const fxRatio = photographerFx / customerFx;

  useEffect(() => {
    dispatch(trolleyPhotographList.invalidateList());
    dispatch(trolleyPhotographList.fetchListIfNeeded());
  }, [photographPrice, customerPhotographPrice]);

  if (photo_delivery_type === "digital_only") {
    photographerPrice = photographPrice;
    customerPrice = customerPhotographPrice;
  } else if (photo_delivery_type === "low_res_digital_only") {
    photographerPrice = lowResPhotographPrice;
    customerPrice = customerLowResPhotographPrice;
  } else {
    photographerPrice =
      photographPrice +
      fxRatio * priceOfPrintConfiguration * printMarkupPercentage;
    customerPrice = photographerPrice / fxRatio;
  }

  const showTrolleyPhotographPrice = (price) => {
    const isDigitalDeliveryType =
      trolleyPhotographList.isDigitalDeliveryType(photo_delivery_type);
    if (
      (isDigitalDeliveryType && price >= 0) ||
      (photo_delivery_type === "digital_plus_prints" && photographerPrice)
    ) {
      return (
        <>
          Item Price:
          <CurrencyValue
            country={get(photographer, "country")}
            value={photographerPrice}
          />
          <span> </span>
          {get(user_country, "id") !== get(photographer, "country") && (
            <>
              (
              <CurrencyValue
                country={get(user_country, "id")}
                value={customerPrice}
              />
              )
            </>
          )}
        </>
      );
    } else {
      return null;
    }
  };
  return showTrolleyPhotographPrice(photographerPrice);
};

import map from "lodash/map";
import head from "lodash/head";
import get from "lodash/get";
import first from "lodash/first";
import filter from "lodash/filter";
import {
  NUM_PHOTOS_PER_GALLERY_PAGE_AFTER_SEARCHING,
  NUM_PHOTOS_PER_GALLERY_PAGE_WHEN_SHOWING_SHOW_MORE_BUTTON,
} from "../v2_actions/gallery";
import React, { useEffect, useState } from "react";
import {
  activityTagsDropdownOptions,
  galleryEventDropdownOptions,
  photographerDropdownOptions,
} from "../v2_actions/dropdown_options";
import {
  formatSessionNameForURL,
  getIsPhotographerIframe,
} from "../v2_actions/nav";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import { Button } from "@material-ui/core";
import CalendarFilter from "./filter/CalendarFilter";
import Card from "@mui/material/Card";
import CustomCalendarFilter from "./filter/CustomCalendarDateFilter";
import { DATE_FILTER_OPTIONS } from "../v2_actions/date";
import { DropFilterText } from "./filter/DropFilterValue";
import { EventPageBreadCrumbs } from "./eventpage/EventPageBreadcrumbs";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { SearchGalleryNameFilter } from "./filter/SearchGalleryNameFilter";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import { albumListForEventTab } from "../v2_actions/customer_album";
import { briefGalleryList } from "../v2_actions/brief_gallery";
import { constructGalleryFilter } from "../v2_actions/gallery";
import { galleryEventDirectAccessUrl } from "../v2_actions/customer_gallery_event_direct_access_url";
import { galleryEventList } from "../v2_actions/gallery_event";
import { getMobMaxWidthPixels } from "./layout/mobile";
import { makeLogger } from "../utils/o11y/logger";
import { makeStyles } from "@material-ui/core";
import moment from "moment";
import { useIsMobile } from "../actions/ui";
import { useNavigate } from "react-router-dom";

const logger = makeLogger({
  enabled: true,
  label: "SubEventFilter",
  color: "darkbrown",
});

const useStyles = makeStyles({
  subNav: {
    display: "flex",
    flexDirection: "column",
  },
  select: {
    width: "310px",
    height: "50px",
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      width: "175px",
    },
  },
  list: {
    width: "300px",
  },
  mobileEventFilterWrapper: {
    top: 50,
    position: "relative",
  },
  mobileEventFilterTop: {
    margin: "5px 10px",
  },
  mobileEventFilterBottom: {
    margin: "5px 10px",
  },
  subRoot: {
    top: "3.9rem",
    zIndex: 9999,
    width: "100%",
    display: "flex",
    position: "sticky",
    flexDirection: "column",
    backgroundColor: "white",
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      top: 45,
      zIndex: 100,
      padding: "10px 0px",
      position: "relative",
    },
  },
  iFrameSubRoot: {
    top: 0,
  },
  filterWrapper: {
    height: 40,
    borderRadius: 5,
    padding: "18px 30px",
    border: "1px solid gray",
    margin: "10px 20px 0px 0px",
  },
  desktopEventFilterWrapper: {
    height: 40,
    left: 0,
    width: "100%",
    position: "absolute",
    padding: "18px 15px",
    margin: "10px 20px 0px 0px",
  },
  photoGalleriesTextWrapper: {
    marginLeft: 12,
  },
  filterHeading: {
    color: "#444444",
    fontWeight: 800,
  },
  iframeFilterHeading: {
    fontWeight: 800,
    color: "#444444",
    margin: "16px 0px 25px",
  },
  root: {
    height: 35,
    margin: 20,
    display: "flex",
    borderRadius: 5,
    position: "relative",
    justifyContent: "end",
    alignItems: "center",
    position: "relative",
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      marginTop: "0px",
      marginBottom: "0px",
      width: "95%",
      fontSize: "16px",
      border: "none",
      flexWrap: "wrap",
      height: "90px",
    },
  },
  search: {
    "& p": {
      fontWeight: 800,
      color: "#0F75BC",
    },
    cursor: "pointer",
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      display: "none",
    },
  },
  location: {
    "& i": {
      paddingRight: "5px",
      fontSize: "17px",
    },
    display: "flex",
    alignItems: "center",
    color: "gray",
    cursor: "pointer",
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      "& i": {
        paddingRight: "5px",
        paddingLeft: "7px",
        fontSize: "15px",
      },
      flex: "1 0 50%",
    },
  },
  calendar: {
    "& i": {
      paddingRight: "5px",
    },
    display: "flex",
    alignItems: "center",
    color: "gray",
    cursor: "pointer",
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      "& i": {
        paddingRight: "5px",
        paddingLeft: "7px",
        fontSize: "15px",
      },
      flex: "1 0 50%",
    },
  },
  galleryEvent: {
    "& i": {
      paddingRight: "5px",
    },
    display: "flex",
    alignItems: "center",
    color: "gray",
    cursor: "pointer",
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      "& i": {
        paddingRight: "5px",
        paddingLeft: "7px",
        fontSize: "15px",
      },
      flex: "1 0 50%",
    },
  },
  sport: {
    "& i": {
      paddingRight: "5px",
    },
    display: "flex",
    alignItems: "center",
    color: "gray",
    cursor: "pointer",
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      "& i": {
        paddingRight: "5px",
        paddingLeft: "7px",
        fontSize: "15px",
      },
      flex: "1 0 50%",
      marginTop: "-14px",
    },
  },
  photo: {
    "& i": {
      paddingRight: "5px",
    },
    display: "flex",
    alignItems: "center",
    color: "gray",
    cursor: "pointer",
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      "& i": {
        paddingRight: "5px",
        paddingLeft: "7px",
        fontSize: "15px",
      },
      flex: "1 0 50%",
      marginTop: "-14px",
    },
  },
  dropDownCard: {
    display: "flex",
    justifyContent: "space-between",
    position: "absolute",
    left: "34%",
    transform: "translateX(-34%)",
    marginTop: "70px",
    zIndex: 9,
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      marginTop: "40px",
      left: "10%",
      transform: "translateX(-10%)",
      marginTop: "0px",
      left: 0,
      zIndex: 9,
      transform: "translateX(0px)",
      marginTop: 40,
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
      width: "100%",
    },
  },
  drop1: {
    marginRight: "20px",
    marginLeft: "20px",
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      marginTop: "0px",
      width: "160px",
    },
  },
  drop2: {
    marginLeft: "130px",
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      marginTop: "0px",
      marginLeft: "30px",
    },
  },
  drop3: {
    marginLeft: "290px",

    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      marginLeft: 0,
      marginTop: 0,
    },
  },
  drop4: {
    marginLeft: "490px",
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      marginTop: "40px",
      marginRight: "0px",
      marginLeft: "0px",
    },
  },
  drop5: {
    marginLeft: "630px",
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      marginLeft: 0,
      marginTop: 0,
    },
  },
  card: {
    width: "330px",
    padding: "20px",
    paddingLeft: "5px",
    borderRadius: "10px",
    transition: "0.3s",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
    },
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      width: "200px",
    },
  },
  wideCard: {
    width: "500px",
    padding: "20px",
    paddingLeft: "5px",
    borderRadius: "10px",
    transition: "0.3s",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
    },
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      width: "100%",
    },
  },
  inputLabel: {
    fontSize: "13px",
    "& i": {
      marginRight: "3px",
    },
  },
  menuItem: {
    color: "gray",
  },
  dropFilterTop: {
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      marginTop: "1px",
    },
  },
  dropFilterRight: {
    "& p": {
      fontWeight: 800,
    },
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      left: "5%",
    },
  },
  dropFilterGeneral: {
    "& p": {
      fontWeight: 800,
    },
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      position: "relative",
      padding: "3px 0px",
      marginBottom: "5px",
      "& p": {
        fontWeight: 400,
      },
    },
  },
  dropFilter: {
    color: "#0C58D3",
    height: "30px",
    paddingLeft: "10px",
    paddingRight: "10px",
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      height: "30px",
      borderRadius: "15px",
      position: "relative",
      padding: "0px 0px",
    },
    "& p": {
      color: "#0C58D3",
      position: "relative",
      left: "7px",
      fontWeight: 800,
    },
    "& i": {
      paddingRight: 0,
    },
  },
  searchDrop: {
    "& input": {
      borderRadius: "15px",
      border: "2px solid black",
      padding: "5px",
      paddingLeft: "30px",
      position: "relative",
      width: "250px",
      marginLeft: "10px",
    },
    "& i": {
      position: "absolute",
      zIndex: "1",
      marginTop: "8px",
      marginLeft: "17px",
    },
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      "& input": {
        width: "140px",
      },
      "& i": {
        fontSize: "13px",
      },
    },
  },
  backBtn: {
    [`@media only screen and (max-width:${getMobMaxWidthPixels()})`]: {
      width: "100%",
    },
  },
});

function SubEventFilter({
  isInIframe,
  photographer,
  album_name,
  gallery_event_name,
  galleryEventNameForURL,
  publicUrlPathForGalleryEvent,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const classes = useStyles();
  const [searchDrop, setSearchDrop] = useState(false);
  const [anyFieldFilter, setAnyFieldFilter] = useState(null);

  const sessionStorageIsPhotographerIframe = getIsPhotographerIframe();
  const [showCalendarDropdown, setshowCalendarDropdown] = useState(false);
  const [showCustomDateFilter, setShowCustomDateFilter] = useState(false);
  const calendar_filter = get(briefGalleryList.getFilter(), "calendar_filter");
  const [calenDropFilter, setCalenDropFilter] = useState(
    calendar_filter || "placeholder_date_filter_option",
  );
  const [dateFilterTouched, setDateFilterTouched] = useState(
    calendar_filter ? true : false,
  );

  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);

  const [sportDrop, setSportDrop] = useState(false);
  const activity_tags_filter = get(
    briefGalleryList.getFilter(),
    "activity_tags",
  );
  const [sportDropFilter, setSportDropFilter] = useState(
    head(activity_tags_filter) || null,
  );
  const activityTagOptions = activityTagsDropdownOptions.getAsOptions();
  const hasDirectUrlAccess = get(
    head(useSelector(() => galleryEventDirectAccessUrl.getVisibleObjects())),
    "gallery_event_id",
  );

  const [photographDrop, setPhotographDrop] = useState(false);
  const [photographerFilterTouched, setPhotographerFilterTouched] =
    useState(false);

  const photographer_filter = get(
    briefGalleryList.getFilter(),
    "photographers",
  );
  const [photoDropFilter, setPhotoDropFilter] = useState(
    head(photographer_filter) || null,
  );
  const photographerOptions = photographerDropdownOptions.getAsOptions();

  const [galleryEventDrop, setGalleryEventDrop] = useState(false);
  const gallery_event_filter = get(
    briefGalleryList.getFilter(),
    "gallery_event",
  );
  const [galleryEventDropFilter, setGalleryEventDropFilter] = useState(
    gallery_event_filter || null,
  );
  const galleryEventOptions = useSelector(() =>
    galleryEventDropdownOptions.getAsOptions(),
  );
  const [filtersWereTouched, setFiltersWereTouched] = useState(false);
  const show_gallery_filter_date_dropdown = useSelector(() =>
    get(briefGalleryList.getFilter(), "show_gallery_filter_date_dropdown"),
  );
  const hasDateFilterBeenTouched = useSelector(() =>
    Boolean(get(briefGalleryList.getFilter(), "shot_at_date_from")),
  );

  useEffect(() => {
    logger.log("load firehose");
    dispatch(activityTagsDropdownOptions.updatePaginationNumItemsPerPage(1000));
    dispatch(activityTagsDropdownOptions.fetchListIfNeeded());
    dispatch(photographerDropdownOptions.updatePaginationNumItemsPerPage(1000));
    dispatch(photographerDropdownOptions.fetchListIfNeeded());
    dispatch(galleryEventDropdownOptions.updatePaginationNumItemsPerPage(1000));
    dispatch(galleryEventDropdownOptions.fetchListIfNeeded());
  }, []);

  useEffect(() => {
    const constructedFilter = constructGalleryFilter({
      gallery_type: "event",
      any_field: anyFieldFilter,
      activity_tags: [sportDropFilter],
      photographers: [photoDropFilter],
      gallery_event: galleryEventDropFilter,
      filters_were_touched: filtersWereTouched,
      shot_at_date_to: dateTo,
      shot_at_date_from: dateFrom,
    });

    if (show_gallery_filter_date_dropdown) {
      dispatch(
        briefGalleryList.updateListFilter({
          show_gallery_filter_date_dropdown: false,
        }),
      );
      setFiltersWereTouched(true);
      onShowDateFilter();
    }

    dispatch(briefGalleryList.invalidateList());

    dispatch(briefGalleryList.trySetFilter(constructedFilter));
    dispatch(galleryEventList.trySetFilter(constructedFilter));
    dispatch(albumListForEventTab.updateListFilter(constructedFilter));
    dispatch(
      briefGalleryList.updatePaginationNumItemsPerPage(
        hasDateFilterBeenTouched
          ? NUM_PHOTOS_PER_GALLERY_PAGE_AFTER_SEARCHING
          : NUM_PHOTOS_PER_GALLERY_PAGE_WHEN_SHOWING_SHOW_MORE_BUTTON,
      ),
    );
    dispatch(
      briefGalleryList.fetchListIfNeeded({ callSource: "SubEventFilter" }),
    );
    dispatch(galleryEventList.invalidateList());
    dispatch(
      galleryEventList.updatePaginationNumItemsPerPage(
        hasDateFilterBeenTouched
          ? NUM_PHOTOS_PER_GALLERY_PAGE_AFTER_SEARCHING
          : NUM_PHOTOS_PER_GALLERY_PAGE_WHEN_SHOWING_SHOW_MORE_BUTTON,
      ),
    );
    dispatch(galleryEventList.fetchListIfNeeded());
    dispatch(albumListForEventTab.fetchListIfNeeded());
  }, [
    dateFrom,
    dateTo,
    show_gallery_filter_date_dropdown,
    sportDropFilter,
    calenDropFilter,
    galleryEventDropFilter,
    photoDropFilter,
    show_gallery_filter_date_dropdown,
    hasDateFilterBeenTouched,
  ]);

  useEffect(() => {
    logger.log("publicUrlPathForGalleryEvent");
    if (publicUrlPathForGalleryEvent) {
      setAnyFieldFilter("");
      navigate(
        `/customer/events/${formatSessionNameForURL(
          publicUrlPathForGalleryEvent,
        )}`,
      );
      dispatch(
        galleryEventDirectAccessUrl.isDirectAccessUrlValid(
          publicUrlPathForGalleryEvent,
        ),
      );
    }
  }, [publicUrlPathForGalleryEvent]);

  useEffect(() => {
    logger.log("hasDirectUrlAccess");
    if (hasDirectUrlAccess) {
      galleryEventDirectAccessUrl.setPasswordBypassForGalleryEvent(
        hasDirectUrlAccess,
      );
    }
  }, [hasDirectUrlAccess]);

  useEffect(() => {
    logger.log("gallery_event_name");
    if (gallery_event_name || publicUrlPathForGalleryEvent) {
      setAnyFieldFilter("");
      setGalleryEventDropFilter(
        gallery_event_name || publicUrlPathForGalleryEvent,
      );
    }
  }, [gallery_event_name]);

  useEffect(() => {
    logger.log("photographer");
    if (photographer) {
      setAnyFieldFilter("");
      setPhotoDropFilter(photographer);
      dispatch(
        galleryEventList.updateListFilter({
          photographers: photographer,
        }),
      );
    }
  }, [photographer]);

  useEffect(() => {
    logger.log("anyFieldFilter, gallery_event_name");
    if (gallery_event_name) {
      dispatch(galleryEventList.trySetFilter({ any_field: "" }));
      dispatch(
        briefGalleryList.updateListFilter({
          any_field: anyFieldFilter,
        }),
      );
      dispatch(
        briefGalleryList.fetchListIfNeeded({ callSource: "SubEventFilter" }),
      );
    } else {
      dispatch(galleryEventList.trySetFilter({ any_field: anyFieldFilter }));
    }
    dispatch(
      albumListForEventTab.updateListFilter({
        session_name: anyFieldFilter,
        photographers: [photoDropFilter],
      }),
    );
    dispatch(
      albumListForEventTab.fetchListIfNeeded({ callSource: "SubEventFilter" }),
    );
  }, [anyFieldFilter, gallery_event_name]);

  useEffect(() => {
    logger.log("setCalenDropFilter");
    const gallery_event_not_selected_manually = !gallery_event_name;

    if (gallery_event_filter && gallery_event_not_selected_manually) {
      //navigate(`/customer/events/${gallery_event_filter}`);
    }
    if (calendar_filter) {
      setCalenDropFilter(calendar_filter);
    }
  }, []);

  const onGalleryEventFilterChanged = (e) => {
    const gallery_event_name = e.target.value;
    setGalleryEventDropFilter(gallery_event_name);
    setGalleryEventDrop(false);

    if (gallery_event_name || publicUrlPathForGalleryEvent) {
      navigate(
        `/customer/events/${formatSessionNameForURL(
          gallery_event_name || publicUrlPathForGalleryEvent,
        )}`,
      );
    } else {
      dispatch(briefGalleryList.updateListFilter({ gallery_event: null }));
      navigate("/customer/events/");
    }
  };

  useEffect(() => {
    switch (calenDropFilter) {
      case null:
        setDateFrom(null);
        setDateTo(null);
        break;
      case "last_24_hrs":
        setDateFrom(moment());
        setDateTo(moment());
        break;
      case "last_2_days":
        setDateFrom(moment().subtract(2, "days"));
        setDateTo(moment());
        break;
      case "last_7_days":
        setDateFrom(moment().subtract(7, "days"));
        setDateTo(moment());
        break;
      case "last_30_days":
        setDateFrom(moment().subtract(31, "days"));
        setDateTo(moment());
        break;
      case "last_90_days":
        setDateFrom(moment().subtract(90, "days"));
        setDateTo(moment());
        break;
      case "last_180_days":
        setDateFrom(moment().subtract(180, "days"));
        setDateTo(moment());
        break;
      case "last_365_days":
        setDateFrom(moment().subtract(365, "days"));
        setDateTo(moment());
        break;
      case "last_2_years":
        setDateFrom(moment().subtract(2, "years"));
        setDateTo(moment());
        break;
      case "last_3_years":
        setDateFrom(moment().subtract(3, "years"));
        setDateTo(moment());
        break;
      case "custom":
        // do nothing, wait for a manual date to be set
        break;
      default:
        if (calenDropFilter !== get(first(DATE_FILTER_OPTIONS), "value")) {
          console.error(`Unknown date filter option: ${calenDropFilter}`);
        }
        setDateFrom(null);
        setDateTo(null);
        break;
    }
    dispatch(
      briefGalleryList.updateListFilter({ calendar_filter: calenDropFilter }),
    );
  }, [calenDropFilter]);

  const onDateFilterChanged = (e) => {
    const option = e.target.value;
    setCalenDropFilter(option);
    if (option === "custom") {
      setShowCustomDateFilter(true);
    }
    setshowCalendarDropdown(false);
  };

  const onCustomDateFilterDoneClicked = () => {
    setShowCustomDateFilter(false);
  };

  const onPhotographerFilterChanged = (e) => {
    setPhotoDropFilter(e.target.value);
    setPhotographDrop(false);
    setPhotographerFilterTouched(true);
    dispatch(
      briefGalleryList.updateListFilter({ photographers: [e.target.value] }),
    );
    dispatch(
      galleryEventList.updateListFilter({
        photographers: [e.target.value],
      }),
    );
    dispatch(
      albumListForEventTab.updateListFilter({
        photographers: [e.target.value],
      }),
    );
  };

  const onSportFilterChanged = (e) => {
    setSportDropFilter(e.target.value);
    setSportDrop(false);
    dispatch(
      briefGalleryList.updateListFilter({ activity_tags: [e.target.value] }),
    );
    dispatch(
      galleryEventList.updateListFilter({
        activity_tags: [e.target.value],
      }),
    );
    dispatch(
      albumListForEventTab.updateListFilter({
        activity_tags: [e.target.value],
      }),
    );
  };

  const onShowGalleryEventFilter = () => {
    setGalleryEventDrop((prevState) => !prevState);
    setSearchDrop(false);
    setPhotographDrop(false);
  };

  const onShowGeneralSearch = () => {
    setSearchDrop((prevState) => !prevState);
    setshowCalendarDropdown(false);
    setSportDrop(false);
    setPhotographDrop(false);
  };

  const onShowDateFilter = () => {
    setshowCalendarDropdown((prevState) => !prevState);
    setSearchDrop(false);
    setSportDrop(false);
    setPhotographDrop(false);
    setDateFilterTouched(true);
  };

  const onShowSportFilter = () => {
    setSportDrop((prevState) => !prevState);
    setSearchDrop(false);
    setPhotographDrop(false);
    setGalleryEventDrop(false);
  };

  const onShowPhotographerFilter = () => {
    setPhotographDrop((prevState) => !prevState);
    setSearchDrop(false);
    setSportDrop(false);
    setGalleryEventDrop(false);
  };

  const onAnyFieldFilterChanged = (event) => {
    setAnyFieldFilter(event.target.value);
  };

  const renderGalleryEventDropFilter = () => {
    return (
      <div
        onClick={onShowGalleryEventFilter}
        className={`${classes.dropFilterGeneral}
           ${classes.galleryEvent}
           ${galleryEventDropFilter ? classes.dropFilter : ""}
           ${
             galleryEventDropFilter && photoDropFilter
               ? classes.dropFilterTop
               : ""
           }`}
      >
        <DropFilterText
          icon="fas fa-trophy"
          default_text="Event/School"
          text={galleryEventDropFilter}
        />
      </div>
    );
  };

  const renderDateDropFilter = () => {
    const firstDateFilterOption = get(head(DATE_FILTER_OPTIONS), "value");
    const conditionsToShowDateFilterIsSelected =
      calenDropFilter &&
      dateFilterTouched &&
      calenDropFilter !== firstDateFilterOption;

    return (
      <div
        className={`${classes.dropFilterGeneral}
           ${classes.dropFilterRight}
           ${classes.calendar}
           ${conditionsToShowDateFilterIsSelected ? classes.dropFilter : ""}`}
        onClick={onShowDateFilter}
      >
        <DropFilterText
          icon={"fas fa-calendar-week"}
          text={get(
            head(
              filter(
                DATE_FILTER_OPTIONS,
                (option) => option.value === calenDropFilter,
              ),
            ),
            "label",
          )}
        />
      </div>
    );
  };

  const renderSportDropFilter = () => {
    return (
      <div
        onClick={onShowSportFilter}
        className={`${classes.dropFilterGeneral}
           ${classes.sport}
           ${sportDropFilter ? classes.dropFilter : ""}
           ${sportDropFilter && photoDropFilter ? classes.dropFilterTop : ""}`}
      >
        <DropFilterText
          icon="fas fa-running"
          default_text="Activity"
          text={get(
            activityTagsDropdownOptions.getObject(sportDropFilter),
            "name",
          )}
        />
      </div>
    );
  };

  const renderPhotographerDropFilter = () => {
    const photographerInUrlAndPhotograperFilterNotTouched =
      photographer && !photographerFilterTouched;
    return (
      <div
        onClick={onShowPhotographerFilter}
        className={`${classes.dropFilterGeneral}
           ${classes.dropFilterRight}
           ${classes.photo}
           ${photoDropFilter ? classes.dropFilter : ""}
           ${sportDropFilter && photoDropFilter ? classes.dropFilterTop : ""}`}
      >
        <DropFilterText
          icon="fas fa-camera"
          default_text={
            photographerInUrlAndPhotograperFilterNotTouched
              ? photographer
              : "Photographer"
          }
          text={get(
            photographerDropdownOptions.getObject(photoDropFilter),
            "name",
          )}
        />
      </div>
    );
  };

  const renderMobileIframeEventFilter = () => {
    return (
      <div>
        <Stack
          height="50px"
          direction="row"
          alignItems="start"
          justifyContent="space-around"
        >
          <Stack
            direction="column"
            alignItems="flex-start"
            justifyContent="space-evenly"
            className={classes.mobileEventFilterTop}
          >
            {sessionStorageIsPhotographerIframe &&
              renderGalleryEventDropFilter()}
            {renderSportDropFilter()}
          </Stack>
          <Stack
            direction="column"
            alignItems="flex-start"
            justifyContent="space-evenly"
            className={classes.mobileEventFilterBottom}
          >
            {renderDateDropFilter()}
            {!sessionStorageIsPhotographerIframe &&
              renderPhotographerDropFilter()}
          </Stack>
        </Stack>
      </div>
    );
  };

  const renderMobileEventFilter = () => {
    return (
      <Box className={classes.mobileEventFilterWrapper}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-evenly"
          className={classes.mobileEventFilterTop}
        >
          {isInIframe && (
            <h6 className={classes.filterHeading}>Photo Galleries</h6>
          )}
          {renderGalleryEventDropFilter()}
          {renderDateDropFilter()}
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-evenly"
          className={classes.mobileEventFilterBottom}
        >
          {renderSportDropFilter()}
          {renderPhotographerDropFilter()}
        </Stack>
      </Box>
    );
  };

  const renderDesktopEventFilter = () => {
    return (
      <div classNames={[classes.subRoot, isInIframe && classes.iFrameSubRoot]}>
        <div className={classes.root}>
          <Stack
            spacing={5}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className={classes.desktopEventFilterWrapper}
          >
            <div className={classes.photoGalleriesTextWrapper}>
              {!isInIframe && (
                <EventPageBreadCrumbs
                  album_name={album_name}
                  gallery_event_name={gallery_event_name}
                  galleryEventNameForURL={galleryEventNameForURL}
                />
              )}
              {isInIframe && (
                <h6 className={classes.filterHeading}>Photo Galleries</h6>
              )}
            </div>
            <Stack
              spacing={2}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              className={classes.filterWrapper}
            >
              <div>
                <h6 className={classes.filterHeading}>Filters</h6>
              </div>
              {(!isInIframe || sessionStorageIsPhotographerIframe) &&
                renderGalleryEventDropFilter()}
              {renderDateDropFilter()}
              {renderSportDropFilter()}
              {!sessionStorageIsPhotographerIframe &&
                renderPhotographerDropFilter()}
            </Stack>
            <div>
              <SearchGalleryNameFilter
                value={anyFieldFilter}
                handleChange={onAnyFieldFilterChanged}
              />
            </div>
          </Stack>
        </div>
      </div>
    );
  };

  const renderDropFilters = () => {
    return (
      <div className={classes.dropDownCard}>
        {searchDrop && (
          <div className={classes.drop1}>
            <Card className={classes.wideCard}>
              <div className={classes.searchDrop}>
                <i className="fas fa-search"></i>
                <input
                  type="text"
                  value={anyFieldFilter}
                  onChange={onAnyFieldFilterChanged}
                />
                <Button
                  className={classes.submitBtn}
                  onClick={() => {
                    setSearchDrop(false);
                  }}
                >
                  Done
                </Button>
              </div>
            </Card>
          </div>
        )}
        {galleryEventDrop && (
          <div className={classes.drop1}>
            <Card className={classes.card}>
              <FormControl sx={{ m: 1, minWidth: 80 }}>
                <InputLabel className={classes.inputLabel}>
                  <i className="fas fa-running"></i>
                  {get(galleryEventDropFilter, "label") || "Event/School"}
                </InputLabel>
                <Select
                  label={get(
                    galleryEventDropdownOptions.getObject(
                      galleryEventDropFilter,
                    ),
                    "name",
                    "Event/School",
                  )}
                  inputProps={{ "aria-label": "Without label" }}
                  className={classes.select}
                  onChange={onGalleryEventFilterChanged}
                  open={galleryEventDrop}
                >
                  <MenuItem value={null} className={classes.menuItem}>
                    All
                  </MenuItem>
                  {map(galleryEventOptions, (option, index) => (
                    <MenuItem
                      key={index}
                      value={option.label}
                      className={classes.menuItem}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Card>
          </div>
        )}
        {showCalendarDropdown && (
          <div className={classes.drop3}>
            <CalendarFilter
              onDateFilterChanged={onDateFilterChanged}
              showCalendarDropdown={showCalendarDropdown}
            />
          </div>
        )}
        {showCustomDateFilter === true && calenDropFilter === "custom" && (
          <CustomCalendarFilter
            dateTo={dateTo}
            dateFrom={dateFrom}
            setDateTo={setDateTo}
            setDateFrom={setDateFrom}
            onCustomDateFilterDoneClicked={onCustomDateFilterDoneClicked}
          />
        )}
        {sportDrop && (
          <div className={classes.drop4}>
            <Card className={classes.card}>
              <FormControl sx={{ m: 1, minWidth: 80 }}>
                <InputLabel className={classes.inputLabel}>
                  <i className="fas fa-running"></i>
                  {get(
                    activityTagsDropdownOptions.getObject(sportDropFilter),
                    "name",
                  )}
                </InputLabel>
                <Select
                  label={get(
                    activityTagsDropdownOptions.getObject(sportDropFilter),
                    "name",
                    "Activity",
                  )}
                  inputProps={{ "aria-label": "Without label" }}
                  className={classes.select}
                  onChange={onSportFilterChanged}
                  open={sportDrop}
                >
                  <MenuItem value={null} className={classes.menuItem}>
                    All
                  </MenuItem>
                  {map(activityTagOptions, (option, index) => (
                    <MenuItem
                      key={index}
                      value={option.value}
                      className={classes.menuItem}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Card>
          </div>
        )}
        {photographDrop && (
          <div className={classes.drop5}>
            ph{" "}
            <Card className={classes.card}>
              <FormControl sx={{ m: 1, minWidth: 80 }}>
                <InputLabel className={classes.inputLabel}>
                  <i className="fas fa-camera"></i>
                  Photographer
                </InputLabel>
                <Select
                  label="Photograph"
                  open={photographDrop}
                  inputProps={{ "aria-label": "Without label" }}
                  className={classes.select}
                  onChange={onPhotographerFilterChanged}
                >
                  <MenuItem value={null} className={classes.menuItem}>
                    All
                  </MenuItem>
                  {map(photographerOptions, (option, index) => (
                    <MenuItem
                      key={index}
                      value={option.value}
                      className={classes.menuItem}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Card>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      {renderDropFilters()}
      {!isMobile && renderDesktopEventFilter()}
      {isMobile && !isInIframe && renderMobileEventFilter()}
      {isMobile && isInIframe && renderMobileIframeEventFilter()}
    </>
  );
}

export default SubEventFilter;

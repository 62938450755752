import React from "react";
import styles from "./HeaderTitle.module.css";

type HeaderTitleProps = Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  "className"
>;

/**
 * HeaderTitle
 * @returns
 */
const HeaderTitle = ({ children, ...props }: HeaderTitleProps) => {
  return (
    <div {...props} className={styles.headertitle}>
      {children}
    </div>
  );
};

export default HeaderTitle;

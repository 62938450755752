import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { getGoogleInitArgs } from "../v2_actions/map";
import { useJsApiLoader, StandaloneSearchBox } from "@react-google-maps/api";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "80%",
    left: "50px",
    position: "absolute",
    margin: "auto",
    textAlign: "center",
    top: "10px",
  },
  input: {
    boxSizing: `border-box`,
    width: `100%`,
    height: `40px`,
    padding: `0 12px`,
    fontSize: `14px`,
    outline: `none`,
    textOverflow: `ellipses`,
    borderRadius: 20,
    zIndex: 9900,
  },
}));

export const AddressSearch = ({ onChanged }) => {
  const classes = useStyles();

  const [searchBox, setSearchBox] = useState(null);

  const onLoad = setSearchBox;
  const { isLoaded } = useJsApiLoader(getGoogleInitArgs());

  const onPlacesChanged = () => {
    const place = searchBox.getPlaces();
    onChanged(place);
  };

  return (
    <>
      {isLoaded && (
        <StandaloneSearchBox onLoad={onLoad} onPlacesChanged={onPlacesChanged}>
          <div className={classes.container}>
            <input type="text" placeholder="Search" className={classes.input} />
          </div>
        </StandaloneSearchBox>
      )}
    </>
  );
};

import map from "lodash/map";
import get from "lodash/get";
import React from "react";
import { makeStyles, FormLabel, TextField } from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";

const useStyles = makeStyles({
  error: {
    color: "red",
  },
  formLabel: {
    color: "#0c59cf",
    fontSize: "20px",
  },
  dropdown: {
    width: "100%",
  },
});

export const MaterialDropdown = ({
  name,
  size = "medium",
  field_label,
  option_label = "",
  options,
  variant = "standard",
  placeholder = "",
  form,
  enabled,
  value,
  additionalClasses,
  ...props
}) => {
  const {
    formState: { errors },
  } = form;
  const field_errors = get(errors, name);
  const classes = useStyles();

  return (
    <>
      {field_label && (
        <FormLabel className={classes.formLabel}>{field_label}</FormLabel>
      )}

      <TextField
        select
        disabled={enabled === false}
        variant={variant}
        label={get(props, "label", placeholder)}
        className={[classes.dropdown, additionalClasses]}
        InputLabelProps={{ shrink: Boolean(value) }}
        value={value || null}
        {...props}
      >
        {map(options, (option, index) => (
          <MenuItem value={option.value} key={index}>
            <span>{option.label}</span>
          </MenuItem>
        ))}
      </TextField>
      {field_errors && <p className={classes.error}>{field_errors.message}</p>}
    </>
  );
};

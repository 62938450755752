import * as React from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import CardMedia from "@mui/material/CardMedia";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import aerialMap from "../assets/images/aerial_map_image.jpg";

export default function DesktopEventPageMapButton() {
  const navigate = useNavigate();
  return (
    <Box sx={{ padding: "15px 0px 0px", width: "100%" }}>
      <CardMedia sx={{ height: 100 }} image={aerialMap} />
      <CardActions sx={{ height: 0 }}>
        <Button
          onClick={() => navigate("/customer/map/")}
          variant="contained"
          size="small"
          sx={{
            fontWeight: "bold",
            display: "block",
            margin: "auto",
            position: "relative",
            bottom: "75px",
          }}
        >
          Search on map
        </Button>
      </CardActions>
    </Box>
  );
}
